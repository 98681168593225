export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0";

  const units = ["Bytes", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  if (unitIndex === 0) {
    return `${size} ${units[unitIndex]}`;
  } else if (unitIndex === 1 && size < 50) {
    return `${parseFloat(size.toFixed(0))} ${units[unitIndex]}`;
  } else {
    return `${parseFloat(size.toFixed(2))} ${units[unitIndex]}`;
  }
};

export const formatFileSize2 = (bytes) => {
  if (bytes === 0) return { value: 0, unit: "KB" };

  const units = ["KB", "MB", "GB"];
  let size = bytes / 1024;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return {
    value: parseFloat(size.toFixed(2)),
    unit: units[unitIndex],
  };
};

export const convertBytes = (bytes, unit) => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  const index = units.indexOf(unit);
  return bytes / Math.pow(1024, index);
};
