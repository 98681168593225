import { lazy } from "react";
// import { STRINGS } from "../utils/base";
import { ROUTES } from "../utils/routes";

export const routes = {
  Public: [
    {
      path: ROUTES.AUTH.SIGN_IN,
      component: lazy(() => import("../main/features/auth/view")),
    },
    {
      path: ROUTES.EXTERNAL_PROJECT.REGISTER,
      component: lazy(() =>
        import("../main/features/publicRoutes/projects/view")
      ),
    },
    {
      path: ROUTES.EXTERNAL_PROJECT_SCHEDULER.REGISTER,
      component: lazy(() =>
        import("../main/features/publicRoutes/Schedule/Scheduler")
      ),
    },
    {
      path: ROUTES.EXTERNAL_SCHEDULE_CONFRIMATION.REGISTER,
      component: lazy(() =>
        import("../main/features/publicRoutes/Schedule/ScheduleConfirmation")
      ),
    },
    {
      path: ROUTES.EMPLOYEES.PREEMLOYEEMENT,
      component: lazy(() =>
        import(
          "../main/features/employee/view/PreEmployementForm/preEmployeeForm"
        )
      ),
    },
    {
      path: ROUTES.AUTH.ADDCARD_PAYMENT,
      component: lazy(() =>
        import(
          "../main/features/payment/view/mainComponent.js"
        )
      ),
    },
    {
      path: ROUTES.PAYMENT.PAY_BILL_DEDICATED_PAGE,
      component: lazy(() =>
        import("../main/features/payment/view/PayBill/payBillDedicatedPage.js")
      ),
    },
  ],

  // Private Route Here
  Private: [
    {
      path: `${ROUTES.UNAUTHORIZED.DEFAULT}`,
      component: lazy(() => import("../main/sharedComponents/unauthorized/Pages/unauthorizedPage")),
    },
    {
      path: `${ROUTES.USER.DEFAULT}:id/*`,
      component: lazy(() => import("../main/features/profile/Profile")),
    },
    {
      path: `${ROUTES.SEARCH.DEFAULT}`,
      component: lazy(() => import("../main/features/search/view/index")),
    },
    {
      path: `${ROUTES.SETTINGS.DEFAULT}`,
      component: lazy(() => import("../main/features/settings")),
    },
    {
      path: ROUTES.ROOT,
      component: lazy(() => import("../main/features/feed/ui/index")),
    },
    {
      path: ROUTES.NEWSFEED.DETAILS,
      component: lazy(() => import("../main/features/feed/ui/postDetail")),
    },
    {
      path: `${ROUTES.MESSENGER.DEFAULT}/*`,
      component: lazy(() => import("../main/features/Messenger/routes/index")),
    },
    {
      path: ROUTES.SCHEDULES.DEFAULT,
      component: lazy(() => import("../main/features/schedule/routes/")),
    },
    {
      path: `${ROUTES.MAIL.DEFAULT}`,
      component: lazy(() => import("../main/features/mail/")),
    },
    {
      path: `${ROUTES.CUSTOM_APPROVALS.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/CustomApprovals/view/index")
      ),
    },

    {
      path: `${ROUTES.CUSTOM_APPROVALS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/CustomApprovals/view/customApprovalsDetailsCards/CustomApprovalsDetailsPopupCard"
        )
      ),
    },
    {
      path: `${ROUTES.WARNINGS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/warning/view/Page/Detail/warningIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.TRAVEL.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/travel/view/Page/Detail/travelIdDetailCard.js"
        )
      ),
    },
    // {
    //   path: `${ROUTES.TRAVEL.AGENTDETAILVIAEMAIL}`,
    //   component: lazy(() =>
    //     import(
    //       "../main/features/travel/view/travelDetailCard/travelDetailCard"
    //     )
    //   ),
    // },
    {
      path: `${ROUTES.APPRAISALS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/appraisalModule/view/Page/Detail/appraisalIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.PROMOTION.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/promotion/view/Page/Detail/promotionIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.REWARDS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/reward/view/Page/Detail/rewardIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.SALARY.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/salary/view/Page/Detail/salaryIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.EXPENSES.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/expense/view/Page/Detail/expenseIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.COMPLAINS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/complain/view/Page/Detail/complainIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.LOAN.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/loan/view/Page/Detail/loanIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.BONUS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/bonus/view/Page/Detail/bonusIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.PAYROLL.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/payroll/view/Page/Detail/payrollIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.CAREER.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/careers/view/Page/Detail/careerIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.REQUISITIONS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/requisition/view/requisitionIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.ASSETS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/assets/view/assetsIdDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.FORMS.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/forms/view/Page/Detail/formDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.RESIGNATION.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/resignation/view/Page/Detail/resignationDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.ELearning.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/eLearning/view/Detail/elearningDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.ELearning.DETAILVIAEMAILQuiz}`,
      component: lazy(() =>
        import(
          "../main/features/eLearning/view/Detail/elearningQuizDetailCard.js"
        )
      ),
    },
    {
      path: `${ROUTES.LEAVES.DETAILVIAEMAIL}`,
      component: lazy(() =>
        import(
          "../main/features/leave/view/Page/leaveDetailCard/leaveIdDetailCard.js"
        )
      ),
    },

    // {
    //   path: ROUTES.EXTERNAL_PROJECT.DETAILVIAEMAIL,
    //   component: lazy(() =>
    //     import("../main/features/publicRoutes/projects/view")
    //   ),
    // },
    {
      path: `${ROUTES.BONUS.DEFAULT}`,
      component: lazy(() => import("../main/features/bonus/view/index")),
    },

    {
      path: `${ROUTES.TASK.DEFAULT}`,
      component: lazy(() => import("../main/features/task/routes/index")),
    },
    {
      path: `${ROUTES.CONSTRACT.DEFAULT}`,
      component: lazy(() => import("../main/features/contracts/view/index")),
    },

    {
      path: `${ROUTES.FINANCE.VOUCHER.DEFAULT}`,
      component: lazy(() => import("../main/features/createVoucher/view/index")),
    },

    {
      path: `${ROUTES.FINANCE.VOUCHERLIST.DEFAULT}`,
      component: lazy(() => import("../main/features/voucher/view/index")),
    },
    {
      path: `${ROUTES.FORMS.DEFAULT}`,
      component: lazy(() => import("../main/features/forms/view/index")),
    },
    // {
    // 		path: `${ROUTES.FORMS.SUBMIT_FORM}`,
    // 		component: lazy(() => import("../main/features/forms/view/forms/SubmitForm/index.js")),
    // 	},
    {
      path: `${ROUTES.FINANCE.TRANSACTION.DEFAULT}`,
      component: lazy(() => import("../main/features/transaction/view/index")),
    },
    {
      path: `${ROUTES.ASSETS.DEFAULT}`,
      component: lazy(() => import("../main/features/assets/view/index")),
    },
    {
      path: `${ROUTES.CREATE_ASSETS.DEFAULT}/*`,
      component: lazy(() =>
        import("../main/features/createAssets/routes/index")
      ),
    },
    {
      path: `${ROUTES.ASSETS_TABLE_LIST.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/assetsTableList/routes/index")
      ),
    },
    {
      path: `${ROUTES.REQUEST_LIST_ITEM.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/RequestListItems/view/index")
      ),
    },
    {
      path: `${ROUTES.FORMS.SUBMIT_FORM}`,
      component: lazy(() =>
        import("../main/features/forms/view/forms/SubmitForm/index.js")
      ),
    },
    {
      path: `${ROUTES.FINANCE.CHART_OF_ACCOUNT.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/chartOfAccount/view/index")
      ),
    },
    {
      path: `${ROUTES.FINANCE.TRANSACTION.DEFAULT}`,
      component: lazy(() => import("../main/features/transaction/view/index")),
    },
    {
      path: `${ROUTES.FINANCE.REPORT.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/financeReport/view/index")
      ),
    },
    {
      path: `${ROUTES.FINANCE.BALANCE.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/trialBalance/view/index")
      ),
    },
    {
      path: ROUTES.APPROVALS.DEFAULT,
      component: lazy(() =>
        import("../main/features/approval/view/AllApprovals/index")
      ),
    },
    {
      path: ROUTES.APPROVALS.MY_APPROVALS,
      component: lazy(() =>
        import("../main/features/approval/view/AllApprovals/index")
      ),
    },
    {
      path: `${ROUTES.BUSINESS_POLICY.DEFAULT}/*`,
      component: lazy(() =>
        import("../main/features/businessPolicy/routes/index")
      ),
    },
    {
      path: ROUTES.MARKETPLACE.DEFAULT,
      component: lazy(() => import("../main/features/marketPlace/routes/index")),
    },
    {
      path: ROUTES.AUCTION.DEFAULT,
      component: lazy(() => import("../main/features/auction/view/")),
    },

    {
      path: `${ROUTES.DISCUSSIONBOARD.ROOT}`,
      component: lazy(() =>
        import("../main/features/discussionBoard/routes/index.js")
      ),
    },
    {
      path: `${ROUTES.PAGES.ROOT}`,
      component: lazy(() => import("../main/features/pages/routes/index")),
    },
    // {
    //   path: ROUTES.MARKETPLACE.REQUISITION,
    //   component: lazy(() =>
    //     import("../main/features/marketPlace/requisition/index.js")
    //   ),
    // },
    // {
    //   path: ROUTES.MARKETPLACE.AUCTION,
    //   component: lazy(() =>
    //     import("../main/features/marketPlace/auction/marketplaceAuctionList.js")
    //   ),
    // },
    {
      path: `${ROUTES.TRAVEL.DEFAULT}`,
      component: lazy(() => import("../main/features/travel/routes/index")),
    },
    {
      path: `${ROUTES.TRAVEL.DETAIL}`,
      component: lazy(() =>
        import(
          "../main/features/travel/view/Page/Detail/DetailItem/travelDetail"
        )
      ),
    },
    {
      path: `${ROUTES.ELearning.DEFAULT}`,
      component: lazy(() => import("../main/features/eLearning/routes/index.js")),
      // ../ main / features / eLearning / routes / index.js
    },
    // {
    // 	path: `${ROUTES.ELearning.DEFAULT + " courses/:id"}`,
    // 	component: lazy(() =>
    // 		import(
    // 			"../main/features/eLearning/view/Detail/CourseDetail/CoursesDetail"
    // 		)
    // 	),
    // },

    // {
    //   path: `${ROUTES.CAREER.JOB_BY_ID}`,
    //   component: lazy(() =>
    //     import("../main/features/careers/view/DetailView/index")
    //   ),
    // },
    {
      path: `${ROUTES.EMPLOYEES.DEFAULT}`,
      component: lazy(() => import("../main/features/employee/")),
    },
    {
      path: `${ROUTES.EMPLOYEES.EMPLOYEEDETAIL}`,
      component: lazy(() => import("../main/features/employee/view/updateEmployee/EmployeePanel/")),
    },
    {
      path: `${ROUTES.EMPLOYEES.PREEMLOYEEMENT}`,
      component: lazy(() =>
        import(
          "../main/features/employee/view/PreEmployementForm/preEmployeeForm"
        )
      ),
    },
    {
      path: `${ROUTES.PROJECT.DEFAULT}/*`,
      component: lazy(() => import("../main/features/projects/routes/index")),
    },
    {
      path: `${ROUTES.PROJECT.WIP}`,
      component: lazy(() => import("../main/features/wip/view/page/index")),
    },
    // {
    //   path: `${ROUTES.PROJECT.DEFAULT}/:projectId`,
    //   component: lazy(() =>
    //     import("../main/features/projects/ProjectDetails/ProjectDetails")
    //   ),
    // },
    {
      path: `${ROUTES.GROUP.DEFAULT}/*`,
      component: lazy(() => import("../main/features/groups/routes/index")),
    },
    // {
    //   path: `${ROUTES.GROUP.DEFAULT}/:groupId`,
    //   component: lazy(() =>
    //     import("../main/features/groups/view/GroupDetails/GroupDetails")
    //   ),
    // },
    {
      path: `${ROUTES.WORKBOARD.DEFAULT}/*`,
      component: lazy(() => import("../main/features/workboard/routes/index")),
    },
    // {
    //   path: `${ROUTES.WORKBOARD.TODO_BOARD}`,
    //   component: lazy(() => import("../main/features/workboard/Trello/Board")),
    // },
    {
      path: `${ROUTES.LEAD_MANAGER.DEFAULT}/*`,
      component: lazy(() => import("../main/features/leadmanager/routes/index")),
    },
    // {
    //   path: `${ROUTES.LEAD_MANAGER.LEAD_DETAIL}`,
    //   component: lazy(() =>
    //     import("../main/features/leadmanager/view/Board/BoardViews")
    //   ),
    // },
    // {
    //   path: `${ROUTES.LEAD_MANAGER.LEAD_SECTION_DETAIL}`,
    //   component: lazy(() =>
    //     import("../main/features/leadmanager/view/SectionDetailPage.js")
    //   ),
    // },
    // {
    //   path: `${ROUTES.LEAD_MANAGER.LEAD_DETAIL_DEDICATED_PAGE}`,
    //   component: lazy(() =>
    //     import("../main/features/leadmanager/view/Board/sectionDetailDedicatedPageWrapper")
    //   ),
    // },
    {
      path: `${ROUTES.WARNINGS.DEFAULT}`,
      component: lazy(() => import("../main/features/warning/routes/index")),
    },
    // {
    //   path: `${ROUTES.HR.WARNINGS.APPROVALS}`,
    //   component: lazy(() => import("../components/HrMenu/Warnings/Approvals/")),
    // },
    {
      path: `${ROUTES.COMPLAINS.DEFAULT}`,
      component: lazy(() => import("../main/features/complain/routes/index")),
    },
    // {
    //   path: `${ROUTES.HR.BONUS.DEFAULT}`,
    //   component: lazy(() => import("../main/features/bonus/view/Bonus")),
    // },
    {
      path: `${ROUTES.HR.OrganizationalChart.DEFAULT}`,
      component: lazy(() => import("../main/features/orgchart/routes/index")),
    },
    {
      path: `${ROUTES.APPRAISALS.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/appraisalModule/routes/index")
      ),
    },

    {
      path: `${ROUTES.PROMOTION.DEFAULT}`,
      component: lazy(() => import("../main/features/promotion/view/index")),
    },
    {
      path: `${ROUTES.JOBS.DEFAULT}`,
      component: lazy(() => import("../main/features/jobs/view/index")),
    },
    {
      path: `${ROUTES.REQUEST_LIST_ITEM.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/RequestListItems/view/index")
      ),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.DEFAULT}`,
      component: lazy(() => import("../main/features/administration")),
    },
    {
      path: `${ROUTES.REWARDS.DEFAULT}`,
      component: lazy(() => import("../main/features/reward/view/index")),
    },
    //*****by SANJANA****
    {
      path: `${ROUTES.TEAMS.DEFAULT}`,
      component: lazy(() => import("../main/features/team/routes/teamRoutes")),
    },

    {
      path: `${ROUTES.LEAVES.DEFAULT}`,
      component: lazy(() => import("../main/features/leave/view/index")),
    },
    {
      path: `${ROUTES.DEPARTMENTS.DEFAULT}`,
      component: lazy(() => import("../main/features/departments/routes/index")),
    },
    {
      path: `${ROUTES.DEPARTMENTS.DETAIL}`,
      component: lazy(() =>
        import(
          "../main/features/departments/DepartmentDetails/DeptartmentDetails"
        )
      ),
    },
    {
      path: `${ROUTES.CAREER.DEFAULT}`,
      component: lazy(() => import("../main/features/careers/view/")),
    },
    // {
    //   path: `${ROUTES.CAREER.JOB_DETAIL}`,
    //   component: lazy(() =>
    //     import("../main/features/careers/view/DetailView/index")
    //   ),
    // },
    {
      path: `${ROUTES.EXPENSES.DEFAULT}`,
      component: lazy(() => import("../main/features/expense/routes/index")),
    },
    {
      path: `${ROUTES.DOCUMENTS.DEFAULT}`,
      component: lazy(() => import("../main/features/documents/view/index")),
    },
    {
      path: `${ROUTES.LOAN.DEFAULT}`,
      component: lazy(() => import("../main/features/loan/view/index")),
    },
    {
      path: `${ROUTES.RESIGNATION.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/resignation/routes/index")
      ),
    },
    {
      path: `/attendance/*`,
      component: lazy(() =>
        import("../main/features/attendance/routes/index")
      ),
    },
    {
      path: `${ROUTES.REQUISITION.DEFAULT}`,
      component: lazy(() =>
        import("../main/features/requisition/view/requisition")
      ),
    },
    {
      path: `${ROUTES.REQUISITION.DETAIL}`,
      component: lazy(() =>
        import("../main/features/requisition/view/myRequisitionDetail/index")
      ),
    },
    {
      path: `${ROUTES.SALARY.DEFAULT}`,
      component: lazy(() => import("../main/features/salary/routes/index")),
    },
    {
      path: `${ROUTES.QUOTATION.DEFAULT}`,
      component: lazy(() => import("../main/features/quotation/view/index")),
    },
    // {
    //   path: `${ROUTES.QUOTATIONCLIENT.DEFAULT}`,
    //   component: lazy(() =>
    //     import("../main/features/quotationClient/view/index")
    //   ),
    // },
    {
      path: `${ROUTES.PAYROLL.DEFAULT}`,
      component: lazy(() => import("../main/features/payroll/view/index")),
    },

    {
      path: `${ROUTES.COMPANIES.DEFAULT}`,
      component: lazy(() => import("../main/features/companies/index")),
    },
    // {
    //   path: `${ROUTES.MARKETPLACE.DEFAULT},`,
    //   component: lazy(() => {
    //     import("../main/features/marketPlace/index");
    //   }),
    // },
  ],
  AdminRoutes: [
    {
      path: `${"/hr/administrator/"},`,
      component: lazy(() => {
        import("../main/features/businessPolicy/view/BusinessPolicy");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.ACCESSROLES}`,
      component: lazy(() => {
        import("../main/features/accessRole/view/AccessRole");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.GRADE},`,
      component: lazy(() => {
        import("../main/features/grade/view/index");
      }),
    },

    {
      path: `${ROUTES.ADMINISTRATOR.ROOM},`,
      component: lazy(() => {
        import("../main/features/Room/view/index");
      }),
    },

    {
      path: `${ROUTES.ADMINISTRATOR.DEFAULT_ACCOUNT_HEADER},`,
      component: lazy(() => {
        import("../main/features/defaultAccountHeader/view/index");
      }),
    },

    {
      path: `${ROUTES.ADMINISTRATOR.ELEARNING_CATEGORY},`,
      component: lazy(() => {
        import("../main/features/eLearningCategory/view/index");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.DESIGNATION},`,
      component: lazy(() => {
        import("../main/features/designation/view/index");
      }),
    },

    {
      path: `${ROUTES.ADMINISTRATOR.APPRASIAL},`,
      component: lazy(() => {
        import("../main/features/appraisal/appraisalQuestion/view/index");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.OFFICETIME},`,
      component: lazy(() => {
        import("../main/features/officeTimings/view/index");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.LEAVE_TYPES},`,
      component: lazy(() => {
        import("../main/features/leave/leaveType/view/index");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.EXPENSE_HEADERS},`,
      component: lazy(() => {
        import("../main/features/expenseHeader/view/index");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.SALARY_HEADERS},`,
      component: lazy(() => {
        import("../main/features/salaryHeader/view/index");
      }),
    },

    {
      path: `${ROUTES.ADMINISTRATOR.EMAIL_CONFIG},`,
      component: lazy(() => {
        import("../main/features/emailConfiguration/view/index");
      }),
    },

    {
      path: `${ROUTES.ADMINISTRATOR.CUSTOM_APPROVAL_CATEGORY},`,
      component: lazy(() => {
        import("../main/features/customApprovalCategory/view");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.ALLOWANCES},`,
      component: lazy(() => {
        import("../main/features/allowance/view/index");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.JOB_SKILLS},`,
      component: lazy(() => {
        import("../main/features/jobDescription/view/index");
      }),
    },

    {
      path: `${ROUTES.ADMINISTRATOR.REWARD_CATEGORY},`,
      component: lazy(() => {
        import("../main/features/reward/rewardCategory/view");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.WARNING_CATEGORY},`,
      component: lazy(() => {
        import("../main/features/warning/warningCategory/view");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.PAYMENT},`,
      component: lazy(() => {
        import("../main/features/payment/view/PaymentCardAdminstration/index.js");
      }),
    },
    {
      path: `${ROUTES.ADMINISTRATOR.COMPANY_STATISTICS},`,
      component: lazy(() => {
        import("../main/features/companies/view/CompaniesStatsPanel/index.js");
      }),
    },



  ],
  Rewards: [],
};
