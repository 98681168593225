import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { CareerInterviewStatusEnum } from "../../../main/features/careers/utils/CareerInterviewStatusEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
// import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { AccountTypeEnum } from "../../../main/features/trialBalance/utils/AccountTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums"
import { InputFieldTypes } from "../../../main/features/quotation/utils/quotationInputFieldTypes";
import { ProjectFeatureEnums } from "../../Shared/enums/projectFeatureEnum";

const czech = {

    sharedLabels: {
        SelectLanguage: "Vyberte jazyk",
        English: "English",
        Turkish: "Turkish",
        Hindi: "Hindi",
        Urdu: "Urdu",
        Arabic: "Arabic",
        czech: "Čeština",
        russian: "Russian",
        french: "French",
        spanish: "Spanish",
        italian: "Italian",
        theme: "Téma",
        awardTo: "Ocenění",
        inprogress: "Probíhá",
        filter: "Filtr",
        approvals: "Schválení",
        approvers: "Schvalující",
        members: "Členové",
        Approvers: "Schvalující",
        resignations: "Odstoupení",
        Description: "Popis",
        Descriptionisrequired: "Popis je povinný!",
        WriteDescriptionhere: "Napište popis zde...",
        enterDescription: "Vložte popis",
        pleseEnterDescription: "Prosím vložte popis",
        pleaseEnterGrade: "Prosím vložte hodnocení",
        manager: "Manažer",
        requiredMessageManager: "Prosím vyberte manažera",
        SelectManager: "Vyberte manažera",
        create: "Vytvořit",
        humanresource: "Personální oddělení",
        finance: "Finance",
        it: "IT",
        admin: "Admin",
        selectMember: "Vyberte člena",
        othersapprovals: "Ostatní schválení",
        exitinterview: "Výstupní pohovor",
        selectAperson: "Vyberte osobu",
        SelectHumanResource: "Vyberte personální oddělení",
        selectIt: "Vyberte IT",
        selectFinance: "Vyberte finance",
        selectAdmin: "Vyberte admina",
        selectOthersApprovals: "Vyberte ostatní schválení",
        inProcess: "V procesu",
        reason: "Důvod",
        refrence: "Reference",
        Rewards: "Odměny",
        name: "Jméno",
        add: "Přidat",
        action: "Akce",
        userType: "Typ uživatele",
        SelectUserType: "Vyberte typ uživatele",
        requiredMessageUserType: "Prosím vyberte typ uživatele",
        RewardTo: "Odměna pro",
        WarningTo: "Varování pro",
        Approved: "Schváleno",
        Declined: "Odmítnuto",
        Resend: "Znovu odeslat",
        NotRequired: "Není vyžadováno",
        Cancelled: "Zrušeno",
        InActive: "Neaktivní",
        ListView: "Zobrazení seznamu",
        TableView: "Tabulkové zobrazení",
        MyReward: "Moje odměna",
        ForApprovals: "K schválení",
        ForApproval: "Ke schválení",
        RewardToMe: "Odměna pro mě",
        Search: "Hledat",
        Disable: "Zakázat",
        Attachments: "Přílohy",
        Update: "Aktualizovat",
        update: "aktualizovat",
        AddEmployee: "Přidat zaměstnance",
        complain: "Stížnost",
        Submit: "Odeslat",
        Delete: "Smazat",
        Edit: "Upravit",
        NotDesignated: "Není určeno",
        Profile: "Profil",
        Settings: "Nastavení",
        Logout: "Odhlásit se",
        Table: "Tabulka",
        List: "Seznam",
        Grid: "Mřížka",
        GridView: "Mřížkové zobrazení",
        startyear: "Začátek roku",
        endyear: "Konec roku",
        startMonth: "Začátek měsíce",
        endMonth: "Konec měsíce",
        EnterStartDate: "Zadejte začátek",
        SelectStartDate: "Vyberte začátek",
        startDate: "Začátek",
        endDate: "Konec",
        businessLogo: "Firemní logo",
        title: "Název",
        enterTitle: "Zadejte název",
        percentage: "Procento",
        PleaseEnterPercentage: "Zadejte prosím procento",
        previousCharge: "Předchozí účet",
        min: "Min",
        max: "Max",
        country: "Země",
        requiredCountry: "Země je povinná",
        SelectCountry: "Vyberte zemi",
        latitude: "Zeměpisná šířka",
        longitude: "Zeměpisná délka",
        address: "Adresa",
        EnterAddress: "Zadejte adresu",
        requiredMessageAddress: "Prosím zadejte adresu",
        branch: "Pobočka",
        question: "Otázky",
        allotLeaves: "Přidělené volno",
        SubjectRequired: "Předmět je povinný",
        WriteSubject: "Napište předmět",
        category: "Kategorie",
        amount: "Částka",
        documents: "Dokumenty",
        enterName: "Zadejte jméno",
        nameRequired: "Jméno je povinné",
        accessTo: "Přístup k",
        selectAccessTo: "Vyberte přístup k",
        collaborators: "Spolupracovníci",
        selectCollaborators: "Vyberte spolupracovníky",
        readers: "Čtenáři",
        selectReaders: "Vyberte čtenáře",
        createBoard: "Vytvořit tabuli",
        createPad: "Vytvořit blok",
        createGrid: "Vytvořit mřížku",
        createSlide: "Vytvořit snímek",
        createMindmap: "Vytvořit myšlenkovou mapu",
        drive: "Pohonná jednotka",
        myDrive: "Moje jednotka",
        allDocuments: "Všechny dokumenty",
        subject: "Předmět",
        Subjectisrequired: "Předmět je povinný",
        pleaseEnterSubject: "Zadejte prosím předmět",
        EnterSubject: "Zadejte předmět",
        enterAmount: "Zadejte částku",
        pleaseEnterAmount: "Zadejte prosím částku",
        upload: "Nahrát",
        value: "Hodnota",
        addCategory: "Přidat kategorii",
        saveCategory: "Uložit kategorii",
        clear: "Vymazat",
        loans: "Půjčky",
        disburse: "Vyplácet",
        referenceNo: "Referenční číslo",
        status: "Stav",
        createDate: "Datum vytvoření",
        creator: "Tvůrce",
        netSalary: "Čistý plat",
        type: "Typ",
        PleaseSelectType: "Vyberte prosím typ",
        CreatedByMe: "Vytvořeno mnou",
        Members: "Členové",
        Member: "Člen",
        pleaseEnterCategory: "Zadejte prosím kategorii",
        Memberisrequired: "Člen je povinný",
        EnterCategoryName: "Zadejte název kategorie",
        Clear: "Vymazat",
        ReferenceNumber: "Referenční číslo",
        Grade: "Hodnocení",
        Status: "Stav",
        CreateDate: "Datum vytvoření",
        Creator: "Tvůrce",
        SNo: "Č.",
        Employee: "Zaměstnanec",
        SelectApprovers: "Vyberte schvalující",
        Approverisrequired: "Schvalující je povinný",
        Yes: "Ano",
        No: "Ne",
        totalAmount: "Celková částka",
        Total: "Celkem",
        Date: "Datum",
        Pleaseselectdate: "Vyberte prosím datum",
        SelectDate: "Vyberte datum",
        requiredMessageDate: "Prosím vyberte datum",
        Priority: "Priorita",
        Pleaseselectpriority: "Vyberte prosím prioritu",
        Pleaseselecttype: "Vyberte prosím typ",
        SelectProject: "Vyberte projekt",
        SelectGroup: "Vyberte skupinu",
        Project: "Projekt",
        Group: "Skupina",
        Progress: "Pokrok",
        SuretoRate: "Opravdu hodnotit?",
        Invalidfiletype: "Neplatný typ souboru",
        Pleaseinputyoursubject: "Zadejte prosím váš předmět",
        Pleaseinputyourdescription: "Zadejte prosím váš popis",
        Return: "Návrat",
        EnterReason: "Zadejte důvod",
        TravelReason: "Důvod cesty",
        NoDesignation: "Žádné určení",
        Hi: "Ahoj",
        Quotation: "Citace",
        Amountisrequired: "Částka je povinná",
        WriteDescriptionHere: "Napište popis zde...",
        Types: "Typy",
        Header: "Hlavička",
        MyTeam: "Můj tým",
        Email: "E-mail",
        EnterEmail: "Zadejte e-mail",
        EmailIsRequired: "E-mail je povinný",
        PhoneNo: "Telefonní číslo",
        Designation: "Určení",
        ReferenceType: "Typ reference",
        referenceId: "ID reference",
        Save: "Uložit",
        save: "Uložit",
        Color: "Barva",
        Yesterday: "Včera",
        Overdue: "Po termínu",
        Today: "Dnes",
        Complete: "Hotovo",
        DueDate: "Datum splatnosti",
        SelectDueDate: "Vyberte datum splatnosti",
        Cover: "Obálka",
        Cancel: "Zrušit",
        cancel: "zrušit",
        and: "a",
        other: "jiný",
        Others: "Ostatní",
        Option: "Možnost",
        Public: "Veřejné",
        Private: "Soukromé",
        click: "klikněte",
        With: "S",
        with: "s",
        Comments: "Komentáře",
        Commentsdot: "Komentáře...",
        Comment: "Komentovat",
        Seeless: "Zobrazit méně",
        Seemore: "Zobrazit více",
        Share: "Sdílet",
        ShareNow: "Sdílet nyní",
        SelectEmployee: "Vyberte zaměstnance",
        SelectCategory: "Vyberte kategorii",
        PleaseSelectCategory: "Prosím vyberte kategorii",
        privacy: "Soukromí",
        tags: "Štítky",
        updateDate: "Datum aktualizace",
        otherApprovals: "Další schválení",
        resignationDate: "Datum rezignace",
        purpose: "Účel",
        warnings: "Varování",
        Courses: "Kurzy",
        selectType: "Vyberte typ",
        daysCount: "Počet dní",
        reportingTo: "Reportování komu",
        selectReason: "Vyberte důvod",
        pleaseSelectReason: "Prosím vyberte důvod",
        days: "Dny",
        day: 'den',
        Dates: "Data",
        selectMembers: "Vyberte členy",
        selectGrade: "Vyberte hodnocení",
        newGrade: "Nové hodnocení",
        currentGrade: "Aktuální hodnocení",
        PleaseSelectMember: "Prosím vyberte člena",
        enterCategory: "Zadejte kategorii",
        deadline: "Deadline",
        Department: "Oddělení",
        designationmessage: "Prosím vyberte označení",
        selectDesignation: "Vyberte označení",
        jobdescription: "Popis práce",
        salaryRange: "Rozsah mzdy",
        interviewers: "Interviewéři",
        hiringBuddy: "Hiring Buddy",
        enterCity: "Zadejte město",
        City: "Město",
        selectCity: "Vyberte město",
        requiredCity: "Město je povinné",
        skills: "Dovednosti",
        experience: "Zkušenosti (roky)",
        Experience: "Zkušenosti",
        jobType: "Typ práce",
        education: "Vzdělání",
        interview: "Interviewér",
        Rating: "Hodnocení",
        poweredbyWorkw: "Powered by Workwise Ltd - Copyrighty 2016-",
        copyLink: "Kopírovat odkaz",
        EffectiveDate: "Platnost",
        experienceRequired: "Požadované zkušenosti",
        user: "Uživatelé",
        postinterview: "Post Interviewrs",
        Copied: "Zkopírováno",
        unreadNotifyItem: "Nepřečtený položka upozornění",
        createBy: "Vytvořeno uživatelem",
        Create: "Vytvořit",
        Open: "Otevřít",
        Closed: "Uzavřený",
        Nodescription: "Žádný popis",
        writeDescription: "Napište svůj popis zde...",
        Employees: "Zaměstnanci",
        BasicInfo: "Základní informace",
        FatherName: "Jméno otce",
        EnterFatherName: "Zadejte jméno otce",
        PersonalEmail: "Osobní e-mail",
        EnterPersonalEmail: "Zadejte osobní e-mail",
        personalEmailIsRequired: "Osobní e-mail je povinný",
        PhoneNumber: "Telefonní číslo",
        EnterPhoneNumber: "Zadejte telefonní číslo",
        NationalIdentity: "Národní identita",
        EnterNationalIdentityNumber: "Zadejte číslo národní identity",
        Grades: "Hodnocení",
        SelectGrade: "Vyberte hodnocení",
        requiredMessageGrade: "Prosím vyberte hodnocení",
        EnterProbationPeriod: "Zadejte zkušební dobu",
        NoticePeriod: "Výpovědní lhůta (dny)",
        EnterNoticePeriod: "Zadejte výpovědní lhůtu",
        requiredMessageNoticePeriod: "Prosím zadejte výpovědní lhůtu",
        requiredMessageNoticePeriodRestriction: "Výpovědní lhůta musí být mezi 1 a 60",
        DateOfBirth: "Datum narození",
        Gender: "Pohlaví",
        requiredMessageGender: "Prosím vyberte pohlaví",
        SelectGender: "Vyberte pohlaví",
        MartialStatus: "Rodinný stav",
        SelectMartialStatus: "Vyberte rodinný stav",
        requiredDepartment: "Oddělení je povinné",
        SelectDepartment: "Vyberte oddělení",
        Subsidiary: "Podsoučást",
        SelectSubsidiary: "Vyberte podsoučást",
        SubsidiaryOffice: "Kancelář podsoučásti",
        SelectSubsidiaryOffice: "Vyberte kancelář podsoučásti",
        PayrollGroup: "Platová skupina",
        SelectPayrollGroup: "Vyberte platovou skupinu",
        DefaultApproval: "Výchozí schválení",
        SelectDefaultApproval: "Vyberte výchozí schválení",
        SelectAll: "Vybrat vše",
        NonMandatoryApprovals: "Nepovinná schválení",
        SelectNonMandatoryApprovals: "Vyberte nepovinná schválení",
        FirstName: "Křestní jméno",
        EnterFirstName: "Zadejte křestní jméno",
        requiredMessageName: "Prosím zadejte jméno",
        requiredMessageFName: "Prosím zadejte křestní jméno",
        requiredMessageLName: "Prosím zadejte příjmení",
        LastName: "Příjmení",
        EnterLastName: "Zadejte příjmení",
        DateOfJoining: "Datum připojení",
        requiredDateOfBirth: "Datum narození je povinné",
        requiredMessageJoiningDate: "Prosím vyberte datum připojení",
        JobShift: "Pracovní směna",
        requiredMessageJobShift: "Prosím vyberte pracovní směnu",
        SelectJobShift: "Vyberte pracovní směnu",
        AccessRole: "Přístupová role",
        requiredMessageAccessRole: "Prosím vyberte alespoň jednu přístupovou roli",
        SelectAccessRole: "Vyberte přístupovou roli",
        StartEndDate: "Datum začátku/konce",
        ErrorInValidation: "Chyba ve validaci",
        EducationInfo: "Informace o vzdělání",
        NonMandatory: "Nepovinné",
        Present: "Přítomný",
        FailedToFetch: "Nepodařilo se načíst",
        Position: "Pozice",
        EnterPosition: "Zadejte pozici",
        requiredMessagePosition: "Prosím zadejte pozici",
        Organization: "Organizace",
        EnterOrganization: "Zadejte organizaci",
        requiredMessageOrganziation: "Prosím zadejte organizaci",
        Number: "Číslo",
        EnterNumber: "Zadejte číslo",
        requiredMessageNumber: "Prosím zadejte číslo",
        Relation: "Vztah",
        SelectRelation: "Vyberte vztah",
        requiredMessageRelation: "Vztah je povinný",
        AccountName: "Název účtu",
        BranchCode: "Kód pobočky",
        EnterBranchCode: "Zadejte kód pobočky",
        requiredMessageBranchCode: "Kód pobočky je povinný",
        BankName: "Název banky",
        requiredMessageBankName: "Název banky je povinný",
        EnterBankName: "Zadejte název banky",
        AccountNumber: "Číslo účtu",
        EnterAccountNumber: "Zadejte číslo účtu",
        requiredAccountNumber: "Číslo účtu je povinné",
        IBAN: "IBAN",
        EnterIBAN: "Zadejte IBAN",
        requiredIBAN: "IBAN je povinný",
        SortCode: "Kód třídění",
        requiredSortCode: "Kód třídění je povinný",
        EnterSortCode: "Zadejte kód třídění",
        BankInfo: "Bankovní informace",
        AccountTitle: "Název účtu",
        EnterAccountTitle: "Zadejte název účtu",
        requiredMessageAccount: "Název účtu je povinný",
        validAccountTitle: "Prosím zadejte platný název účtu",
        AttachmentType: "Typ přílohy",
        requiredAttchmentType: "Typ přílohy je povinný",
        Attachment: "Příloha",
        requiredAttachment: "Příloha je povinná",
        AddAttachment: "Přidat přílohu",
        requiredMessageAssignMember: "Prosím vyberte přiřazeného člena",
        Remove: "Odebrat",
        ContactNo: "Kontaktní číslo",
        EnterContactNo: "Zadejte kontaktní číslo",
        requiredContactNo: "Kontaktní číslo je povinné",
        Contact: "Kontakt",
        pleasEnterDescription: "Prosím zadejte popis.",
        Information: "Informace",
        Features: "Funkce",
        AddMembers: "Přidat členy",
        Summary: "Souhrn",
        AcessRole: "Přístupová role",
        defaultApprovers: "Výchozí schvalující",
        NonMandatoryApprovers: "Nepovinní schvalující",
        Pleasefillallrequiredfields: "Prosím vyplňte všechna povinná pole",
        Suretodelete: "Opravdu chcete smazat?",
        Code: "Kód",
        createdDate: "Datum vytvoření",
        Searchmembers: "Hledat členy",
        AddDate: "Přidat datum",
        duesoon: "splatnost brzy",
        Payroll: "Mzdy",
        ReferenceNo: "Referenční číslo",
        Associate: "Spolupracovník",
        InternalMembers: "Interní členové",
        ExternalMembers: "Externí členové",
        TotalExpense: "Celkové náklady",
        TotalExpenseAmount: "Celková částka nákladů",
        TotalPost: "Celkový počet",
        TotalMeeting: "Celkový počet schůzek",
        Expense: "Náklad",
        TotalBudget: "Celkový rozpočet",
        Balance: "Zůstatek",
        successfully: "úspěšně",
        enterValidEmail: 'Prosím zadejte platnou e-mailovou adresu',
        ViewSummary: "Zobrazit souhrn",
        AddAdmin: "Přidat správce",
        AddExternals: "Přidat externí",
        DefaultApprovers: "Výchozí schvalující",
        Billings: "Fakturace",
        spent: "Strávený",
        SelectUser: "Vybrat uživatele",
        Image: "Obrázek",
        Contacts: "Kontakty",
        noContacts: "Žádné kontakty",
        AddContact: "Přidat kontakt",
        Roles: "Role",
        Tag: "Značka",
        AccessRoles: "Přístupové role",
        member: "člen",
        uploadCover: "Nahrát obálku",
        Pleaseselect: "Prosím vyberte",
        composeEmail: "Napsat e-mail",
        send: "Odeslat",
        ContactDetail: "Detail kontaktu",
        Detail: "Detail",
        UploadImage: "Nahrát obrázek",
        WriteName: "Napište jméno",
        business: "Obchod",
        website: "Webové stránky",
        Unknown: "Neznámý",
        Interested: "Zájem",
        NotInterested: "Nezajímavý",
        meetings: "Schůzky",
        noMeetings: "Žádné schůzky",
        Communications: "Komunikace",
        Details: "Podrobnosti",
        LastUpdate: "Poslední aktualizace",
        nameRequiredMessage: "Jméno je povinné!",
        addressRequiredMessage: "Adresa je povinná!",
        Intrested: "Zájem",
        StartDateTime: "Datum a čas začátku",
        DateTimeisrequired: "Datum a čas jsou povinné",
        Selectdatetime: "Vyberte datum a čas",
        Board: "Deska",
        Notes: "Poznámky",
        Miletap: "Míl",
        EMail: "E-mail",
        Attendance: "Účast",
        Absent: "Absent",
        NoData: "Žádná data",
        Prority: "Priorita",
        Heigh: "Vysoká",
        Medium: "Střední",
        Low: "Nízká",
        error: "chyba",
        Select: "Vyberte",
        Interview: "Pohovor",
        Meeting: "Schůzka",
        Appointment: "Schůzka",
        Pleaseinputyouremail: "Prosím zadejte svůj e-mail",
        EnterEmailAddress: "Zadejte e-mailovou adresu",
        AddExternalMember: "Přidat externího člena",
        Pleaseenterexternalmembers: "Prosím zadejte externí členy",
        externals: "externí",
        Decline: "Odmítnout",
        NotAvailable: "Není k dispozici",
        AddApprovals: "Přidat schválení",
        Everyone: "Všichni",
        SelectDownloaders: "Vyberte stahující",
        SelectCollabrators: "Vyberte spolupracovníky",
        pleaseSelectAssignMemeber: "Prosím vyberte přiřazeného člena!",
        Availed: "Využito",
        Allotted: "Přiděleno",
        Degree: "Stupeň",
        Time: "Čas",
        Mood: "Nálada",
        CheckOut: "Odhlásit se",
        CheckIn: "Přihlásit se",
        Late: "Pozdě",
        State: "Stav",
        PleaseSelectYesNo: "Prosím vyberte Ano/Ne",
        PleaseselectGrade: "Prosím vyberte hodnocení",
        PleaseenterAmount: "Prosím zadejte částku",
        Salary: "Plat",
        Task: "Úkol",
        Nameisrequired: "Jméno je povinné",
        PhoneNumberisrequired: "Telefonní číslo je povinné",
        Dateisrequired: "Datum je povinné",
        Month: "Měsíc",
        Year: "Rok",
        FinalApprovers: "Koneční schvalující",
        link: "Odkaz",
        forFinalApproval: "Pro konečné schválení",
        validateBonus: "Nelze zpracovat bonus: Pro zaměstnance nejsou k dispozici žádné informace o platbě",
        validateLoan: "Nelze zpracovat půjčku: Pro zaměstnance nejsou k dispozici žádné informace o platbě",
        User: "Uživatel",
        Action: "Akce",
        Created: "Vytvořeno",
        selectyourcountry: "Prosím, vyberte svou zemi",
        PleaseEnterPhoneNumber: "Zadejte prosím telefonní číslo",
        Pleaseselectyourcity: "Prosím, vyberte své město",
        SelectCurrency: "Vyberte měnu",
        Pleaseselectcurrency: "Prosím, vyberte měnu",
        totalGroups: "Celkový počet skupin",
        totalProjects: "Celkový počet projektů",
        totalMemory: "Celková paměť",
        memory: "Paměť",
        EnterMemory: "Zadejte paměť",
        availedMemory: "Využitá paměť (GB)",
        used: "Použito",
        usedMemory: "Použitá paměť (GB)",
        From: "Od",
        To: "Do",
        WelcomeSendEmail: "Vítejte, poslat e-mail",
        SendEmail: "Odeslat e-mail",
        writeYourRemarksHere: "Sem napište své poznámky...",
        NoRemarkFound: "Žádná poznámka nenalezena",
        ToastEnable: "Povolit oznámení",
        EmailEnable: "Povolit e-maily",
        FeatureName: "Název funkce",
        LoginMessage: "Nejprve zadejte přihlašovací údaje",
        SelectedDate: "Vybrané datum",
        SelectedUser: "Vybraný uživatel",
        Default: "Výchozí",
        netAmount: "čistá částka",
        mylog: "Můj záznam",
        SelectStatus: "Vyberte stav",
        Selectuser: "Vyberte uživatele",
        PolicyType: "Typ politiky",
        Percent: "Procento",
        uploadProfilePicture: "Nahrát profilový obrázek",
        inputUsername: "Zadejte prosím uživatelské jméno",
        userName: "uživatelské jméno",
        Dummy: "Fiktivní",
        Lesson: "Lekce",
        Duration: "Délka",
        Text: "Text",
        Enterlink: "Zadejte odkaz",
        Dashboard: "Přehled",
        searchSchedule: "Hledat plán...",
        AddOption: "Přidat možnost",
        ChangeImage: "Změnit obrázek",
        DeleteImage: "Smazat obrázek",
        Question: "Otázka",
        Next: "Další",
        Prev: "Předchozí",
        Wrong: "Špatně",
        Correct: "Správně",
        YourResult: "Váš výsledek",
        Createdby: "Vytvořeno",
        Lastupdated: "Poslední aktualizace",
        Assignedto: "Přiřazeno k",
        Addlesson: "Přidat lekci",
        Addtopic: "Přidat téma",
        minutes: "minuty",
        Modules: "Moduly",
        Valid: "Platné",
        Expired: "Prošlé",
        AssignedMembers: "Přiřazení členové",
        Start: "Start",
        Previous: "Předchozí",
        Read: "Číst",
        DeleteBook: "Smazat knihu",
        Assign: "Přiřadit",
        Explanation: "Vysvětlení",
        Hey: "Ahoj",
        requiredMessageEmail: "Prosím, zadejte e-mail",
        BusinessName: "Název firmy",
        PleaseEnterBusinessName: "Prosím, zadejte název firmy",
        BusinessAddress: "Adresa firmy",
        PleaseEnterBusinessAddress: "Prosím, zadejte adresu firmy",
        Offers: "Nabídky",
        PleaseEnterOffer: "Prosím, zadejte nabídku",
        CreateOffer: "Vytvořit nabídku",
        AppliedDate: "Datum aplikace",
        ShortList: "Krátký seznam",
        Finalised: "Finalizováno",
        Member: "Člen",
        SelectMember: 'Vybrat člena',
        Approver: "Schvalovatelé",
        CategoryName: "Název kategorie",
        serialNo: "Sériové číslo",
        Allocated: "Přiděleno",
        Available: "Dostupné",
        lost: "Ztracený",
        damage: "Poškozený",
        itemType: "Typ položky",
        Service: "Služba",
        consumable: "Spotřební",
        NonConsumable: "Nespotřební",
        Quantity: "Množství",
        accountType: "Typ účtu",
        PleaseEnterQuantity: "Prosím, zadejte množství",
        QuantityMustBePositive: "Množství musí být kladné",
        MembersIsRequired: "Členové jsou vyžadováni",
        CreateRequest: "Vytvořit žádost",
        IBANNumber: "Číslo IBAN",
        About: "O",
        AboutMe: "O mně",
        TimePeriod: "Časové období",
        Company: "Společnost",
        CityTown: "Město",
        BankDetails: "Bankovní údaje",
        Work: "Práce",
        addWorkPlace: "Přidat pracoviště",
        AddEducation: "Přidat vzdělání",
        basicSalary: "Základní plat",
        feed: 'Krmivo',
        TakeaNote: "Zapsat poznámku",
        awards: 'Ocenění',
        Appraisal: "Hodnocení",
        salary: 'Plat',
        activityLog: "Záznam činnosti",
        device: 'Zařízení',
        leave: 'Odejít',
        Ip: "IP",
        DeviceId: "ID zařízení",
        AppVersion: "Verze aplikace",
        Selectfeature: "Vyberte funkci",
        EnterYourFeedBackDescription: "Zadejte popis zpětné vazby",
        HideAll: "Skrýt vše",
        ShowAll: "Zobrazit vše",
        PrintBy: "Tisknout podle",
        AccCode: "Kód účtu",
        SelectAccountType: "Vyberte typ účtu",
        Files: "Soubory",
        Space: "Prostor",
        DisburseVoucher: "Vyplacení/Voucher",
        TopUsersbyMostFiles: "Nejlepší uživatelé podle počtu souborů",
        TopUsersbyStorageSize: "Nejlepší uživatelé podle velikosti úložiště",
        Apply: "Použít",
        NumberOfTopUsers: "Počet nejlepších uživatelů",
        TopUsers: "Nejlepší uživatelé",
        SearchUser: "Hledat uživatele",
        ByStorageSize: "Podle velikosti úložiště",
        ByFileSize: "Podle velikosti souboru",
        companyDashboard: "Firemní panel",
        signup: "Registrace nepotvrzena",
        billing: "Fakturace",
        PendingBills: "Čekající faktury",
        MonthYear: "Měsíc - Rok",
        SendBill: "Odeslat fakturu",
        BillingUsers: "Fakturační uživatelé",
        Item: "Položka",
        Rate: "Sazba",
        PaidAmount: "Zaplacená částka",
        PaymentDate: "Datum platby",
        DarkDays: "Tmavé dny",
        ProcessingFee: "Poplatek za zpracování",
        Currency: "Měna",
        IsEnable: "Je povoleno",
        Actions: "Akce",
        TakeNote: "Poznamenejte si",
        PleaseSelectUsers: "Vyberte prosím uživatele",
        StickyNotes: "Lepicí poznámky",
        Copy: "Kopírovat",
        MarkAllRead: "Označit vše jako přečtené",
        SeeAll: "Zobrazit vše",
        Refresh: "Obnovit",
        close: "Zavřít",
        Calling: "Volání",
        LoadMore: "Načíst více",
        Quizez: "Kvízy",
        content: "Obsah",

    },

    /*** navMenuLabel only used in LeftSideBar ***/

    navMenuLabel: {
        Schedules: "Plány",
        workWiseCompanies: "Workwise Firmy",
        promotions: "Akce",
        customApprovals: "Vlastní Schválení",
        feed: "Novinky",
        messenger: "Messenger",
        chartOfAccount: "Účetní Rozvaha",
        createVoucher: "Vytvořit Voucher",
        voucherList: "Seznam Voucherů",
        Quotation: "Kalkulace",
        QuotationClient: "Kalkulace Klienta",
        transaction: "Transakce",
        report: "Report",
        mailBox: "Doručená Pošta",
        groups: "Skupiny",
        projects: "Projekty",
        tasks: "Úkoly",
        discussionBoard: "Diskuzní Fórum",
        pages: "Stránky",
        workBoard: "Pracovní Desky",
        leadManager: "Správce Potenciálních Zákazníků",
        schedules: "Plány",
        expense: "Náklady",
        travel: "Cesty",
        docsArchives: "Dokumenty/Archivy",
        eLearning: "E-Learning",
        inventory: "Inventář",
        myTeam: "Můj Tým",
        orgChart: "Organizační Struktura",
        employee: "Zaměstnanci",
        administration: "Administrace",
        appraisals: "Hodnocení",
        departments: "Oddělení",
        leaves: "Dovolená",
        basicInfo: "Základní Informace",
        holidays: "Dovolené",
        careers: "Kariéra",
        companiesSection: "Workwise Firmy",
        companies: "Firmy",
        menu: "Menu",
        hr: "HR",
        finance: "Finance",
        inventorySection: "Inventář",
        privacy: "Ochrana Soukromí",
        TC: "Obchodní Podmínky",
        more: "více",
        download: "Stáhnout",
        feedBack: "Zpětná Vazba",
        warnings: "Varování",
        complains: "Stížnosti",
        bonus: "Bonus",
        resignations: "Odstoupení",
        requisition: "Rekvizice",
        assets: "Přidělení Aktiv",
        assetsList: "Seznam Aktiv",
        createAssets: "Vytvořit Aktiva",
        requestListItems: "Žádost O Položky",
        rewards: "Odměny",
        businessPolicy: "Firemní Politiky",
        companiesPolicy: "Politiky Společností",
        form: "Formuláře",
        ledgerReport: "Účetní Zprávy",
        trialBalance: "Zkušební Rozvaha",
        salary: "Plat",
        payroll: "Mzdové Účetnictví",
        jobBoard: "Nabídka Práce",
        teams: "Můj Tým",
        LD: "Vývoj & Vzdělávání",
        marketPlace: "Tržiště",
        loan: "Půjčky",
        Courses: "Kurzy",
        selectMembers: "Vyberte Členy",
        auction: "Aukce",
        Nodescription: "Žádný popis",
        contract: "Smlouva"
    },

    /*** AppHeader only used in Component headerTab ***/

    appHeader: {
        newsFeed: {
            posts: "Příspěvky",
            photos: "Fotky",
            videos: "Videa",
            polls: "Ankety",
            docs: "Dokumenty",
            tagged: "Otagováno",
        },
        mailBox: {},
        messenger: {},
        groups: {
            groups: "Skupiny",
        },
        projects: {
            projects: "Projekty",
            dashboard: "Dashboard",
        },
        Task: {
            Tasks: "Úkoly",
            myTask: "Moje Úkoly",
            assignedByMe: "Přidělené Mnou",
            createdByMe: "Vytvořené Mnou",
            teamTask: "Týmový Úkol",
            dashboard: "Dashboard",
        },
        leadManager: {
            leadCategory: "Kategorie Potenciálních Zákazníků",
        },
        schedules: {
            calendar: "Kalendář",
            schedule: "Plán",
            scheduleInterviews: "Plánování Pohovorů",
        },
        expense: {
            expenses: "Náklady",
            forApprovals: "K Schválení",
            myExpenses: "Moje Náklady",
            forExecution: "K Realizaci",
            forFinance: "K Financím",
            reports: "Zprávy",
            dashboard: "Dashboard",
        },
        travel: {
            travels: "Cesty",
            forApprovals: "K Schválení",
            agentProcess: "Proces Agentury",
            travelDetail: "Detail Cesty",
        },
        docsArchives: {
            documents: "Dokumenty",
            myDrive: "Můj Disk",
            myDocuments: "Moje Dokumenty",
            forApprovals: "K Schválení",
        },
        eLearning: {
            dashboard: "Dashboard",
        },
        inventory: {
            approvals: "Schválení",
            inventory: "Inventář",
            category: "Kategorie",
        },
        customApproval: {
            CreateCustomApproval: "Vytvořit Vlastní Schválení",
            CustomApproval: "Vlastní Schválení",
            ForApproval: "K Schválení",
            MyCustomApprovals: "Moje Vlastní Schválení",
            AllCustomApproval: "Všechna Vlastní Schválení",
        },
        payroll: {
            payroll: "Mzdové Účetnictví",
            payrollApproval: "Schválení Mzdového Účetnictví",
            salaryApproval: "Schválení Platů",
        },
        administration: {
            administration: "Administrace",
        },
        appraisals: {
            appraisals: "Hodnocení",
        },
        reward_category: {
            reward_category: "Kategorie Odměn",
        },
        departments: {
            departments: "Oddělení",
        },
        leaves: {
            myLeaves: "Moje Dovolená",
            forApprovals: "K Schválení",
        },
        holidays: {
            holidays: "Dovolené",
        },
        warnings: {
            warnings: "Varování",
        },
        resignations: {
            resignations: "Odstoupení",
            approvers: "Schvalující",
        },
        complains: {
            complains: "Stížnosti",
            approvers: "Schvalující",
            createComplain: "Vytvořit Stížnost",
        },
        rewards: {
            rewards: "Odměny",
            AllRewards: "Všechny Bonusy",
            CreatedByMe: "Vytvořené Mnou",
            ForApprovals: "K Schválení",
            MyRewards: "Moje Odměny"
        },
        promotions: {
            Promotions: "Akce",
        },
        salary: {
            Salary: "Plat",
            CreateSalary: "Vytvořit Plat",
        },
        pages: {
            Pages: "Stránky",
        }
    },

    /*** Main Menu ***/
    globalSearch: {
        All: "Vše",
        feed: "Zdroj",
        Lead_Manager: "Manažer leadů",
        Travel: "Cestování",
        Document: "Dokument",
        Project: "Projekt",
        Workboard: "Pracovní tabule",
        Group: "Skupina",
        Expense: "Výdaj",
        Task: "Úkol",
        Employee: "Zaměstnanec",
        e_learning_course: "E-learningový kurz",
        e_learning_videos: "E-learningová videa",
        e_learning_article: "E-learningový článek",
        e_learning_quiz: "E-learningový kvíz",
        e_learning_book: "E-learningová kniha",
        e_learning_tedTalks: "E-learningové TED Talks",
        rewards: "Odměny",
    },


    newsFeed: {
        WriteForMe: "Napište pro mě",
        AddDocuments: "Přidat dokumenty",
        AddPolls: "Přidat ankety",
        WhatsOnYourMind: "Co vám leží na srdci?",
        Direction: "ltr",
        Generate: "Generovat",
        Change: "Změnit",
        Whatwouldyouliketotalkabout: "O čem byste chtěli mluvit?",
        Post: "Odeslat",
        Poll: "Anketa",
        Documents: "Dokumenty",
        PhotoVideo: "Fotka/Video",
        Addpolloption: "Přidat možnost ankety",
        Removepoll: "Odstranit anketu",
        WriteName: "Napsat jméno",
        AddImageVideo: "Přidat obrázek/video",
        CheckIn: "Ověření",
        ShareonFeed: "Sdílet na Feed",
        ShareinChat: "Sdílet v Chatu",
        NoPosts: "Žádné příspěvky...",
        Nomoreposts: "Žádné další příspěvky...",
        noDataMessage: "Vytvořte příspěvek nyní!",
        Saysomethingaboutthis: "Řekněte něco o tomto...",
        specificTimeline: " časová osa",
        SharedestinationNames: {
        [SharePostDestinations.FEED]: "Kanál",
        [SharePostDestinations.MEMBERS_PROFILE]: "Profil člena",
        [SharePostDestinations.GROUP]: "Skupina",
        [SharePostDestinations.PROJECT]: "Projekt",
        [SharePostDestinations.TIMELINE]: "Timeline",
        },
        SharePostSuggestionPlaceholder: {
        [SuggestionTypeEnum.Employee]: "Vyberte člena",
        [SharePostDestinations.GROUP]: "Vyberte skupinu",
        [SharePostDestinations.PROJECT]: "Vyberte projekt",
        },
        CreateFeedNow: "Vytvořit zdroj nyní!",

        QuickOptions: {
            CreateSlide: "Vytvořit Slide",
            CreateBoard: "Vytvořit Board",
            CreateGrid: "Vytvořit Grid",
            CreatePad: "Vytvořit Pad",
            CreateTask: "Vytvořit Úkol",
            CreateExpense: "Vytvořit Náklad",
            AddUser: "Přidat Uživatele",
        },
        reactionDescription: {
            [ReactionType.Like]: "Líbí se",
            [ReactionType.Love]: "Láska",
            [ReactionType.NoReaction]: "Líbí se",
            [ReactionType.Support]: "Oceňuji",
            [ReactionType.Celebrate]: "Slavit",
            [ReactionType.Curious]: "Zvědavý",
        },
        PostTypePlaceHolder: {
            [PostType.DEFAULT]: "Co vám leží na srdci",
            [PostType.POLL]: "Zeptejte se na něco...",
        },
        Titlecantbeempty: "Název nemůže být prázdný",
        Attachmentscantbeempty: "Přílohy nemohou být prázdné",
        Optioncantbeempty: "Možnost nemůže být prázdná",
        SuccessfullySharedonFeed: "Úspěšně sdíleno na Feed",
        SuccessfullySharedonMembersProfile: "Úspěšně sdíleno na profilu člena",
        SuccessfullyPostSharedMessage:{
        [PostReferenceType.MAIN_FEED]: "Úspěšně sdíleno v hlavním kanálu",
        [PostReferenceType.GROUP]: "Úspěšně sdíleno ve skupině",
        [PostReferenceType.PROJECTS]: "Úspěšně sdíleno v projektu",
        [PostReferenceType.TIMELINE]: "Úspěšně sdíleno na časové ose",
        },
    },
    auction: {
        auctionType: "Typ aukce",
        personal: "Osobní",
        official: "Oficiální",
        offer: "Nabídka",
        startingBid: "Počáteční nabídka",
        buyNowAmount: "Koupit ihned za",
        immediateBuy: "Okamžitý nákup",
        minIncreaseBid: "Minimální zvýšení nabídky",
        Auction: "Aukce",
        CreateAuction: "Vytvořit aukci",
        actionType: "Typ aukce", // remove extra one
        external: "Externí",
        auctionDetail: "Detaily aukce",
        currentBid: "Aktuální nabídka",

        FormLabel: {
            startingBid: "Počáteční nabídka",
            member: "Člen",
            approvers: "Schvalovatelé",
            minIncreaseBid: "Minimální zvýšení nabídky",
            buyNowAmount: "Koupit ihned za",
            offer: "Nabídka",
            immediateBuy: "Okamžitý nákup",
        },
        FormPlaceholder: {
            name: "Zadejte název aukce",
            startingBid: "Zadejte počáteční nabídku",
            minIncreaseBid: "Zadejte minimální zvýšení nabídky",
            selectMember: "Vyberte člena",
            selectApprovers: "Vyberte schvalovatele",
            buyNowAmount: "Zadejte částku koupit ihned za",
            offer: "Zadejte nabídku",

        },
        FormRequiredMessage: {
            name: "Zadejte prosím název aukce",
            startingBid: "Zadejte prosím počáteční nabídku",
            minIncreaseBid: "Zadejte prosím minimální zvýšení nabídky",
            buyNowAmount: "Zadejte prosím částku koupit ihned za",
            offer: "Zadejte prosím nabídku",
            endDate: "Zadejte prosím datum konce",

        },
        auctionFilterTypeEnumLabel: {
            [auctionFilterTypeEnum.All]: "Všechny aukce",
            [auctionFilterTypeEnum.CreatedByMe]: "Moje aukce",
            [auctionFilterTypeEnum.ForApproval]: "Ke schválení",
        },
        statusEnum: {
            [AuctionStatusEnum.InProcess]: "V procesu",
            [AuctionStatusEnum.Approved]: "Schváleno",
            [AuctionStatusEnum.Declined]: "Zamítnuto",
            [AuctionStatusEnum.Resend]: "Znovu odeslat",
            [AuctionStatusEnum.InActive]: "Neaktivní",
            [AuctionStatusEnum.NotRequired]: "Není vyžadováno",
            [AuctionStatusEnum.Cancelled]: "Zrušeno",
            [AuctionStatusEnum.Hold]: "Pozastaveno",
            [AuctionStatusEnum.Sold]: "Prodáno",

        },
        auctionOfferStatus: {
            [AuctionOfferStatusEnum.BidInProcess]: "Nabídka v procesu",
            [AuctionOfferStatusEnum.BidSold]: "Nabídka prodána",
            [AuctionOfferStatusEnum.OutBid]: "Překonána nabídka",


        },
        noDataMessage: "Vytvořte aukci",
    },
    mail: {
        composer: "Napsat",
        menuItem: {
            inbox: "Doručená",
            starred: "Oblíbené",
            snoozed: "Odkládané",
            sent: "Odeslaná",
            spam: "Spam",
            archive: "Archiv",
            trash: "Koš",
            draft: "Koncepty",
            allMail: "Všechna pošta",
            createNewLabel: "Vytvořit novou značku",
        },
        close: "Zavřít",
        send: "Odeslat",
        newMessage: "Nová zpráva",
        newEmail: "Vytvořit nový e-mail",
        ComposeEmail: "Napsat e-mail",
    },
    messenger: {
        Messenger: "Messenger",
        Typing: "Píše...",
        TypeaMessage: "Napište zprávu...",
        Recording: "Nahrávání...",
        nameYourGroup: "Pojmenujte skupinu",
        Create: "Vytvořit",
        GroupNameRequired: "Název skupiny je povinný",
        searchMemberToAdd: "Vyhledat člena k přidání",
        contacts: "Kontakty",
        selected: "Vybráno",
        whatwouldyoutalkingabout: "O čem byste chtěli mluvit?",
        SayHi: "Pozdravte své kontakty",
    },
    marketPlace: {
        jobBoardName: "Nabídka pracovních pozic",
        requsitionName: "Rekvizice",
    },
    discussionBoard: {
        DiscussionBoard: "Diskusní fórum",
        Topic: "Téma",
        pleaseEnterTopicName: "Zadejte název tématu",
        enterTopicName: "Zadejte název tématu",
        addDiscussionBoard: "Přidat diskusní fórum",
        errormessage: "Při načítání diskusního fóra došlo k chybě.",
        noDataMessage: "– Vytvořte nyní diskusi!",
    },
    pages: {
        messageName: "Prosím, zadejte jméno",
        tags: "Štítky",
        messageTags: "Prosím, zadejte štítky",
        enterTag: "Zadejte štítky",
        createPage: "Vytvořit stránku",
        noDataMessage: "– Vytvořte nyní stránku!"
    },
    group: {
        group: "Skupiny",
        createGroup: "Vytvořit skupinu",
        Updategroup: "Aktualizovat skupinu",
        enterGroupName: "Prosím, zadejte název skupiny.",
        Code: "Kód",
        exampleText: "např. G (pro název skupiny)",
        GroupTag: "Štítek skupiny",
        AddAdmin: "Přidat správce",
        TotalBudget: "Celkový rozpočet",
        Spent: "Utraceno",
        Balance: "Zůstatek",
        EnterGroupTag: "Zadejte štítek skupiny",
        SaveGroupTag: "Uložit štítek skupiny",
        AddGroupTag: "Přidat štítek skupiny",
        EditGroup: "Upravit skupinu",
        CreateGroupNow: "Vytvořit skupinu nyní!"
    },
    project: {
        WIP: "Ve vývoji",
        privateProject: "Soukromý projekt",
        Projects: "Projekty",
        UpdateProject: "Aktualizovat projekt",
        CreateProject: "Vytvořit projekt",
        noDataMessage: "– Vytvořte nyní projekt!",
        ProjectTag: "Štítek projektu",
        ProjectRole: "Role v projektu",
        exampleProjectName: "např. P (pro název projektu)",
        projectDate: "Datum projektu",
        EnterProjectTag: "Zadejte štítek projektu",
        SaveProjectTag: "Uložit štítek projektu",
        AddProjectTag: "Přidat štítek projektu",
        editProject: "Upravit projekt",
        PersonalNotes: "Osobní poznámky",
        EnterProjectRole: "Zadejte roli v projektu",
        SaveCustomTag: "Uložit vlastní štítek",
        AddProjectRole: "Přidat roli v projektu",
        TakeaNote: "Učinit poznámku",
        PublicProject: "Veřejný projekt",
        ProjectFeatureTabs:{
            [ProjectFeatureEnums.Feed]: "Aktuality",
            [ProjectFeatureEnums.Schedule]: "Rozvrh",
            [ProjectFeatureEnums.WorkBoard]: "Pracovní deska",
            [ProjectFeatureEnums.Document]: "Soubory",
            [ProjectFeatureEnums.Task]: "Úkoly",
            [ProjectFeatureEnums.Expense]: "Náklady",
            [ProjectFeatureEnums.Travel]: "Cesty",
            [ProjectFeatureEnums.Quotation]: "Odhad",
            [ProjectFeatureEnums.Budget]: "Rozpočet",
            [ProjectFeatureEnums.Setting]: "Nastavení",
            [ProjectFeatureEnums.DiscussionBoard]: "Diskusní fórum",
            [ProjectFeatureEnums.Pages]: "Stránky",
            [ProjectFeatureEnums.Issues]: "Problémy",
            [ProjectFeatureEnums.Contract]: "Smlouvy",
        }
    },
    tasks: {
        Tasks: "Úkoly",
        Predecessor: "Předchůdce",
        AssignTo: "Přiřadit komu",
        TaskDate: "Datum úkolu",
        CreateTask: "Vytvořit úkol",
        TaskAssignTo: "Přiřadit úkol",
        Rating: "Hodnocení",
        TaskDetail: "Detail úkolu",
        ProgressofAssignedmembers: "Pokrok přiřazených členů",
        SubTasks: "Podúkoly",
        SubGoals: "Podcíle",
        Subtask: "Podúkol",
        CreateSubtask: "Vytvořit podúkol",
        Addsubtask: "Přidat podúkol",
        AddGoal: "Přidat cíl",
        ParentTask: "Mateřský úkol",
        DueToday: "S termínem dnes",
        DueTomorrow: "S termínem zítra",
        Overdue: "S termínem minulý",
        Duein: "S termínem za",
        days: "dny",
        TaskCreatedSuccessfully: "Úkol byl úspěšně vytvořen",
        noDataMessage: "– Vytvořte nyní úkol!",

        selfTaskEnum: {
            [SelfTaskEnum.SelfTask]: "Osobní úkol",
            [SelfTaskEnum.AssignTask]: "Přiřadit komu",
        },
        Priority: "Priorita",
        taskFilterTypeEnum: {
            [TaskFilterTypeEnum.CreatedByMe]: "Vytvořeno mnou",
            [TaskFilterTypeEnum.MyTasks]: "Mé úkoly",
            [TaskFilterTypeEnum.MyTeamTasks]: "Úkoly týmu",
        },
        statusEnum: {
            [TaskStatusEnum.NotStarted]: "Nezahájeno",
            [TaskStatusEnum.InProcess]: "V procesu",
            [TaskStatusEnum.Completed]: "Dokončeno",
            [TaskStatusEnum.RatingAssign]: "Hodnocení přiřazeno",
            [TaskStatusEnum.Cancel]: "Zrušeno",
            [TaskStatusEnum.Hold]: "Pozastaveno",
        },
        taskReferenceTypeEnum: {
            [TaskReferenceTypeEnum.General]: "Obecné",
            [TaskReferenceTypeEnum.Project]: "Projekt",
            [TaskReferenceTypeEnum.Group]: "Skupina",
            [TaskReferenceTypeEnum.Lead]: "Lead",
        },
        taskPriorityEnum: {
            [TaskPriorityEnum.Default]: "Standardní",
            [TaskPriorityEnum.Low]: "Nízká",
            [TaskPriorityEnum.Medium]: "Střední",
            [TaskPriorityEnum.High]: "Vysoká",
        },
        SortEnum: {
            [TaskFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [TaskFilterSortEnum.CreateDateAsc]: "Datum vytvoření - Vzestupně",
            [TaskFilterSortEnum.StartDateDesc]: "Datum zahájení - Sestupně",
            [TaskFilterSortEnum.StartDateAsc]: "Datum zahájení - Vzestupně",
            [TaskFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [TaskFilterSortEnum.UpdateDateAsc]: "Datum aktualizace - Vzestupně"
        }
    }
    ,
    contracts: {
        Contract: "Smlouva",
        createContract: "Vytvořit smlouvu",
        contractDate: "Datum smlouvy",
        contractPurpose: "Účel smlouvy",
        selectContractPurpose: "Vyberte účel smlouvy",
        purposeofContractisRequired: "Účel smlouvy je povinný",
        category: "Kategorie",
        purpose: "Účel",
        signatureMember: "Podpisoví členové",
        contractDetail: "Detail smlouvy",
        selectCategory: "Vyberte kategorii",
        cateogryofContractisRequired: "Kategorie je povinná",
        externalMember: "Externí podpisoví členové",
        selectinternalMembers: "Vyberte interní podpisové členy",
        internalMembers: "Interní podpisoví členové",
        selectExternalMember: "Vyberte externí podpisové členy",
        contractType: "Typ smlouvy",
        contractTypeRequired: "Typ smlouvy je povinný",
        internal: "Interní",
        external: "Externí",
        noDataMessage: "Vytvořte smlouvu nyní!",
        contractsFilterTypeLables: {
            [ContractFilterTypeEnum.Contracts]: "Všechny obchodní smlouvy",
            [ContractFilterTypeEnum.BusinessContract]: "Moje smlouvy"
        },
        statusEnum: {
            [ContractStatusEnum.InProcess]: "V procesu",
            [ContractStatusEnum.Completed]: "Dokončeno",
            [ContractStatusEnum.Cancelled]: "Zrušeno"
        },
        contractReferenceTypeEnum: {
            [ContractReferenceTypeEnum.General]: "Obecné",
            [ContractReferenceTypeEnum.Project]: "Projekt",
            [ContractReferenceTypeEnum.Group]: "Skupina",
            [ContractReferenceTypeEnum.Lead]: "Potenciální zákazník"
        }
    }
    ,

    workBoard: {
        WorkBoardDashBoard: "Dashboard pracovní desky",
        pleaseEnterYourBoardName: "Zadejte název vaší desky",
        pleaseEnterYourBoardDescription: "Zadejte popis vaší desky",
        EnterMembers: "Zadejte členy",
        UpdateBoard: "Aktualizovat desku",
        CreateBoard: "Vytvořit desku",
        privacy: "Ochrana soukromí",
        AddSection: "Přidat sekci",
        AddTodo: "Přidat úkol",
        Save: "Uložit",
        EnterSectionTitle: "Zadejte název sekce",
        InSection: "V sekci",
        Label: "Štítek",
        Activity: "Aktivita",
        Labels: "Štítky",
        UploadCoverPhoto: "Nahrát obrázek na obálku",
        Section: "Sekce",
        Board: "Deska",
        Todos: "Úkoly",
        CompleteDate: "Datum dokončení",
        DetailedDesc: "Přidejte podrobnější popis...",
        EnterTodo: "Zadejte text pro tento úkol...",
        CreateWorkBoardNow: "- Vytvořte pracovní desku nyní!",
        workBoardExample: "Např. PB (pro desku s názvem Pracovní deska)",
    },
    leadManager: {
        LeadManager: "Správce příležitostí",
        AssignedLeads: "Přidělené příležitosti",
        AllLeads: "Všechny příležitosti",
        Groups: "Skupiny",
        email: "E-mail",
        Lead: "Příležitost",
        Leads: "Příležitosti",
        Pleaseinputyourboardname: "Zadejte název vaší desky!",
        exampleLeadManagerMessage: "Např. LM (pro skupinu s názvem Správce příležitostí)",
        inputBoardDescription: "Zadejte popis vaší desky!",
        UpdateLeadManager: "Aktualizovat správce příležitostí",
        CreateLeadManager: "Vytvořit správce příležitostí",
        CreateLeadGroup: "Vytvořit skupinu příležitostí",
        noDataMessage: "– Vytvořte nyní správce příležitostí!",
        LastUpdated: "Poslední aktualizace",
        LeadValue: "Hodnota příležitosti",
        individual: "Jednotlivec",
        leadEmailAddress: "E-mailová adresa příležitosti",
        leadPhoneNumber: "Telefonní číslo příležitosti",
        leadAddress: "Adresa příležitosti",
        leadUrl: "URL příležitosti",
        ConvertProject: "Převést na projekt",
        goToProject: "Přejít na projekt",
        SelectAssignMembers: "Vyberte přiřazené členy",
        LeadGroup: "Skupina příležitostí",
        leadName: "Název příležitosti",
        leadMembers: "Členové příležitosti",
        leadWebiste: "Webová stránka příležitosti",
        LeadValuenegative: "Hodnota příležitosti nemůže být záporná!",
        AddLead: "Přidat příležitost",
        SelectCommunicationTo: "Vyberte komunikaci s",
        CommunicationTypeisrequired: "Typ komunikace je povinný!",
        PleaseselectCommunicationType: "Vyberte prosím typ komunikace",
        CommunicationType: "Typ komunikace",
        AddCommunication: "Přidat komunikaci",
        CommunicationTo: "Komunikace s",
        NoCalls: "Žádné hovory",
        LeadsValue: "Hodnota příležitostí",
        metricTypeEnum: {
            [LeadManagerMetricTypeEnum.allGroup]: "Celkem skupin",
            [LeadManagerMetricTypeEnum.allAssign]: "Přidělené příležitosti",
            [LeadManagerMetricTypeEnum.leadToProject]: "Převést na projekt",
            [LeadManagerMetricTypeEnum.leadSection]: "Maximální sekce příležitostí",
        },
    },
    schedule: {
        StartMeeting: "Začít schůzi",
        MeetinginProgress: "Schůze probíhá",
        EndMeeting: "Ukončit schůzi",
        MeetingEnded: "Schůze ukončena",
        NewTokenAdded: "Nový token přidán",
        Copied: "Zkopírováno",
        SuccessfullyBlocked: "Úspěšně zablokováno",
        SuccessfullyUnBlocked: "Úspěšně odblokováno",
        UpdateCalendarLink: "Aktualizovat odkaz na kalendář",
        AddCalendarLink: "Přidat odkaz na kalendář",
        GettingCalendarLink: "Získání odkazu na kalendář",
        CopyLink: "Kopírovat odkaz",
        Block: "Blokovat",
        UnBlock: "Odblokovat",
        CreateSchedule: "Vytvořit plán",
        Draft: "Návrh",
        Finalize: "Dokončit",
        Complete: "Hotovo",
        Preview: "Náhled",
        MinutesOftheMeeting: "Zápis z jednání",
        MeetingId: "ID schůze",
        Venue: "Místo",
        Workwisecall: "Hovor přes Workwise",
        ConferenceCall: "Konferenční hovor",
        Attendees: "Účastníci",
        Absentees: "Neúčastníci",
        PointsofDiscussion: "Body jednání",
        AddPoint: "Přidat bod",
        Print: "Tisk",
        SaveToPDF: "Uložit do PDF",
        MEETINGMINUTES: "ZÁPIS Z JEDNÁNÍ",
        Yes: "Ano",
        No: "Ne",
        WorkwiseCall: "Hovor přes Workwise",
        MeetingObjectives: "Cíle schůze",
        Points: "Body",
        RefNo: "Odkazovat se na číslo",
        AttendanceatMeeting: "Účast na schůzi",
        Loadingschedules: "Načítání plánů...",
        Nodata: "Žádná data...",
        Projects: "Projekty",
        Groups: "Skupiny",
        titleMessage: "Jste si jisti? Změna typu odstraní přiřazené členy.",
        pleaseSelectAssignMemeber: "Vyberte prosím přiřazeného člena!",
        SelectAssignto: "Vybrat přiřazení k",
        GetLink: "Získat odkaz",
        Anyone: "Kdokoli",
        anyoneText: "s odkazem může vytvořit schůzku.",
        TodayEvents: "Události dnes",
        UpcomingEvents: "Připravované události",
        InterviewRequests: "Poptávky o pohovor",
        sendviachat: "poslat prostřednictvím chatu",
        OnVideoConference: "Na video konferenci:",
        OnWorkwiseCall: "Na hovor přes Workwise",
        Enterlocation: "Zadejte místo",
        Duration: "Trvání",
        inminutes: "v minutách",
        Durationisrequired: "Trvání je povinné",
        Rooms: "Místnosti",
        SelectRoom: "Vyberte místnost",
        CheckRoomAvailability: "Zkontrolovat dostupnost místnosti",
        Available: "Dostupné",
        NotAvailable: "Nedostupné",
        TravelTime: "Doba cesty",
        PreparationTime: "Čas na přípravu",
        SendviaEmail: "Odeslat e-mailem",
        schedulerCalender: "Kalendář plánovače",
        Next: "Další",
        Prev: "Předchozí",
        titlemyCustomButton: "název mého vlastního tlačítka",
        prevnexttoday: "předchozí další dnes",
        timeGridDaytimeGridWeekdayGridMonth: "timeGridDay timeGridWeek dayGridMonth",
        timeGridDay: "timeGridDay",
        top: "nahoře",
        block: "blok",
        minutes: "minuty",
        AllDay: "Celý den",
        timeGridWeek: "timeGridWeek",
        slotDuration: "00:15:00",
        numeric: "číselný",
        dayGridMonth: "dayGridMonth",
        dayGridWeek: "dayGridWeek",
        timeGrid: "timeGrid",
        Day: "Den",
        Week: "Týden",
        Month: "Měsíc",
        NoEventsonthisday: "V tento den žádné události.",
        CreateInterviewSchedule: "Vytvořit plán pohovoru",
        Interviewwith: "Pohovor s",
        MySchedules: "Moje plány",
        TeamSchedules: "Plány týmu",
        Past: "Minulé",
        Today: "Dnes",
        Upcoming: "Nadcházející",
        ScheduleDetail: "Detail plánu",
        JoinWorkwiseCall: "Připojit se k hovoru přes Workwise",
        AddWorkwiseCall: "Přidat hovor přes Workwise",
        Host: "(Hostitel)",
        MakeHost: "Nastavit jako hostitele",
        Attending: "Účastník",
        UpdateSchedule: "Aktualizovat plán",
        Calendar: "Kalendář",
        Schedule: "Plán",
        ShareCalendarLink: "Sdílet odkaz na kalendář",
        pointer: "ukazatel",
        default: "výchozí",
        blue: "modrá",
        red: "červená",
        purple: "fialová",
        Self: "Osoba",
        AssignTo: "Přiřadit k",
        assign: "přiřadit",
        min: "min",
        Minutes: "15 minut",
    },
    expenses: {
        CreateExpense: "Vytvořit náklady",
        IsReimbursable: "Je náhradní",
        WriteHeaderHere: "Napište nadpis zde...",
        ExpenseDetail: "Podrobnosti o nákladech",
        ExpenseDate: "Datum nákladů",
        Executors: "Vykonavatelé",
        Financers: "Financové",
        ExecutorRequired: "Vykonavatel je povinný",
        selectExecutor: "Vyberte vykonavatele nákladů",
        noDataMessage: "Posilujte svou finanční cestu - Vytvořte náklady nyní!",
        expenseFilterTypeEnum: {
            [ExpenseFilterTypeEnum.MyExpense]: "Moje náklady",
            [ExpenseFilterTypeEnum.ForApproval]: "K schválení",
            [ExpenseFilterTypeEnum.ForExecutions]: "K provedení",
            [ExpenseFilterTypeEnum.ForFinance]: "K financování",
        },
        expenseType: {
            [ExpenseReferenceTypeEnum.General]: "Obecné",
            [ExpenseReferenceTypeEnum.Group]: "Skupina",
            [ExpenseReferenceTypeEnum.Project]: "Projekt",
            [ExpenseReferenceTypeEnum.Travel]: "Cestování",
            [ExpenseReferenceTypeEnum.Assets]: "Aktiva",
        },
        statusEnum: {
            [ExpenseApprovalStatusEnum.InProcess]: "Probíhá",
            [ExpenseApprovalStatusEnum.Approved]: "Schváleno",
            [ExpenseApprovalStatusEnum.Cancelled]: "Zrušeno",
            [ExpenseApprovalStatusEnum.Completed]: "Dokončeno",
            [ExpenseApprovalStatusEnum.Hold]: "Zastaveno",
            [ExpenseApprovalStatusEnum.Disbursed]: "Vyplaceno",
            [ExpenseApprovalStatusEnum.WaitingForDisburse]: "Čeká na vyplacení",
        },
        SortEnum: {
            [ExpenseFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [ExpenseFilterSortEnum.CreateDateAsc]: "Datum vytvoření - Vzestupně",
            [ExpenseFilterSortEnum.ExpenseDateDesc]: "Datum nákladů - Sestupně",
            [ExpenseFilterSortEnum.ExpenseDate]: "Datum nákladů - Vzestupně",
            [ExpenseFilterSortEnum.ReferenceNo]: "Referenční číslo",
            [ExpenseFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [ExpenseFilterSortEnum.Amount]: "Částka",
            [ExpenseFilterSortEnum.AmountDesc]: "Částka - Sestupně"
        },
        Category: {
            [ExpenseCategoryEnum.Transport]: "Doprava",
            [ExpenseCategoryEnum.Health]: "Zdraví",
            [ExpenseCategoryEnum.Food]: "Jídlo",
            [ExpenseCategoryEnum.Shopping]: "Nákupy",
            [ExpenseCategoryEnum.Entertainment]: "Zábava",
            [ExpenseCategoryEnum.Travel]: "Cestování",
            [ExpenseCategoryEnum.Bill]: "Účet",
            [ExpenseCategoryEnum.Office]: "Kancelář",
            [ExpenseCategoryEnum.Fuel]: "Palivo",
            [ExpenseCategoryEnum.Asset]: "Aktiva",
            [ExpenseCategoryEnum.Other]: "Ostatní"
        }

    },
    travel: {
        TravelDetail: "Detail cesty",
        CreateTravel: "Vytvořit cestu",
        PleaseAddTravelDetail: "Prosím, přidejte detail cesty",
        TravelToandFrom: "Cesta tam a zpět",
        ReturnDateIsRequired: "Datum návratu je povinný",
        PleaseSelectTravelType: "Prosím, vyberte typ cesty",
        HotelTADACar: "Hotel, TADA & Auto",
        HotelRequired: "Hotel je povinný",
        RentaCar: "Pronájem auta",
        PickUpRequired: "Vyžaduje se vyzvednutí",
        DropOffRequired: "Vyžaduje se odevzdání",
        TADAApplicable: "TADA je použitelný",
        TravelBy: "Cestování",
        SpecialRequest: "Speciální požadavek",
        SearchDestinationCity: "Hledat cílové město",
        SearchDepartureCity: "Hledat odjezdové město",
        PleaseSelectDepartureCity: "Prosím, vyberte odjezdové město.",
        PleaseSelectDestinationCity: "Prosím, vyberte cílové město.",
        DepartureTimeIsRequired: "Čas odjezdu je povinný",
        RetunTimeIsRequired: "Čas návratu je povinný",
        AttachmentType: "Typ přílohy",
        WriteSpecialRequestDetail: "Napište podrobnosti speciálního požadavku",
        Agents: "Agenti",
        Departure: "Odjezd",
        Arrival: "Příjezd",
        DepartureDate: "Datum odjezdu",
        DepartureTime: "Čas odjezdu",
        DepartureCity: "Odjezdové město",
        ShiftType: "Typ směny",
        DepartureShift: "Odjezdová směna",
        ArrivalCity: "Cílové město",
        CreateTravelnow: "Vytvořit cestu nyní",
        UpdateTravel: "Aktualizovat cestu",
        TravelAttachmentTypeOptions: {
            [TravelAttachmentTypeEnum.Passport]: "Pas",
            [TravelAttachmentTypeEnum.Ticket]: "Letenka",
            [TravelAttachmentTypeEnum.Insurance]: "Pojištění",
            [TravelAttachmentTypeEnum.CarRental]: "Pronájem auta",
            [TravelAttachmentTypeEnum.Other]: "Ostatní",
        },

        Shift: {
            [ShiftEnum.Morning]: "Ráno",
            [ShiftEnum.Afternoon]: "Odpoledne",
            [ShiftEnum.Evening]: "Večer",
            [ShiftEnum.Night]: "Noc",
        },

        travelBy: {
            plane: "Letadlem",
            bus: "Autobusem",
            ship: "Lodí",
            train: "Vlakem",
            car: "Autem",
        },

        TravelOptions: {
            [TravelOptionsEnums.ByPlane]: "Letadlem",
            [TravelOptionsEnums.ByShip]: "Lodí",
            [TravelOptionsEnums.ByBus]: "Autobusem",
            [TravelOptionsEnums.ByTrain]: "Vlakem",
            [TravelOptionsEnums.ByCar]: "Autem",
        },

        travelFilterTypeEnum: {
            [TravelFilterTypeEnum.Travels]: "Cesty",
            [TravelFilterTypeEnum.MyTravel]: "Moje cesty",
            [TravelFilterTypeEnum.ForApproval]: "K schválení",
            [TravelFilterTypeEnum.AgentProcess]: "Proces agenta",
        },

        statusEnum: {
            [TravelStatusEnum.InProcess]: "Probíhá",
            [TravelStatusEnum.Approved]: "Schváleno",
            [TravelStatusEnum.Declined]: "Odmítnuto",
            [TravelStatusEnum.Cancelled]: "Zrušeno",
            [TravelStatusEnum.ApprovedByApprovers]: "Schváleno správci",
            [TravelStatusEnum.ApprovedByAgents]: "Schváleno agenty",
            [TravelStatusEnum.DeclineByApproves]: "Odmítnuto správci",
            [TravelStatusEnum.DeclineByAgents]: "Odmítnuto agenty",
            [TravelStatusEnum.Hold]: "Zastaveno",
        },
    },
    eLearning: {
        Course: "Kurz",
        eBook: "eKniha",
        Quiz: "Kvíz",
        TedTalk: "Ted Talk",
        Article: "Článek",
        Video: "Video",
        BasicCourseDetail: "Základní informace o kurzu",
        SelectSubject: "Vyberte téma",
        SelectCourse: "Vyberte kurz",
        PleaseSelectOption: "Prosím vyberte možnost",
        Selectcoursetype: "Vyberte typ kurzu",
        CourseName: "Název kurzu",
        Entercoursename: "Zadejte název kurzu",
        Level: "Úroveň",
        PleaseSelectLevel: "Prosím vyberte úroveň",
        Selectlevel: "Vyberte úroveň",
        CourseAdminMembers: "Správci kurzu",
        CourseAdminisrequired: "Správce kurzu je povinný",
        AssignMembers: "Přiřadit členy",
        UpdateEBook: "Aktualizovat eKnihu",
        CreateEBook: "Vytvořit eKnihu",
        AuthorName: "Jméno autora",
        EnterInformation: "Zadejte informace",
        UploadBook: "Nahrát knihu (PDF)",
        CreateQuiz: "Vytvořit kvíz",
        QuizName: "Název kvízu",
        CreateTedTalk: "Vytvořit Ted Talk",
        ViaLink: "Přes odkaz",
        ViaVideoUpload: "Nahrát videem",
        PleaseInsertLink: "Prosím vložte odkaz",
        UploadVideo: "Nahrát video",
        CreateArticle: "Vytvořit článek",
        InsertLink: "Vložit odkaz",
        UploadPDF: "Nahrát fotku nebo PDF",
        CreateVideo: "Vytvořit video",
        LessonDetail: "Detail lekce",
        Eitherselect: "Buď vyberte kvíz nebo ho odeberte.",
        Selectquiz: "Vyberte kvíz",
        AddNewQuiz: "Přidat nový kvíz",
        Atleastonetopic: "Je vyžadováno alespoň jedno téma této lekce.",
        LessonNameRequired: "Název lekce je povinný",
        LessonName: "Název lekce",
        DeleteLesson: "Smazat lekci",
        LessonDescriptionRequired: "Popis lekce je povinný",
        TopicNameIsRequired: "Název tématu je povinný",
        TopicName: "Název tématu",
        DeleteTopic: "Smazat téma",
        TopicDescription: "Popis tématu je povinný",
        eitherEnterText: "Buď zadejte text nebo ho odeberte.",
        EitherenterA: "Buď zadejte",
        OrRemoveIt: "nebo ho odeberte.",
        CreateArticlesNow: "Vytvořit články nyní!",
        CreateCoursesNow: "Vytvořit kurzy nyní!",
        CreateEbooksNow: "Vytvořit eKnihy nyní!",
        CreateElearningNow: "Vytvořit e-learning nyní!",
        CreateQuizzesNow: "Vytvořit kvízy nyní!",
        CreateTeamDashboardNow: "Vytvořit týmový přehled nyní!",
        CreateTedTalksNow: "Vytvořit Ted Talks nyní!",
        CreateVideosNow: "Vytvořit videa nyní!",
        OptionEmpty: "Možnost nesmí být prázdná",
        CorrectOptionRequired: "Vyžaduje se správná možnost",
        QuestionEmpty: "Otázka nesmí být prázdná",
        UntitledQuestion: "Bez názvu",
        AddQuestion: "Přidat otázku",
        DeleteQuestion: "Smazat otázku",
        Quiznamerequired: "Vyžaduje se název kvízu!",
        QuizexpirationDate: "Datum expirace kvízu",
        QuizExpirationDateIsRequired: "Datum expirace kvízu je povinný",
        QuizDescriptionRequired: "Popis kvízu je povinný!",
        StartQuiz: "Začít kvíz",
        GoToResults: "Přejít k výsledkům",
        Gettoknown: "Poznejte uživatelskou zkušenost",
        Missingdata: "Chybějící data.",
        UntitledLesson: "Bez názvu",
        RemoveText: "Odstranit text",
        AddText: "Přidat text",
        RemoveImage: "Odstranit obrázek",
        AddImage: "Přidat obrázek",
        RemoveVideo: "Odstranit video",
        AddVideo: "Přidat video",
        RemovePDF: "Odstranit PDF",
        AddPDF: "Přidat PDF",
        RemoveQuiz: "Odstranit kvíz",
        AddQuiz: "Přidat kvíz",
        RemoveLink: "Odstranit odkaz",
        AddLink: "Přidat odkaz",
        RemoveYouTubeLink: "Odstranit odkaz na YouTube",
        AddYouTubeLink: "Přidat odkaz na YouTube",
        RemoveExternalCourse: "Odstranit externí kurz",
        AddExternalCourse: "Přidat externí kurz",
        UntitledTopic: "Bez názvu",
        DashboardChart: "Graf přehledu",
        LatestTrendInBusiness: "Nejnovější trend v podnikání",
        InformationWillComeHere: "Informace budou zde",
        CourseContent: "Obsah kurzu",
        DummyContent: "Dummy obsah",
        EndCourse: "Ukončit kurz",
        Chapters: "Kapitoly •",
        Lectures: "Lekce •",
        LessonsInThisProgram: "Lekce v tomto programu",
        BookInformation: "Informace o knize",
        TedTalksDaily: "Ted Talks Denně",
        TopicTitle: "Název tématu",
        VisitThis: "Navštivte tento",
        LearnPractical: "Naučte se praktické dovednosti prostřednictvím kurzů a získejte placené učňovské pozice.",
        Courses: "Kurzy",
        Book: "Kniha",
        Tedtalk: "Ted talk",
        Articles: "Články",
        NoPageExist: "Stránka neexistuje",
        Books: "Knihy",
        TedTalks: "Ted Talks",
        Videos: "Videa",
        GoTo: "Přejít na",
        CourseLevelIdLabel: {
            [CourseLevelIdEnum.Advance]: "Pokročilý",
            [CourseLevelIdEnum.Intermediate]: "Středně pokročilý",
            [CourseLevelIdEnum.Beginner]: "Začátečník"
        }
    },
    attendence: {
        Attendance: "Přítomnost",
        TimeSpent: "Strávený čas",
        GraceTime: "Čas tolerance",
        GoodDay: "Hezký den",
        Howfeeling: "Jak se dnes cítíte?",
        Note: "Poznámka",
        Noattendanceforapproval: "Žádná přítomnost ke schválení.",
        Iwaslate: "Přišel jsem pozdě kvůli dopravě.",
        Showtimespent: "Zobrazit strávený čas",
        ClockIn: "Zaznamenejte se pro zahájení vaší docházky.",
        selectStatus: "Prosím vyberte stav!",
        ByTime: "Podle času",
        ByStatus: "Podle stavu",
        DeductionPolicy: "Politika srážek",
        UpdateDeductionPolicy: "Aktualizovat politiku srážek",
        AddDeductionPolicy: "Přidat politiku srážek",
        DaysRange: "Rozsah dnů",
        PerDay: "Za den",
        SaveDeductionPolicy: "Uložit politiku srážek",
        AbsentPolicy: "Politika nepřítomnosti",
        LatePolicy: "Politika pozdního příchodu",
        IsPercentage: "Je to procento",

        attendanceFilterTypeEnum: {
            [AttendanceFilterTypeEnum.checkInForApproval]: "Přihlášení ke schválení",
            [AttendanceFilterTypeEnum.dayWiseAttendance]: "Denní docházka",
            [AttendanceFilterTypeEnum.userAttendance]: "Uživatelská docházka",
            [AttendanceFilterTypeEnum.Summary]: "Souhrn",
        },

        updateErrorMessage: {
            [AttendanceStateEnum.Off]: "Aktualizace není dostupná pro stav vypnuto",
            [AttendanceStateEnum.Holiday]: "Aktualizace není dostupná pro prázdniny",
            [AttendanceStateEnum.Leave]: "Aktualizace není dostupná pro stav dovolené",
        },
        statusEnum: {
            [AttendanceStatusEnum.InProcess]: "Probíhá",
            [AttendanceStatusEnum.Approved]: "Schváleno",
            [AttendanceStatusEnum.Declined]: "Odmítnuto",
        },
        moodLabels: {
            [AttendanceMoodEnum.VeryUnsatisfied]: "Velmi nespokojený",
            [AttendanceMoodEnum.Unsatisfied]: "Nespokojený",
            [AttendanceMoodEnum.Neutral]: "Neutrální",
            [AttendanceMoodEnum.Satisfied]: "Spokojený",
            [AttendanceMoodEnum.VerySatisfied]: "Velmi spokojený",
        },
        attendanceState: {
            [AttendanceStateEnum.Unknown]: "",
            [AttendanceStateEnum.Present]: "Přítomen",
            [AttendanceStateEnum.Absent]: "Nepřítomen",
            [AttendanceStateEnum.Leave]: "Dovolená",
            [AttendanceStateEnum.Late]: "Pozdní příchod",
            [AttendanceStateEnum.WorkFromHome]: "Práce z domova",
            [AttendanceStateEnum.Off]: "Vypnuto",
            [AttendanceStateEnum.Holiday]: "Prázdniny",
        },
        attendanceType: {
            [ATTENDANCE_ENUM.CHECK_IN.IN]: "Příchod",
            [ATTENDANCE_ENUM.CHECK_IN.OUT]: "Odchod",
        },
        attendanceMetricLabel: {
            [AttendanceMetricTypeEnum.overallTimeSpent]: "Celkový strávený čas",
            [AttendanceMetricTypeEnum.Present]: "Přítomen",
            [AttendanceMetricTypeEnum.Absent]: "Nepřítomen",
            [AttendanceMetricTypeEnum.Leave]: "Dovolená",
            [AttendanceMetricTypeEnum.Late]: "Pozdní příchod",
        },
    },
    customApproval: {
        createCustomApproval: "Vytvořit vlastní schválení",
        customApproval: "Vlastní schválení",
        noDataMessage: "– Vytvořte vlastní schválení nyní!",
        customApprovalFilterTypeEnum: {
            [CustomApprovalFilterTypeEnum.All]: "Všechna vlastní schválení",
            [CustomApprovalFilterTypeEnum.CreatedByMe]: "Moje vlastní schválení",
            [CustomApprovalFilterTypeEnum.ForApproval]: "K schválení",
        },
        statusEnum: {
            [CustomApprovalStatusEum.InProcess]: "Probíhá",
            [CustomApprovalStatusEum.Approved]: "Schváleno",
            [CustomApprovalStatusEum.Declined]: "Zamítnuto",
            [CustomApprovalStatusEum.Resend]: "Znovu odeslat",
            [CustomApprovalStatusEum.InActive]: "Neaktivní",
            [CustomApprovalStatusEum.NotRequired]: "Není vyžadováno",
            [CustomApprovalStatusEum.Cancelled]: "Zrušeno",
            [CustomApprovalStatusEum.Hold]: "Pozastaveno",
        },
        SortEnum: {
            [CustomApprovalFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [CustomApprovalFilterSortEnum.UpdateDateAsc]: "Datum aktualizace",
            [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [CustomApprovalFilterSortEnum.ReferenceNo]: "Referenční číslo",
            [CustomApprovalFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [CustomApprovalFilterSortEnum.CreateDateAsc]: "Datum vytvoření"
        }

    },
    reward: {
        CreateReward: "Vytvořit odměnu",
        Rewards: "Odměny",
        RewardTo: "Odměna pro",
        RewardCategory: "Kategorie odměn",
        EnterRewardCategory: "Zadejte kategorii odměn",
        RewardName: "Název odměny",
        EnterRewardName: "Zadejte název odměny",
        PleaseEnterRewardName: "Zadejte prosím název odměny",
        ReasonForReward: "Důvod odměny",
        EnterReasonForReward: "Zadejte důvod odměny",
        PleaseEnterReasonForReward: "Zadejte prosím důvod odměny",
        noDataMessage: "– Vytvořte odměnu nyní!",
        rewardFilterTypeEnum: {
            [RewardFilterTypeEnum.All]: "Všechny odměny",
            [RewardFilterTypeEnum.CreatedByMe]: "Vytvořené mnou",
            [RewardFilterTypeEnum.ForApproval]: "K schválení",
            [RewardFilterTypeEnum.RewardForMe]: "Moje odměny",
        },
        statusEnum: {
            [RewardStatusEnum.InProcess]: "Probíhá",
            [RewardStatusEnum.Approved]: "Schváleno",
            [RewardStatusEnum.Declined]: "Zamítnuto",
            [RewardStatusEnum.Cancelled]: "Zrušeno",
            [RewardStatusEnum.Hold]: "Pozastaveno",
        },
        SortEnum: {
            [RewardFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [RewardFilterSortEnum.UpdateDateAsc]: "Datum aktualizace",
            [RewardFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [RewardFilterSortEnum.ReferenceNo]: "Referenční číslo",
            [RewardFilterSortEnum.StatusDesc]: "Stav - Sestupně",
            [RewardFilterSortEnum.Status]: "Stav",
            [RewardFilterSortEnum.CategoryDesc]: "Kategorie - Sestupně",
            [RewardFilterSortEnum.Category]: "Kategorie",
            [RewardFilterSortEnum.NameDesc]: "Název - Sestupně",
            [RewardFilterSortEnum.Name]: "Název",
            [RewardFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [RewardFilterSortEnum.CreateDateAsc]: "Datum vytvoření"
        }
    },
    document: {
        mySigned: "Mým podpisem",
        forMySignature: "Pro můj podpis",
        draftSignature: "Návrh",
        inSigning: "V podpisu",
        signaturesCompleted: "Podpisy dokončeny",
        DocsArchive: "Dokumenty a archivy",
        createDocument: "Vytvořit dokument",
        CreateSlides: "Vytvořit prezentaci",
        CreateBoard: "Vytvořit tabuli",
        CreatePad: "Vytvořit blok",
        CreateGrid: "Vytvořit mřížku",
        newFolder: "Nová složka",
        createFolder: "Vytvořit složku",
        uploadDocuments: "Nahrát dokumenty",
        addBoard: "Přidat tabuli",
        addPad: "Přidat blok",
        addGrid: "Přidat mřížku",
        addSlide: "Přidat snímek",
        addMindmap: "Přidat myšlenkovou mapu",
        CompleteDocument: "Dokument dokončen",
        noDataMessage: "- Vytvořte dokument nyní!",
        AllDocuments: "Všechny dokumenty",
        MyDrive: "Můj disk",
        MyDocuments: "Mé dokumenty ke schválení",
        ForApprovals: "K schválení",
        OnlyFiles: "Pouze soubory",
        fileName: "Název souboru",
        shortDescription: "Stručný popis (max. 100 znaků)",
        AllFiles: "Všechny soubory",
        Grids: "Mřížky",
        Pads: "Bloky",
        Slides: "Snímky",
        Boards: "Tabule",
        Mindmaps: "Myšlenkové mapy",
        updateFolder: "Aktualizovat složku",
        updateGrid: "Aktualizovat mřížku",
        updatePad: "Aktualizovat podložku",
        updateSlide: "Aktualizovat snímek",
        updateBoard: "Aktualizovat nástěnku",
        updateMindMap: "Aktualizovat myšlenkovou mapu",
        updateDocuments: "Aktualizovat dokument",

        status: {
            InProcess: "Probíhá",
            Approved: "Schváleno",
            Declined: "Zamítnuto",
            Resend: "Znovu odeslat",
            NotRequired: "Není vyžadováno",
            Cancelled: "Zrušeno",
            Draft: "Návrh"
        },
        documentReferenceType: {
            [DocumentReferenceTypeEnum.General]: "Obecné",
            [DocumentReferenceTypeEnum.Group]: "Skupina",
            [DocumentReferenceTypeEnum.Project]: "Projekt",
        },
    },
    warning: {
        WarningDetail: "Podrobnosti varování",
        warningTo: "Varování pro",
        createWarning: "Vytvořit varování",
        enterwarningCategory: "Zadejte kategorii varování",
        noDataMessage: '– Vytvořte varování nyní!',
        filter: "Filtr",
        warningFilterTypeEnum: {
            [WarningFilterTypeEnum.Warning]: "Varování",
            [WarningFilterTypeEnum.CreatedByMe]: "Vytvořeno mnou",
            [WarningFilterTypeEnum.ForApproval]: "K schválení",
            [WarningFilterTypeEnum.WarningToMe]: "Varování pro mě",
        },
        statusEnum: {
            [WarningStatusEnum.InProcess]: "Probíhá",
            [WarningStatusEnum.Approved]: "Schváleno",
            [WarningStatusEnum.Declined]: "Zamítnuto",
            [WarningStatusEnum.Cancelled]: "Zrušeno",
            [WarningStatusEnum.Hold]: "Zdrženo",
        },

        SortEnum: {
            [WarningFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [WarningFilterSortEnum.CreateDateAsc]: "Datum vytvoření",
            [WarningFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [WarningFilterSortEnum.UpdateDateAsc]: "Datum aktualizace",
            [WarningFilterSortEnum.CategoryDesc]: "Kategorie - Sestupně",
            [WarningFilterSortEnum.Category]: "Kategorie",
            [WarningFilterSortEnum.StatusDesc]: "Stav - Sestupně",
            [WarningFilterSortEnum.Status]: "Stav",
            [WarningFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [WarningFilterSortEnum.ReferenceNo]: "Referenční číslo",
        }
    },
    jobBoard: {
        jobBoard: "Nabídka práce",
        noDataMessage: " – Vytvořte kariéru nyní!",
    },
    businessPolicy: {
        BusinessPolicy: "Firemní politika",
        policies: "Politiky",
        noPolicy: "Žádná nalezená politika...",
    },
    forms: {
        CreateForm: "Vytvořit formulář",
        Forms: "Formuláře",
        Attempt: "Pokus",
        CopyLink: "Kopírovat odkaz",
        Title: "Název",
        EnterTitle: "Zadejte název",
        PleaseEnterTitle: "Prosím zadejte název",
        PleaseFillTheQuestionField: "Prosím vyplňte pole otázky",
        EnterQuestion: "Zadejte otázku",
        SelectAnswerType: "Vyberte typ odpovědi",
        Text: "Text",
        Number: "Číslo",
        Polls: "Ankety",
        AddQuestion: "Přidat otázku",
        AddOption: "Přidat možnost",
        Details: "Detaily",
        PoweredBy: "Poháněno",
        Submit: "Odeslat",
        EditForm: "Upravit formulář",
        EnterEmail: "Zadejte e-mail",
        Question: "Otázka...",
        YourAnswer: "Vaše odpověď",
        noDataMessage: "– Vytvořte formulář nyní!",
        formQuestionTypeEnum: {
            [FormQuestionTypeEnum.Number]: "Číslo",
            [FormQuestionTypeEnum.Text]: "Text",
            [FormQuestionTypeEnum.Poll]: "Ankety",
        },
        formFilterTypeEnum: {
            [FormFilterTypeEnum.All]: "Všechny formuláře",
            [FormFilterTypeEnum.CreatedByMe]: "Vytvořené mnou",
            [FormFilterTypeEnum.ForApproval]: "K schválení",
            [FormFilterTypeEnum.FormForMe]: "Formuláře pro mě"
        },
        statusEnum: {
            [FormStatusEnum.InProcess]: "V procesu",
            [FormStatusEnum.Approved]: "Schváleno",
            [FormStatusEnum.Declined]: "Odmítnuto",
            [FormStatusEnum.Resend]: "Znovu odeslat",
            [FormStatusEnum.InActive]: "Neaktivní",
            [FormStatusEnum.NotRequired]: "Není vyžadováno",
            [FormStatusEnum.Cancelled]: "Zrušeno",
            [FormStatusEnum.Hold]: "Podrženo",
        },
        SortEnum: {
            [FormFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [FormFilterSortEnum.CreateDateAsc]: "Datum vytvoření",
            [FormFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [FormFilterSortEnum.UpdateDateAsc]: "Datum aktualizace",
            [FormFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [FormFilterSortEnum.ReferenceNo]: "Referenční číslo"
        }
    },
    careers: {
        Careers: "Kariéra",
        CareerDetail: "Podrobnosti kariéry",
        createCareer: "Vytvořit kariéru",
        MinSalary: "Minimální plat",
        MaxSalary: "Maximální plat",
        All: "Vše",
        Applicants: "Uchazeči",
        ShortListed: "Ve výběru",
        Rejected: "Zamítnuto",
        Completed: "Dokončeno",
        Finalist: "Finalista",
        Maybe: "Možná",
        OfferSent: "Nabídka odeslána",
        WaitingForInterview: "Čekání na pohovor",
        OfferAccepted: "Nabídka přijata",
        OfferDeclined: "Nabídka odmítnuta",
        OfferResend: "Nabídka znovu odeslána",
        noDataMessage: "– Vytvořte kariéru nyní!",
        writeHeaderHere: "Napište záhlaví",
        pickCurrentDate: "Vyberte aktuální datum",
        enterAmount: "Zadejte částku",
        selectApprovers: "Vyberte schvalovatele",
        selectFinancers: "Vyberte financovatele",
        selectExecutors: "Vyberte vykonavatele",
        selectDesignation: "Vyberte označení",
        writeDescription: "Napište popis",
        enterMinSalary: "Zadejte minimální plat",
        enterMaxSalary: "Zadejte maximální plat",
        selectDepartment: "Vyberte oddělení",
        selectSupervisor: "Vyberte supervizora",
        selectInterviewers: "Vyberte interviewery",
        selectPostInterviewers: "Vyberte interviewery po interview",
        description: "Napište váš popis zde...",
        addSkills: "Přidat dovednosti",
        selectJobType: "Vyberte typ práce",
        selectJobshift: "Vyberte směnu práce",
        selectEducation: "Vyberte vzdělání",
        selectCareerLevel: "Vyberte úroveň kariéry",
        selectEndDate: "Vyberte konečné datum",
        enterreviewcriteria: "Zadejte kritéria pro recenzi",
        reviewCriteria: "Kritéria recenze",
        enterDepartment: "Zadejte oddělení",
        supervisor: "Supervizor",
        supervisorrequired: "Supervizor je vyžadován",
        postInterviewers: "Post Interviewers",
        jobShift: "Směna práce",
        enterJobShift: "Zadejte směnu práce",
        enterJobType: "Zadejte typ práce",
        careerLevel: "Úroveň kariéry",
        selectBuddy: "Vyberte spolupracovníka na náboru",
        selectInerview: "Vyberte interviewery",
        requiredInterview: "Interviewer je vyžadován",
        enterCareerLevel: "Zadejte úroveň kariéry",
        createJob: "Vytvořit pracovní pozici",
        applyJob: "Přihlásit se o pracovní pozici",
        firstName: "Křestní jméno",
        enterFirstName: "Zadejte křestní jméno",
        requiredMessageFName: "Křestní jméno je povinné",
        lastName: "Příjmení",
        enterLastName: "Zadejte příjmení",
        requiredMessageLName: "Příjmení je povinné",
        email: "Email",
        enterEmail: "Zadejte email",
        EmailIsRequired: "Email je povinný",
        phoneNumber: "Telefonní číslo",
        enterPhoneNumber: "Zadejte telefonní číslo",
        currentSalary: "Současný plat",
        expectedSalary: "Očekávaný plat",
        radioMessageApplicant: "Uchazeč",
        radioMessageShortlisted: "Ve výběru",
        radioMessageCompleted: "Dokončeno",
        coverNote: "Poznámka k životopisu",
        noticePeriod: "Výpovědní lhůta",
        requiredMessageFName: "Prosím zadejte křestní jméno",
        requiredMessageLName: "Prosím zadejte příjmení",
        requiredMessageEmail: "Prosím zadejte email",
        requiredMessagePhoneNumbeer: "Prosím zadejte telefonní číslo",
        requiredMessageCurrentSalary: "Prosím zadejte současný plat",
        requiredMessageExpextedsalary: "Prosím zadejte očekávaný plat",
        requiredMessageExperience: "Prosím zadejte zkušenosti",
        requiredMessageNoticePeriod: "Prosím zadejte výpovědní lhůtu",
        appliedDate: "Datum přihlášení",
        interviewSchedule: "Plánování pohovoru",
        applyNow: "Přihlásit se nyní",
        jobPost: "Příspěvek o pracovní pozici",
        jobexpire: "Pracovní pozice vypršela",
        skillrequired: "Požadované dovednosti",
        joblocation: "Lokalita práce",
        jobType: "Typ práce",
        jobDetails: "Detaily práce",
        EducationLevel: "Úroveň vzdělání",
        MinimumSalary: "Minimální plat",
        MaximumSalary: "Maximální plat",
        YearOfExperience: "Roky zkušeností",
        errormessage: "Nelze změnit stav",
        MakeOffer: "Vytvořit nabídku",
        updatestatus: "Opravdu chcete aktualizovat stav?",
        Pleaseenterminsalary: " Prosím, zadejte minimální plat",
        Pleaseentermaxsalary: "Prosím, zadejte maximální plat",
        NoRequirements: " Žádné požadavky",
        careerInterviewStatuEnum: {
            [CareerInterviewStatusEnum.NotScheduled]: "Nenaplánováno",
            [CareerInterviewStatusEnum.Ended]: "Ukončeno",
            [CareerInterviewStatusEnum.Scheduled]: "Naplánováno",
            [CareerInterviewStatusEnum.Started]: "Začalo",
            [CareerInterviewStatusEnum.WaitingForReSchedule]: "Čekání na nové plánování"

        },
        statusEnum: {
            [CareerStatusEnum.InProcess]: "V procesu",
            [CareerStatusEnum.Approved]: "Schváleno",
            [CareerStatusEnum.Declined]: "Odmítnuto",
            [CareerStatusEnum.Cancelled]: "Zrušeno",
            [CareerStatusEnum.Hold]: "Podrženo",
        },
        careerFilterTypeEnum: {
            [CareerFilterTypeEnum.All]: "Všechny kariéry",
            [CareerFilterTypeEnum.MyCareers]: "Moje kariéry",
            [CareerFilterTypeEnum.ForApproval]: "Ke schválení",
        },
    },
    myTeam: {
        MyTeams: "Moje týmy",
        EmployeeNo: "Číslo zaměstnance",
        noDataMessage: "Vytvořte týmy nyní!",
        teamDetails: "Detaily týmu",
        DeductionPerMonth: "Odpočet za měsíc",
        teamActivities: {
            attendence: "Účast",
            checkIn: "Příchod",
            leaves: "Dovolená",
            rewards: "Odměny",
            appraisals: "Hodnocení",
            warnings: "Varování",
            complains: "Stížnosti",
            courses: "Kurzy",
            education: "Vzdělání",
            experience: "Zkušenosti",
            loan: "Půjčka",
            activityLog: "Aktivita v logu",
            checkInCheckOut: "Přihlášení / Odhlášení",
            storageStatistics: "Statistika úložiště"
        },
    },
    administration: {
        basicInfo: "Základní informace",
        Subsidiary_Office: "Pobočka",
        AddSubsidiary: "Přidat dceřinou společnost",
        Fiscal_Year: "Fiskální rok",
        Tax_Slabs: "Daňové sazby",
        Tax_Slabs_Group: "Skupina daňových sazeb",
        E_Learning_Category: "Kategorie e-learningu",
        Company_Policy: "Firemní politiky",
        Default_Hiring_Criteria: "Výchozí kritéria pro nábor",
        subsidiary: "Dceřiná společnost",
        Business_Logo: "Firemní logo",
        Complain_Category: "Kategorie stížností",
        Custom_Tag: "Vlastní značka",
        Payroll_Group: "Platová skupina",
        Business_Policy: "Firemní politika",
        Companies_Policy: "Firemní politiky",
        Billing: "Fakturace",
        Rebate_Category: "Kategorie slev",
        Assets_Category: "Kategorie majetku",
        AccessRole: "Přístupová role",
        Grade: "Třída",
        Default_Account_Header: "Výchozí záhlaví účtu",
        Business_Paging: "Obchodní stránkování",
        Discussion_Board_Category: "Kategorie diskuzního fóra",
        Room: "Místnost",
        Business_Association: "Obchodní sdružení",
        Designation: "Jmenování",
        Company_Goal: "Cíl společnosti",
        Individual_Goal: "Individuální cíl",
        Office_Timings: "Časy kanceláře",
        Leave_Types: "Typy dovolené",
        User_Types: "Typy uživatelů",
        Expense_Headers: "Záhlaví nákladů",
        Salary_Headers: "Záhlaví platů",
        Request_For_Right: "Žádost o právo",
        Custom_Approval_Category: "Vlastní kategorie schvalování",
        GRADE_ALLOWANCES: "Třídní příspěvky",
        Paging_Category: "Stránkovací kategorie",
        Page_Category: "Kategorie stránky",
        Project_Category: "Kategorie projektů",
        Email_Configuration: "Konfigurace e-mailu",
        Allowances: "Příspěvky",
        Default_Approvals: "Výchozí schválení",
        NonMandatory_Approvals: "Nepovinná schválení",
        WORKGATING: "Kritéria WIP Gating",
        Job_Description: "Popis práce",
        Rewards: "Odměny",
        Reward_Category: "Kategorie odměn",
        Warning_Category: "Kategorie varování",
        Holidays: "Svátky",
        paymentCard: "Platební karta",
        Company_Info: "Obchodní informace",
        business_Info:"Business Information",
        Business_Approval: "Schválení obchodu",
        COMPANY_STATISTICS: "Statistiky společnosti",
        ATTENDANCE_SETTINGS: 'Nastavení docházky',
        businessInfo : {
            businessInformation: 'Obchodní informace',
            companyBackgroundLabel: 'Pozadí společnosti',
            productAndServicesLable: 'Produkty a služby',
            competitorsLable: 'Konkurenti',
            recentNewsLable: 'Nedávné zprávy',
            leadershipTeamLabel: 'Vedení týmu',
            organizationalStructureLabel: 'Organizační struktura',
            customerBaseLabel: 'Zákaznická základna',
            FinancialHealthLabel: 'Finanční zdraví',
            industryLabel: 'Průmysl',
            websiteAndSocialMediaLabel: 'Web a sociální média',
            additionalCommentLabel: 'Další komentáře',
            companyCultureLabel: 'Firemní kultura',
            ceoLabel: 'CEO',
            companyBackground: {
                mission: 'Mise',
                vision: 'Vize',
                values: 'Hodnoty',
                YearFounded: 'Rok založení',
                placeHolders: {
                    enterMission: 'Zadejte misi',
                    enterVision: 'Zadejte vizi',
                    enterValues: 'Zadejte hodnoty',
                    enterYearFounded: 'Zadejte rok založení',
                },
                requiredMessages: {
                    enterMission: 'Zadejte prosím misi',
                    enterVision: 'Zadejte prosím vizi',
                    enterValues: 'Zadejte prosím hodnoty',
                    enterYearFounded: 'Zadejte prosím rok založení',
                }
            },
            productAndService: {
                Benefits: 'Výhody'
            },
            competitors: {
                MarketPosition: 'Tržní pozice',
                Strength: 'Síla',
                Weakness: 'Slabost',
            },
            recentNews: {
                Headline: 'Nadpis',
                News: 'Zprávy'
            },
            leadershipTeam: {
                Background: 'Pozadí',
                ContactNumber: 'Kontaktní číslo',
            },
            financialHealth: {
                Revenue: 'Příjmy',
                Profitability: 'Ziskovost'
            },
            additionalComment: {
                label: {
                    comments: 'Komentáře'
                },
                placeholders: {
                    comments: 'Zadejte komentáře'
                },
                requireMessages: {
                    enterComments: 'Zadejte prosím komentáře'
                }
            },
            comapnyCulture: {
                placeHolders: {
                    enterDescription: 'Zadejte popis',
                    enterWorkEnvironment: 'Zadejte pracovní prostředí'
                },
                label: {
                    description: 'Popis',
                    workEnvironment: 'Pracovní prostředí'
                },
                requiredMessages: {
                    enterDescription: 'Zadejte prosím popis',
                    enterWorkEnvironment: 'Zadejte prosím pracovní prostředí'
                }
            }
        },
        Form: {
            Name: "Jméno",
            Entername: "Zadejte jméno",
            PleaseEnterName: "Zadejte prosím jméno",
            Description: "Popis",
            enterDescription: "Zadejte prosím popis",
            Create: "Vytvořit",
            Add: "Přidat",
            Save: "Uložit",
            Clear: "Vymazat",
            CategoryName: "Název kategorie",
            Entercategoryname: "Zadejte název kategorie",
            SaveCategory: "Uložit kategorii",
            Date: "Datum",
            startDate: "Počáteční datum",
            endDate: "Koncové datum",
            Upload: "Nahrát",
            Percent: "Procento",
            Amount: "Množství",
            EnterAmount: "Zadejte množství",
            addCategory: "Přidat kategorii",
            SelectMember: "Vyberte člena",
            Email: "E-mail",
            EnterEmail: "Zadejte e-mail",
            Types: "Typy",
            SelectStatus: "Vyberte stav",
            Submit: "Odeslat",
            Nomoreapprovals: "Žádná další schválení",
        },
        Table: {
            Name: "Jméno",
            Members: "Členové",
            Description: "Popis",
            Value: "Hodnota",
            Allot_Leave: "Přidělit volno",
            Type: "Typ",
            Unit: "Jednotka",
            Taxable: "Zdanitelné",
            Provider: "Poskytovatel",
            IncomingServerAddress: "Adresa serveru pro příchozí poštu",
            OutgoingServerAddress: "Adresa serveru pro odchozí poštu",
            IncomingPort: "Příchozí port",
            OutgoingPort: "Odchozí port",
            Action: "Akce",
            startDate: "Počáteční datum",
            endDate: "Koncové datum",
            Actions: "Akce",
            Account: "Účet",
            approvers: "Schvalující",
            Status: "Stav",
            Allocated: "Přiděleno",
            AddMember: "Přidat člena",
            CreateDate: "Datum vytvoření",
        },
        accessRole: {
            Button: {
                AddAccessRole: "Přidat přístupovou roli",
                UpdateAccessRole: "Aktualizovat přístupovou roli",
            },
            Drawer: {
                CreateAccessRole: "Vytvořit přístupovou roli",
                UpdateAccessRole: "Aktualizovat přístupovou roli",
                UserType: "Typ uživatele",
                Description: "Popis",
                placeholders: {
                    EnterName: "Zadejte název přístupové role",
                    UserType: "Vyberte prosím typ uživatele",
                    Description: "Zadejte popis",
                },
                formLabel: {
                    AccessRoleName: "Název přístupové role",
                },
                placeholders: {
                    EnterName: "Zadejte přístupovou roli",
                    SelectUserType: "Vyberte typ uživatele",
                    EnterDescription: "Zadejte popis",
                },
            },
        },
        grade: {
            AddGrade: "Přidat třídu",
            EnterGrade: "Zadejte třídu",
            EnterDescription: "Zadejte popis",
            Grade: "Třída",
            Description: "Popis",
            Name: "Jméno",
            clear: "Vymazat",
            saveGrade: "Uložit třídu",
        },
        fiscalyear: {
            Add: "Přidat",
            EnterName: "Zadejte název",
            EnterDescription: "Zadejte popis",
            Fiscalyear: "Fiskální rok",
            description: "Popis",
            name: "Jméno",
            clear: "Vymazat",
            save: "Uložit",
            startyear: "Počáteční rok",
            endyear: "Koncový rok",
            startMonth: "Počáteční měsíc",
            endMonth: "Koncový měsíc",
            startDate: "Počáteční datum",
            endDate: "Koncové datum",
        },
        businessPolicy: {
            companypolicy: "Firemní politiky",
            enterName: "Zadejte název",
            name: "Jméno",
            type: "Vyberte typ",
            typee: "Typ",
            description: "Popis",
            EnterDescription: "Zadejte popis",
            create: "Vytvořit",
            save: "Uložit",
            createPolicy: "Vytvořit politiku",
            updatePolicy: "Aktualizovat politiku",
        },
        taxSlab: {
            TaxSlabs: "Daňové sazby",
            UpdateTaxSlab: "Aktualizovat daňovou sazbu",
            CreateTaxSlab: "Vytvořit daňovou sazbu",
            NoTaxSlabGroup: "Žádná skupina daňových sazeb...",
            enterCountry: "Vyberte zemi",
            enterName: "Zadejte název",
            enterDescription: "Zadejte popis",
            description: "Popis",
            name: "Jméno",
            TaxSlab: "Daňová sazba",
            title: "Titul",
            enterTitle: "Zadejte titul",
            percentage: "Procento",
            previousCharge: "Předchozí poplatek",
            min: "Min",
            max: "Max",
            enterMin: "Zadejte min",
            enterMax: "Zadejte max",
            enterPercent: "Zadejte procento",
            save: "Uložit",
            clear: "Vymazat",
            Add: "Přidat",
            TaxSlabgroup: "Skupina daňových sazeb",
            country: "Země",
            minimumCharge: "Minimální poplatek",
        },
        payrollGroup: {
            PayrollGroup: "Platová skupina",
            save: "Uložit skupinu",
            clear: "Vymazat",
            Add: "Přidat skupinu",
            name: "Jméno",
            enterName: "Zadejte název",
        },
        subsidiaryOffice: {
            enterName: "Zadejte název",
            enterAddress: "Zadejte adresu",
            name: "Jméno",
            subsidiary_Office: "Dceřiná společnost",
            latitude: "Zeměpisná šířka",
            longitude: "Zeměpisná délka",
            address: "Adresa",
            branch: "Pobočka",
            Subsidiary: "Dceřiná společnost",
            select: "Vyberte dceřinou společnost",
            save: "Uložit",
            clear: "Vymazat",
            Add: "Přidat",
            title: "Titul",
            enterTitle: "Zadejte titul",
        },
        defaultHiringCriteria: {
            default: "Výchozí kritéria pro nábor",
            question: "Otázky",
            enterQuestions: "Zadejte otázku",
            save: "Uložit otázku",
            clear: "Vymazat",
            Add: "Přidat otázku",
        },
        workGatingCriteria: {
            work: "Kritéria pro WIP Gating",
            question: "Otázky",
            enterQuestions: "Zadejte otázku",
            save: "Uložit otázku",
            clear: "Vymazat",
            Add: "Přidat otázku",
        },
        designation: {
            desig: "Jmenování",
            enterdesig: "Zadejte jmenování",
            enterName: "Zadejte jméno",
            enterDescription: "Zadejte popis",
            description: "Popis",
            name: "Jméno",
            save: "Uložit jmenování",
            clear: "Vymazat",
            Add: "Přidat jmenování",
        },
        appraisal: {
            Appraisal: "Hodnocení",
            enterAppraisal: "Zadejte hodnocení",
            enterDescription: "Zadejte popis",
            description: "Popis",
            save: "Uložit hodnocení",
            clear: "Vymazat",
            Add: "Přidat hodnocení",
            CompanyGoal: "Cíl společnosti",
            IndividualGoal: "Individuální cíl",
            DepartmentsGoal: "Cíl oddělení",
            Goal: "Cíl",
            saveGoal: "Uložit cíl",
            AddCompanyGoal: "Přidat cíl společnosti",
            AddDepartmentGoal: "Přidat cíl oddělení",
            AddIndividualGoal: "Přidat individuální cíl",
            enterCompanyGoal: "Zadejte cíl společnosti",
            enterDepartmentGoal: "Zadejte cíl oddělení",
            enterIndividualGoal: "Zadejte individuální cíl",
        },
        office: {
            Office_Timing: "Časy kanceláře",
            group: "Název skupiny",
            enterGroup: "Zadejte jméno",
            enterDescription: "Zadejte popis",
            description: "Popis",
            save: "Uložit časy kanceláře",
            clear: "Vymazat",
            Add: "Přidat časy kanceláře",
            days: "Dny",
            work: "Pracuje",
            checkIn: "Přihlášení",
            checkOut: "Odhlášení",
            time: "Příznivý čas",
            min: "Min",
            create: "Vytvořit skupinu",
            new: "Nový časový rámec",
            UpdateOfficeTiming: "Aktualizovat časy kanceláře",
            CreateOfficeTiming: "Vytvořit časy kanceláře",
            Monday: "Pondělí",
            Tuesday: "Úterý",
            Wednesday: "Středa",
            Thursday: "Čtvrtek",
            Friday: "Pátek",
            Saturday: "Sobota",
            Sunday: "Neděle",
        },
        leave: {
            Leave: "Typ dovolené",
            leaveType: "Typ dovolené",
            enterLeave: "Zadejte typ dovolené",
            save: "Uložit typ dovolené",
            clear: "Vyčistit",
            Add: "Přidat typ dovolené",
            enterDescription: "Zadejte popis",
            description: "Popis",
            allotLeaves: "Přidělené volno",
            balanceLeaves: "Typy volna (zbývající volno)",
            selectType: "Vyberte typ",
            leaveFor: "Dovolená pro",
            leaveDates: "Dovolené datumy",
            createleave: "Vytvořit dovolenou",
        },
        expense: {
            Expense: "Název výdaje",
            enterExpense: "Zadejte název výdaje",
            enterDescription: "Zadejte popis",
            description: "Popis",
            save: "Uložit název výdaje",
            clear: "Vyčistit",
            Add: "Přidat název výdaje",
        },
        elearningCategory: {
            elearningCategory: "Kategorie e-learningu",
            description: "Popis",
            enterelearningCategory: "Zadejte kategorii",
            enterDescription: "Zadejte popis",
            save: "Uložit kategorii e-learningu",
            clear: "Vyčistit",
            Add: "Přidat kategorii e-learningu",
        },
        salaryHeader: {
            SalaryHeader: "Název platového záhlaví",
            enterSalaryHeader: "Zadejte název platového záhlaví",
            desc: "Popis",
            enterDesc: "zadejte popis",
            saveHeader: "Uložit záhlaví",
            addHeader: "Přidat záhlaví",
            name: "Název",
            clear: "vyčistit",
        },
        room: {
            Room: "Pokoj",
            Name: "Název",
            Entername: "Zadejte název",
            Description: "Popis",
            Enterdescription: "Zadejte popis",
            Location: "Lokace",
            Enterlocation: "Zadejte lokaci",
            TotalSeats: "Celková sedadla",
            Enterseats: "Zadejte počet sedadel",
            SaveRoom: "Uložit pokoj",
            AddRoom: "Přidat pokoj",
            clear: "vyčistit"
        },
        RebateCategory: {
            rebateCategory: "Kategorie slev",
            maxPercentage: "Maximální procento",
            entermaxPercentage: "Zadejte maximální procento",
            maxAmount: "Maximální částka",
            enterMaxAmount: "Zadejte maximální částku",
            type: "Typ",
            basic: "Základní",
            tax: "Daň",
            fullAmount: "Celková částka",
            rebateType: "Typ slevy",
        },
        projectCategory: {
            ProjectCategory: "Kategorie projektu",
            AddProjectCategory: "Přidat kategorii projektu",
        },
        pageCategory: {
            Pagecategory: "Kategorie stránky",
            AddPageCategory: "Přidat kategorii stránky",
        },
        NonMandatoryApprovals: {
            NonMandatoryApproval: "Nepovinná schválení",
        },
        jobdescription: {
            jobDesc: "Popis práce",
            designation: "Pozice",
            selectDesign: "Vyberte pozici",
            saveJobDesc: "Uložit popis práce",
            addJobdesc: "Přidat popis práce",
        },
        Holiday: {
            Holidays: "Svátky",
            AddHoliDay: "Přidat dovolenou"
        },
        GradeAllowance: {
            gradeAllowance: "Přídavek stupně",
            Grades: "Stupně",
            AllowanceType: "Typ příplatku",
            SelectType: "Vyberte typ",
            AddAllowance: "Přidat příplatek",
            UpdateAllowance: "Aktualizovat příplatek",
            Allowance: "Příplatek",
            IsTaxable: "Je zdaňován",
        },
        EmailConfiguration: {
            emailConfi: "Konfigurace emailu",
            enterEmailConfi: "Zadejte konfiguraci emailu",
            incomingPort: "Příchozí port",
            enterIncomingPort: "Zadejte příchozí port",
            incomingServerAddress: "Adresa příchozího serveru",
            enterIncomingServerAddress: "Zadejte adresu příchozího serveru",
            outgoingPort: "Odchozí port",
            enterOutgoingPort: "Zadejte odchozí port",
            outgoingServerAddress: "Adresa odchozího serveru",
            enterOutgoingServerAddress: "Zadejte adresu odchozího serveru",
            provider: "Poskytovatel",
            enterProvider: "Zadejte poskytovatele",
            saveEmailConfi: "Uložit konfiguraci emailu",
            addEmailConfi: "Přidat konfiguraci emailu",
        },
        DiscussionBoardCategory: {
            discussionBoardCategory: "Kategorie diskusního fóra",
            SaveDiscussionBoardCategory: "Uložit kategorii diskusního fóra",
            AddDiscussionBoardCategory: "Přidat kategorii diskusního fóra",
        },
        DefaultApprovers: {
            defaultApprovers: "Výchozí schvalovatelé",
        },
        DefaultAccountHeader: {
            defaultAccountHeader: "Výchozí účetní záhlaví",
            SaveAccountHeader: "Uložit účetní záhlaví",
            SelectAccountTypes: "Vyberte typy účtu",
        },
        CustomTag: {
            customTag: "Vlastní značka",
            Entercustomtag: "Zadejte vlastní značku",
            SaveCustomTag: "Uložit vlastní značku",
            AddCustomTag: "Přidat vlastní značku",
        },
        customApprovalCategory: {
            customApprovalcategory: "Vlastní kategorie schvalování",
            enterCustomApprovalcategory: "Zadejte vlastní kategorii schvalování",

        },
        BusinessNumber: {
            Manual: "Ruční",
            TotalNumber: "Celkový počet",
            Country: "Země",
            SelectCountry: "Vyberte zemi",
            Number: "Číslo",
            Random: "Náhodné",
            SelectNumber: "Vyberte číslo",
            Cost: "Náklad",
            Request: "Žádost",
        },
        BusinessAssociation: {
            businessAssociation: "Obchodní asociace",
            SaveBusinessAssociation: "Uložit obchodní asociaci",
            AddBusinessAssociation: "Přidat obchodní asociaci",
            AssociationType: "Typ asociace",
        },
        AssetsCategory: {
            assetsCategory: "Kategorie majetku",
            categoryName: "Název kategorie",
            enterCategoryName: "Zadejte název kategorie",
            desc: "Popis",
            enterDesc: "Zadejte popis",
            accountType: "Typ účtu",
            parentAccount: "Mateřský účet",
            selectParent: "Vyberte mateřský účet",
            selectType: "Vyberte typ",
            add: "Přidat",
            save: "Uložit",
            clear: "Vyčistit",
            name: "Název",
            assets: "Aktiva",
            liability: "Závazky",
            capital: "Kapitál",
            revenue: "Příjmy",
            expense: "Výdaje",
            costOfGoodsale: "Náklady na prodané zboží",
        },
        Allowance: {
            allowance: "Přídavek",
            enterAllownace: "Zadejte přídavek",
            percent: "Procento",
            desc: "Zadejte popis",
            grade: "Stupeň",
            selectGrade: "Vyberte stupeň",
            amount: "Částka",
            benefit: "Přínos",
            deduction: "Odečtení",
            texable: "Zdaňování",
            nonTexable: "Nezdaňování",
            director: "Ředitel",
            ceo: "CEO",
            addAllowance: "Přidat přídavek",
            saveAllowance: "Uložit přídavek",
            clear: "Vyčistit",
            name: "Název",
            allowanceType: "Typ přídavku",
            allowanceUnit: "Jednotka přídavku",
            istexable: "Je zdaňován",
            yes: "Ano",
            no: "Ne",
        },
        CompanyInfo: {
            businessLogo: "Firemní logo",
            basicinfo: "Základní informace",
            ShortLogo: "Krátké logo",
            Logo: "Logo",
            FullLogo: "Plné logo",
            companyName: "Název společnosti",
            PleaseEnterCompanyName: "Zadejte prosím název společnosti",
            enterCompanyName: "Zadejte název společnosti",
            companyEmail: "Zadejte e-mail společnosti",
            PleaseEnterCompanyEmail: "Zadejte prosím e-mail společnosti",
            SelectOwnerName: "Vyberte jméno vlastníka",
            totalEmployees: "Celkový počet zaměstnanců",
            totalEmployeesInCall: "Celkový počet zaměstnanců v hovoru",
            SaveShortLogo: "Uložit krátké logo",
            LongLogo: "Dlouhé logo",
            SaveLongLogo: "Uložit dlouhé logo",
            SaveFullLogo: "Uložit plné logo",
        },
        billing: {
            Invoice: "Faktura",
            InvoiceNo: "Číslo faktury",
            CustomerName: "Jméno zákazníka",
            PayInvoice: "Zaplatit fakturu",
            Payment: "Platba",
            BillNo: "Číslo faktury",
            CardLast: "Poslední 4 číslice karty",
            BusinessName: "Název společnosti",
            CardHolder: "Držitel karty",
            Viewinvoicedetails: "Zobrazit detaily faktury a platby",
            Viewinvoicedetail: "Zobrazit detail faktury",
            Qty: "Množství",
        },
        PaymentCard: {
            paymentCard: "Platební karta",
            CardHolderName: "Jméno držitele karty",
            AddCard: "Přidat kartu",
            PayInvoice: "Zaplatit fakturu",
            CardHolderNameRequired: "Jméno držitele karty je povinné.",
            PayCard: "Zaplatit kartou",
            payanotherway: "Nebo zaplatit jiným způsobem",
            credentialstocontinue: "Zadejte své přihlašovací údaje pro pokračování.",
            Expiry: "Expirace",
            Brand: "Značka",
            Country: "Země",
            LastFourDigits: "Poslední čtyři číslice",
        },

    },
    billing: {
        BillType: "Typ účtu",
        billingTypeEnum: {
            [BillingTypeEnum.NewUser]: "Nový uživatel",
            [BillingTypeEnum.MonthlySubscription]: "Měsíční předplatné",
            [BillingTypeEnum.NewBusinessNumber]: "Nové obchodní číslo"
        }
    },
    businessNumber: {
        statusEnum: {
            [BuisnessNumberStatusEnum.Waiting]: "Čeká se",
            [BuisnessNumberStatusEnum.Declined]: "Odmítnuto",
            [BuisnessNumberStatusEnum.NumberCancelled]: "Číslo zrušeno",
            [BuisnessNumberStatusEnum.Purchased]: "Zakoupeno",
            [BuisnessNumberStatusEnum.RequestCancelled]: "Žádost zrušena",
            [BuisnessNumberStatusEnum.Rejected]: "Zamítnuto",
        },
    },
    appraisal: {
        Appraisals: "Hodnocení",
        createAppraisals: "Vytvořit hodnocení",
        noDataMessage: "– Vytvořte hodnocení nyní!",
        Approvers: "Schvalující",
        Questions: "Otázky",
        Tasks: "Úkoly",
        Bonus: "Bonus",
        Increment: "Zvýšení",
        previousApraisals: "Předchozí hodnocení",
        warning: "Varování",
        rewards: "Odměny",
        courses: "Kurzy",
        Promotion: "Povýšení",
        PleaseEnterBonusPercentage: "Zadejte prosím procento bonusu",
        validateIncrement: "Nelze zpracovat zvýšení: Pro zaměstnance nejsou k dispozici informace o platbě",
        PleaseEnterBonusAmount: "Zadejte prosím částku bonusu",
        PleaseEnterIncrementAmount: "Zadejte prosím částku zvýšení",
        RateAtleastOneItem: "Ohodnoťte alespoň jednu položku",
        Noemployeeselected: "Nebyl vybrán žádný zaměstnanec",
        submitAppraisal: "Odeslat hodnocení",
        Appraisedof: "Hodnoceno",
        AppraisalApprovers: "Schvalující hodnocení",
        AppraisalDetail: "Detail hodnocení",
        appraisalFilterTypeEnum: {
            [AppraisalFilterEnum.All]: "Všechna hodnocení",
            [AppraisalFilterEnum.CreatedByMe]: "Vytvořeno mnou",
            [AppraisalFilterEnum.ForApproval]: "K schválení",
            [AppraisalFilterEnum.TeamAppraisal]: "Týmové hodnocení",
            [AppraisalFilterEnum.MyApprovedAppraisal]: "Má schválená hodnocení",
        },
        statusEnum: {
            [AppraisalStatusEnum.InProcess]: "Probíhá",
            [AppraisalStatusEnum.Approved]: "Schváleno",
            [AppraisalStatusEnum.Declined]: "Odmítnuto",
            [AppraisalStatusEnum.Cancelled]: "Zrušeno",
            [AppraisalStatusEnum.Hold]: "Zdrženo",
        },
        SortEnum: {
            [AppraisalFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [AppraisalFilterSortEnum.CreateDateAsc]: "Datum vytvoření",
            [AppraisalFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [AppraisalFilterSortEnum.UpdateDateAsc]: "Datum aktualizace",
            [AppraisalFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [AppraisalFilterSortEnum.ReferenceNo]: "Referenční číslo"
        }

    },
    loan: {
        loan: "Půjčka",
        createLoan: "Vytvořit půjčku",
        loanTenure: "Doba trvání půjčky (v měsících)",
        enterLoanTenure: "Zadejte dobu trvání půjčky (v měsících)",
        deduction: "Srážka/Měsíce",
        selectLoanPurpose: "Vyberte účel půjčky",
        noDataMessage: "– Vytvořte půjčky nyní!",
        SortBy: "Řadit podle",
        SelectSortBy: "Vyberte řazení podle",
        InterestRate: "Úroková sazba",
        loanPurpose: {
            loanPurpose: "Účel půjčky",
            vehicle: "Vozidlo",
            personal: "Osobní",
            wedding: "Svatba",
            medical: "Zdravotní",
            education: "Vzdělání",
            house: "Dům",
            other: "Jiné",
        },
        loanPurposeEnumList: {
            [LoanPurposeEnum.Personal]: "Osobní",
            [LoanPurposeEnum.Wedding]: "Svatba",
            [LoanPurposeEnum.Medical]: "Zdravotní",
            [LoanPurposeEnum.Education]: "Vzdělání",
            [LoanPurposeEnum.House]: "Dům",
            [LoanPurposeEnum.Vehicle]: "Vozidlo",
            [LoanPurposeEnum.Other]: "Jiné",
        },
        loanFilterTypeEnum: {
            [LoanFilterTypeEnum.All]: "Všechny půjčky",
            [LoanFilterTypeEnum.ForApproval]: "K schválení",
            [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "Moje půjčky",
            [LoanFilterTypeEnum.LoanForMe]: "Půjčka pro mě",
        },
        statusEnum: {
            [LoanStatusEnum.InProcess]: "Probíhá",
            [LoanStatusEnum.Approved]: "Schváleno",
            [LoanStatusEnum.Declined]: "Odmítnuto",
            [LoanStatusEnum.Cancelled]: "Zrušeno",
            [LoanStatusEnum.Disbursed]: "Vybaveno",
            [LoanStatusEnum.PartialCleared]: "Částečně vyrovnáno",
            [LoanStatusEnum.Completed]: "Dokončeno",
            [LoanStatusEnum.Hold]: "Zdrženo",
            [LoanStatusEnum.WaitingForDisburse]: "Čeká se na vyplacení",
        },
        loanTypeEnum: {
            [LoanTypeEnum.Company]: "Firemní půjčka",
            [LoanTypeEnum.WorkWise]: "Workwise půjčka",
        },
    },
    quotations: {
        Quotation: "Kalkulace",
        CreateQuotation: "Vytvořit kalkulaci",
        quotationDate: "Datum",
        ClientName: "Jméno klienta",
        ClientEmail: "E-mail klienta",
        PhoneNumber: "Telefonní číslo",
        TotalAmount: "Celková částka",
        QuotationType: "Typ",
        Rate: "Sazba",
        Quantity: "Množství",
        Attachments: "Přílohy",
        serviceItem: "Služba/Položka",
        price: "Cena",
        tax: "Daň (%)",
        taxAmount: "Daňová částka",
        sno: "Č. st.",
        EnterClientName: "Zadejte jméno klienta",
        clientsName: "Jméno klienta",
        EnterClientEmail: "Zadejte e-mail klienta",
        addressLine1: "Adresa řádek 1",
        addressLine2: "Adresa řádek 2",
        EnterAddress1: "Zadejte adresu 1:",
        EnterAddress2: "Zadejte adresu 2:",
        Product: "Produkt",
        Service: "Služba",
        netAmount: "Čistá částka",
        CODE: "KÓD",
        DATE: "DATUM",
        TOTAL: "CELKEM",
        To: "Pro",
        SubTotal: "Mezisoučet",
        noDataMessage: "– Vytvořte kalkulaci nyní!",
        quotationFilterTypeEnum: {
            [QuotationFilterEnum.All]: "Kalkulace",
            [QuotationFilterEnum.CreatedByMe]: "Vytvořeno mnou",
            [QuotationFilterEnum.ForApproval]: "K schválení",
        },
        statusEnum: {
            [QuotationStatusEnum.InProcess]: "Probíhá",
            [QuotationStatusEnum.Approved]: "Schváleno",
            [QuotationStatusEnum.Declined]: "Odmítnuto",
            [QuotationStatusEnum.Cancelled]: "Zrušeno",
            [QuotationStatusEnum.Hold]: "Zdrženo",
            [QuotationStatusEnum.ClientApproved]: "Schváleno klientem",
            [QuotationStatusEnum.ClientRejected]: "Zamítnuto klientem",
        },
        SortEnum: {
            [QuotationFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [QuotationFilterSortEnum.CreateDateAsc]: "Datum vytvoření - Vzestupně",
            [QuotationFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [QuotationFilterSortEnum.UpdateDateAsc]: "Datum aktualizace - Vzestupně",
            [QuotationFilterSortEnum.ReferenceNo]: "Referenční číslo",
            [QuotationFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně"
        },
        ErrorFieldMessage:{
            [InputFieldTypes.PRICE]: "Cena musí být větší než 0",
            [InputFieldTypes.QUANTITY]: "Množství musí být větší než 0",
            [InputFieldTypes.TAX]: "Daň musí být větší než 0",
          },
          singleItemError: "Vyberte alespoň jednu položku pro kalkulaci",

    },
    payroll: {
        Payroll: "Mzdové účetnictví",
        createPayroll: "Vytvořit mzdové účetnictví",
        noDataMessage: "– Vytvořte mzdové účetnictví nyní!",
        basicSalary: "Základní plat",
        loan: "Půjčka",
        allowance: "Přídavek",
        ExpenseReimbursement: "Náhrada nákladů",
        deduction: "Srážka",
        tax: "Daň",
        rebate: "Sleva",
        bonus: "Bonus",
        selectMonthYear: "Vyberte měsíc a rok",
        disburseDate: "Datum výplaty",
        Voucher: "Doklad",
        DownloadtoExcel: "Stáhnout do Excelu",
        PayrollDetail: "Detail mzdového účetnictví",
        TransactionMode: "Režim transakce",
        IBANNumber: "IBAN číslo",
        statusEnum: {
            [PayrollStatusEnum.InProcess]: "Probíhá",
            [PayrollStatusEnum.Approved]: "Schváleno",
            [PayrollStatusEnum.Declined]: "Odmítnuto",
            [PayrollStatusEnum.Resend]: "Odeslat znovu",
            [PayrollStatusEnum.Inactive]: "Neaktivní",
            [PayrollStatusEnum.NotRequired]: "Není požadováno",
            [PayrollStatusEnum.Cancelled]: "Zrušeno",
            [PayrollStatusEnum.Hold]: "Zdrženo",
            [PayrollStatusEnum.Disbursed]: "Vyplaceno",
            [PayrollStatusEnum.Completed]: "Dokončeno",
        },
        payrollFilterTypeEnum: {
            [PayrollFilterTypeEnum.All]: "Mzdové účetnictví",
            [PayrollFilterTypeEnum.CreatedByMe]: "Vytvořeno mnou",
            [PayrollFilterTypeEnum.ForApproval]: "K schválení",
        },
    },
    voucher: {
        createVoucher: "Vytvořit doklad",
        SelectVoucherType: "Vyberte typ dokladu",
        BasicSalary: "Základní plat",
        LoanPayment: "Splatka půjčky",
        LoanDispersable: "Vyplácení půjčky",
        Allowance: "Přídavek",
        ExpenseReimbursement: "Náhrada nákladů",
        Deduction: "Srážka",
        VoucherDetail: "Detail dokladu",
        SavePrint: "Uložit a tisknout",
        Bonus: "Bonus",
        Rebate: "Sleva",
        Tax: "Daň",
        ChequeNo: "Číslo šeku",
        Account: "Účet",
        Narration: "Příběh",
        Debit: "Debet",
        Credit: "Kredit",
        Dr: "Deb",
        Cr: "Kred",
        differences: "Rozdíly",
        drCr: "Debet/Kredit",
        LinkVoucher: "Odkaz na doklad",
        PostVoucher: "Publikovat doklad",
        voucherDate: "Datum dokladu",
        VoucherType: "Typ poukázky",
        voucherTypeList: {
            [voucherTypeEnum.GeneralVoucher]: "Obecný doklad",
            [voucherTypeEnum.PaymentVoucher]: "Platební doklad",
            [voucherTypeEnum.ReceiptVoucher]: "Doklad o přijetí",
        },
        accountTypeList: {
            [accountTypeEnum.Asset]: "Aktiva",
            [accountTypeEnum.Capital]: "Kapitál",
            [accountTypeEnum.Expense]: "Výdaje",
            [accountTypeEnum.Liability]: "Závazky",
            [accountTypeEnum.Revenue]: "Příjmy",
        }
    },
    trialBalance: {
        accountTypeList: {
            [AccountTypeEnum.Asset]: "Aktiva",
            [AccountTypeEnum.Capital]: "Kapitál",
            [AccountTypeEnum.Expense]: "Výdaj",
            [AccountTypeEnum.Liability]: "Závazek",
            [AccountTypeEnum.Revenue]: "Příjem",
        }
    },
    businessApproval: {
        statusTypeList: {
            [ApprovalStatusTypeEnum.InProcess]: "Probíhá",
            [ApprovalStatusTypeEnum.Approved]: "Schváleno",
            [ApprovalStatusTypeEnum.Declined]: "Odmítnuto",
            [ApprovalStatusTypeEnum.Cancelled]: "Zrušeno",
            [ApprovalStatusTypeEnum.Completed]: "Dokončeno",

        },
        approvalModuleList : {
            [ApprovalsModuleEnum.ExpenseApproval]: "Výdaje",
            [ApprovalsModuleEnum.ExpenseExecutor]: "Výkonný výdajů",
            [ApprovalsModuleEnum.ExpenseFinance]: "Finanční výdaje",
            [ApprovalsModuleEnum.LeaveApproval]: "Dovolená",
            [ApprovalsModuleEnum.TravelApproval]: "Cestování",
            [ApprovalsModuleEnum.TravelAgent]: "Cestovní agent",
            [ApprovalsModuleEnum.WarningApproval]: "Varování",
            [ApprovalsModuleEnum.UserEducation]: "Vzdělání",
            [ApprovalsModuleEnum.UserWorkExperience]: "Pracovní zkušenosti",
            [ApprovalsModuleEnum.ComplainApproval]: "Stížnost",
            [ApprovalsModuleEnum.RewardApproval]: "Odměna",
            [ApprovalsModuleEnum.BonusApproval]: "Bonus",
            [ApprovalsModuleEnum.PromotionApproval]: "Povýšení",
            [ApprovalsModuleEnum.RequestForItemApproval]: "Žádost o položku",
            [ApprovalsModuleEnum.LoanApproval]: "Půjčka",
            [ApprovalsModuleEnum.CustomApproval]: "Vlastní",
            [ApprovalsModuleEnum.FormApproval]: "Formulář",
            [ApprovalsModuleEnum.DocumentApproval]: "Dokument",
            [ApprovalsModuleEnum.SalaryApproval]: "Plat",
            [ApprovalsModuleEnum.SignupApproval]: "Registrace",
            [ApprovalsModuleEnum.PayrollApproval]: "Mzdová agenda",
            [ApprovalsModuleEnum.CareerApproval]: "Kariéra",
            [ApprovalsModuleEnum.RequisitionApproval]: "Žádost o nákup",
            [ApprovalsModuleEnum.RequisitionFinalApproval]: "Konečné schválení žádosti",
            [ApprovalsModuleEnum.RequestForItemAssetControllerApproval]: "Žádost o položku (Správce aktiv)",
            [ApprovalsModuleEnum.ItemApproval]: "Položka",
            [ApprovalsModuleEnum.AssetApproval]: "Aktivum",
            [ApprovalsModuleEnum.ResignationAdminApproval]: "Rezignace (Administrátor)",
            [ApprovalsModuleEnum.ResignationExitApproval]: "Rezignace (Odchod)",
            [ApprovalsModuleEnum.ResignationFinanceApproval]: "Rezignace (Finance)",
            [ApprovalsModuleEnum.ResignationHrApproval]: "Rezignace (HR)",
            [ApprovalsModuleEnum.ResignationItApproval]: "Rezignace (IT)",
            [ApprovalsModuleEnum.ResignationOtherApproval]: "Rezignace (Ostatní)",
            [ApprovalsModuleEnum.ResignationReportingToApproval]: "Rezignace (Nadřízený)",
            [ApprovalsModuleEnum.QuotationApproval]: "Nabídka",
            [ApprovalsModuleEnum.QuotationClientApproval]: "Nabídka (Klient)",
            [ApprovalsModuleEnum.AppraisalApproval]: "Hodnocení",
            [ApprovalsModuleEnum.AuctionApproval]: "Aukce"
        }
        
    },
    disperse: {
        transactionModeEnumList: {
            [TransactionModeTypeEnum.Cash]: "Hotovost",
            [TransactionModeTypeEnum.BankTransfer]: "Bankovní převod",
            [TransactionModeTypeEnum.Cheque]: "Šek",
        },
    },
    promotion: {
        CreatePromotion: "Vytvořit povýšení",
        Promotion: "Povýšení",
        promotionTo: "Povýšit na",
        noDataMessage: "Vytvořte povýšení nyní!",
        selectPromotionTo: "Vyberte povýšení na",
        pleseSelectPromotionTo: "Prosím vyberte povýšení na",
        createPromotion: "Vytvořit povýšení",
        promotionFilterTypeEnum: {
            [PromotionFilterTypeEnum.All]: "Všechna povýšení",
            [PromotionFilterTypeEnum.CreatedByMe]: "Vytvořeno mnou",
            [PromotionFilterTypeEnum.ForApproval]: "K schválení",
            [PromotionFilterTypeEnum.PromotionForMe]: "Má povýšení",
        },
        statusEnum: {
            [PromotionStatusEnum.InProcess]: "Probíhá",
            [PromotionStatusEnum.Approved]: "Schváleno",
            [PromotionStatusEnum.Declined]: "Odmítnuto",
            [PromotionStatusEnum.Cancelled]: "Zrušeno",
            [PromotionStatusEnum.Hold]: "Zdrženo",
        },
    },
    complains: {
        createComplain: "Vytvořit stížnost",
        myComplain: "Moje stížnosti",
        AllComplains: "Všechny stížnosti",
        complainOf: "Stížnost na",
        ComplainDetail: "Detail stížnosti",
        ComplainToMe: "Stížnost na mě",
        ComplainAboutMe: "Stížnost o mně",
        noDataMessage: "– Vytvořte stížnost nyní!",
        statusEnum: {
            [ComplainApprovalStatusEnum.InProcess]: "Probíhá",
            [ComplainApprovalStatusEnum.Approved]: "Schváleno",
            [ComplainApprovalStatusEnum.Declined]: "Odmítnuto",
            [ComplainApprovalStatusEnum.Resend]: "Znovu odeslat",
            [ComplainApprovalStatusEnum.Inactive]: "Neaktivní",
            [ComplainApprovalStatusEnum.NotRequired]: "Není vyžadováno",
            [ComplainApprovalStatusEnum.Cancelled]: "Zrušeno",
            [ComplainApprovalStatusEnum.Hold]: "Zdrženo",
        },
        SortEnum: {
            [ComplainFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [ComplainFilterSortEnum.CreateDateAsc]: "Datum vytvoření",
            [ComplainFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [ComplainFilterSortEnum.UpdateDateAsc]: "Datum aktualizace",
            [ComplainFilterSortEnum.ReferenceNo]: "Referenční číslo",
            [ComplainFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně"
        }

    },
    salary: {
        SalaryMembers: "Členové platové listiny",
        EffectiveDate: "Platné od",
        BasicSalary: "Základní plat",
        Allowances: "Příplatky",
        Allowance: "Příplatek",
        AllowanceDetail: "Detail příplatku",
        IsTaxable: "Je zdanitelný",
        Deduction: "Srážka",
        GrossSalary: "Hrubý plat",
        CreateSalary: "Vytvořit plat",
        SalaryDetail: "Detail platové listiny",
        AddSalary: "Přidat plat",
        Increment: "Zvýšení",
        Deductions: "Srážky",
        AllowanceType: "Typ příplatku",
        EffectiveDateIsRequired: "Je vyžadováno platné datum",
        BasicSalaryIsRequired: "Je vyžadován základní plat",
        Negativesalaryisnotallowed: "Záporný plat není povolen",
        Benefit: "Výhoda",
        noDataMessage: "– Vytvořte plat nyní!",
        EnterIncrementPercentage: "Prosím zadejte procentuální zvýšení",
        salaryFilterTypeEnum: {
            [SalaryFilterTypeEnum.Salaries]: "Platové listiny",
            [SalaryFilterTypeEnum.CreateByMe]: "Vytvořeno mnou",
            [SalaryFilterTypeEnum.ForApproval]: "K schválení",
        },
        statusEnum: {
            [EmployeeSalaryStatusEnum.InProcess]: "Probíhá",
            [EmployeeSalaryStatusEnum.Approved]: "Schváleno",
            [EmployeeSalaryStatusEnum.Declined]: "Odmítnuto",
            [EmployeeSalaryStatusEnum.Cancelled]: "Zrušeno",
            [EmployeeSalaryStatusEnum.Hold]: "Zdrženo",
            [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "Čeká se na další schválení",
        },
        SortEnum: {
            [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "Datum vytvoření - Vzestupně",
            [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "Datum aktualizace - Vzestupně",
            [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [EmployeeSalaryFilterSortEnum.ReferenceNo]: "Referenční číslo"
        }

    },
    resignations: {
        resignationHeader: "Odchody",
        resignationDate: "Datum odchodu",
        createresignation: "Vytvořit odchod",
        noDataMessage: "– Vytvořte odchod nyní!",
        reasonforresignation: "Důvod odchodu",
        istermination: "Jedná se o ukončení",
        resignationDetail: "Detail odchodu",
        statusEnum: {
            [ResignationApprovalStatusEnum.InProcess]: "Probíhá",
            [ResignationApprovalStatusEnum.Approved]: "Schváleno",
            [ResignationApprovalStatusEnum.Declined]: "Odmítnuto",
            [ResignationApprovalStatusEnum.Cancelled]: "Zrušeno",
            [ResignationApprovalStatusEnum.Completed]: "Dokončeno",
            [ResignationApprovalStatusEnum.Hold]: "Zdrženo",
        },
        resignationFilterTypeEnum: {
            [ResignationFilterTypeEnum.Resignation]: "Odchod",
            [ResignationFilterTypeEnum.CreateByMe]: "Vytvořeno mnou",
            [ResignationFilterTypeEnum.ForApproval]: "K schválení",
        },
        resignationTypeEnum: {
            [ResignationTypeEnum.Resignation]: "Odchod",
            [ResignationTypeEnum.Termination]: "Ukončení",
        },
    },
    Employees: {
        employeeAttachments: "Přílohy zaměstnance",
        CreateEmployeeNow: "Vytvořit zaměstnance nyní",
        ProbationPeriod: "Zkušební doba (dny)",
        requiredMessageProbation: "Zadejte délku zkušební doby",
        requiredMessageProbationPeriod: "Zkušební doba musí být mezi 0 a 30",
        EmployeeNo: "Číslo zaměstnance",
        EnterEmployeeNo: "Zadejte číslo zaměstnance",
        EmploymentType: "Typ zaměstnání",
        SelectEmploymentType: "Vyberte typ zaměstnání",
        requiredMessageEmploymentType: "Prosím vyberte typ zaměstnání",
        OfficeShift: "Směna",
        requiredMessageOfficeShift: "Prosím vyberte směnu",
        SelectOfficeShift: "Vyberte směnu",
        UpdateBasicInfo: "Aktualizovat základní informace",
        Degree: "Stupeň",
        EnterDegree: "Zadejte stupeň",
        requiredDegree: "Prosím zadejte stupeň",
        Institute: "Institut",
        EnterInstituteName: "Zadejte název institutu",
        requiredMessageInstitute: "Prosím zadejte název institutu",
        ObtainedMarks: "Získané body",
        requiredMessageObtained: "Prosím zadejte získané body",
        EnterObtainedMarks: "Zadejte získané body",
        TotalMarks: "Celkové body",
        EnterTotalMarks: "Zadejte celkové body",
        requiredMessageMarks: "Prosím zadejte celkové body",
        UpdateEducation: "Aktualizovat vzdělání",
        AddEducation: "Přidat vzdělání",
        WorkExperienceInfo: "Informace o pracovním zkušenostech",
        UpdateExperience: "Aktualizovat zkušenosti",
        AddExperience: "Přidat zkušenosti",
        EmergencyInfo: "Informace o nouzových situacích",
        UpdateEmergencyForm: "Aktualizovat nouzový formulář",
        AddEmergency: "Přidat nouzový kontakt",
        UpdateEmergency: "Aktualizovat nouzový kontakt",
        UpdateBank: "Aktualizovat bankovní účet",
        AddBank: "Přidat bankovní účet",
        EnableEmployee: "Opravdu chcete povolit tohoto zaměstnance?",
        DisableEmployee: "Opravdu chcete zakázat tohoto zaměstnance?",
        Enable: "Povolit",
        Disabled: "Zakázáno",
        UpdateFamily: "Aktualizovat rodinu",
        AddFamily: "Přidat rodinného člena",
        AddEmployeesLinkage: "Přidat propojení zaměstnanců",
        AllEmployees: "Všichni zaměstnanci",
        FamilyInfo: "Informace o rodině",
        Basic_Info: "Základní informace",
        Email_Configuration: "Konfigurace e-mailu",
        Bank_Detail: "Bankovní účet",
        Education: "Vzdělání",
        Emergency_Info: "Nouzové informace",
        Work_Experience: "Pracovní zkušenosti",
        Experience: "Zkušenosti",
        Rebate: "Sleva",
        Salary: "Plat",
        Leaves: "Dovolená",
        Devices: "Zařízení",
        basicInfo: "Základní informace",
        Appraisal: "Hodnocení",
        Family: "Rodina",
        Attachments: "Přílohy",
        My_Team: "Můj tým",
        Employees_Linkage: "Propojení zaměstnanců",
        Company_Goal: "Cíl společnosti",
        Individual_Goal: "Individuální cíl",
        Emails: "E-maily",
        Finance: "Finance",
        Goal: "Cíle",
        RebateInfo: "Informace o slevě",
        UpdateRebate: "Aktualizovat slevu",
        AddRebate: "Přidat slevu",
        AddSalary: "Přidat plat",
        Statistics: "Statistika"
    },
    leaves: {
        leave: "Dovolená",
        leavedetail: "Detaily dovolené",
        Leave: "Typ dovolené",
        leaveType: "Typ dovolené",
        enterLeave: "Zadejte typ dovolené",
        save: "Uložit typ dovolené",
        Add: "Přidat typ dovolené",
        allotLeaves: "Přidělené dovolené",
        balanceLeaves: "Typy dovolené (zůstávající dovolená)",
        leaveFor: "Dovolená pro",
        leaveDates: "Termíny dovolené",
        createleave: "Vytvořit dovolenou",
        messages: "Prosím vyberte typ dovolené",
        allocatedLeaves: "Přidělené dovolené",
        availedLeaves: "Využité dovolené",
        remainingLeaves: "Zbývající dovolená",
        noDataMessage: "– Vytvořte dovolenou nyní!",
        Leavesinfo: "Informace o dovolené",
        UpdateLeave: "Aktualizovat dovolenou",
        leavesFilterTypeEnum: {
            [LeaveFilterTypeEnum.All]: "Všechny dovolené",
            [LeaveFilterTypeEnum.CreatedByMe]: "Vytvořeno mnou",
            [LeaveFilterTypeEnum.ForApproval]: "K schválení",
            [LeaveFilterTypeEnum.LeaveForMe]: "Moje dovolená",
        },
        statusEnum: {
            [LeaveStatusEum.InProcess]: "Probíhá",
            [LeaveStatusEum.Approved]: "Schváleno",
            [LeaveStatusEum.Declined]: "Zamítnuto",
            [LeaveStatusEum.Resend]: "Znovu odeslat",
            [LeaveStatusEum.InActive]: "Neaktivní",
            [LeaveStatusEum.NotRequired]: "Není vyžadováno",
            [LeaveStatusEum.Cancelled]: "Zrušeno",
            [LeaveStatusEum.Hold]: "Zastaveno",
        },
    },
    bonus: {
        Bonus: "Bonus",
        createBonus: "Vytvořit bonus",
        BonusTo: "Bonus pro",
        CurrentSalary: "Aktuální plat",
        noDataMessage: "– Vytvořte bonus nyní!",
        PercentageShouldNotExceed: "Procento by nemělo přesáhnout 100",
        bonusFilterTypeEnum: {
            [BonusFilterTypeEnum.All]: "Všechny bonusy",
            [BonusFilterTypeEnum.CreatedByMe]: "Vytvořeno mnou",
            [BonusFilterTypeEnum.ForApproval]: "K schválení",
            [BonusFilterTypeEnum.MyBonus]: "Můj bonus",
        },
        statusEnum: {
            [BonusStatusEnum.InProcess]: "Probíhá",
            [BonusStatusEnum.Approved]: "Schváleno",
            [BonusStatusEnum.Declined]: "Zamítnuto",
            [BonusStatusEnum.Cancelled]: "Zrušeno",
            [BonusStatusEnum.Inactive]: "Neaktivní",
            [BonusStatusEnum.NotRequired]: "Není vyžadováno",
            [BonusStatusEnum.Resend]: "Znovu odeslat",
            [BonusStatusEnum.WaitingForOtherApproval]: "Čeká na další schválení",
            [BonusStatusEnum.Hold]: "Zastaveno",
            [BonusStatusEnum.Disbursed]: "Vyplaceno",
            [BonusStatusEnum.Completed]: "Dokončeno",
        },
        SortEnum: {
            [BonusFilterSortEnum.CreateDateDesc]: "Datum vytvoření - Sestupně",
            [BonusFilterSortEnum.CreateDateAsc]: "Datum vytvoření",
            [BonusFilterSortEnum.UpdateDateDesc]: "Datum aktualizace - Sestupně",
            [BonusFilterSortEnum.UpdateDateAsc]: "Datum aktualizace",
            [BonusFilterSortEnum.ReferenceNoDesc]: "Referenční číslo - Sestupně",
            [BonusFilterSortEnum.ReferenceNo]: "Referenční číslo",
            [BonusFilterSortEnum.Amount]: "Částka",
            [BonusFilterSortEnum.AmountDesc]: "Částka - Sestupně",
            [BonusFilterSortEnum.Salary]: "Plat",
            [BonusFilterSortEnum.SalaryDesc]: "Plat - Sestupně"
        }
    },
    chartOfAccount: {
        ChartOfAccount: "Účetní karta",
        CreateAccount: "Vytvořit účet",
        CreateChartOfAccount: "Vytvořit účetní kartu",
        accountType: "Typ účtu",
        selectType: "Vyberte typ",
        parentAccount: "Nadřazený účet",
        selectParent: "Vyberte nadřazený účet",
        EnterAccountType: "Zadejte typ účtu",
        clossingBalance: "Závěrečný zůstatek",
    },
    ledgerReports: {
        reports: "Zprávy",
        selectAccount: "Vyberte účet",
        selectDate: "Vyberte datum",
        generateReport: "Vygenerovat zprávu",
        VoucherNo: "Číslo účetního dokladu",
    },
    requisitions: {
        requisition: "Požadavky",
        MyRequisitions: "Moje požadavky",
        createRequisition: "Vytvořit požadavek",
        PleaseEnterRequisitionName: "Prosím, zadejte název požadavku",
        EnterRequisitionName: "Zadejte název požadavku",
        Budget: "Rozpočet",
        EnterBudget: "Zadejte rozpočet",
        Dateofrequestexpire: "Datum vypršení žádosti",
        noDataMessage: "– Vytvořte nyní požadavky!",
    },
    DefaultApprovers: {
        TypeEnum: {
            [DefaultApprovalTypeEnum.Expense]: "Náklady",
            [DefaultApprovalTypeEnum.ExpenseFinancers]: "Financování nákladů",
            [DefaultApprovalTypeEnum.Travel]: "Cestování",
            [DefaultApprovalTypeEnum.TravelAgent]: "Cestovní agent",
            [DefaultApprovalTypeEnum.Loan]: "Půjčka",
            [DefaultApprovalTypeEnum.Leave]: "Dovolená",
            [DefaultApprovalTypeEnum.Asset]: "Aktiva",
            [DefaultApprovalTypeEnum.Salary]: "Plat",
            [DefaultApprovalTypeEnum.Payroll]: "Mzdové účetnictví",
            [DefaultApprovalTypeEnum.Reward]: "Odměna",
            [DefaultApprovalTypeEnum.ResignationHr]: "Odstoupení - HR",
            [DefaultApprovalTypeEnum.ResignationAdmin]: "Odstoupení - Administrativa",
            [DefaultApprovalTypeEnum.ResignationIt]: "Odstoupení - IT",
            [DefaultApprovalTypeEnum.ResignationFinance]: "Odstoupení - Finance",
            [DefaultApprovalTypeEnum.ResignationExit]: "Odstoupení - Exit",
            [DefaultApprovalTypeEnum.Requisition]: "Žádost",
            [DefaultApprovalTypeEnum.CustomApproval]: "Vlastní schválení",
            [DefaultApprovalTypeEnum.Forms]: "Formuláře",
            [DefaultApprovalTypeEnum.Appraisals]: "Hodnocení",
            [DefaultApprovalTypeEnum.Promotion]: "Povýšení",
            [DefaultApprovalTypeEnum.Warning]: "Varování",
            [DefaultApprovalTypeEnum.Bonus]: "Bonus",
            [DefaultApprovalTypeEnum.Complains]: "Stížnosti",
            [DefaultApprovalTypeEnum.Career]: "Kariéra",
            [DefaultApprovalTypeEnum.Quotations]: "Cenové nabídky",
            [DefaultApprovalTypeEnum.RequisitionFinal]: "Konečné schválení žádosti",
            [DefaultApprovalTypeEnum.Attendance]: "Účast",
            [DefaultApprovalTypeEnum.Auction]: "Aukce",
        },
    },
    NonMandatoryApprovers: {
        TypeEnum: {
            [NonMandatoryApprovalTypeEnum.Expense]: "Náklady",
            [NonMandatoryApprovalTypeEnum.Travel]: "Cestování",
            [NonMandatoryApprovalTypeEnum.Loan]: "Půjčka",
            [NonMandatoryApprovalTypeEnum.Leave]: "Dovolená",
            [NonMandatoryApprovalTypeEnum.Asset]: "Aktiva",
            [NonMandatoryApprovalTypeEnum.Salary]: "Plat",
            [NonMandatoryApprovalTypeEnum.Payroll]: "Mzdové účetnictví",
            [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "Financování nákladů",
            [NonMandatoryApprovalTypeEnum.TravelAgent]: "Cestovní agent",
            [NonMandatoryApprovalTypeEnum.Reward]: "Odměna",
            [NonMandatoryApprovalTypeEnum.ResignationHr]: "Odstoupení - HR",
            [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "Odstoupení - Administrativa",
            [NonMandatoryApprovalTypeEnum.ResignationIt]: "Odstoupení - IT",
            [NonMandatoryApprovalTypeEnum.ResignationFinance]: "Odstoupení - Finance",
            [NonMandatoryApprovalTypeEnum.ResignationExit]: "Odstoupení - Exit",
            [NonMandatoryApprovalTypeEnum.Requisition]: "Žádost",
            [NonMandatoryApprovalTypeEnum.Warning]: "Varování",
            [NonMandatoryApprovalTypeEnum.Complain]: "Stížnost",
            [NonMandatoryApprovalTypeEnum.Bonus]: "Bonus",
            [NonMandatoryApprovalTypeEnum.Promotion]: "Povýšení",
            [NonMandatoryApprovalTypeEnum.RequestForItem]: "Žádost o položku",
            [NonMandatoryApprovalTypeEnum.CustomApproval]: "Vlastní schválení",
            [NonMandatoryApprovalTypeEnum.Form]: "Formulář",
            [NonMandatoryApprovalTypeEnum.Document]: "Dokument",
            [NonMandatoryApprovalTypeEnum.Career]: "Kariéra",
            [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "Konečné schválení žádosti",
            [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "Žádost o položku - Asset Controller",
            [NonMandatoryApprovalTypeEnum.Quotation]: "Cenová nabídka",
            [NonMandatoryApprovalTypeEnum.QuotationClient]: "Cenová nabídka - Klient",
            [NonMandatoryApprovalTypeEnum.Appraisal]: "Hodnocení",
            [NonMandatoryApprovalTypeEnum.Attendance]: "Účast",
            [NonMandatoryApprovalTypeEnum.Auction]: "Aukce",
        }
    },
    activityLog: {
        DeviceType: "Typ zařízení",
        DeviceToken: "Token zařízení",
        OSVersion: "Verze OS",
        Device: "Zařízení",
        IP: "IP adresa",
        Comment: "Komentář",
        DeviceName: "Název zařízení",
        LoginDate: "Datum přihlášení",

    },
    ErrorMessage: {
        someThingWentWrong: "Něco se pokazilo",
    },
    setting: {
        BasicInformation: "Základní informace",
        ChangePassword: "Změnit heslo",
        Notifications: "Oznámení",
        Mobile: "Mobilní číslo",
        About: "O aplikaci",
        ExistingPassword: "Stávající heslo",
        EnterExistingPassword: "Zadejte stávající heslo",
        NewPassword: "Nové heslo",
        EnterNewPassword: "Zadejte nové heslo",
        ConfirmPassword: "Potvrzení hesla",
        EnterConfirmPassword: "Zadejte potvrzení hesla",
        Signature: "Podpis dokumentu",
        NoSignature: "Vytvořte prosím podpis",
        ErrorSignature: "Chyba při vytváření podpisu",
        NoSignatureSave: "Žádný podpis k uložení",
        SignatureSaved: "Podpis uložen",
        SaveSignature: "Uložit podpis",
        ClearSignature: "Vymazat podpis",
        SignatureCreated: "Podpis vytvořen",
        SignatureActive: "Aktivní",
        FailedSignature: "Chyba při získávání podpisů",
        BlockNotificationFeatureTypeEnum: {
            [NotificationBlockFeatureTypeEnum.Reward]: "Odměna",
            [NotificationBlockFeatureTypeEnum.Feed]: "Zprávy",
            [NotificationBlockFeatureTypeEnum.Document]: "Dokument",
            [NotificationBlockFeatureTypeEnum.Project]: "Projekt",
            [NotificationBlockFeatureTypeEnum.Task]: "Úkol",
            [NotificationBlockFeatureTypeEnum.WorkBoard]: "Pracovní deska",
            [NotificationBlockFeatureTypeEnum.Schedule]: "Rozvrh",
            [NotificationBlockFeatureTypeEnum.Group]: "Skupina",
            [NotificationBlockFeatureTypeEnum.Messenger]: "Messenger",
            [NotificationBlockFeatureTypeEnum.Expense]: "Náklady",
            [NotificationBlockFeatureTypeEnum.Auction]: "Aukce",
            [NotificationBlockFeatureTypeEnum.Leave]: "Odejít",
            [NotificationBlockFeatureTypeEnum.Travel]: "Cestování",
            [NotificationBlockFeatureTypeEnum.Warning]: "Varování",
            [NotificationBlockFeatureTypeEnum.Complain]: "Stížnost",
            [NotificationBlockFeatureTypeEnum.Bonus]: "Bonus",
            [NotificationBlockFeatureTypeEnum.Promotion]: "Povýšení",
            [NotificationBlockFeatureTypeEnum.Loan]: "Půjčka",
            [NotificationBlockFeatureTypeEnum.CustomApproval]: "Vlastní schválení",
            [NotificationBlockFeatureTypeEnum.Form]: "Formulář",
            [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "Mzda zaměstnance",
            [NotificationBlockFeatureTypeEnum.Payroll]: "Výplatní pásky",
            [NotificationBlockFeatureTypeEnum.Career]: "Kariéra",
            [NotificationBlockFeatureTypeEnum.Requisition]: "Žádost",
            [NotificationBlockFeatureTypeEnum.Resignation]: "Odstoupení",
            [NotificationBlockFeatureTypeEnum.Quotation]: "Kvotace",
            [NotificationBlockFeatureTypeEnum.Appraisal]: "Ocenění",
            [NotificationBlockFeatureTypeEnum.LeadManager]: "Vedoucí manažer",
            [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "Diskusní deska",
            [NotificationBlockFeatureTypeEnum.Pages]: "Stránky",
        }
    },
    emailConfiguration: {
        emailConfiHeader: "Konfigurace uživatelského emailu",
        emailConfi: "Uživatelské jméno",
        password: "Heslo",
        enterEmailConfi: "Zadejte uživatelské jméno",
        incomingPort: "Příchozí port",
        enterIncomingPort: "Zadejte příchozí port",
        incomingServerAddress: "Adresa příchozího serveru",
        enterIncomingServerAddress: "Zadejte adresu příchozího serveru",
        outgoingPort: "Odchozí port",
        enterOutgoingPort: "Zadejte odchozí port",
        outgoingServerAddress: "Adresa odchozího serveru",
        enterOutgoingServerAddress: "Zadejte adresu odchozího serveru",
        provider: "Poskytovatel",
        enterProvider: "Zadejte poskytovatele",
        saveEmailConfi: "Uložit konfiguraci emailu",
        addEmailConfi: "Přidat konfiguraci emailu",
        name: "Název",
        clear: "Vymazat",
    },
    assetsAllocation: {
        assetsAllocation: "Přidělení aktiv",
        assests: "Aktiva",
        createAssetAllocation: "Vytvořit přidělení aktiv",
        handover: "Předání",
        forMaintenance: "Pro údržbu",
        noResultFound: "Nenalezen žádný výsledek...",
        addAssetsAllocation: "Přidat přidělení aktiv",
        noDataMessage: "– Vytvořte nyní přidělení aktiv!",
        allocationForMe: "Přidělení pro mě",
        allocationApprovals: "Schválení přidělení",
        deAllocation: "Změnit stav přidělení",
        liability: "Závazek",
        capital: "Kapitál",
        revenue: "Příjmy",
        expense: "Výdaje",
        costOfGoodsale: "Náklady na prodané zboží",
        assetsCategory: "Kategorie aktiv",
        parentAccount: "Nadřazený účet",
        selectParent: "Vyberte nadřazeného",
        assetsList: "Seznam aktiv",
        createAssests: 'Vytvořit aktiva',
        sno: 'Pořadové číslo',
        inventoryName: 'Název inventáře',
        inventoryValue: 'Hodnota inventáře',
        SelectAssetsController: "Vyberte správce aktiv",
    },
    requestListItems: {
        noDataMessage: "– Vytvořte žádost nyní!",
        requestForItems: "Žádost o položky",
        RequestForItemsApprovals: "Schválení žádosti o položky",
        AssetController: "Správce aktiv",
        RequestItem: "Žádat položku",
    },
    profile: {
        firstMenu: '1. položka menu',
        secondMenu: '2. položka menu (zakázáno)',
        thirdMenu: '3. položka menu (zakázáno)',
        dangerItem: 'nebezpečná položka',
        NoPosition: "Žádná pozice",
        NoInstitute: "Žádný institut",
        NotFound: "Nenalezeno",
        University: "Univerzita",
        School: "Škola",
        Graduated: "Absolvoval",
        CurrentlyWorkHere: "Aktuálně zde pracuji",
        Statistics: "Statistiky",
        StorageStatistics: "Statistiky úložiště",
        TotalStorageUsed: "Celkové použité úložiště"
    },

    /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***///
    status: {
        InProcess: "Probíhá",
        Approved: "Schváleno",
        Declined: "Odmítnuto",
        Resend: "Znovu odeslat",
        Inactive: "Neaktivní",
        NotRequired: "Není vyžadováno",
        Cancelled: "Zrušeno",
        ApprovalRequired: "Vyžaduje se schválení",
        Hold: "Podržet",
        WaitingForOtherApprover: "Čekání na další schvalovatele",
        Draft: "Návrh",
        Remark: "Poznámka",
        Removed: "Odebráno"
    },
    approvalDictionaryLabel: {
        Approval: "Schválení",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Vykonavatel",
        [ApprovalsModuleEnum.ExpenseFinance]: "Finance",
        [ApprovalsModuleEnum.ResignationHrApproval]: "HR",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finance",
        [ApprovalsModuleEnum.ResignationItApproval]: "IT",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Správa",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Ostatní schválení",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Exit Interview",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Reporting To",
        [ApprovalsModuleEnum.TravelApproval]: "Schvalovatelé",
        [ApprovalsModuleEnum.TravelAgent]: "Agent",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Poslední schvalovatelé",
        [ApprovalsModuleEnum.LoanApproval]: "Schvalovatelé",
        [ApprovalsModuleEnum.AuctionApproval]: "Schvalovatelé"
    },

    approvalDictionaryPlaceHolder: {
        Approval: "Schválení",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Vyberte vykonavatele nákladů",
        [ApprovalsModuleEnum.ExpenseFinance]: "Vyberte schvalovatele z finančního oddělení",
        [ApprovalsModuleEnum.ExpenseApproval]: "Vyberte schvalovatele nákladů",
        [ApprovalsModuleEnum.ResignationHrApproval]: "Vyberte HR",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Vyberte finance",
        [ApprovalsModuleEnum.ResignationItApproval]: "Vyberte IT",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Vyberte správu",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Vyberte ostatní schvalování",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Vyberte exitový rozhovor",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Vyberte Reporting To",
        [ApprovalsModuleEnum.TravelApproval]: "Vyberte schvalovatele pro cestování",
        [ApprovalsModuleEnum.TravelAgent]: "Vyberte agenta pro cestovní aranžmá",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Vyberte poslední schvalovatele",
        [ApprovalsModuleEnum.LoanApproval]: "Vyberte schvalovatele",
        [ApprovalsModuleEnum.AuctionApproval]: "Vyberte schvalovatele"

    },


    // *** Extra label *** //
    /*** Define language write side ***/
    Direction: "ltr",
};

export default czech;