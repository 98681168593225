import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import {
  PostReferenceType,
  PostType,
  ReactionType,
} from "../../../main/features/feed/utils/constants";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import {
  AuctionOfferStatusEnum,
  AuctionStatusEnum,
  auctionFilterTypeEnum,
} from "../../../main/features/auction/utils/enums";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { AccountTypeEnum } from "../../../main/features/trialBalance/utils/AccountTypeEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { CareerInterviewStatusEnum } from "../../../main/features/careers/utils/CareerInterviewStatusEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { LoanFilterSortEnum } from "../../../main/features/loan/utils/loanFilterSortEnum";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { LeaveFilterSortEnum } from "../../../main/features/leave/utils/leaveFilterSortEnum";
import { PromotionFilterSortEnum } from "../../../main/features/promotion/utils/PromotionFilterSortEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { ResignationFilterSortEnum } from "../../../main/features/resignation/utils/ResignationFilterSortEnum";
import { ResignationPurposeEnum } from "../../../main/features/resignation/utils/resignationPurposeEnum";
import { TravelFilterSortEnum } from "../../../main/features/travel/utils/travleFilterSortEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { CareerFilterSortEnum } from "../../../main/features/careers/utils/careerFilterSortEnum";
import { CareerLevelEnum } from "../../../main/features/careers/utils/careerLevelTypeEnum";
import { JobTypeEnums } from "../../../main/features/careers/utils/jobTypeEnum";
import { JobShiftTypeEnums } from "../../../main/features/careers/utils/jobShiftTypeEnum";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum"
import { BusinessNumberContactReferenceTypeEnum } from "../../../main/features/dialer/utils/enum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums"
import { InputFieldTypes } from "../../../main/features/quotation/utils/quotationInputFieldTypes";
import { ProjectFeatureEnums } from "../../Shared/enums/projectFeatureEnum";
const en = {
  /*** PLEASE READ FIRST ALL COMMENTS BEFORE ADD LABELS ***/

  /*** add all shared labels like send, ok, cancel, etc ***/

  sharedLabels: {
    SelectLanguage: "Select Language",
    English: "English ",
    Turkish: "Turkish",
    Hindi: "Hindi",
    Urdu: "اردو",
    Arabic: "عربي",
    czech: "Czech",
    russian: "Russian",
    french: "French",
    spanish: "Spanish",
    italian: "Italian",
    theme: "Theme",
    awardTo: "Award To",
    inprogress: "In Progress",
    filter: "Filter",
    approvals: "Approvals",
    approvers: "Approvers",
    members: "Members",
    Approvers: "Approvers",
    resignations: "Resignations",
    Description: "Description",
    Descriptionisrequired: "Description is required!",
    WriteDescriptionhere: "Write Description here...",
    enterDescription: "Enter Description",
    pleseEnterDescription: "Please Enter Description",
    pleaseEnterGrade: "Please Enter Grade",
    manager: "Manager",
    requiredMessageManager: "Please Select Manager",
    SelectManager: "Select Manager",
    create: "Create",
    humanresource: "HR",
    finance: "Finance",
    it: "IT",
    admin: "Admin",
    selectMember: "Select Member",
    othersapprovals: "Others Approvals",
    exitinterview: "Exit Interview",
    selectAperson: "Select a person",
    SelectHumanResource: "Select HR",
    selectIt: "Select IT",
    selectFinance: "Select Finance",
    selectAdmin: "Select Admin",
    selectOthersApprovals: "Select Others Approvals",
    inProcess: "In Process",
    reason: "Reason",
    refrence: "Refrence",
    Rewards: "Rewards",
    name: "Name",
    add: "Add",
    action: "Action",
    userType: "User Type",
    SelectUserType: "Select User Type",
    requiredMessageUserType: "Please Select User Type",
    RewardTo: "Reward To",
    WarningTo: "Warning To",
    Approved: "Approved",
    Declined: "Declined",
    Resend: "Resend",
    NotRequired: "Not Required",
    Cancelled: "Cancelled",
    InActive: "In Active",
    ListView: "List View",
    TableView: "Table View",
    MyReward: "My Reward",
    ForApprovals: "For Approvals",
    SignatureDocument: "Signature Documents",
    ForApproval: "For Approval",
    RewardToMe: "Reward To Me",
    Search: "Search",
    Disable: "Disable",
    Attachments: "Attachments",
    Update: "Update",
    update: "update",
    AddEmployee: "Add Employee",
    complain: "Complain",
    Submit: "Submit",
    Delete: "Delete",
    Edit: "Edit",
    NotDesignated: "Not Designated",
    Profile: "Profile",
    Settings: "Settings",
    Logout: "Logout",
    Table: "Table",
    List: "List",
    Grid: "Grid",
    GridView: "Grid View",
    startyear: "Start Year",
    endyear: "End Year",
    startMonth: "Start Month",
    endMonth: "End Month",
    EnterStartDate: "Enter Start Date",
    SelectStartDate: "Select Start Date",
    startDate: "Start Date",
    endDate: "End Date",
    businessLogo: "Business Logo",
    title: "Title",
    enterTitle: "Enter Title",
    percentage: "Percentage",
    PleaseEnterPercentage: "Please Enter Percentage",
    previousCharge: "Previous Charge",
    min: "Min",
    max: "Max",
    country: "Country",
    requiredCountry: "Country is required",
    SelectCountry: "Select Country",
    latitude: "Latitude",
    longitude: "Longitude",
    address: "Address",
    EnterAddress: "Enter Address",
    requiredMessageAddress: "Please enter address",
    branch: "Branch",
    question: "Questions",
    allotLeaves: "Alloted Leaves",
    SubjectRequired: "Subject Required",
    WriteSubject: "Write Subject ",
    category: "Category",
    amount: "Amount",
    documents: "Documents",
    enterName: "Enter Name",
    nameRequired: "Name is Required",
    accessTo: "Access To",
    selectAccessTo: "Select Access To",
    collaborators: "Collaborators",
    selectCollaborators: "Select Collaborators",
    readers: "Readers",
    c: "Select Readers",
    createBoard: "Create Board",
    createPad: "Create Pad",
    createGrid: "Create Grid",
    createSlide: "Create Slide",
    createMindmap: "Create Mindmap",
    drive: "Drive",
    myDrive: "My Drive",
    allDocuments: "All Documents",
    subject: "Subject",
    Subjectisrequired: "Subject is required",
    pleaseEnterSubject: "Please Enter Subject",
    EnterSubject: "Enter Subject",
    enterAmount: "Enter Amount",
    pleaseEnterAmount: "Please Enter Amount",
    upload: "Upload",
    value: "Value",
    addCategory: "Add Category",
    saveCategory: "Save category",
    clear: "Clear",
    loans: "Loans",
    disburse: "Disburse",
    disburseDate: "Disburse Date",
    disburseDateMessage: "Please Enter Salary Disburse Date",
    referenceNo: "Reference No",
    status: "Status",
    createDate: "Create Date",
    creator: "Creator",
    netSalary: "Net Salary",
    type: "Type",
    PleaseSelectType: "Please Select Type",
    CreatedByMe: "Created By Me",
    Members: "Members",
    Member: "Member",
    pleaseEnterCategory: "Please Enter Category",
    Memberisrequired: "Member is required",
    EnterCategoryName: "Enter Category Name",
    Clear: "Clear",
    ReferenceNumber: "Reference Number",
    Grade: "Grade",
    Status: "Status",
    CreateDate: "Create Date",
    Creator: "Creator",
    SNo: "S.No.",
    Employee: "Employee",
    SelectApprovers: "Select Approvers",
    Approverisrequired: "Approver is required",
    Yes: "Yes",
    No: "No",
    totalAmount: "Total Amount",
    Total: "Total",
    Date: "Date",
    Pleaseselectdate: "Please select date",
    SelectDate: "Select Date",
    requiredMessageDate: "Please Select Date",
    Priority: "Priority",
    Pleaseselectpriority: "Please select priority",
    Pleaseselecttype: "Please select type",
    SelectProject: "Select Project",
    SelectGroup: "Select Group",
    Project: "Project",
    Group: "Group",
    Progress: "Progress",
    SuretoRate: "Sure to Rate ?",
    Invalidfiletype: "Invalid file type",
    Pleaseinputyoursubject: "Please input your subject",
    Pleaseinputyourdescription: "Please input your description",
    Return: "Return",
    EnterReason: "Enter Reason",
    TravelReason: "Reason for Travel",
    NoDesignation: "No Designation",
    Hi: "Hi",
    Quotation: "Quotation",
    Amountisrequired: "Amount is required",
    WriteDescriptionHere: "Write Description Here ...",
    Types: "Types",
    Header: "Header",
    MyTeam: "My Team",
    Email: "Email",
    EnterEmail: "Enter Email",
    EmailIsRequired: "Email is required",
    PhoneNo: "Phone No",
    Designation: "Designation",
    ReferenceType: "Reference Type",
    referenceId: "referenceId",
    Save: "Save",
    save: "save",
    Color: "Color",
    Yesterday: "Yesterday",
    Overdue: "Overdue",
    Today: "Today",
    Complete: "Complete",
    DueDate: "Due Date",
    SelectDueDate: "Select Due Date",
    Cover: "Cover",
    Cancel: "Cancel",
    cancel: "Cancel",
    and: "and",
    other: "other",
    Others: "Others",
    Option: "Option",
    Public: "Public",
    Private: "Private",
    click: "click",
    With: "With",
    with: "with",
    Comments: "Comments",
    Commentsdot: "Comments...",
    Comment: "Comment",
    Seeless: "See less",
    Seemore: "See more",
    Share: "Share",
    ShareNow: "Share Now",
    SelectEmployee: "Select Employee",
    SelectCategory: "Select Category",
    PleaseSelectCategory: "Please Select Category",
    privacy: "Privacy",
    tags: "Tags",
    updateDate: "Update Date",
    otherApprovals: "Other Approvals",
    resignationDate: "Resignation Date",
    purpose: "Purpose",
    warnings: "Warnings",
    Courses: "Courses",
    selectType: "Select type",
    daysCount: "Days Count",
    reportingTo: "Reporting To",
    selectReason: "Select Reason",
    pleaseSelectReason: "Please Select Reason",
    days: "Days",
    day: 'day',
    Day: 'Day',
    Dates: "Dates",
    selectMembers: "Select Members",
    selectGrade: "Select Grade",
    newGrade: "New Grade",
    currentGrade: "Current Grade",
    PleaseSelectMember: "Please Select Member",
    enterCategory: "Enter Category",
    deadline: "Deadline",
    Department: "Department",
    designationmessage: "Please select designation",
    selectDesignation: "Select designation",
    jobdescription: "Job Description",
    salaryRange: "Salary Range",
    interviewers: "Interviewers",
    hiringBuddy: "Hiring Buddy",
    enterCity: "Please enter city",
    City: "City",
    selectCity: "Select city",
    requiredCity: "City is required",
    skills: "Skills",
    experience: "Experience (Years)",
    Experience: "Experience",
    jobType: "Job Type",
    education: "Education",
    interview: "Interviewer",
    Rating: "Rating",
    poweredbyWorkw: "Powered by Workwise Ltd - Copyrights 2016-",
    copyLink: "Copy Link",
    EffectiveDate: "Effective Date",
    experienceRequired: "Experience Required",
    user: "Users",
    postinterview: "Post Interviewrs",
    Copied: "Copied",
    unreadNotifyItem: "unreadNotifyItem",
    createBy: "Create By",
    Create: "Create",
    Open: "Open",
    Closed: "Closed",
    Nodescription: "No description ",
    writeDescription: "Write your description here...",
    Employees: "Employees",
    BasicInfo: "Basic Info",
    FatherName: "Father Name",
    EnterFatherName: "Enter Father Name",
    PersonalEmail: "Personal Email",
    EnterPersonalEmail: "Enter Personal Email",
    personalEmailIsRequired: "Personal Email is required",
    PhoneNumber: "Phone Number",
    EnterPhoneNumber: "Enter Phone Number",
    NationalIdentity: "National  Identity",
    EnterNationalIdentityNumber: "Enter National Identity Number",
    Grades: "Grades",
    SelectGrade: "Select Grade",
    requiredMessageGrade: "Please Select Grade",
    EnterProbationPeriod: "Enter Probation Period",
    NoticePeriod: "Notice Period(Days)",
    EnterNoticePeriod: "Enter Notice Period",
    requiredMessageNoticePeriod: "Please input your Notice Period",
    requiredMessageNoticePeriodRestriction: "Notice period must be between 1 and 60",
    DateOfBirth: " Date Of Birth",
    Gender: "Gender",
    requiredMessageGender: "Please Select Gender",
    SelectGender: "Select Gender",
    MartialStatus: "Martial Status",
    SelectMartialStatus: "Select Martial Status",
    requiredDepartment: "Department is required",
    SelectDepartment: "Select Department",
    Subsidiary: "Subsidiary",
    SelectSubsidiary: "Select Subsidiary",
    SubsidiaryOffice: "Subsidiary Office",
    SelectSubsidiaryOffice: "Select Subsidiary Office",
    PayrollGroup: "Payroll Group",
    SelectPayrollGroup: "Select Payroll Group",
    DefaultApproval: "Default Approval",
    SelectDefaultApproval: "Select Default Approval",
    SelectAll: "Select All",
    NonMandatoryApprovals: "Non-Mandatory Approvals",
    SelectNonMandatoryApprovals: "Select Non-Mandatory Approvals",
    FirstName: "First Name",
    EnterFirstName: "Enter First Name",
    requiredMessageName: "Please Enter Name",
    requiredMessageFName: "Please Enter First Name",
    requiredMessageLName: "Please Enter Last Name",
    LastName: "LastName",
    EnterLastName: "Enter Last Name",
    DateOfJoining: "Date Of Joining",
    requiredDateOfBirth: "Date Of Birth is required",
    requiredMessageJoiningDate: "Please Select Joining Date",
    JobShift: "Job Shift",
    requiredMessageJobShift: "Please Select Job Shift",
    SelectJobShift: "Select Job Shift",
    AccessRole: "Access Role",
    requiredMessageAccessRole: "Please select at least one access role",
    SelectAccessRole: "Select Access Role",
    StartEndDate: "Start/End Date",
    ErrorInValidation: "Error in validation",
    EducationInfo: "Education Info",
    NonMandatory: "Non-Mandatory",
    Present: "Present",
    FailedToFetch: "Failed To Fetch",
    Position: "Position",
    EnterPosition: "Enter Position",
    requiredMessagePosition: "Please enter position",
    Organization: "Organization",
    EnterOrganization: "Enter Organization",
    requiredMessageOrganziation: "Please Enter Organization",
    Number: "Number",
    EnterNumber: "Enter Number",
    requiredMessageNumber: "Please Enter Number",
    Relation: "Relation",
    Id: "ID",
    SelectRelation: "Select Relation",
    requiredMessageRelation: "Relation is required",
    AccountName: "Account Name",
    BranchCode: "Branch Code",
    EnterBranchCode: "Enter Branch Code",
    requiredMessageBranchCode: "Branch Code is required",
    BankName: "Bank Name",
    requiredMessageBankName: "Bank Name is required",
    EnterBankName: "Enter Bank Name",
    AccountNumber: "Account Number",
    EnterAccountNumber: "Enter Account Number",
    requiredAccountNumber: "Account Number Is required",
    IBAN: "IBAN",
    EnterIBAN: "Enter IBAN",
    requiredIBAN: "IBAN Number Is required",
    SortCode: "Sort Code",
    IsDefault: "IsDefault",
    requiredSortCode: "Sort Code is required",
    EnterSortCode: "Enter Sort Code",
    BankInfo: "Bank Info",
    AccountTitle: "Account Title",
    EnterAccountTitle: "Enter Account Title",
    requiredMessageAccount: "Account Title is required",
    validAccountTitle: "Please enter a valid account title",
    AttachmentType: "Attachment Type",
    requiredAttchmentType: "Attachment Type Required",
    Attachment: "Attachment",
    requiredAttachment: "Attachment is required",
    AddAttachment: "Add Attachment",
    requiredMessageAssignMember: "Please Select Assign Member",
    Remove: "Remove",
    ContactNo: "Contact No.",
    EnterContactNo: "Enter Contact No",
    requiredContactNo: "Contact No. is required",
    Contact: "Contact",
    pleasEnterDescription: "Please enter description.",
    Information: "Information",
    Features: "Features",
    AddMembers: "Add Members",
    Summary: "Summary",
    AcessRole: "Acess Role",
    defaultApprovers: "default Approvers",
    NonMandatoryApprovers: "Non Mandatory Approvers",
    Pleasefillallrequiredfields: "Please fill all required fields",
    Suretodelete: "Sure to delete?",
    Code: "Code",
    createdDate: "Created Date",
    Searchmembers: "Search members",
    AddDate: "Add Date",
    duesoon: "due soon",
    Payroll: "Payroll",
    ReferenceNo: "ReferenceNo",
    Associate: "Associate",
    InternalMembers: "Internal Members",
    ExternalMembers: "External Members",
    TotalExpense: "Total Expense",
    TotalExpenseAmount: "Total Expense Amount",
    TotalPost: "Total Post",
    TotalMeeting: "Total Meeting",
    Expense: "Expense",
    TotalBudget: "Total Budget",
    Balance: "Balance",
    successfully: "successfully",
    enterValidEmail: 'Please enter a valid email address',
    ViewSummary: "View Summary",
    AddAdmin: "Add Admin",
    AddExternals: "Add Externals",
    DefaultApprovers: " Default Approvers",
    Billings: " Billings",
    spent: "Spent",
    SelectUser: "Select User",
    Image: "Image",
    Contacts: "Contacts",
    noContacts: "No Contacts",
    AddContact: "Add Contact",
    Roles: "Roles",
    Tag: "Tag",
    AccessRoles: "Access Roles",
    member: "member",
    uploadCover: "Upload Cover",
    Pleaseselect: "Please select",
    composeEmail: "Compose Email",
    send: "Send",
    ContactDetail: "Contact Detail",
    Detail: "Detail",
    UploadImage: "Upload Image",
    WriteName: "Write Name",
    business: "Business",
    website: "Website",
    enterWebsite: "Enter Website",
    Unknown: "Unknown",
    Interested: "Interested",
    NotInterested: "Not Interested",
    meetings: "Meetings",
    noMeetings: "No Meetings",
    Communications: "Communications",
    Details: "Details",
    LastUpdate: "Last Update",
    nameRequiredMessage: "Name required!",
    addressRequiredMessage: "Address required!",
    Intrested: "Interested",
    StartDateTime: " Date & Time",
    DateTimeisrequired: "Date & Time is required",
    Selectdatetime: "Select date & time",
    Board: "Board",
    Notes: " Notes",
    Miletap: "Miletap",
    EMail: "E-mail",
    Attendance: "Attendance",
    Absent: " Absent",
    NoData: "No Data",
    Prority: "Prority",
    Heigh: "Heigh",
    Medium: "Medium",
    Low: "Low",
    error: "error",
    Select: "Select",
    Interview: "Interview",
    Meeting: "Meeting",
    Appointment: " Appointment",
    Pleaseinputyouremail: "Please input your email",
    EnterEmailAddress: "Enter Email Address",
    AddExternalMember: "Add External Member",
    Pleaseenterexternalmembers: "Please enter external members",
    externals: "externals",
    Decline: "Decline",
    Approve: "Approve",
    NotAvailable: "Not Available",
    AddApprovals: "Add Approvals",
    Everyone: "Everyone",
    SelectDownloaders: "Select Downloaders",
    SelectCollabrators: "Select Collabrators",
    pleaseSelectAssignMemeber: "Please Select Assign Member!",
    Availed: "Availed",
    Allotted: "Allotted",
    Degree: "Degree",
    Time: "Time",
    Mood: "Mood",
    CheckOut: "Check Out",
    CheckIn: "Check In",
    Late: "Late",
    State: "State",
    PleaseSelectYesNo: "Please Select Yes/No",
    PleaseselectGrade: "Please select Grade",
    PleaseenterAmount: "Please enter Amount",
    Salary: "Salary",
    Task: "Task",
    Nameisrequired: "Name is required",
    PhoneNumberisrequired: "Phone Number is required",
    Dateisrequired: "Date is required",
    Month: "Month",
    Year: "Year",
    FinalApprovers: "Final Approvers",
    link: "Link",
    forFinalApproval: "For Final Approval",
    validateBonus: "Unable to process bonus: No salary information available for the employee",
    validateLoan: "Unable to process loan: No salary information available for the employee",
    User: "User",
    Action: "Action",
    Created: "Created",
    Toast: "Toast",
    selectyourcountry: "Please select your country",
    PleaseEnterPhoneNumber: "Please Enter Phone Number",
    Pleaseselectyourcity: "Please select your city",
    SelectCurrency: "Select Currency",
    Pleaseselectcurrency: "Please select currency",
    totalGroups: "Total Groups",
    totalProjects: "Total Projects",
    totalMemory: "Total Memory",
    memory: "Memory",
    EnterMemory: "Enter Memory",
    availedMemory: "Availed Memory (gbs)",
    used: "Used",
    usedMemory: "Used Memory (gbs)",
    From: "From",
    To: "To",
    WelcomeSendEmail: "Welcome Send Email",
    SendEmail: "Send Email",
    writeYourRemarksHere: "Write Yours Remarks Here...",
    NoRemarkFound: "No Remark Found",
    ToastEnable: "Toast Enable",
    EmailEnable: "Email Enable",
    FeatureName: "Feature Name",
    LoginMessage: "Please enter credentials first",
    netAmount: "Net Amount",
    SelectedDate: "Selected Date",
    SelectedUser: "Selected User",
    Default: "Default",
    mylog: "My Log",
    SelectStatus: "Select Status",
    Selectuser: "Select a user",
    PolicyType: "Policy Type",
    Percent: "Percent",
    uploadProfilePicture: "Upload Profile Picture",
    inputUsername: "Please input username",
    userName: "username",
    Dummy: "Dummy",
    Lesson: "Lesson",
    Duration: "Duration",
    Text: "Text",
    Enterlink: "Enter link",
    Dashboard: "Dashboard",
    searchSchedule: "Search Schedule...",
    AddOption: "Add Option",
    ChangeImage: "Change Image",
    DeleteImage: "Delete Image",
    Question: "Question",
    Next: "Next",
    Prev: "Prev",
    Wrong: "Wrong",
    Correct: "Correct",
    YourResult: "Your Result",
    Createdby: "Created by",
    Lastupdated: "Last updated",
    Assignedto: "Assigned to",
    Addlesson: "Add lesson",
    Addtopic: "Add topic",
    minutes: "minutes",
    Modules: "Modules",
    Valid: "Valid",
    Expired: "Expired",
    AssignedMembers: "Assigned members",
    Start: "Start",
    Previous: "Previous",
    Read: "Read",
    DeleteBook: "Delete Book",
    Assign: "Assign",
    Explanation: "Explanation",
    Hey: "Hey",
    Pleaseenterenddate: "Please enter end date",
    requiredMessageEmail: "Please Enter Email",
    BusinessName: "Business Name",
    PleaseEnterBusinessName: "Please Enter Busimess Name",
    BusinessAddress: "Business Address",
    PleaseEnterBusinessAddress: "Please Enter Busimess Address",
    Offers: "Offers",
    PleaseEnterOffer: "Please Enter Offer",
    CreateOffer: "Create Offer",
    AppliedDate: "Applied Date",
    ShortList: "Short List",
    Finalised: "Finalised",
    Member: "Member",
    SelectMember: 'Select member',
    Approver: "Approvers",
    CategoryName: "Category Name",
    serialNo: "serial No",
    Allocated: "Allocated",
    Available: "Available",
    lost: "Lost",
    damage: "Damaged",
    itemType: "Item Type",
    Service: "Service",
    consumable: "Consumable",
    NonConsumable: "Non Consumable",
    Quantity: "Quantity",
    accountType: "Account Type",
    PleaseEnterQuantity: "Please enter a quantity",
    QuantityMustBePositive: "Quantity must be a positive",
    MembersIsRequired: "Members is required",
    CreateRequest: "Create Request",
    IBANNumber: "IBAN Number",
    About: "About",
    AboutMe: "About Me",
    TimePeriod: "Time Period",
    Company: "Company",
    CityTown: "City/Town",
    BankDetails: "Bank Details",
    Work: "Work",
    addWorkPlace: "Add Work Place",
    AddEducation: "Add a education",
    basicSalary: "Basic Salary",
    feed: 'Feed',
    TakeaNote: "Take a Note",
    awards: 'Awards',
    Appraisal: "Appraisal",
    salary: 'Salary',
    activityLog: "Activity Log",
    device: 'Device',
    leave: 'Leave',
    Ip: "Ip",
    DeviceId: "Device Id",
    AppVersion: "App Version",
    Selectfeature: "Select feature",
    EnterYourFeedBackDescription: "Enter your feedback description",
    HideAll: "Hide All",
    ShowAll: "Show All",
    PrintBy: "Print By",
    AccCode: "Acc.Code",
    SelectAccountType: "Select Account Type",
    Files: "Files",
    Space: "Space",
    DisburseVoucher: "Disburse/Voucher",
    TopUsersbyMostFiles: "Top Users by Most Files",
    TopUsersbyStorageSize: "Top Users by Storage Size",
    Apply: "Apply",
    NumberOfTopUsers: "Number of Top Users",
    TopUsers: "Top Users",
    SearchUser: "Search User",
    ByStorageSize: "By Storage Size",
    ByFileSize: "By File Size",
    companyDashboard: "Company Dashboard",
    signup: "Signup Not Confirmed",
    billing: "Billing",
    PendingBills: "Pending Bills",
    MonthYear: "Month - Year",
    SendBill: "Send Bill",
    BillingUsers: "Billing Users",
    Item: "Item",
    Rate: "Rate",
    PaidAmount: "Paid Amount",
    PaymentDate: "Payment Date",
    DarkDays: "Dark Days",
    ProcessingFee: "Processing Fee",
    Currency: "Currency",
    IsEnable: "Is Enable",
    Actions: "Actions",
    TakeNote: "Take A Note",
    PleaseSelectUsers: "Please Select Users",
    StickyNotes: "Sticky Notes",
    Copy: "Copy",
    MarkAllRead: "Mark All Read",
    SeeAll: "See All",
    Refresh: "Refresh",
    close: "Close",
    Calling: "Calling",
    LoadMore: "Load More",
    Quizez: "Quizez",
    content: "Content"
  },


  /*** navMenuLabel only used in LeftSideBar ***/
  navMenuLabel: {
    Schedules: "Schedules",
    workWiseCompanies: "Workwise Companies",
    promotions: "Promotions",
    customApprovals: "Custom Approvals",
    feed: "Newsfeed",
    messenger: "Messenger",
    chartOfAccount: "Chart of Accounts",
    createVoucher: "Create Voucher",
    voucherList: "Voucher List",
    Quotation: "Quotations",
    QuotationClient: "Quotation Client",
    transaction: "Transactions",
    report: "Report",
    mailBox: "Mail Box",
    groups: "Groups",
    projects: "Projects",
    tasks: "Tasks",
    discussionBoard: "Discussion Board",
    pages: "Pages",
    workBoard: "Work Boards",
    leadManager: "Lead Manager",
    schedules: "Schedules",
    expense: "Expenses",
    travel: "Travels",
    docsArchives: "Docs/Archives",
    eLearning: "E-Learning",
    inventory: "Inventory",
    myTeam: "My Team",
    orgChart: "Organizational Chart",
    employee: "Employees",
    administration: "Administration",
    appraisals: "Appraisals",
    departments: "Departments",
    leaves: "Leaves",
    basicInfo: "Basic Info",
    holidays: "Holidays",
    careers: "Careers",
    companiesSection: "Workwise Companies",
    companies: "Companies",
    menu: "Menu",
    hr: "HR",
    finance: "Finance",
    inventorySection: "Inventory",
    privacy: "Privacy",
    TC: "T & C",
    more: "more",
    download: "Downloads",
    feedBack: "Feedback",
    warnings: "Warnings",
    complains: "Complains",
    bonus: "Bonus",
    resignations: "Resignations",
    requisition: "Requisitions",
    assets: "Assets Allocation",
    assetsList: "Assets List",
    createAssets: "Create Assets",
    requestListItems: "Request For Items",
    rewards: "Rewards",
    businessPolicy: "Business Policies",
    companiesPolicy: "Compnaies Policies",
    form: "Forms",
    ledgerReport: "Ledger Reports",
    trialBalance: "Trial Balance",
    salary: "Salary",
    payroll: "Payroll",
    jobBoard: "Job Board",
    teams: "My Team",
    LD: "L & D",
    marketPlace: "Marketplace",
    loan: "Loans",
    Courses: "Courses",
    selectMembers: "Select Members",
    auction: "Auction",
    Nodescription: " No description ",
    items: 'Items',
    selectItems: 'Please select items',
    contract: "Contracts"
  },

  /*** AppHeader only used in Component headerTab ***/
  appHeader: {

    newsFeed: {
      posts: "Posts",
      photos: "Photos",
      videos: "Videos",
      polls: "Polls",
      docs: "Docs",
      tagged: "Tagged",
    },
    mailBox: {},
    messenger: {},
    groups: {
      groups: "Groups",
    },
    projects: {
      projects: "Projects",
      dashboard: "Dashboard",
    },
    Task: {
      Tasks: "Tasks",
      myTask: "My Tasks",
      assignedByMe: "Assigned By Me",
      createdByMe: "Created By Me",

      teamTask: "Team Task",
      dashboard: "Dashboard",
    },
    leadManager: {
      leadCategory: "Lead Category",
    },

    schedules: {
      calendar: "Calendar",
      schedule: "Schedule",
      scheduleInterviews: "Schedule Interviews",
    },
    expense: {
      expenses: "Expenses",
      forApprovals: "For Approvals",
      myExpenses: "My Expenses",
      forExecution: "For Execution",
      forFinance: "For Finance",
      reports: "Reports",
      dashboard: "Dashboard",
    },
    travel: {
      travels: "Travels",
      forApprovals: "For Approvals",
      agentProcess: "Agent Process",
      travelDetail: "Travel Detail",
    },
    docsArchives: {
      documents: "Documents",
      myDrive: "My Drive",
      myDocuments: "My Documents",
      forApprovals: "For Approvals",
    },
    eLearning: {
      dashboard: "Dashboard",
    },
    inventory: {
      approvals: "Approvals",
      inventory: "Inventory",
      category: "Category",
    },
    customApproval: {
      CreateCustomApproval: "Create Custom Approval",
      CustomApproval: "Custom Approval",
      ForApproval: "For Approval",
      MyCustomApprovals: "My Custom Approval",
      AllCustomApproval: "All Custom Approval",
    },
    payroll: {
      payroll: "payroll",
      payrollApproval: "Payroll Approval",
      salaryApproval: "Salary Approval",
    },
    // employee: {
    //   employees: "Employees",
    // },
    administration: {
      administration: "Administration",
    },
    appraisals: {
      appraisals: "Appraisals",
    },
    reward_category: {
      reward_category: "Reward Category",

    },
    departments: {
      departments: "Departments",
    },
    leaves: {
      myLeaves: "My Leaves",
      forApprovals: "For Approvals",
    },
    holidays: {
      holidays: "Holidays",
    },
    warnings: {
      warnings: "Warnings",
    },
    resignations: {
      resignations: "Resignations",
      approvers: "Approvals",
    },
    complains: {
      complains: "Complains",
      approvers: "Approvals",
      createComplain: "Create Complain",
    },

    rewards: {
      rewards: "Rewards",
      AllRewards: "All Bonus",
      CreatedByMe: "Created By Me",
      ForApprovals: "For Approvals",
      MyRewards: "My Rewards"
    },
    promotions: {
      Promotions: "Promotions",
    },
    salary: {
      Salary: "Salary",
      CreateSalary: "Create Salary",
    },
    pages: {
      Pages: "Pages",
    }
  },

  /*** Main Menu ***/

  globalSearch: {
    All: "All",
    feed: "Feed",
    Lead_Manager: "Lead Manager",
    Travel: "Travel",
    Document: "Document",
    Project: "Project",
    Workboard: "Workboard",
    Group: "Group",
    Expense: "Expense",
    Task: "Task",
    Employee: "Employee",
    e_learning_course: "eLearning Course",
    e_learning_videos: "eLearning Videos",
    e_learning_article: "eLearning Article",
    e_learning_quiz: "eLearning Quiz",
    e_learning_book: "eLearning Book",
    e_learning_tedTalks: "eLearning TedTalks",
    rewards: "Rewards",
  },
  auction: {
    auctionType: "Auction Type",
    personal: "Personal",
    official: "Official",
    offer: "Offer",
    startingBid: "Starting Bid",
    buyNowAmount: "Buy Now Amount",
    immediateBuy: "Immediate Buy",
    minIncreaseBid: "Min Increase Bid",
    Auction: "Auction",
    CreateAuction: "Create Auction",
    actionType: "Auction Type",
    external: "External",
    auctionDetail: "Auction Details",
    currentBid: "Current Bid",

    FormLabel: {
      startingBid: "Starting Bid",
      member: "Member",
      approvers: "Approvers",
      minIncreaseBid: "Min Increase Bid",
      buyNowAmount: "Buy Now Amount",
      offer: "Offer",
      immediateBuy: "Immediate Buy",
    },
    FormPlaceholder: {
      name: "Enter Auction Name",
      startingBid: "Enter Starting Bid",
      minIncreaseBid: "Enter Min Increase Bid",
      selectMember: "Select Member",
      selectApprovers: "Select Approvers",
      buyNowAmount: "Enter Buy Now Amount",
      offer: "Enter Offer",

    },
    FormRequiredMessage: {
      name: "Please Enter Auction Name",
      startingBid: "Please Enter Starting Bid",
      minIncreaseBid: "Please Enter Min Increase Bid",
      buyNowAmount: "Please Enter Buy Now Amount",
      offer: "Please Enter Offer",
      endDate: "Please Enter End Date",

    },
    auctionFilterTypeEnumLabel: {
      [auctionFilterTypeEnum.All]: "All Auction",
      [auctionFilterTypeEnum.CreatedByMe]: "My Auction",
      [auctionFilterTypeEnum.ForApproval]: "For Approval",
    },
    statusEnum: {
      [AuctionStatusEnum.InProcess]: "In Process",
      [AuctionStatusEnum.Approved]: "Approved",
      [AuctionStatusEnum.Declined]: "Declined",
      [AuctionStatusEnum.Resend]: "Resend",
      [AuctionStatusEnum.InActive]: "In Active",
      [AuctionStatusEnum.NotRequired]: "Not Required",
      [AuctionStatusEnum.Cancelled]: "Cancelled",
      [AuctionStatusEnum.Hold]: "Hold",
      [AuctionStatusEnum.Sold]: "Sold",

    },
    auctionOfferStatus: {
      [AuctionOfferStatusEnum.BidInProcess]: "Bid In Process",
      [AuctionOfferStatusEnum.BidSold]: "Bid Sold",
      [AuctionOfferStatusEnum.OutBid]: "Out Bid",


    },
    noDataMessage: "Create Auction",
  },
  newsFeed: {
    WriteForMe: "Write For Me",
    AddDocuments: "Add Documents",
    AddPolls: "Add Polls",
    WhatsOnYourMind: "What’s on your mind",
    Direction: "ltr",
    Generate: "Generate",
    Change: "Change",
    Whatwouldyouliketotalkabout: "What would you like to talk about?",
    Post: "Post",
    Poll: "Poll",
    Documents: "Documents",
    PhotoVideo: "Photo/Video",
    Addpolloption: "Add poll option",
    Removepoll: "Remove poll",
    WriteName: "Write Name",
    AddImageVideo: "Add Image/Video",
    CheckIn: "Check In",
    ShareonFeed: "Share on Feed",
    ShareinChat: "Share in Chat",
    NoPosts: "No Posts...",
    Nomoreposts: "No more posts...",
    noDataMessage: "Create Post now!",
    Saysomethingaboutthis :"Say something about this...",
    specificTimeline :"'s timeline",
    SharedestinationNames : {
      [SharePostDestinations.FEED]: "Feed",
      [SharePostDestinations.MEMBERS_PROFILE]: "Member's profile",
      [SharePostDestinations.GROUP]: "Group",
      [SharePostDestinations.PROJECT]: "Project",
      [SharePostDestinations.TIMELINE]: "Timeline",
    CreateFeedNow: "Create Feed now!",

    },
    SharePostSuggestionPlaceholder : {
      [SuggestionTypeEnum.Employee]: "Select Memeber",
      [SharePostDestinations.GROUP]: "Select Group",
      [SharePostDestinations.PROJECT]: "Select Project",
    },
    QuickOptions: {
      CreateSlide: "Create Slide",
      CreateBoard: "Create Board",
      CreateGrid: "Create Grid",
      CreatePad: "Create Pad",
      CreateTask: "Create Task",
      CreateExpense: "Create Expense",
      AddUser: "Add User",
      QuickSetup: "Quick Setup"
    },
    reactionDescription: {
      [ReactionType.Like]: "Like",
      [ReactionType.Love]: "Love",
      [ReactionType.NoReaction]: "Like",
      [ReactionType.Support]: "Appreciate",
      [ReactionType.Celebrate]: "Celebrate",
      [ReactionType.Curious]: "Curious",
    },
    PostTypePlaceHolder: {
      [PostType.DEFAULT]: "What's on your mind",
      [PostType.POLL]: "Ask something...",
    },
    Titlecantbeempty: "Title can't be empty",
    Attachmentscantbeempty: "Attachments can't be empty",
    Optioncantbeempty: "Option can't be empty",
    SuccessfullySharedonFeed: "Successfully Shared on Feed",
    SuccessfullySharedonMembersProfile: "Successfully Shared on Member's Profile",
    SuccessfullyPostSharedMessage:{
      [PostReferenceType.MAIN_FEED]: " Successfully Shared on Feed",
      [PostReferenceType.GROUP]: "Successfully Shared on Group",
      [PostReferenceType.PROJECTS]: "Successfully Shared on Project",
      [PostReferenceType.TIMELINE]: "Successfully Shared on Timeline",
    },
  },
  mail: {
    composer: "Compose",
    menuItem: {
      inbox: "Inbox",
      starred: "Starred",
      snoozed: "Snoozed",
      sent: "Sent",
      spam: "Spam",
      archive: "Archive",
      trash: "Trash",
      draft: "Draft",
      allMail: "All Mail",
      createNewLabel: "Create New Label",
    },
    close: "Close",
    send: "Send",
    newMessage: "New Message",
    newEmail: "Create New Email",
    ComposeEmail: "Compose E-mail",
  },
  messenger: {
    Messenger: "Messenger",
    Typing: "Typing...",
    TypeaMessage: "Type a Message...",
    Recording: "Recording...",
    nameYourGroup: "Name Your Group",
    Create: "Create",
    GroupNameRequired: "Group Name Required",
    searchMemberToAdd: "Search Member To Add",
    contacts: "Contacts",
    selected: "Selected",
    whatwouldyoutalkingabout: "What would you talking about?",
    SayHi: "Say hi to your Contacts",
  },
  marketPlace: {
    jobBoardName: "job Board",
    requsitionName: "requisition",

  },
  discussionBoard: {
    DiscussionBoard: "Discussion Board",
    Topic: "Topic",
    pleaseEnterTopicName: "Please Enter Topic name",
    enterTopicName: "Enter Topic Name",
    addDiscussionBoard: "Add Discussion Board",
    errormessage: "An error occurred while fetching the discussion board.",
    noDataMessage: "– Create Discussion now!",

  },
  pages: {
    messageName: "Please Enter Name",
    tags: "Tags",
    messageTags: "Please Enter Tags",
    enterTag: "Enter Tags",
    createPage: "Create Page",
    noDataMessage: "– Create Page now!"

  },
  group: {
    group: "Groups",
    createGroup: "Create Group",
    Updategroup: "Update group",
    enterGroupName: "Please enter group name.",
    Code: "Code",
    exampleText: "E.g. G (for a group name)",
    GroupTag: "Group Tag",
    AddAdmin: "Add Admin",
    TotalBudget: "Total Budget",
    Spent: "Spent",
    Balance: "Balance",
    EnterGroupTag: "Enter Group Tag",
    SaveGroupTag: "Save GroupTag",
    AddGroupTag: "Add GroupTag",
    EditGroup: "Edit Group",
    CreateGroupNow: "Create Group now!",
  },
  project: {
    WIP: "WIP",
    privateProject: "Private Project",
    Projects: "Projects",
    UpdateProject: "Update Project",
    CreateProject: "Create Project",
    noDataMessage: "– Create Project now!",
    ProjectTag: "ProjectTag",
    ProjectRole: "Project Role",
    exampleProjectName: "E.g. P (for a project name)",
    projectDate: "Project Date",
    EnterProjectTag: "Enter Project Tag",
    SaveProjectTag: " Save ProjectTag",
    AddProjectTag: "Add ProjectTag",
    editProject: "Edit Project",
    PersonalNotes: "Personal Notes",
    EnterProjectRole: "Enter Project Role",
    SaveCustomTag: "Save Custom Tag",
    AddProjectRole: "Add Project Role",
    TakeaNote: "Take a Note",
    PublicProject: "Public Project",
    ProjectFeatureTabs:{
      [ProjectFeatureEnums.Feed]: "Feed",
      [ProjectFeatureEnums.Schedule]: "Schedule",
      [ProjectFeatureEnums.WorkBoard]: "Work Board",
      [ProjectFeatureEnums.Document]: "Document",
      [ProjectFeatureEnums.Task]: "Task",
      [ProjectFeatureEnums.Expense]: "Expense",
      [ProjectFeatureEnums.Travel]: "Travel",
      [ProjectFeatureEnums.Quotation]: "Quotation",
      [ProjectFeatureEnums.Budget]: "Budget",
      [ProjectFeatureEnums.Setting]: "Setting",
      [ProjectFeatureEnums.DiscussionBoard]: "Discussion Board",
      [ProjectFeatureEnums.Pages]: "Pages",
      [ProjectFeatureEnums.Issues]: "Issues",
      [ProjectFeatureEnums.Contract]: "Contract",
    }
  },
  tasks: {
    Tasks: "Tasks",
    Predecessor: "Predecessor",
    AssignTo: "Assign To",
    TaskDate: "Task Date",
    CreateTask: "Create Task",
    TaskAssignTo: "Task Assign To",
    Rating: "Rating",
    TaskDetail: "Task Detail",
    ProgressofAssignedmembers: "Progress of Assigned members",
    SubTasks: "Sub-Tasks",
    SubGoals: "Sub-Goals",
    Subtask: "Subtask",
    CreateSubtask: "Create Subtask",
    Addsubtask: "Add subtask",
    AddGoal: "Add Goal",
    ParentTask: "Parent Task",
    DueToday: "Due Today",
    DueTomorrow: "Due Tomorrow",
    Overdue: "Overdue",
    Duein: "Due in",
    days: "days",
    TaskCreatedSuccessfully: "Task created successfully",
    noDataMessage: "– Create Task now!",

    selfTaskEnum: {
      [SelfTaskEnum.SelfTask]: "Self Task",
      [SelfTaskEnum.AssignTask]: "Assign To",
    },
    Priority: "Priority",
    taskFilterTypeEnum: {
      [TaskFilterTypeEnum.CreatedByMe]: "Assigned By Me",
      [TaskFilterTypeEnum.MyTasks]: "My Tasks",
      [TaskFilterTypeEnum.MyTeamTasks]: "Team Tasks",
    },
    statusEnum: {
      [TaskStatusEnum.NotStarted]: "Not Started",
      [TaskStatusEnum.InProcess]: "In Process",
      [TaskStatusEnum.Completed]: "Completed",
      [TaskStatusEnum.RatingAssign]: "Rating Assign",
      [TaskStatusEnum.Cancel]: "Canceled",
      [TaskStatusEnum.Hold]: "Hold",
    },
    taskReferenceTypeEnum: {
      [TaskReferenceTypeEnum.General]: "General",
      [TaskReferenceTypeEnum.Project]: "Project",
      [TaskReferenceTypeEnum.Group]: "Group",
      [TaskReferenceTypeEnum.Lead]: "Lead",
    },
    taskPriorityEnum: {
      [TaskPriorityEnum.Default]: "Default",
      [TaskPriorityEnum.Low]: "Low",
      [TaskPriorityEnum.Medium]: "Medium",
      [TaskPriorityEnum.High]: "High",
    },
    SortEnum: {
      [TaskFilterSortEnum.CreateDateDesc]: "Creation Date - Descending",
      [TaskFilterSortEnum.CreateDateAsc]: "Creation Date - Ascending",
      [TaskFilterSortEnum.StartDateDesc]: "Start Date - Descending",
      [TaskFilterSortEnum.StartDateAsc]: "Start Date - Ascending",
      [TaskFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [TaskFilterSortEnum.UpdateDateAsc]: "Update Date - Ascending"
    }

  },
  contracts: {
    Contract: "Contract",
    createContract: "Create Contract",
    contractDate: "Contract Date",
    contractPurpose: "Purpose of Contract",
    selectContractPurpose: "Select Purpose of Contract",
    purposeofContractisRequired: "Purpose of Contract is Required",
    category: "Category",
    purpose: "Purpose",
    signatureMember: "Signature Members",
    contractDetail: "Contract Detail",
    selectCategory: "Select Category",
    cateogryofContractisRequired: "Category is Required",
    externalMember: "External Signature Members",
    selectinternalMembers: "Select Internal Signature Members",
    internalMembers: "Internal Signature Members",
    selectExternalMember: "Select Signature External Members",
    contractType: "Contract Type",
    contractTypeRequired: "Contract Type is Required",
    internal: "Internal",
    external: "External",
    noDataMessage: " Create Contract now!",
    contractsFilterTypeLables: {
      [ContractFilterTypeEnum.Contracts]: "All Business Contracts",
      [ContractFilterTypeEnum.BusinessContract]: "My Contracts",
    },
    statusEnum: {
      [ContractStatusEnum.InProcess]: "In Process",
      [ContractStatusEnum.Completed]: "Completed",
      [ContractStatusEnum.Cancelled]: "Canceled",
    },
    contractReferenceTypeEnum: {
      [ContractReferenceTypeEnum.General]: "General",
      [ContractReferenceTypeEnum.Project]: "Project",
      [ContractReferenceTypeEnum.Group]: "Group",
      [ContractReferenceTypeEnum.Lead]: "Lead",
    },
  },
  workBoard: {
    WorkBoardDashBoard: "Work board Dashboard",
    pleaseEnterYourBoardName: "Please Enter Your Board Name",
    pleaseEnterYourBoardDescription: "Please Enter Your Board Description",
    EnterMembers: "Enter Members",
    UpdateBoard: "Update Board",
    CreateBoard: "Create Board",
    privacy: "Privacy",
    AddSection: "Add Section",
    AddTodo: "Add Todo",
    Save: "Save",
    EnterSectionTitle: "Enter Section Title",
    InSection: "In Section",
    Label: "Label",
    Activity: "Activity",
    Labels: "Labels",
    UploadCoverPhoto: "Upload Cover Photo",
    Section: "Section",
    Board: " Board",
    Todos: "Todos",
    CompleteDate: "Complete Date",
    DetailedDesc: "Add More Detailed Description...",
    EnterTodo: "Enter the text for this card...",
    CreateWorkBoardNow: "- Create WorkBoard now!",
    workBoardExample: "E.g. WB (for a board named Work Board)",
  },
  leadManager: {
    LeadManager: "Lead Manager",
    AssignedLeads: "Assigned Leads",
    AllLeads: "All Leads",
    Groups: "Groups",
    email: "Email",
    Lead: "Lead",
    Leads: "Leads",
    Pleaseinputyourboardname: "Please input your board name!",
    exampleLeadManagerMessage: "E.g. LM (for a group named Lead Manager)",
    inputBoardDescription: "Please input your board description!",
    UpdateLeadManager: "Update Lead Manager",
    CreateLeadManager: "Create Lead Manager",
    CreateLeadGroup: "Create Lead Group",
    noDataMessage: "– Create LeadManager now!",
    LastUpdated: "Last Updated",
    LeadValue: "Lead Value",
    individual: "Individual",
    leadEmailAddress: "Lead Email Address",
    leadPhoneNumber: "Lead Phone Number",
    leadAddress: "Lead Address",
    leadUrl: "Lead URL",
    ConvertProject: "Convert to Project",
    goToProject: "Go to Project",
    SelectAssignMembers: "Select Assign Members",
    LeadGroup: "Lead Group",
    leadName: "Lead Name",
    leadMembers: "Lead Members",
    leadWebiste: "Lead Website",
    LeadValuenegative: "Lead Value cannot be negative!",
    AddLead: "Add Lead",
    SelectCommunicationTo: "Select Communication To",
    CommunicationTypeisrequired: "Communication Type is required!",
    PleaseselectCommunicationType: "Please select Communication Type",
    CommunicationType: "Communication Type",
    AddCommunication: "Add Communication",
    CommunicationTo: "Communication To",
    NoCalls: "No Calls",
    LeadsValue: "Leads Value",
    metricTypeEnum: {
      [LeadManagerMetricTypeEnum.allGroup]: "Total Groups",
      [LeadManagerMetricTypeEnum.allAssign]: "Assigned Lead",
      [LeadManagerMetricTypeEnum.leadToProject]: "Convert To Project",
      [LeadManagerMetricTypeEnum.leadSection]: "Max Leads Section",
    },
  },
  schedule: {
    StartMeeting: "Start Meeting",
    MeetinginProgress: "Meeting in Progress",
    EndMeeting: "End Meeting",
    MeetingEnded: "Meeting Ended",
    NewTokenAdded: "New Token Added",
    Copied: "Copied",
    SuccessfullyBlocked: "Successfully Blocked",
    SuccessfullyUnBlocked: "Successfully Un-Blocked",
    UpdateCalendarLink: "Update Calendar Link",
    AddCalendarLink: "Add Calendar Link",
    GetCalendarLink: "Get Calendar Link",
    CopyLink: "Copy Link",
    Block: "Block",
    UnBlock: "Un-Block",
    CreateSchedule: "Create Schedule",
    Draft: "Draft",
    Finalize: "Finalize",
    Complete: "Complete",
    Preview: "Preview",
    MinutesOftheMeeting: "Minutes of the Meeting",
    MeetingId: "Meeting Id ",
    Venue: "Venue",
    Workwisecall: "Workwise call",
    ConferenceCall: "Conference Call",
    Attendees: "Attendees",
    Absentees: "Absentees",
    PointsofDiscussion: "Points of Discussion",
    AddPoint: "Add Point",
    Print: " Print",
    SaveToPDF: "Save To PDF",
    MEETINGMINUTES: "MEETING MINUTES",
    Yes: "Yes",
    No: "No",
    WorkwiseCall: "Workwise Call",
    MeetingObjectives: "Meeting Objectives",
    Points: "Points",
    RefNo: "Ref No",
    AttendanceatMeeting: "Attendance at Meeting",
    Loadingschedules: "Loading schedules...",
    Nodata: "No data...",
    Projects: "Projects",
    Groups: "Groups",
    titleMessage: "Are you sure? Change Type will remove assign members.",
    pleaseSelectAssignMemeber: "Please Select Assign Member!",
    SelectAssignto: "Select Assign to",
    GetLink: "Get Link",
    Anyone: "Anyone",
    anyoneText: "with the link can create an appointment.",
    TodayEvents: "Today Events",
    UpcomingEvents: "Upcoming Events",
    InterviewRequests: "Interview Requests",
    sendviachat: "send via chat",
    OnVideoConference: "On Video Conference:",
    OnWorkwiseCall: "On Workwise Call",
    Enterlocation: "Enter location",
    Duration: "Duration",
    inminutes: "in minutes",
    Durationisrequired: "Duration is required",
    Rooms: "Rooms",
    SelectRoom: "Select Room",
    CheckRoomAvailability: "Check Room Availability",
    Available: "Available",
    NotAvailable: "Not Available",
    TravelTime: "Travel Time",
    PreparationTime: "Preparation Time",
    SendviaEmail: "Send via Email",
    schedulerCalender: "schedulerCalender",
    Next: "Next",
    Prev: "Prev",
    titlemyCustomButton: "title myCustomButton",
    prevnexttoday: "prev next today",
    timeGridDaytimeGridWeekdayGridMonth: "timeGridDay timeGridWeek dayGridMonth",
    timeGridDay: "timeGridDay",
    top: "top",
    block: "block",
    minutes: "minutes",
    AllDay: "All Day",
    timeGridWeek: "timeGridWeek",
    slotDuration: "00:15:00",
    numeric: "numeric",
    dayGridMonth: "dayGridMonth",
    dayGridWeek: "dayGridWeek",
    timeGrid: "timeGrid",
    Day: "Day",
    Week: "Week",
    Month: "Month",
    NoEventsonthisday: "No Events on this day.",
    CreateInterviewSchedule: "Create Interview Schedule",
    Interviewwith: "Interview with",
    MySchedules: "My Schedules",
    TeamSchedules: "Team Schedules",
    Past: "Past",
    Today: "Today",
    Upcoming: "Upcoming",
    ScheduleDetail: "Schedule Detail",
    JoinWorkwiseCall: "Join Workwise Call",
    AddWorkwiseCall: "Add Workwise Call",
    Host: "(Host)",
    MakeHost: "Make Host",
    Attending: "Attending",
    UpdateSchedule: "Update Schedule",
    Calendar: "Calendar",
    Schedule: "Schedule",
    ShareCalendarLink: "Share Calendar Link",
    pointer: "pointer",
    default: "default",
    blue: "blue",
    red: "red",
    purple: "purple",
    Self: "Self",
    AssignTo: "Assign To",
    assign: "assign",
    min: "min",
    Minutes: "15 minutes",
    MeetingTranscript: "Meeting Transcript",
  },
  expenses: {
    CreateExpense: "Create Expense",
    IsReimbursable: "Is Reimbursable",
    WriteHeaderHere: "Write Header Here...",
    ExpenseDetail: "Expense Detail",
    ExpenseDate: "Expense Date",
    Executors: "Executors",
    Financers: "Financers",
    ExecutorRequired: "Executor is required",
    selectExecutor: "Select the Executer of the Expense",
    noDataMessage: "Empower your financial journey – Create Expense now!",
    expenseFilterTypeEnum: {
      [ExpenseFilterTypeEnum.MyExpense]: "My Expense",
      [ExpenseFilterTypeEnum.ForApproval]: "For Approval",
      [ExpenseFilterTypeEnum.ForExecutions]: "For Execution",
      [ExpenseFilterTypeEnum.ForFinance]: "For Finance",
    },
    expenseType: {
      [ExpenseReferenceTypeEnum.General]: "General",
      [ExpenseReferenceTypeEnum.Group]: "Group",
      [ExpenseReferenceTypeEnum.Project]: "Project",
      [ExpenseReferenceTypeEnum.Travel]: "Travel",
      [ExpenseReferenceTypeEnum.Assets]: "Assets",
    },
    statusEnum: {
      [ExpenseApprovalStatusEnum.InProcess]: "In Process",
      [ExpenseApprovalStatusEnum.Approved]: "Approved",
      [ExpenseApprovalStatusEnum.Declined]: "Declined",
      [ExpenseApprovalStatusEnum.Cancelled]: "Cancelled",
      [ExpenseApprovalStatusEnum.Completed]: "Completed",
      [ExpenseApprovalStatusEnum.Hold]: "Hold",
      [ExpenseApprovalStatusEnum.Disbursed]: "Disbursed",
      [ExpenseApprovalStatusEnum.WaitingForDisburse]: "Waiting For Disburse",


    },

    SortEnum: {
      [ExpenseFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [ExpenseFilterSortEnum.CreateDateAsc]: "Create Date",
      [ExpenseFilterSortEnum.ExpenseDateDesc]: "Expense Date - Descending",
      [ExpenseFilterSortEnum.ExpenseDate]: "Expense Date",
      [ExpenseFilterSortEnum.ReferenceNo]: "Reference No",
      [ExpenseFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [ExpenseFilterSortEnum.Amount]: "Amount",
      [ExpenseFilterSortEnum.AmountDesc]: "Amount - Descending"
    },
    Category: {
      [ExpenseCategoryEnum.Transport]: "Transport",
      [ExpenseCategoryEnum.Health]: "Health",
      [ExpenseCategoryEnum.Food]: "Food",
      [ExpenseCategoryEnum.Shopping]: "Shopping",
      [ExpenseCategoryEnum.Entertainment]: "Entertainment",
      [ExpenseCategoryEnum.Travel]: "Travel",
      [ExpenseCategoryEnum.Bill]: "Bill",
      [ExpenseCategoryEnum.Office]: "Office",
      [ExpenseCategoryEnum.Fuel]: "Fuel",
      [ExpenseCategoryEnum.Asset]: "Asset",
      [ExpenseCategoryEnum.Other]: "Other"
    }
  },
  travel: {
    TravelDetail: "Travel Detail",
    CreateTravel: "Create Travel",
    PleaseAddTravelDetail: "Please Add Travel Detail",
    TravelToandFrom: "Travel To and From",
    ReturnDateIsRequired: "Return Date Is Required",
    PleaseSelectTravelType: "Please Select Travel Type",
    HotelTADACar: "Hotel, TADA & Car",
    HotelRequired: "Hotel Required",
    RentaCar: "Rent a Car",
    PickUpRequired: "Pick-Up Required",
    DropOffRequired: "Drop-Off Required",
    TADAApplicable: "TADA Applicable",
    TravelBy: "Travel By",
    SpecialRequest: "Special Request",
    SearchDestinationCity: "Search Destination City",
    SearchDepartureCity: "Search Departure City",
    PleaseSelectDepartureCity: "Please Select Departure City.",
    PleaseSelectDestinationCity: "Please Select Destination City.",
    DepartureTimeIsRequired: "Departure Time Is Required",
    RetunTimeIsRequired: "Retun Time Is Required",
    AttachmentType: "Attachment Type",
    WriteSpecialRequestDetail: "Write Special Request Detail",
    Agents: "Agents",
    Departure: "Departure",
    Arrival: "Arrival",
    DepartureDate: "Departure Date",
    DepartureTime: "Departure Time",
    DepartureCity: "Departure City",
    ShiftType: "Shift Type",
    DepartureShift: "Departure Shift",
    ArrivalCity: "Arrival City",
    CreateTravelnow: "Create Travel now",
    UpdateTravel: "Update Travel",
    TravelAttachmentTypeOptions: {
      [TravelAttachmentTypeEnum.Passport]: "Passport",
      [TravelAttachmentTypeEnum.Ticket]: "Ticket",
      [TravelAttachmentTypeEnum.Insurance]: "Insurance",
      [TravelAttachmentTypeEnum.CarRental]: "Car Rental",
      [TravelAttachmentTypeEnum.Other]: "Other",
    },

    Shift: {
      [ShiftEnum.Morning]: "Morning",
      [ShiftEnum.Afternoon]: "Afternoon",
      [ShiftEnum.Evening]: "Evening",
      [ShiftEnum.Night]: "Night",
    },

    travelBy: {
      plane: "By Plane",
      bus: "By Bus",
      ship: "By Ship",
      train: "By Train",
      car: "By Car",
    },

    TravelOptions: {
      [TravelOptionsEnums.ByPlane]: "By Plane",
      [TravelOptionsEnums.ByShip]: "By Ship",
      [TravelOptionsEnums.ByBus]: "By Bus",
      [TravelOptionsEnums.ByTrain]: "By Train",
      [TravelOptionsEnums.ByCar]: "By Car",
    },

    travelFilterTypeEnum: {
      [TravelFilterTypeEnum.Travels]: "Travels",
      [TravelFilterTypeEnum.MyTravel]: "My Travel",
      [TravelFilterTypeEnum.ForApproval]: "For Approval",
      [TravelFilterTypeEnum.AgentProcess]: "Agent Process",
    },

    statusEnum: {
      [TravelStatusEnum.InProcess]: "In Process",
      [TravelStatusEnum.Approved]: "Approved",
      [TravelStatusEnum.Declined]: "Declined",
      [TravelStatusEnum.Cancelled]: "Cancelled",
      [TravelStatusEnum.ApprovedByApprovers]: "Approved By Approvers",
      [TravelStatusEnum.ApprovedByAgents]: "Approved By Agents",
      [TravelStatusEnum.DeclineByApproves]: "Decline By Approves",
      [TravelStatusEnum.DeclineByAgents]: "Decline By Agents",
      [TravelStatusEnum.Hold]: "Hold",
    },
    SortEnum: {
      [TravelFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [TravelFilterSortEnum.UpdateDateAsc]: "Update Date",
      [TravelFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [TravelFilterSortEnum.ReferenceNoAsc]: "Reference No",
      [TravelFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [TravelFilterSortEnum.CreateDateAsc]: "Create Date",
      [TravelFilterSortEnum.TravelEndDateDesc]: "Travel End Date",
      [TravelFilterSortEnum.TravelStartDate]: "Travel Start Date"
    }
  },
  eLearning: {
    Course: "Course",
    eBook: "eBook",
    Quiz: "Quiz",
    TedTalk: "TedTalk",
    Article: "Article",
    Video: "Video",
    BasicCourseDetail: "Basic Course Detail",
    SelectSubject: "Select Subject",
    SelectCourse: "Select Course",
    PleaseSelectOption: "Please Select Option",
    Selectcoursetype: "Select course type",
    CourseName: "Course Name",
    Entercoursename: "Enter course name",
    Level: "Level",
    PleaseSelectLevel: "Please Select Level",
    Selectlevel: "Select level",
    CourseAdminMembers: "Course Admin Members",
    CourseAdminisrequired: "Course Admin is required",
    AssignMembers: "Assign Members",
    UpdateEBook: "Update eBook",
    CreateEBook: "Create eBook",
    AuthorName: "Author Name",
    EnterInformation: "Enter Information",
    UploadBook: "Upload Book (PDF)",
    CreateQuiz: "Create Quiz",
    QuizName: "Quiz Name",
    CreateTedTalk: "Create TedTalk",
    ViaLink: "Via Link",
    ViaVideoUpload: "Via Video Upload",
    PleaseInsertLink: "Please Insert Link",
    UploadVideo: "Upload Video",
    CreateArticle: "Create Article",
    InsertLink: "Insert link",
    UploadPDF: "Upload Photo or PDF",
    CreateVideo: "Create Video",
    LessonDetail: "Lesson Detail",
    Eitherselect: "Either select a quiz or remove it.",
    Selectquiz: "Select quiz",
    AddNewQuiz: "Add New Quiz",
    Atleastonetopic: "Atleast one topic of this lesson is required.",
    LessonNameRequired: "Lesson name is required.",
    LessonName: "Lesson Name",
    DeleteLesson: "Delete Lesson",
    LessonDescriptionRequired: "Lesson description is required.",
    TopicNameIsRequired: "Topic name is required.",
    TopicName: "Topic Name",
    DeleteTopic: "Delete Topic",
    TopicDescription: "Topic description is required.",
    eitherEnterText: "Either enter text or remove it.",
    EitherenterA: "Either enter a",
    OrRemoveIt: "or remove it.",
    CreateArticlesNow: "Create Articles now!",
    CreateCoursesNow: "Create Courses now!",
    CreateEbooksNow: "Create Ebooks now!",
    CreateElearningNow: "Create E-learning now!",
    CreateQuizzesNow: "Create Quizzes now!",
    CreateTeamDashboardNow: "Create Team Dashboard now!",
    CreateTedTalksNow: "Create TedTalks now!",
    CreateVideosNow: "Create Videos now!",
    OptionEmpty: "Option can not be empty",
    CorrectOptionRequired: "Correct option required",
    QuestionEmpty: "Question can not be empty",
    UntitledQuestion: "Untitled Question",
    AddQuestion: "Add Question",
    DeleteQuestion: "Delete Question",
    Quiznamerequired: "Quiz name required!",
    QuizexpirationDate: "Quiz expiration date",
    QuizExpirationDateIsRequired: "Quiz expiration date is required",
    QuizDescriptionRequired: "Quiz description required!",
    StartQuiz: " Start Quiz",
    GoToResults: "Go to results",
    Gettoknown: "Get to known user experience",
    Missingdata: "Missing data.",
    UntitledLesson: "Untitled Lesson",
    RemoveText: "Remove Text",
    AddText: "Add Text",
    RemoveImage: "Remove Image",
    AddImage: "Add Image",
    RemoveVideo: "Remove Video",
    AddVideo: "Add Video",
    RemovePDF: "Remove PDF",
    AddPDF: "Add PDF",
    RemoveQuiz: "Remove Quiz",
    AddQuiz: "Add Quiz",
    RemoveLink: "Remove Link",
    AddLink: "Add Link",
    RemoveYouTubeLink: "Remove YouTube Link",
    AddYouTubeLink: "Add YouTube Link",
    RemoveExternalCourse: "Remove External Course",
    AddExternalCourse: "Add External Course",
    UntitledTopic: "Untitled Topic",
    DashboardChart: "DashboardChart",
    LatestTrendInBusiness: "Latest Trend In Business",
    InformationWillComeHere: "Information will come here",
    CourseContent: "Course content",
    DummyContent: "Dummy Content",
    EndCourse: "End Course",
    Chapters: "Chapters •",
    Lectures: "Lectures •",
    LessonsInThisProgram: "Lessons In This Program",
    BookInformation: "Book Information",
    TedTalksDaily: "TedTalks Daily",
    TopicTitle: "Topic Title",
    VisitThis: "Visit This",
    LearnPractical: "Learn practical skills through courses and get paid apprenticeships.",
    Courses: "Courses",
    Book: "Book",
    Tedtalk: "Tedtalk",
    Articles: "Articles",
    NoPageExist: "No page exist",
    Books: "Books",
    TedTalks: "Ted Talks",
    Videos: "Videos",
    GoTo: "Go to",
    CourseLevelIdLabel: {
      [CourseLevelIdEnum.Advance]: "Advance",
      [CourseLevelIdEnum.Intermediate]: "Intermediate",
      [CourseLevelIdEnum.Beginner]: "Beginner"
    }
  },
  attendence: {
    Attendance: "Attendance",
    TimeSpent: "Time Spent",
    GraceTime: "Grace Time",
    GoodDay: "Good Day",
    Howfeeling: "How are you feeling today?",
    Note: "Note",
    Noattendanceforapproval: "No attendance for approval.",
    Iwaslate: "I was late for the cause of traffic.",
    Showtimespent: "Show time spent",
    ClockIn: "Clock-In to start your attendance log.",
    selectStatus: "Please select a status!",
    ByTime: "By Time",
    ByStatus: "By Status",
    DeductionPolicy: "Deduction Policy",
    UpdateDeductionPolicy: "Update Deduction Policy",
    AddDeductionPolicy: "Add Deduction Policy",
    DaysRange: "Days Range",
    PerDay: "Per Day",
    SaveDeductionPolicy: "Save Deduction Policy",
    AbsentPolicy: "Absent Policy",
    LatePolicy: "Late Policy",
    IsPercentage: "Is Percentage",
    attendanceFilterTypeEnum: {
      [AttendanceFilterTypeEnum.checkInForApproval]: "Check-In For Approval",
      [AttendanceFilterTypeEnum.dayWiseAttendance]: "Day Attendance",
      [AttendanceFilterTypeEnum.userAttendance]: "User Attendance",
      [AttendanceFilterTypeEnum.Summary]: "Summary",
    },

    updateErrorMessage: {
      [AttendanceStateEnum.Off]: "Update action unavailable for off state",
      [AttendanceStateEnum.Holiday]: "Update action unavailable for holidays",
      [AttendanceStateEnum.Leave]: "Update action unavailable for leave state",
    },
    statusEnum: {
      [AttendanceStatusEnum.InProcess]: "In Process",
      [AttendanceStatusEnum.Approved]: "Approved",
      [AttendanceStatusEnum.Declined]: "Declined",
    },
    moodLabels: {
      [AttendanceMoodEnum.VeryUnsatisfied]: "VeryUnsatisfied",
      [AttendanceMoodEnum.Unsatisfied]: "Unsatisfied",
      [AttendanceMoodEnum.Neutral]: "Neutral",
      [AttendanceMoodEnum.Satisfied]: "Satisfied",
      [AttendanceMoodEnum.VerySatisfied]: "Very Satisfied",
    },
    attendanceState: {
      [AttendanceStateEnum.Unknown]: "",
      [AttendanceStateEnum.Present]: "Present",
      [AttendanceStateEnum.Absent]: "Absent",
      [AttendanceStateEnum.Leave]: "Leave",
      [AttendanceStateEnum.Late]: "Late",
      [AttendanceStateEnum.WorkFromHome]: "Work From Home",
      [AttendanceStateEnum.Off]: "Off",
      [AttendanceStateEnum.Holiday]: "Holiday",
    },
    attendanceType: {
      [ATTENDANCE_ENUM.CHECK_IN.IN]: "Check-In",
      [ATTENDANCE_ENUM.CHECK_IN.OUT]: "Check-Out",
    },
    attendanceMetricLabel: {
      [AttendanceMetricTypeEnum.overallTimeSpent]: "Overall Time Spent",
      [AttendanceMetricTypeEnum.Present]: "Present",
      [AttendanceMetricTypeEnum.Absent]: "Absent",
      [AttendanceMetricTypeEnum.Leave]: "Leave",
      [AttendanceMetricTypeEnum.Late]: "Late",
    },
  },
  customApproval: {
    createCustomApproval: "Create Custom Approval",
    customApproval: "Custom Approval",
    noDataMessage: "– Create Custom Approvals now!",
    customApprovalFilterTypeEnum: {
      [CustomApprovalFilterTypeEnum.All]: "All Custom Approval",
      [CustomApprovalFilterTypeEnum.CreatedByMe]: "My Custom Approval",
      [CustomApprovalFilterTypeEnum.ForApproval]: "For Approval",
    },
    statusEnum: {
      [CustomApprovalStatusEum.InProcess]: "In Process",
      [CustomApprovalStatusEum.Approved]: "Approved",
      [CustomApprovalStatusEum.Declined]: "Declined",
      [CustomApprovalStatusEum.Resend]: "Resend",
      [CustomApprovalStatusEum.InActive]: "In Active",
      [CustomApprovalStatusEum.NotRequired]: "Not Required",
      [CustomApprovalStatusEum.Cancelled]: "Cancelled",
      [CustomApprovalStatusEum.Hold]: "Hold",
    },
    SortEnum: {
      [CustomApprovalFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [CustomApprovalFilterSortEnum.UpdateDateAsc]: "Update Date",
      [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [CustomApprovalFilterSortEnum.ReferenceNo]: "Reference No",
      [CustomApprovalFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [CustomApprovalFilterSortEnum.CreateDateAsc]: "Create Date"
    }
  },
  reward: {
    CreateReward: "Create Reward",
    Rewards: "Rewards",
    RewardTo: "Reward To",
    RewardCategory: "Reward Category",
    AddRewardCategory: "Add Reward Category",
    EnterRewardCategory: "Enter Reward Category",
    RewardName: "Reward Name",
    EnterRewardName: "Enter Reward Name",
    PleaseEnterRewardName: "Please Enter Reward Name",
    ReasonForReward: "Reason For Reward",
    EnterReasonForReward: "Enter Reason For Reward",
    PleaseEnterReasonForReward: "Please Enter Reason For Reward",
    noDataMessage: "– Create Reward now!",
    rewardFilterTypeEnum: {
      [RewardFilterTypeEnum.All]: "All Rewards",
      [RewardFilterTypeEnum.CreatedByMe]: "Created By Me",
      [RewardFilterTypeEnum.ForApproval]: "For Approval",
      [RewardFilterTypeEnum.RewardForMe]: "My Rewards",
    },
    statusEnum: {
      [RewardStatusEnum.InProcess]: "In Process",
      [RewardStatusEnum.Approved]: "Approved",
      [RewardStatusEnum.Declined]: "Declined",
      [RewardStatusEnum.Cancelled]: "Cancelled",
      [RewardStatusEnum.Hold]: "Hold",
    },
    SortEnum: {
      [RewardFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [RewardFilterSortEnum.UpdateDateAsc]: "Update Date",
      [RewardFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [RewardFilterSortEnum.ReferenceNo]: "Reference No",
      [RewardFilterSortEnum.StatusDesc]: "Status - Descending",
      [RewardFilterSortEnum.Status]: "Status",
      [RewardFilterSortEnum.CategoryDesc]: "Category - Descending",
      [RewardFilterSortEnum.Category]: "Category",
      [RewardFilterSortEnum.NameDesc]: "Name - Descending",
      [RewardFilterSortEnum.Name]: "Name",
      [RewardFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [RewardFilterSortEnum.CreateDateAsc]: "Create Date"
    }

  },
  document: {
    DocsArchive: "Docs & Archives",
    createDocument: "Create Document",
    CreateSlides: "Create Slides",
    CreateBoard: "Create Board",
    CreatePad: "Create Pad",
    CreateGrid: "Create Grid",
    newFolder: "New Folder",
    createFolder: "Create Folder",
    uploadDocuments: "Upload Documents",
    addBoard: "Add Board",
    addPad: "Add Pad",
    addGrid: "Add Grid",
    addSlide: "Add Slide",
    addMindmap: "Add Mindmap",
    CompleteDocument: "Complete Document",
    CompleteDocumentWithoutApprovers: "Complete Document Without Approvers",
    mySigned: "My Signed",
    forMySignature: "For My Signature",
    draftSignature: "Draft",
    inSigning: "In Signing",
    signaturesCompleted: "Signatures Completed",

    noDataMessage: "- Create Document now!",
    AllDocuments: "All Documents",
    MyDrive: "My Drive",
    MyDocuments: "My Docs For Approval",
    ForApprovals: "For Approvals",
    OnlyFiles: "Only Files",
    fileName: "File Name",
    shortDescription: "Short Description (max 100 charaters)",
    AllFiles: "All Files",
    Grids: "Grids",
    Pads: "Pads",
    Slides: "Slides",
    Boards: "Boards",
    Mindmaps: "Mindmaps",
    updateFolder: "Update Folder",
    updateGrid: "Update Grid",
    updatePad: "Update Pad",
    updateSlide: "Update Slide",
    updateBoard: "Update Board",
    updateMindMap: "Update Mindmap",
    updateDocuments: "Update Document",
    status: {
      InProcess: "InProcess",
      Approved: "Approved",
      Declined: "Declined",
      Resend: "Resend",
      NotRequired: "Not Required",
      Cancelled: "Cancelled",
      Draft: "Draft"
    },
    documentReferenceType: {
      [DocumentReferenceTypeEnum.General]: "General",
      [DocumentReferenceTypeEnum.Group]: "Group",
      [DocumentReferenceTypeEnum.Project]: "Project",
    },
  },
  warning: {
    WarningDetail: "Warning Detail",
    warningTo: "Warning To",
    createWarning: "Create Warning",
    enterwarningCategory: "Enter warning category",
    noDataMessage: '– Create Warning now!',
    filter: "Filter",
    warningFilterTypeEnum: {
      [WarningFilterTypeEnum.Warning]: "Warnings",
      [WarningFilterTypeEnum.CreatedByMe]: "Created By Me",
      [WarningFilterTypeEnum.ForApproval]: "For Approvals",
      [WarningFilterTypeEnum.WarningToMe]: "Warning To Me",
    },
    statusEnum: {
      [WarningStatusEnum.InProcess]: "In Process",
      [WarningStatusEnum.Approved]: "Approved",
      [WarningStatusEnum.Declined]: "Declined",
      [WarningStatusEnum.Cancelled]: "Cancelled",
      [WarningStatusEnum.Hold]: "Hold",
    },
    SortEnum: {
      [WarningFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [WarningFilterSortEnum.UpdateDateAsc]: "Update Date - Ascending",
      [WarningFilterSortEnum.ReferenceNoDesc]: "Reference Number - Descending",
      [WarningFilterSortEnum.ReferenceNo]: "Reference Number",
      [WarningFilterSortEnum.StatusDesc]: "Status - Descending",
      [WarningFilterSortEnum.Status]: "Status",
      [WarningFilterSortEnum.CategoryDesc]: "Category - Descending",
      [WarningFilterSortEnum.Category]: "Category",
      [WarningFilterSortEnum.NameDesc]: "Name - Descending",
      [WarningFilterSortEnum.Name]: "Name",
      [WarningFilterSortEnum.CreateDateDesc]: "Creation Date - Descending",
      [WarningFilterSortEnum.CreateDateAsc]: "Creation Date - Ascending"
    }

  },
  jobBoard: {
    jobBoard: "Job Board",
    noDataMessage: " – Create Careers now!",
  },
  businessPolicy: {
    BusinessPolicy: "Business Policy",
    policies: "Policies",
    noPolicy: "No Policy Found...",
  },
  forms: {
    CreateForm: "Create Form",
    Forms: "Forms",
    Attempt: "Attempt",
    CopyLink: "Copy Link",
    Title: "Title",
    EnterTitle: "Enter Title",
    PleaseEnterTitle: "Please Enter Title",
    PleaseFillTheQuestionField: "Please Fill The Question Field",
    EnterQuestion: "Enter Question",
    SelectAnswerType: "Select Answer Type",
    Text: "Text",
    Number: "Number",
    Polls: "Polls",
    Poll: "Poll",
    AddQuestion: "Add Question",
    AddOption: "Add Option",
    Details: "Details",
    PoweredBy: "Powered By",
    Submit: "Submit",
    EditForm: "Edit Form",
    EnterEmail: "Enter Email",
    Question: "Question...",
    YourAnswer: "Your Answer",
    noDataMessage: "– Create Form now!",
    formQuestionTypeEnum: {
      [FormQuestionTypeEnum.Number]: "Number",
      [FormQuestionTypeEnum.Text]: "Text",
      [FormQuestionTypeEnum.Poll]: "Poll",
    },
    formFilterTypeEnum: {
      [FormFilterTypeEnum.All]: "All Forms",
      [FormFilterTypeEnum.CreatedByMe]: "Created By Me",
      [FormFilterTypeEnum.ForApproval]: "For Approval",
      [FormFilterTypeEnum.FormForMe]: "Form For Me"
    },
    statusEnum: {
      [FormStatusEnum.InProcess]: "In Process",
      [FormStatusEnum.Approved]: "Approved",
      [FormStatusEnum.Declined]: "Declined",
      [FormStatusEnum.Resend]: "Resend",
      [FormStatusEnum.Inactive]: "In Active",
      [FormStatusEnum.NotRequired]: "Not Required",
      [FormStatusEnum.Cancelled]: "Cancelled",
      [FormStatusEnum.Hold]: "Hold",
    },

    SortEnum: {
      [FormFilterSortEnum.CreateDateDesc]: "Creation Date - Descending",
      [FormFilterSortEnum.CreateDateAsc]: "Creation Date ",
      [FormFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [FormFilterSortEnum.UpdateDateAsc]: "Update Date ",
      [FormFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [FormFilterSortEnum.ReferenceNo]: "Reference No"
    }

  },
  careers: {
    Careers: "Careers",
    CareerDetail: "Career Detail",
    createCareer: "Create Career",
    MinSalary: "Min Salary",
    MaxSalary: "Max Salary",
    All: "All",
    Applicants: "Applicants",
    ShortListed: "Short Listed",
    Rejected: "Rejected",
    Completed: "Completed",
    Finalist: "Finalist",
    Maybe: "May be",
    OfferSent: "Offer Sent",
    WaitingForInterview: "Waiting For Interview",
    OfferAccepted: "Offer Accepted",
    OfferDeclined: "Offer Declined",
    OfferResend: "Offer Resend",
    noDataMessage: "– Create Career now!",
    writeHeaderHere: "Write header",
    pickCurrentDate: "Pick current date",
    enterAmount: "Enter amount",
    selectApprovers: "Select approvers",
    selectFinancers: "Select financers",
    selectExecutors: "Select executors",
    selectDesignation: "Select designation",
    writeDescription: "Write description",
    enterMinSalary: "Enter minimum salary",
    enterMaxSalary: "Enter maximun salary",
    selectDepartment: "Select department",
    selectSupervisor: "Select supervisor",
    selectInterviewers: "Select interviewer",
    selectPostInterviewers: "Select post interviewers",
    description: "Write your description here...",
    addSkills: "Add skill",
    selectJobType: "Select job type",
    selectJobshift: "Select job shift",
    selectEducation: "Please select eduction",
    selectCareerLevel: "Select career level",
    selectEndDate: "Select end date",
    enterreviewcriteria: "Enter review criteria",
    reviewCriteria: "Review Criteria",
    enterDepartment: "Please enter department",
    supervisor: "Supervisor",
    supervisorrequired: "Supervisor is required",
    postInterviewers: "Post Interviewers",
    jobShift: "Job Shift",
    enterJobShift: "Please enter job shift",
    enterJobType: "Please enter job type",
    careerLevel: "Career Level",
    selectBuddy: "Select hirring buddy",
    selectInerview: "Select Interviewers",
    requiredInterview: "Interviewer is required",
    enterCareerLevel: "Please enter career level",
    createJob: "Create Job",
    applyJob: "Apply Job",
    firstName: "First Name",
    enterFirstName: "Enter First Name",
    requiredMessageFName: "First Name is required",
    lastName: "Last Name",
    enterLastName: "Enter Last Name",
    requiredMessageLName: "Last Name is required",
    email: "Email",
    enterEmail: "Enter Email",
    EmailIsRequired: "Email is required",
    phoneNumber: "Phone Number",
    enterPhoneNumber: "Enter Phone Number",
    currentSalary: "Current Salary",
    expectedSalary: "Expected Salary",
    radioMessageApplicant: "Applicant",
    radioMessageShortlisted: "Shortlisted",
    radioMessageCompleted: "Completed",
    coverNote: "Cover Note",
    noticePeriod: "Notice Period",
    requiredMessageFName: "Please Enter First Name",
    requiredMessageLName: "Please Enter Last Name",
    requiredMessageEmail: "Please Enter Email",
    requiredMessagePhoneNumbeer: "Please Enter Phone Number",
    requiredMessageCurrentSalary: "Please Enter Current Salary",
    requiredMessageExpextedsalary: "Please Enter Expected Salary",
    requiredMessageExperience: "Please Enter Experience",
    requiredMessageNoticePeriod: "Please Enter Notice Period ",
    appliedDate: "Applied Date",
    interviewSchedule: "Schedule Interview",
    applyNow: "Apply Now",
    jobPost: "Job Posted",
    jobexpire: "Job Expires",
    skillrequired: "Skills Required",
    joblocation: "Job Location",
    jobType: "Job Type",
    jobDetails: "Job Details",
    EducationLevel: "Education Level",
    MinimumSalary: "Minimum Salary",
    MaximumSalary: "Maximum Salary",
    YearOfExperience: "Year Of Experience",
    errormessage: "Cannot Change Status",
    MakeOffer: "Make Offer",
    updatestatus: "Are you sure you want to update the status?",
    Pleaseenterminsalary: "Please enter minimum salary",
    Pleaseentermaxsalary: "Please enter maximun salary",
    NoRequirements: "No Requirements",
    careerInterviewStatuEnum: {
      [CareerInterviewStatusEnum.NotScheduled]: "Not Scheduled",
      [CareerInterviewStatusEnum.Ended]: "Ended",
      [CareerInterviewStatusEnum.Scheduled]: "Scheduled",
      [CareerInterviewStatusEnum.Started]: "Started",
      [CareerInterviewStatusEnum.WaitingForReSchedule]: "Waiting For Reschedule"

    },
    statusEnum: {
      [CareerStatusEnum.InProcess]: "In Process",
      [CareerStatusEnum.Approved]: "Approved",
      [CareerStatusEnum.Declined]: "Declined",
      [CareerStatusEnum.Cancelled]: "Cancelled",
      [CareerStatusEnum.Available]: "Available",
      [CareerStatusEnum.Expired]: "Expired",
      [CareerStatusEnum.Hold]: "Hold",
    },
    careerFilterTypeEnum: {
      [CareerFilterTypeEnum.All]: "All Careers",
      [CareerFilterTypeEnum.MyCareers]: "My Careers",
      [CareerFilterTypeEnum.ForApproval]: "For Approvals",
    },
    SortEnum: {
      [CareerFilterSortEnum.CreateDateDesc]: "Creation Date - Descending",
      [CareerFilterSortEnum.CreateDateAsc]: "Creation Date ",
      [CareerFilterSortEnum.ReferenceNoDesc]: "Update Date - Descending",
      [CareerFilterSortEnum.ReferenceNoAsc]: "Update Date ",
      [CareerFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [CareerFilterSortEnum.UpdateDateAsc]: "Update Date ",
      [CareerFilterSortEnum.MinSalary]: "Min Salary",
      [CareerFilterSortEnum.MinSalaryDesc]: "Min Salary - Descending",
      [CareerFilterSortEnum.MaxSalary]: "Max Salary",
      [CareerFilterSortEnum.MaxSalaryDesc]: "Max Salary - Descending",

    },
    CareerLevelEnum: {
      [CareerLevelEnum.Entry]: "Entry",
      [CareerLevelEnum.MidLevel]: "Mid Level",
      [CareerLevelEnum.SeniorLevel]: "Senior Level",
      [CareerLevelEnum.ExecutiveLevel]: "Executive Level",
      [CareerLevelEnum.CSuite]: "C-Suite",
      [CareerLevelEnum.PresidentCeo]: "President Ceo",

    },
    JobTypeEnums: {
      [JobTypeEnums.FullTime]: "Full Time",
      [JobTypeEnums.PartTime]: "Part Time",
      [JobTypeEnums.Contractual]: "Contractual",
      [JobTypeEnums.Consultant]: "Consultant",
      [JobTypeEnums.Agent]: "Agent",
      [JobTypeEnums.ProjectBased]: "Project Based",
    },
    JobShiftTypeEnums: {
      [JobShiftTypeEnums.Day]: "Day",
      [JobShiftTypeEnums.Morning]: "Morning",
      [JobShiftTypeEnums.AfterNoon]: "AfterNoon",
      [JobShiftTypeEnums.Night]: "Night",
      [JobShiftTypeEnums.Other]: "Other",

    }
  },
  bussinessContacts: {

    referenceTypeEnums: {
      [BusinessNumberContactReferenceTypeEnum.BusinessNumberContact]: "Business",
      [BusinessNumberContactReferenceTypeEnum.Employee]: "Employee",
      [BusinessNumberContactReferenceTypeEnum.LeadManagerDetailContact]: "Lead",
      [BusinessNumberContactReferenceTypeEnum.ProjectContact]: "Project",
    }
  }
  ,
  /*** HR Menu ***/
  myTeam: {
    MyTeams: "My Teams",
    EmployeeNo: "EmployeeNo",
    noDataMessage: "Create Teams now!",
    teamDetails: "Team Details",
    DeductionPerMonth: "Deduction Per Month",
    teamActivities: {
      attendance: "Attendance",
      checkIn: "Check In",
      leaves: "Leaves",
      rewards: "Rewards",
      appraisals: "Appraisals",
      warnings: "Warnings",
      complains: "Complains",
      courses: "Courses",
      education: "Education",
      experience: "Experience",
      loan: "Loan",
      activityLog: "Activity Log",
      checkInCheckOut: "Check-In / Check-Out",
      device: "Device",
      storageStatistics: "Storage Statistics"

    },
  },
  administration: {
    Category_Purpose: "Contract Purpose",
    basicInfo: "Basic Info",
    Subsidiary_Office: "Subsidiary Office",
    Fiscal_Year: "Fiscal Year",
    Tax_Slabs: "Tax Slabs",
    Tax_Slabs_Group: "Tax Slabs Group",
    E_Learning_Category: "Elearning Category",
    Company_Policy: "Company Policies",
    Default_Hiring_Criteria: "Default Hiring Criteria",
    subsidiary: "Subsidiary",
    AddSubsidiary: "Add Subsidiary",
    Business_Logo: "Business Logo",
    Complain_Category: "Complain Category",
    Custom_Tag: "Custom Tag",
    Payroll_Group: "Payroll Group",
    Business_Policy: "Business Policy",
    Companies_Policy: "Company Policies",
    Billing: "Billing",
    Rebate_Category: "Rebate Category",
    Assets_Category: " Assets Category",
    AccessRole: "Access Role",
    Grade: "Grade",
    Default_Account_Header: "Default Account Header",
    Business_Paging: "Business Number",
    Discussion_Board_Category: "Discussion Board Category",
    Room: "Room",
    Business_Association: "Business Association",
    Designation: "Designation",
    Company_Goal: "Company Goal",
    Individual_Goal: "Individual Goal",
    Office_Timings: "Office Timings",
    Leave_Types: "Leave Types",
    User_Types: "User Types",
    Expense_Headers: "Expense Header",
    Salary_Headers: "Salary Header",
    Request_For_Right: "Request For Right",
    Custom_Approval_Category: "Custom Approval Category",
    GRADE_ALLOWANCES: "Grade Allowances",
    Paging_Category: "Paging Category",
    Page_Category: "Page Category",
    Add_Paging_Category: "Add Page Category",
    Project_Category: "Project Category",
    Email_Configuration: "Email Configuration",
    Allowances: "Allowances",
    Default_Approvals: "Default Approval",
    NonMandatory_Approvals: "Non-Mandatory Approvals",
    WORKGATING: "WIP Gating Criteria",
    Job_Description: "Job Description",
    Rewards: "Rewards",
    Reward_Category: "Reward Category",
    Add_Reward_Category: "Add Reward Category",
    Warning_Category: "Warning Category",
    Add_Warning_Category: "Add Warning Category",
    Holidays: "Holidays",
    paymentCard: "Payment Card",
    addPaymentCard: "Add Payment Card",
    Company_Info: "Company Info",
    business_Info: "Business Information",
    Business_Approval: "Business Approval",
    ATTENDANCE_SETTINGS: `Attendance Settings`,
    businessInfo:{
      businessInformation:"Business Information",
      companyBackgroundLabel:"Company Background",
      productAndServicesLable:"Product & Services",
      competitorsLable:"Competitors",
      recentNewsLable:"Recent News",
      leadershipTeamLabel:"Leadership Team",
      organizationalStructureLabel:"Organizational Structure",
      customerBaseLabel:"Customer Base",
      FinancialHealthLabel:"Financial Health",
      industryLabel:"Industry",
      websiteAndSocialMediaLabel:"Website And Social Media",
      additionalCommentLabel:"Additional Comments",
      companyCultureLabel:"Company Culture",
      ceoLabel:"CEO",
      companyBackground:{
        mission:"Mission",
        vision:"Vision",
        values:"Values",
        YearFounded: "Year Founded",
        placeHolders:{
            enterMission:"Enter Mission",
            enterVision:"Enter Vision",
            enterValues:"Enter Values",
            enterYearFounded:"Enter Year Founded",
  
        },
        requiredMessages:{
            enterMission:"Please Enter Mission",
            enterVision:"Please Enter Vision",
            enterValues:"Please Enter Values",
            enterYearFounded:"Please Enter Year Founded"
  
        }  
      },
      productAndService:{
        Benefits: "Benefits"
      },
      competitors:{
        MarketPosition: "Market Position",
        Strength: "Strength",
        Weakness: "Weakness", 
      },
      recentNews:{
        Headline: "Headline",
        News:"News"
      },
      leadershipTeam:{
        Background: "Background",
        ContactNumber: "Contact Number",
      },
      financialHealth:{
        Revenue: "Revenue",
        Profitability: "Profitability"
      },
      additionalComment:{
         label:{
          comments:"Comments"
         },
        placeholders:{
          comments:"Enter Comments"
        },
        requiredMessages: {
          enterMission: "Please Enter Mission",
          enterVision: "Please Enter Vision",
          enterValues: "Please Enter Values",
          enterYearFounded: "Please Enter Year Founded"

        }
      },
      additionalComment: {
        label: {
          comments: "Comments"
        },
        placeholders: {
          comments: "Enter Comments"
        },
        requireMessages: {
          enterComments: "Enter Comments"
        }
      },
      comapnyCulture: {
        placeHolders: {
          enterDescription: "Enter Description",
          enterWorkEnvironment: "Enter Work Environment"
        },
        label: {
          description: "Description",
          workEnvironment: "Work Environemnt"

        },
        requiredMessages: {
          enterDescription: "Please Enter Description",
          enterWorkEnvironment: "Please Enter Work Environment"
        }
      }
    },
    COMPANY_STATISTICS: "Company Statistics",
    CATEGORY_PURPOSE: "Contract Purpose",
    Form: {
      Name: "Name",
      AddCategoryPurpose: "Add Contract Purpose",
      Entername: "Enter name",
      PleaseEnterName: "Please Enter Name",
      Description: "Description",
      enterDescription: "Please Enter Description",
      Create: "Create",
      Add: "Add",
      AddBusinessNumber: "Add Business Number",
      AddAllowance: "Add Allowance",
      AddAssetsCategory: "Add Assets Category",
      UpdateAssetsCategory: "Update Assets Category",
      AddBusinessAssociation: "Add Business Association",
      AddCustomApprovalCategory: "Add Custom Approval Category",
      AddCustomTag: "Add Custom Tag",
      AddDesignation: "Add Designation",
      AddComplain: "Add Complain",
      Save: "Save",
      Clear: "Clear",
      CategoryName: "Category Name",
      Entercategoryname: "Enter category name",
      SaveCategory: "Save Category",
      Date: "Date",
      startDate: "Start Date",
      endDate: "End Date",
      Upload: "Upload",
      Percent: "Percent",
      Amount: "Amount",
      EnterAmount: "Enter Amount",
      addCategory: "Add Category",
      SelectMember: "Select Member",
      Email: "Email",
      EnterEmail: "Enter Email",
      Types: "Types",
      SelectStatus: "Select Status",
      Submit: "Submit",
      Nomoreapprovals: "No more approvals",
    },
    Table: {
      Name: "Name",
      Members: "Members",
      Description: "Description",
      Value: "Value",
      Allot_Leave: "Allot Leave",
      Type: "Type",
      Unit: "Unit",
      Taxable: "Taxable",
      Provider: "Provider",
      IncomingServerAddress: "Incoming Server Address",
      OutgoingServerAddress: "Outgoing Server Address",
      IncomingPort: "Incoming Port",
      OutgoingPort: "Outgoing Port",
      Action: "Action",
      startDate: "Start Date",
      endDate: "End Date",
      Actions: "Actions",
      Account: "Account",
      approvers: "Approvers",
      Status: "Status",
      Allocated: "Allocated",
      AddMember: "AddMember",
      CreateDate: "Create Date",
    },
    accessRole: {
      Button: {
        AddAccessRole: "Add Access Role",
        UpdateAccessRole: "Update Access Role",
      },
      Drawer: {
        CreateAccessRole: "Create Access Role",
        UpdateAccessRole: "Update Access Role",
        UserType: "User Type",
        Description: "Description",
        placeholders: {
          EnterName: "Enter Name of Access Role",
          UserType: "Please Select User Type",
          Description: "Enter Description",
        },
        formLabel: {
          AccessRoleName: "Access Role Name",
        },
        placeholders: {
          EnterName: "Enter access role",
          SelectUserType: "Select user type",
          EnterDescription: "Enter description",
        },
      },
    },
    grade: {
      AddGrade: "Add Grade",
      AddGradeAllowances: "Add Grade Allowances",
      EnterGrade: "Enter grade",
      EnterDescription: "Enter description",
      Grade: "Grade",
      Description: "Description",
      Name: "Name",
      clear: "Clear",
      saveGrade: "Save Grade",
    },
    fiscalyear: {
      Add: "Add",
      AddFiscalYear: "Add Fiscal Year",
      EnterName: "Enter name",
      EnterDescription: "Enter description",
      Fiscalyear: "Fiscal Year",
      description: "Description",
      name: "Name",
      clear: "Clear",
      save: "Save ",
      startyear: "Start Year",
      endyear: "End Year",
      startMonth: "Start Month",
      endMonth: "End Month",
      startDate: "Start date",
      endDate: "End Date",
    },
    businessPolicy: {
      companypolicy: " Company Policies",
      enterName: "Enter name",
      name: "Name",
      type: "Select type",
      typee: "Type",
      description: "Description",
      EnterDescription: "Enter Description",
      create: "Create",
      save: "Save",
      createPolicy: "Create Policy",
      updatePolicy: "Update Policy",

    },
    taxSlab: {
      TaxSlabs: "Tax Slabs",
      UpdateTaxSlab: "Update Tax Slab",
      CreateTaxSlab: "Create Tax Slab",
      NoTaxSlabGroup: "No Tax Slab Group...",
      enterCountry: "Select Country",
      enterName: "Enter Name",
      enterDescription: "Enter Description",
      description: "Description",
      name: "Name",
      TaxSlab: "Tax Slab",
      title: "Title",
      enterTitle: "Enter Title",
      percentage: "Percentage",
      previousCharge: "Previous Charge",
      min: "Min",
      max: "Max",
      enterMin: "Enter Min",
      enterMax: "enter Max",
      enterPercent: "Enter Percentage",
      save: "Save",
      clear: "Clear",
      Add: "Add",
      TaxSlabgroup: "Tax Slab Group",
      country: "Country",
      minimumCharge: "Minimum Charge",
    },
    payrollGroup: {
      PayrollGroup: "Payroll Group",
      AddPayrollGroup: "Add Payroll Group",
      save: "Save Group",
      clear: "Clear",
      Add: "Add Group",
      name: "Name",
      enterName: "Enter name",
    },
    subsidiaryOffice: {
      enterName: "Enter name",
      enterAddress: "Enter address",
      name: "Name",
      subsidiary_Office: "Subsidiary Office",
      latitude: "Latitude",
      longitude: "Longitude",
      address: "Address",
      branch: "Branch",
      Subsidiary: "Subsidiary",
      select: "Select Subsidiary",
      save: "Save",
      clear: "Clear",
      AddSubsidiaryOffice: "Add Subsidiary Office",
      Add: "Add",
      title: "Title",
      enterTitle: "Enter title",
    },
    defaultHiringCriteria: {
      default: "Default Hiring Criteria",
      question: "Questions",
      enterQuestions: "Enter Question",
      save: "Save Question",
      clear: "Clear",
      Add: "Add Question",
    },
    workGatingCriteria: {
      work: "WIP Gating Criteria",
      question: "Questions",
      enterQuestions: "Enter question",
      save: "Save Question",
      clear: "Clear",
      Add: "Add Question",
      addWork: "Add WIP Gating Criteria"
    },
    designation: {
      desig: "Designation",
      enterdesig: "Enter designation",
      enterName: "Enter Name",
      enterDescription: "Enter description",
      description: "Description",
      name: "Name",
      save: "Save Designation",
      clear: "Clear",
      Add: "Add Designation",
    },
    appraisal: {
      Appraisal: "Appraisal",
      enterAppraisal: "Enter appraisal",
      enterDescription: "Enter description",
      description: "Description",
      save: "Save Appraisal",
      clear: "Clear",
      Add: "Add Appraisal",
      CompanyGoal: "Company Goal",
      IndividualGoal: "Individual Goal",
      DepartmentsGoal: "Departments Goal",
      Goal: "Goal",
      saveGoal: "Save Goal",
      AddCompanyGoal: "Add Company Goal",
      AddDepartmentGoal: "Add Department Goal",
      AddIndividualGoal: "Add Individual Goal",
      enterCompanyGoal: "Enter Company Goal",
      enterDepartmentGoal: "Enter Department Goal",
      enterIndividualGoal: "Enter Individual Goal",
    },
    office: {
      Office_Timing: "Office Timing",
      group: "Group Name",
      enterGroup: "Enter name",
      enterDescription: "Enter description",
      description: "Description",
      save: "Save office Timings",
      clear: "Clear",
      Add: "Add  office Timings",
      days: "Days",
      work: "Is Working",
      checkIn: "Check In",
      checkOut: "Check Out",
      time: "Grace Time",
      min: "Min",
      create: "Create Group",
      new: "New Time Group",
      UpdateOfficeTiming: "Update Office Timing",
      CreateOfficeTiming: "Create Office Timing",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
    },
    leave: {
      Leave: "Leave Type",
      leaveType: "Leave Type",
      enterLeave: "Enter leave type",
      save: "Save Leave Type",
      clear: "Clear",
      Add: "Add Leave Type",
      enterDescription: "Enter description",
      description: "Description",
      allotLeaves: "Alloted leaves",
      balanceLeaves: "Leave Types (balance leaves)",
      selectType: "Select type",
      leaveFor: "Leave For",
      leaveDates: "Leave Dates",
      createleave: "Create Leave",
    },
    expense: {
      Expense: "Expense Header",
      enterExpense: "Enter expense header",
      enterDescription: "Enter description",
      description: "Description",
      save: "Save",
      clear: "Clear",
      Add: "Add  Expense Header",
    },
    elearningCategory: {
      elearningCategory: "Elearning Category",
      description: "Description",
      enterelearningCategory: "Enter category ",
      enterDescription: "Enter description",
      save: "Save",
      clear: "Clear",
      Add: "Add ElearningCategory",
    },
    salaryHeader: {
      SalaryHeader: "Salary Header",
      AddSalaryHeader: "Add Salary Header",
      enterSalaryHeader: "Enter salary header",
      desc: "Description",
      enterDesc: "enter description",
      saveHeader: "Save Header",
      addHeader: "Add Header",
      name: "Name",
      clear: "clear",
    },
    room: {
      Room: "Room",
      Name: "Name",
      Entername: "Enter name",
      Description: "Description",
      Enterdescription: "Enter description",
      Location: "Location",
      Enterlocation: "Enter location",
      TotalSeats: "Total Seats",
      Enterseats: "Enter seats",
      SaveRoom: "SaveRoom",
      AddRoom: "Add Room",
      clear: "clear"
    },
    RebateCategory: {
      rebateCategory: "Rebate Category",
      addRebateCategory: "Add Rebate Category",
      maxPercentage: "Max Percentage",
      entermaxPercentage: "Enter max percentage",
      maxAmount: "Max Amount",
      enterMaxAmount: "Enter max amount",
      type: "Type",
      basic: "Basic",
      tax: "Tax",
      fullAmount: "Full Amount",
      rebateType: "Rebate Type",
    },
    projectCategory: {
      ProjectCategory: "Project Category",
      AddProjectCategory: "Add Project Category",
    },
    pageCategory: {
      Pagecategory: "Page Category",
      AddPageCategory: "Add Page Category",
    },
    NonMandatoryApprovals: {

      NonMandatoryApproval: "Non-Mandatory Approvals",
    },
    jobdescription: {
      jobDesc: "Job Description",
      designation: "Designation",
      selectDesign: "Select Designation",
      saveJobDesc: "Save",
      addJobdesc: "Add Job Description",
    },
    Holiday: {
      Holidays: "Holidays",
      AddHolidays: "Add Holidays",
      AddHoliDay: "Add Holiday"
    },
    GradeAllowance: {
      gradeAllowance: "Grade Allowance",
      Grades: "Grades",
      AllowanceType: "Allowance Type",
      SelectType: "Select Type",
      AddAllowance: "Add Allowance",
      UpdateAllowance: "Update Allowance",
      Allowance: "Allowance",
      IsTaxable: "Is Taxable",
    },
    EmailConfiguration: {
      emailConfi: "Email Configuration",
      enterEmailConfi: "Enter email configuration",
      incomingPort: "Incoming Port",
      enterIncomingPort: "Enter incoming port",
      incomingServerAddress: "Incoming Server Address",
      enterIncomingServerAddress: "Enter incoming server address",
      outgoingPort: "Outgoing Port",
      enterOutgoingPort: "Enter outgoing Port",
      outgoingServerAddress: "Outgoing Server Address",
      enterOutgoingServerAddress: "Enter outgoing server address",
      provider: "Provider",
      enterProvider: "Enter provider",
      saveEmailConfi: "Save Email Configuration",
      addEmailConfi: "Add Email Configuration",
    },
    DiscussionBoardCategory: {
      discussionBoardCategory: "Discussion Board Category",
      SaveDiscussionBoardCategory: "Save Discussion Board Category",
      AddDiscussionBoardCategory: "Add Discussion Board Category",
      Save: "Save",
      Clear: "Clear",
      Update: "Update",
    },
    DefaultApprovers: {
      defaultApprovers: "Default Approvers",
    },
    DefaultAccountHeader: {
      defaultAccountHeader: "Default Account Header",
      SaveAccountHeader: " Save Account Header",
      SelectAccountTypes: "Select Account Types",
    },
    CustomTag: {
      customTag: "Custom Tag",
      Entercustomtag: "Enter custom tag",
      SaveCustomTag: "Save Custom Tag",
      AddCustomTag: "Add Custom Tag",
    },
    customApprovalCategory: {
      customApprovalcategory: "Custom Approval Category",
      enterCustomApprovalcategory: "Enter custom approval category",

    },
    BusinessNumber: {
      Manual: "Manual",
      TotalNumber: "Total Number",
      Country: "Country",
      SelectCountry: "Select Country",
      Number: "Number",
      Random: "Random",
      SelectNumber: "Select Number",
      Cost: "Cost",
      Request: "Request",
    },
    BusinessAssociation: {
      businessAssociation: "BusinessAssociation",
      SaveBusinessAssociation: "Save Business Association",
      AddBusinessAssociation: "Add Business Association",
      AssociationType: "Association Type",
    },
    AssetsCategory: {
      assetsCategory: "Assets Category",
      categoryName: "Category Name",
      enterCategoryName: "Enter category name",
      desc: "Description",
      enterDesc: "Enter description",
      accountType: "Account Type",
      parentAccount: "Parent Account",
      selectParent: "Select Parent",
      selectType: "Select Type",
      add: "Add",
      save: "Save",
      clear: "Clear",
      name: "Name",
      assets: "Asset",
      liability: "Liability",
      capital: "Capital",
      revenue: "Revenue",
      expense: "Expense",
      costOfGoodsale: "Cost of Good Sold",
    },
    Allowance: {
      allowance: "Allowance",
      enterAllownace: "Enter allowance",
      percent: "Percent",
      desc: "Enter description",
      grade: "Grade",
      selectGrade: "Select Grade",
      amount: "Amount",
      benefit: "Benefit",
      deduction: "Deduction",
      texable: "Taxable",
      nonTexable: "Non Taxable",
      director: "Director",
      ceo: "CEO",
      addAllowance: "Add Allowance",
      saveAllowance: "Save Allowance",
      clear: "Clear",
      name: "Name",
      allowanceType: "Allowance Type",
      allowanceUnit: "Allowance Unit",
      istexable: "Is Taxable",
      yes: "Yes",
      no: "No",
    },
    CompanyInfo: {
      businessLogo: "Business Logo",
      basicinfo: "Basic Info",
      ShortLogo: "Short Logo",
      Logo: "Logo",
      FullLogo: "Full Logo",
      companyName: "Company Name",
      PleaseEnterCompanyName: "Please Enter Company Name",
      enterCompanyName: "Enter Company Name",
      companyEmail: "Enter Company Email",
      PleaseEnterCompanyEmail: "Please Enter Company Email",
      SelectOwnerName: "Select Owner Name",
      totalEmployees: "Total Employees",
      totalEmployeesInCall: "Total Employees In Call",
      SaveShortLogo: "Save Short Logo",
      LongLogo: "Long Logo",
      SaveLongLogo: "Save Long Logo",
      SaveFullLogo: "Save Full Logo",

    },
    billing: {
      Invoice: "Invoice",
      InvoiceNo: "Invoice No",
      CustomerName: "Customer Name",
      PayInvoice: "Pay Invoice",
      Payment: "Payment",
      BillNo: "Bill No",
      CardLast: "Card Last 4",
      BusinessName: "Business Name",
      CardHolder: "Card Holder",
      Viewinvoicedetails: "View invoice and payment details",
      Viewinvoicedetail: "View invoice detail",
      Qty: "Qty",
      BillType: "Bill Type"


    },
    PaymentCard: {
      paymentCard: "Payment Card",
      CardHolderName: "Card Holder Name",
      AddCard: "Add Card",
      PayInvoice: "Pay Invoice",
      CardHolderNameRequired: "Card Holder Name is Required.",
      PayCard: "Pay Card",
      payanotherway: "Or pay another way",
      credentialstocontinue: "Enter your credentials to continue.",
      Expiry: "Expiry",
      Brand: "Brand",
      Country: "Country",
      LastFourDigits: "Last Four Digits",

    }
  },
  billing: {
    BillType: "Bill Type",
    billingTypeEnum: {
      [BillingTypeEnum.NewUser]: "New User",
      [BillingTypeEnum.MonthlySubscription]: "Monthly Subscription",
      [BillingTypeEnum.NewBusinessNumber]: "New Business Number",
    }
  },
  businessNumber: {
    statusEnum: {
      [BuisnessNumberStatusEnum.Waiting]: "Waiting",
      [BuisnessNumberStatusEnum.Declined]: "Declined",
      [BuisnessNumberStatusEnum.NumberCancelled]: "Number Cancelled",
      [BuisnessNumberStatusEnum.Purchased]: "Purchased",
      [BuisnessNumberStatusEnum.RequestCancelled]: "Request Cancelled",
      [BuisnessNumberStatusEnum.Rejected]: "Rejected",
    },
  },
  appraisal: {
    Appraisals: "Appraisals",
    createAppraisals: "Create Appraisals",
    noDataMessage: "– Create Appraisals now!",
    Approvers: "Approvers",
    Questions: "Questions",
    Tasks: "Tasks",
    Bonus: "Bonus",
    Increment: "Increment",
    previousApraisals: "Previous Appraisals",
    warning: "Warning",
    rewards: "Rewards",
    courses: "Courses",
    Promotion: "Promotion",
    PleaseEnterBonusPercentage: "Please Enter Bonus Percentage",
    validateIncrement: "Unable to process increment: No salary information available for the employee",
    PleaseEnterBonusAmount: "Please Enter Bonus Amount",
    PleaseEnterIncrementAmount: "Please Enter Increment Amount",
    RateAtleastOneItem: "Rate Atleast One Item",
    Noemployeeselected: "No employee selected",
    submitAppraisal: "Submit Appraisals",
    Appraisedof: "Appraised of",
    AppraisalApprovers: "Appraisal Approvers",
    AppraisalDetail: "Appraisal Detail",
    appraisalFilterTypeEnum: {
      [AppraisalFilterEnum.All]: "All Appraisals",
      [AppraisalFilterEnum.CreatedByMe]: "Created By Me",
      [AppraisalFilterEnum.ForApproval]: "For Approval",
      [AppraisalFilterEnum.TeamAppraisal]: "Team Appraisal",
      [AppraisalFilterEnum.MyApprovedAppraisal]: "My Approved Appraisal",
    },
    statusEnum: {
      [AppraisalStatusEnum.InProcess]: "In Process",
      [AppraisalStatusEnum.Approved]: "Approved",
      [AppraisalStatusEnum.Declined]: "Declined",
      [AppraisalStatusEnum.Cancelled]: "Cancelled",
      [AppraisalStatusEnum.Hold]: "Hold",
    },
    SortEnum: {
      [AppraisalFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [AppraisalFilterSortEnum.CreateDateAsc]: "Create Date",
      [AppraisalFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [AppraisalFilterSortEnum.UpdateDateAsc]: "Update Date",
      [AppraisalFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [AppraisalFilterSortEnum.ReferenceNo]: "Reference No",

    }
  },
  loan: {
    loan: "Loan",
    createLoan: "Create Loan",
    loanTenure: "Loan Tenure (In Months)",
    enterLoanTenure: "Enter Loan Tenure (In Months)",
    deduction: "Deduction/Months",
    selectLoanPurpose: "Select Loan Purpose",
    noDataMessage: " – Create Loans now!",
    SortBy: "Sort By",
    SelectSortBy: "Select SortBy",
    InterestRate: "Interest Rate",
    loanPurpose: {
      loanPurpose: "Loan Purpose",
      vehicle: "Vehicle",
      personal: "Personal",
      wedding: "Wedding",
      medical: "Medical",
      education: "Education",
      house: "House",
      other: "Other",
    },
    loanPurposeEnumList: {
      [LoanPurposeEnum.Personal]: "Personal",
      [LoanPurposeEnum.Wedding]: "Wedding",
      [LoanPurposeEnum.Medical]: "Medical",
      [LoanPurposeEnum.Education]: "Education",
      [LoanPurposeEnum.House]: "House",
      [LoanPurposeEnum.Vehicle]: "Vehicle",
      [LoanPurposeEnum.Other]: "Other",
    },
    loanFilterTypeEnum: {
      [LoanFilterTypeEnum.All]: "All Loans",
      [LoanFilterTypeEnum.ForApproval]: "For Approvals",
      [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "My Loans",
      [LoanFilterTypeEnum.LoanForMe]: "Loan For Me",
    },
    statusEnum: {
      [LoanStatusEnum.InProcess]: "In Process",
      [LoanStatusEnum.Approved]: "Approved",
      [LoanStatusEnum.Declined]: "Declined",
      [LoanStatusEnum.Cancelled]: "Cancelled",
      [LoanStatusEnum.Disbursed]: "Disbursed",
      [LoanStatusEnum.PartialCleared]: "Partial Cleared",
      [LoanStatusEnum.Completed]: "Completed",
      [LoanStatusEnum.Hold]: "Hold",
      [LoanStatusEnum.WaitingForDisburse]: "Waiting For Disburse",

    },
    loanTypeEnum: {
      [LoanTypeEnum.Company]: "Company Loan",
      [LoanTypeEnum.WorkWise]: "Workwise Loan",
      [LoanTypeEnum.Both]: "Both",
    },
    loanFilterSortEnum: {
      [LoanFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [LoanFilterSortEnum.CreateDateAsc]: "Create Date",
      [LoanFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [LoanFilterSortEnum.UpdateDateAsc]: "Update Date",
      [LoanFilterSortEnum.ReferenceNo]: "Reference No",
    },
  },
  quotations: {
    Quotation: "Quotation",
    CreateQuotation: "Create Quotation",
    quotationDate: "Date",
    ClientName: "Client's Name",
    ClientEmail: "Client's Email",
    PhoneNumber: "Phone Number",
    TotalAmount: "Total Amount",
    QuotationType: "Type",
    Rate: "Rate",
    Quantity: "Quantity",
    Attachments: "Attachments",
    serviceItem: "Service/Item",
    price: "Price",
    tax: "Tax (%)",
    taxAmount: "Tax Amount",
    sno: "S.No.",
    EnterClientName: "Enter Client's Name",
    clientsName: "Client's Name",
    EnterClientEmail: "Enter Client's Email",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    EnterAddress1: "Enter Address 1:",
    EnterAddress2: "Enter Address 2:",
    Product: "Product",
    Service: "Service",
    netAmount: "Net Amount",
    CODE: "CODE",
    DATE: "DATE",
    TOTAL: "TOTAL",
    To: "To",
    SubTotal: "SubTotal",
    noDataMessage: "– Create Quotation now!",
    quotationFilterTypeEnum: {
      [QuotationFilterEnum.All]: "Quotations",
      [QuotationFilterEnum.CreatedByMe]: "Created By Me",
      [QuotationFilterEnum.ForApproval]: "For Approval",
    },
    statusEnum: {
      [QuotationStatusEnum.InProcess]: "In Process",
      [QuotationStatusEnum.Approved]: "Approved",
      [QuotationStatusEnum.Declined]: "Declined",
      [QuotationStatusEnum.Cancelled]: "Cancelled",
      [QuotationStatusEnum.Hold]: "Hold",
      [QuotationStatusEnum.ClientApproved]: "Client Approved",
      [QuotationStatusEnum.ClientRejected]: "Client Rejected",
    },
    SortEnum: {
      [QuotationFilterSortEnum.CreateDateDesc]: "Creation Date - Descending",
      [QuotationFilterSortEnum.CreateDateAsc]: "Creation Date - Ascending",
      [QuotationFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [QuotationFilterSortEnum.UpdateDateAsc]: "Update Date - Ascending",
      [QuotationFilterSortEnum.ReferenceNo]: "Reference No",
      [QuotationFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending"
    },
    ErrorFieldMessage:{
      [InputFieldTypes.PRICE]: "Price should be greater than 0",
      [InputFieldTypes.QUANTITY]: "Quantity should be greater than 0",
      [InputFieldTypes.TAX]: "Tax should be greater than 0",
    },
    singleItemError: "Please add at least one item to the quotation",
  },
  payroll: {
    Payroll: "Payroll",
    createPayroll: "Create Payroll",
    noDataMessage: "– Create Payroll now!",
    basicSalary: "Basic Salary",
    loan: "Loan",
    allowance: "Allowance",
    ExpenseReimbursement: "Expense Reimbursement",
    deduction: "Deduction",
    tax: "Tax",
    rebate: "Rebate",
    bonus: "Bonus",
    selectMonthYear: "Select Month & Year",
    disburseDate: "Disburse Date",
    Voucher: "Voucher",
    DownloadtoExcel: "Download to Excel",
    PayrollDetail: "Payroll Detail",
    TransactionMode: "Transaction Mode",
    IBANNumber: "IBAN Number",

    statusEnum: {
      [PayrollStatusEnum.InProcess]: "In Process",
      [PayrollStatusEnum.Approved]: "Approved",
      [PayrollStatusEnum.Declined]: "Declined",
      [PayrollStatusEnum.Resend]: "Resend",
      [PayrollStatusEnum.Inactive]: "In Active",
      [PayrollStatusEnum.NotRequired]: "Not Required",
      [PayrollStatusEnum.Cancelled]: "Cancelled",
      [PayrollStatusEnum.Hold]: "Hold",
      [PayrollStatusEnum.Disbursed]: "Disbursed",
      [PayrollStatusEnum.Completed]: "Completed",
    },
    payrollFilterTypeEnum: {
      [PayrollFilterTypeEnum.All]: "Payroll",
      [PayrollFilterTypeEnum.CreatedByMe]: "Created By Me",
      [PayrollFilterTypeEnum.ForApproval]: "For Approval",
    },
  },
  voucher: {
    createVoucher: "Create Voucher",
    SelectVoucherType: "Select Voucher Type",
    BasicSalary: "Basic Salary",
    LoanPayment: "Loan Payment",
    LoanDispersable: "Loan Dispersable",
    Allowance: "Allowance",
    ExpenseReimbursement: "Expense Reimbursement",
    Deduction: "Deduction",
    VoucherDetail: "Voucher Detail",
    SavePrint: "Save&Print",
    Bonus: "Bonus",
    Rebate: "Rebate",
    Tax: "Tax",
    ChequeNo: "Cheque No",
    Account: "Account",
    Narration: "Narration",
    Debit: "Debit",
    Credit: "Credit",
    Dr: "Dr",
    Cr: "Cr",
    differences: "Differences",
    drCr: "Dr/Cr",
    LinkVoucher: "Link Voucher",
    PostVoucher: "Post Voucher",
    voucherDate: "Voucher Date",
    VoucherType: "Voucher Type",

    voucherTypeList: {
      [voucherTypeEnum.GeneralVoucher]: "General Voucher",
      [voucherTypeEnum.PaymentVoucher]: "Payment Voucher",
      [voucherTypeEnum.ReceiptVoucher]: "Receipt Voucher",
    },
    accountTypeList: {
      [accountTypeEnum.Asset]: "Asset",
      [accountTypeEnum.Capital]: "Capital",
      [accountTypeEnum.Expense]: "Expense",
      [accountTypeEnum.Liability]: "Liability",
      [accountTypeEnum.Revenue]: "Revenue",
    }
  },
  trialBalance: {
    accountTypeList: {
      [AccountTypeEnum.Asset]: "Asset",
      [AccountTypeEnum.Capital]: "Capital",
      [AccountTypeEnum.Expense]: "Expense",
      [AccountTypeEnum.Liability]: "Liability",
      [AccountTypeEnum.Revenue]: "Revenue",
    }
  },
  businessApproval: {
    statusTypeList: {
      [ApprovalStatusTypeEnum.InProcess]: "In Process",
      [ApprovalStatusTypeEnum.Approved]: "Approved",
      [ApprovalStatusTypeEnum.Declined]: "Declined",
      [ApprovalStatusTypeEnum.Cancelled]: "Cancelled",
      [ApprovalStatusTypeEnum.Completed]: "Completed",
    },
     approvalModuleList : {
      [ApprovalsModuleEnum.ExpenseApproval]: "Expense",
      [ApprovalsModuleEnum.ExpenseExecutor]: "Expense Executor",
      [ApprovalsModuleEnum.ExpenseFinance]: "Expense Finance",
      [ApprovalsModuleEnum.LeaveApproval]: "Leave",
      [ApprovalsModuleEnum.TravelApproval]: "Travel",
      [ApprovalsModuleEnum.TravelAgent]: "Travel Agent",
      [ApprovalsModuleEnum.WarningApproval]: "Warning",
      [ApprovalsModuleEnum.UserEducation]: "User Education",
      [ApprovalsModuleEnum.UserWorkExperience]: "User Work Experience",
      [ApprovalsModuleEnum.ComplainApproval]: "Complaint",
      [ApprovalsModuleEnum.RewardApproval]: "Reward",
      [ApprovalsModuleEnum.BonusApproval]: "Bonus",
      [ApprovalsModuleEnum.PromotionApproval]: "Promotion",
      [ApprovalsModuleEnum.RequestForItemApproval]: "Request for Item",
      [ApprovalsModuleEnum.LoanApproval]: "Loan",
      [ApprovalsModuleEnum.CustomApproval]: "Custom",
      [ApprovalsModuleEnum.FormApproval]: "Form",
      [ApprovalsModuleEnum.DocumentApproval]: "Document",
      [ApprovalsModuleEnum.SalaryApproval]: "Salary",
      [ApprovalsModuleEnum.SignupApproval]: "Signup",
      [ApprovalsModuleEnum.PayrollApproval]: "Payroll",
      [ApprovalsModuleEnum.CareerApproval]: "Career",
      [ApprovalsModuleEnum.RequisitionApproval]: "Requisition",
      [ApprovalsModuleEnum.RequisitionFinalApproval]: "Requisition Final",
      [ApprovalsModuleEnum.RequestForItemAssetControllerApproval]: "Request for Item (Asset Controller)",
      [ApprovalsModuleEnum.ItemApproval]: "Item",
      [ApprovalsModuleEnum.AssetApproval]: "Asset",
      [ApprovalsModuleEnum.ResignationAdminApproval]: "Resignation (Admin)",
      [ApprovalsModuleEnum.ResignationExitApproval]: "Resignation (Exit)",
      [ApprovalsModuleEnum.ResignationFinanceApproval]: "Resignation (Finance)",
      [ApprovalsModuleEnum.ResignationHrApproval]: "Resignation (HR)",
      [ApprovalsModuleEnum.ResignationItApproval]: "Resignation (IT)",
      [ApprovalsModuleEnum.ResignationOtherApproval]: "Resignation (Other)",
      [ApprovalsModuleEnum.ResignationReportingToApproval]: "Resignation (Reporting To)",
      [ApprovalsModuleEnum.QuotationApproval]: "Quotation",
      [ApprovalsModuleEnum.QuotationClientApproval]: "Quotation (Client)",
      [ApprovalsModuleEnum.AppraisalApproval]: "Appraisal",
      [ApprovalsModuleEnum.AuctionApproval]: "Auction"
  }  
  },
  disperse: {
    transactionModeEnumList: {
      [TransactionModeTypeEnum.Cash]: "Cash",
      [TransactionModeTypeEnum.BankTransfer]: "Bank Transfer",
      [TransactionModeTypeEnum.Cheque]: "Cheque",
    },
  },
  promotion: {
    CreatePromotion: "Create Promotion",
    Promotion: "Promotion",
    promotionTo: "Promotion To",
    noDataMessage: "Create Promotion now!",
    selectPromotionTo: "Select Promotion To",
    pleseSelectPromotionTo: "Plese Select Promotion To",
    createPromotion: "Create Promotion",
    promotionFilterTypeEnum: {
      [PromotionFilterTypeEnum.All]: "All Promotions",
      [PromotionFilterTypeEnum.CreatedByMe]: "Created By Me",
      [PromotionFilterTypeEnum.ForApproval]: "For Approvals",
      [PromotionFilterTypeEnum.PromotionForMe]: "My Promotions",
    },
    statusEnum: {
      [PromotionStatusEnum.InProcess]: "In Process",
      [PromotionStatusEnum.Approved]: "Approved",
      [PromotionStatusEnum.Declined]: "Declined",
      [PromotionStatusEnum.Cancelled]: "Cancelled",
      [PromotionStatusEnum.Hold]: "Hold",
    },
    SortEnum: {
      [PromotionFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [PromotionFilterSortEnum.UpdateDateAsc]: "Update Date",
      [PromotionFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [PromotionFilterSortEnum.ReferenceNo]: "Reference No",
      [PromotionFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [PromotionFilterSortEnum.CreateDateAsc]: "Create Date"

    }

  },
  complains: {
    createComplain: "Create Complain",
    myComplain: "My Complains",
    AllComplains: "All Complains",
    complainOf: "Complain Of",
    ComplainDetail: "Complain Detail",
    ComplainToMe: "Complain To Me",
    ComplainAboutMe: "Complain About Me",
    noDataMessage: " – Create Complains now!",
    statusEnum: {
      [ComplainApprovalStatusEnum.InProcess]: "In Process",
      [ComplainApprovalStatusEnum.Approved]: "Approved",
      [ComplainApprovalStatusEnum.Declined]: "Declined",
      [ComplainApprovalStatusEnum.Resend]: "Resend",
      [ComplainApprovalStatusEnum.Inactive]: "In Active",
      [ComplainApprovalStatusEnum.NotRequired]: "Not Required",
      [ComplainApprovalStatusEnum.Cancelled]: "Cancelled",
      [ComplainApprovalStatusEnum.Hold]: "Hold",
    },
    SortEnum: {
      [ComplainFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [ComplainFilterSortEnum.CreateDateAsc]: "Create Date",
      [ComplainFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [ComplainFilterSortEnum.UpdateDateAsc]: "Update Date",
      [ComplainFilterSortEnum.ReferenceNo]: "Reference No",
      [ComplainFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending"
    },
  },
  salary: {
    SalaryMembers: "Salary Members",
    EffectiveDate: "Effective Date",
    BasicSalary: "Basic Salary",
    Allowances: "Allowances",
    Allowance: "Allowance",
    AllowanceDetail: "Allowance Detail",
    IsTaxable: "Is Taxable",
    Deduction: "Deduction",
    GrossSalary: "Gross Salary",
    CreateSalary: "Create Salary",
    SalaryDetail: "Salary Detail",
    AddSalary: "Add Salary",
    Increment: "Increment",
    Deductions: "Deductions",
    AllowanceType: "Allowance Type",
    EffectiveDateIsRequired: "Effective Date Is Required",
    BasicSalaryIsRequired: "Basic Salary Is Required",
    Negativesalaryisnotallowed: "Negative salary is not allowed",
    Benefit: "Benefit",
    noDataMessage: "– Create Salary now!",
    EnterIncrementPercentage: "Please Enter Increment Percentage",
    salaryFilterTypeEnum: {
      [SalaryFilterTypeEnum.Salaries]: "Salaries",
      [SalaryFilterTypeEnum.CreateByMe]: "Created By Me",
      [SalaryFilterTypeEnum.ForApproval]: "For Approval",
    },
    statusEnum: {
      [EmployeeSalaryStatusEnum.InProcess]: "In Process",
      [EmployeeSalaryStatusEnum.Approved]: "Approved",
      [EmployeeSalaryStatusEnum.Declined]: "Declined",
      [EmployeeSalaryStatusEnum.Cancelled]: "Cancelled",
      [EmployeeSalaryStatusEnum.Hold]: "Hold",
      [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "Waiting For Other Approval",
    },
    SortEnum: {
      [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "Create Date",
      [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "Update Date",
      [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [EmployeeSalaryFilterSortEnum.ReferenceNo]: "Reference No",

    }
  },
  resignations: {
    resignationHeader: "Resignations",
    resignationDate: "Resignation Date",
    createresignation: "Create Resignation",
    noDataMessage: "– Create Resignation now!",
    reasonforresignation: "Reason for Resignation",
    istermination: "Is Termination",
    resignationDetail: "Resignation Detail",
    statusEnum: {
      [ResignationApprovalStatusEnum.InProcess]: "In Process",
      [ResignationApprovalStatusEnum.Approved]: "Approved",
      [ResignationApprovalStatusEnum.Declined]: "Declined",
      [ResignationApprovalStatusEnum.Cancelled]: "Cancelled",
      [ResignationApprovalStatusEnum.Completed]: "Completed",
      [ResignationApprovalStatusEnum.Hold]: "Hold",
    },
    resignationFilterTypeEnum: {
      [ResignationFilterTypeEnum.Resignation]: "Resignation",
      [ResignationFilterTypeEnum.CreateByMe]: "Created By Me",
      [ResignationFilterTypeEnum.ForApproval]: "For Approvals",
    },
    resignationTypeEnum: {
      [ResignationTypeEnum.Resignation]: "Resignation",
      [ResignationTypeEnum.Termination]: "Termination",
      [ResignationTypeEnum.Both]: "Both",
    },
    SortEnum: {
      [ResignationFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [ResignationFilterSortEnum.CreateDateAsc]: "Create Date",
      [ResignationFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [ResignationFilterSortEnum.UpdateDateAsc]: "Update Date",
      [ResignationFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [ResignationFilterSortEnum.ReferenceNo]: "Reference No",
    },
    ResignationPurposeEnum: {
      [ResignationPurposeEnum.InsufficientWorkLifeBalance]: "Insufficient Work Life Balance",
      [ResignationPurposeEnum.UnrealisticGoalsAndPerformanceObjectives]: "Unrealistic Goals And Performance Objectives",
      [ResignationPurposeEnum.LackOfAClearPathForCareerAdvancement]: "Lack Of A Clear Path For Career Advancement",
      [ResignationPurposeEnum.FeelUnsupportedByManage]: "Feel Unsupported By Management",
      [ResignationPurposeEnum.DontFeelChallenged]: "Dont Feel Challenged",
      [ResignationPurposeEnum.TimeOffAndFlexibility]: "Time-Off And Flexibility",
      [ResignationPurposeEnum.Relocation]: "Relocation",
      [ResignationPurposeEnum.Others]: "Others",
    },
  },
  Employees: {
    employeeAttachments: "Employee Attachments",
    CreateEmployeeNow: "Create Employee Now",
    ProbationPeriod: "Probation Period(Days)",
    requiredMessageProbation: "Please input your probation period",
    requiredMessageProbationPeriod: "Probation period must be between 0 and 30",
    EmployeeNo: "Employee No",
    EnterEmployeeNo: "Enter Employee No",
    EmploymentType: "Employment Type",
    SelectEmploymentType: "Select Employment Type",
    requiredMessageEmploymentType: "Please select employment type",
    OfficeShift: "Office Shift",
    requiredMessageOfficeShift: "Please Select Office Shift",
    SelectOfficeShift: "Select Office Shift",
    UpdateBasicInfo: "Update Basic Info",
    Degree: "Degree",
    EnterDegree: "Enter Degree",
    requiredDegree: "Please Enter Degree",
    Institute: "Institute",
    EnterInstituteName: "Enter Institute Name",
    requiredMessageInstitute: "Please Enter Institute Name",
    ObtainedMarks: "Obtained Marks",
    requiredMessageObtained: "Please Enter Obtained Marks",
    EnterObtainedMarks: "Enter Obtained Marks",
    TotalMarks: "Total Marks",
    EnterTotalMarks: "Enter Total Marks",
    requiredMessageMarks: "Please Enter Total Marks",
    UpdateEducation: "Update Education",
    AddEducation: "Add Education",
    WorkExperienceInfo: "Work Experience Info",
    UpdateExperience: "Update Experience",
    AddExperience: "Add Experience",
    EmergencyInfo: "Emergency Info",
    UpdateEmergencyForm: "Update Emergency Form",
    AddEmergency: "Add Emergency",
    UpdateEmergency: "Update Emergency",
    UpdateBank: "Update Bank",
    AddBank: "Add Bank",
    EnableEmployee: "Are you sure to enable this employee ?",
    DisableEmployee: "Are you sure to disable this employe ?",
    Enable: "Enable",
    Disabled: "Disabled",
    UpdateFamily: "Update Family",
    AddFamily: "Add Family",
    AddEmployeesLinkage: "Add Employees Linkage",
    AllEmployees: "All Employees",
    FamilyInfo: "Family Info",
    Basic_Info: "Basic Information",
    Email_Configuration: "Email Configuration",
    Bank_Detail: "Bank Detail",
    Education: "Education",
    Emergency_Info: "Emergency Information",
    Work_Experience: "Work Experience",
    Experience: "Experience",
    Rebate: "Rebate",
    Salary: "Salary",
    Leaves: "Leaves",
    Devices: "Devices",
    basicInfo: "Basic Info",
    Appraisal: "Appraisal",
    Family: "Family",
    Attachments: "Attachments",
    My_Team: "My Team",
    Employees_Linkage: "Employees Linkage",
    Company_Goal: "Company Goal",
    Individual_Goal: "Individual Goal",
    Emails: "Emails",
    Finance: "Finance",
    Goal: "Goals",
    RebateInfo: "Rebate Info",
    UpdateRebate: "Update Rebate",
    AddRebate: "Add Rebate",
    AddSalary: "Add Salary",
    AddEmailConfiguration: "Add Email Configuration",
    AddBank: "Add Bank Detail",
    Statistics: "Statistics"
  },
  leaves: {
    leave: "Leaves",
    leavedetail: "Leave Details",
    Leave: "Leave Type",
    leaveType: "Leave Type",
    enterLeave: "Enter leave type",
    save: "Save Leave Type",
    Add: "Add Leave Type",
    allotLeaves: "Alloted leaves",
    balanceLeaves: "Leave Types (balance leaves)",
    leaveFor: "Leave For",
    leaveDates: "Leave Dates",
    createleave: "Create Leave",
    messages: "Please select leave type",
    allocatedLeaves: "Allocated Leaves",
    availedLeaves: "Availed Leaves",
    remainingLeaves: "Remaining Leaves",
    noDataMessage: " – Create Leave now!",
    Leavesinfo: "Leaves info",
    UpdateLeave: "Update Leave",
    leavesFilterTypeEnum: {
      [LeaveFilterTypeEnum.All]: "All Leaves",
      [LeaveFilterTypeEnum.CreatedByMe]: "Created By Me",
      [LeaveFilterTypeEnum.ForApproval]: "For Approvals",
      [LeaveFilterTypeEnum.LeaveForMe]: "My Leaves",
    },
    statusEnum: {
      [LeaveStatusEum.InProcess]: "In Process",
      [LeaveStatusEum.Approved]: "Approved",
      [LeaveStatusEum.Declined]: "Declined",
      [LeaveStatusEum.Resend]: "Resend",
      [LeaveStatusEum.InActive]: "In Active",
      [LeaveStatusEum.NotRequired]: "Not Required",
      [LeaveStatusEum.Cancelled]: "Cancelled",
      [LeaveStatusEum.Hold]: "Hold",
    },
    SortEnum: {
      [LeaveFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [LeaveFilterSortEnum.CreateDateAsc]: "Create Date",
      [LeaveFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [LeaveFilterSortEnum.UpdateDateAsc]: "Update Date",
      [LeaveFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [LeaveFilterSortEnum.ReferenceNo]: "Reference No",
    }
  },
  bonus: {
    Bonus: "Bonus",
    createBonus: "Create Bonus",
    BonusTo: "Bonus To",
    CurrentSalary: "Current Salary",
    noDataMessage: "– Create Bonus Now!",
    PercentageShouldNotExceed: "Percentage should not exceed 100",
    bonusFilterTypeEnum: {
      [BonusFilterTypeEnum.All]: "All Bonus",
      [BonusFilterTypeEnum.CreatedByMe]: "Created By Me",
      [BonusFilterTypeEnum.ForApproval]: "For Approvals",
      [BonusFilterTypeEnum.MyBonus]: "My Bonus",
    },
    statusEnum: {
      [BonusStatusEnum.InProcess]: "In Process",
      [BonusStatusEnum.Approved]: "Approved",
      [BonusStatusEnum.Declined]: "Declined",
      [BonusStatusEnum.Cancelled]: "Cancelled",
      [BonusStatusEnum.Inactive]: "In Active",
      [BonusStatusEnum.NotRequired]: "Not Required",
      [BonusStatusEnum.Resend]: "Resend",
      [BonusStatusEnum.WaitingForOtherApproval]: "Waiting For Other Approval",
      [BonusStatusEnum.Hold]: "Hold",
      [BonusStatusEnum.Disbursed]: "Disbursed",
      [BonusStatusEnum.Completed]: "Completed",


    },

    SortEnum: {
      [BonusFilterSortEnum.CreateDateDesc]: "Create Date - Descending",
      [BonusFilterSortEnum.CreateDateAsc]: "Create Date",
      [BonusFilterSortEnum.UpdateDateDesc]: "Update Date - Descending",
      [BonusFilterSortEnum.UpdateDateAsc]: "Update Date",
      [BonusFilterSortEnum.ReferenceNoDesc]: "Reference No - Descending",
      [BonusFilterSortEnum.ReferenceNo]: "Reference No",
      [BonusFilterSortEnum.Amount]: "Amount",
      [BonusFilterSortEnum.AmountDesc]: "Amount - Descending",
      [BonusFilterSortEnum.Salary]: "Salary",
      [BonusFilterSortEnum.SalaryDesc]: "Salary - Descending"
    }
  },
  chartOfAccount: {
    ChartOfAccount: "Chart Of Account",
    CreateAccount: "Create Account",
    CreateChartOfAccount: "Create Chart Of Account",
    accountType: "Account Type",
    selectType: "Select Type",
    parentAccount: "Parent Account",
    selectParent: "Select Parent",
    EnterAccountType: "Please Enter Account Type",
    clossingBalance: "Closing Balance",
  },
  ledgerReports: {
    reports: "Reports",
    selectAccount: "Select Account",
    selectDate: "Select Date",
    generateReport: "Generate Report",
    VoucherNo: "Voucher No",
  },
  requisitions: {
    requisition: "Requisitions",
    MyRequisitions: "My Requisitions",
    createRequisition: "Create Requisition",
    PleaseEnterRequisitionName: "Please Enter Requisition Name",
    EnterRequisitionName: "Enter Requisition Name",
    Budget: "Budget",
    EnterBudget: "Enter Budget",
    Dateofrequestexpire: "Date of request expire",
    noDataMessage: "– Create Requisitions now!",
  },
  DefaultApprovers: {
    TypeEnum: {
      [DefaultApprovalTypeEnum.Expense]: "Expense",
      [DefaultApprovalTypeEnum.ExpenseFinancers]: "Expense Financers",
      [DefaultApprovalTypeEnum.Travel]: "Travel",
      [DefaultApprovalTypeEnum.TravelAgent]: "Travel Agent",
      [DefaultApprovalTypeEnum.Loan]: "Loan",
      [DefaultApprovalTypeEnum.Leave]: "Leave",
      [DefaultApprovalTypeEnum.Asset]: "Asset",
      [DefaultApprovalTypeEnum.Salary]: "Salary",
      [DefaultApprovalTypeEnum.Payroll]: "Payroll",
      [DefaultApprovalTypeEnum.Reward]: "Reward",
      [DefaultApprovalTypeEnum.ResignationHr]: "Resignation Hr",
      [DefaultApprovalTypeEnum.ResignationAdmin]: "  Resignation Admin",
      [DefaultApprovalTypeEnum.ResignationIt]: "Resignation It",
      [DefaultApprovalTypeEnum.ResignationFinance]: "Resignation Finance",
      [DefaultApprovalTypeEnum.ResignationExit]: "Resignation Exit",
      [DefaultApprovalTypeEnum.Requisition]: "Requisition",
      [DefaultApprovalTypeEnum.CustomApproval]: "Custom Approval",
      [DefaultApprovalTypeEnum.Forms]: "Forms",
      [DefaultApprovalTypeEnum.Appraisals]: "Appraisals",
      [DefaultApprovalTypeEnum.Promotion]: "Promotion",
      [DefaultApprovalTypeEnum.Warning]: "Warning",
      [DefaultApprovalTypeEnum.Bonus]: "Bonus",
      [DefaultApprovalTypeEnum.Complains]: "Complains",
      [DefaultApprovalTypeEnum.Career]: "Career",
      [DefaultApprovalTypeEnum.Quotations]: "Quotations",
      [DefaultApprovalTypeEnum.RequisitionFinal]: "Requisition Final",
      [DefaultApprovalTypeEnum.Attendance]: "Attendance",
      [DefaultApprovalTypeEnum.Auction]: "Auction",
    },
  },
  NonMandatoryApprovers: {
    TypeEnum: {
      [NonMandatoryApprovalTypeEnum.Expense]: "Expense",
      [NonMandatoryApprovalTypeEnum.Travel]: "Travel",
      [NonMandatoryApprovalTypeEnum.Loan]: "Loan",
      [NonMandatoryApprovalTypeEnum.Leave]: "Leave",
      [NonMandatoryApprovalTypeEnum.Asset]: "Asset",
      [NonMandatoryApprovalTypeEnum.Salary]: "Salary",
      [NonMandatoryApprovalTypeEnum.Payroll]: "Payroll",
      [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "Expense Financers",
      [NonMandatoryApprovalTypeEnum.TravelAgent]: "Travel Agent",
      [NonMandatoryApprovalTypeEnum.Reward]: "Reward",
      [NonMandatoryApprovalTypeEnum.ResignationHr]: "Resignation Hr",
      [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "Resignation Admin",
      [NonMandatoryApprovalTypeEnum.ResignationIt]: "Resignation It",
      [NonMandatoryApprovalTypeEnum.ResignationFinance]: "Resignation Finance",
      [NonMandatoryApprovalTypeEnum.ResignationExit]: "Resignation Exit",
      [NonMandatoryApprovalTypeEnum.Requisition]: "Requisition",
      [NonMandatoryApprovalTypeEnum.Warning]: "Warning",
      [NonMandatoryApprovalTypeEnum.Complain]: "Complain",
      [NonMandatoryApprovalTypeEnum.Bonus]: "Bonus",
      [NonMandatoryApprovalTypeEnum.Promotion]: "Promotion",
      [NonMandatoryApprovalTypeEnum.RequestForItem]: "Request For Item",
      [NonMandatoryApprovalTypeEnum.CustomApproval]: "CustomApproval",
      [NonMandatoryApprovalTypeEnum.Form]: "Form",
      [NonMandatoryApprovalTypeEnum.Document]: "Document",
      [NonMandatoryApprovalTypeEnum.Career]: "Career",
      [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "Requisition Final Approval",
      [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "Request For Item Asset Controller",
      [NonMandatoryApprovalTypeEnum.Quotation]: "Quotation",
      [NonMandatoryApprovalTypeEnum.QuotationClient]: "Quotation Client",
      [NonMandatoryApprovalTypeEnum.Appraisal]: "Appraisal",
      [NonMandatoryApprovalTypeEnum.Attendance]: "Attendance",
      [NonMandatoryApprovalTypeEnum.Auction]: "Auction",


    }
  },
  activityLog: {
    DeviceType: "Device Type",
    DeviceToken: "Device Token",
    OSVersion: "OS Version",
    Device: "Device",
    IP: "IP",
    Comment: "Comment",
    DeviceName: "Device Name",
    LoginDate: "Log in Date",
    LoginTime: "Log in Time"
  },
  ErrorMessage: {
    someThingWentWrong: "Something went wrong",
  },
  setting: {
    BasicInformation: "Basic Information",
    ChangePassword: "Change Password",
    Notifications: "Notifications",
    Mobile: "Mobile #",
    About: "About",
    ExistingPassword: "Existing Password",
    EnterExistingPassword: "Enter Existing Password",
    NewPassword: "New Password",
    EnterNewPassword: "Enter New Password",
    ConfirmPassword: "Confirm Password",
    EnterConfirmPassword: "Enter Confirm Password",
    Signature: "Document Signature",
    NoSignature: "Please create signature",
    ErrorSignature: "Error Creating Signature",
    NoSignatureSave: "No signature to save",
    SignatureSaved: "Signature Saved",
    SaveSignature: "Save Signature",
    ClearSignature: "Clear Signature",
    SignatureCreated: "Signature Signature",
    SignatureActive: "Active",
    FailedSignature: "Failed to fetch signatures",
    Accounts: "Accounts",

    BlockNotificationFeatureTypeEnum: {
      [NotificationBlockFeatureTypeEnum.Reward]: "Reward",
      [NotificationBlockFeatureTypeEnum.Feed]: "Feed",
      [NotificationBlockFeatureTypeEnum.Document]: "Document",
      [NotificationBlockFeatureTypeEnum.Project]: "Project",
      [NotificationBlockFeatureTypeEnum.Task]: "Task",
      [NotificationBlockFeatureTypeEnum.WorkBoard]: "Work Board",
      [NotificationBlockFeatureTypeEnum.Schedule]: "Schedule",
      [NotificationBlockFeatureTypeEnum.Group]: "Group",
      [NotificationBlockFeatureTypeEnum.Messenger]: "Messenger",
      [NotificationBlockFeatureTypeEnum.Expense]: "Expense",
      [NotificationBlockFeatureTypeEnum.Auction]: "Auction",
      [NotificationBlockFeatureTypeEnum.Leave]: "Leave",
      [NotificationBlockFeatureTypeEnum.Travel]: "Travel",
      [NotificationBlockFeatureTypeEnum.Warning]: "Warning",
      [NotificationBlockFeatureTypeEnum.Complain]: "Complain",
      [NotificationBlockFeatureTypeEnum.Bonus]: "Bonus",
      [NotificationBlockFeatureTypeEnum.Promotion]: "Promotion",
      [NotificationBlockFeatureTypeEnum.Loan]: "Loan",
      [NotificationBlockFeatureTypeEnum.CustomApproval]: "Custom Approval",
      [NotificationBlockFeatureTypeEnum.Form]: "Form",
      [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "Employee Salary",
      [NotificationBlockFeatureTypeEnum.Payroll]: "Payroll",
      [NotificationBlockFeatureTypeEnum.Career]: "Career",
      [NotificationBlockFeatureTypeEnum.Requisition]: "Requisition",
      [NotificationBlockFeatureTypeEnum.Resignation]: "Resignation",
      [NotificationBlockFeatureTypeEnum.Quotation]: "Quotation",
      [NotificationBlockFeatureTypeEnum.Appraisal]: "Appraisal",
      [NotificationBlockFeatureTypeEnum.LeadManager]: "Lead Manager",
      [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "Discussion Board",
      [NotificationBlockFeatureTypeEnum.Pages]: "Pages",
    }
  },
  emailConfiguration: {
    emailConfiHeader: "User Email Configuration",
    emailConfi: "User Name",
    password: "Password",
    enterEmailConfi: "Enter User Name",
    incomingPort: "Incoming Port",
    enterIncomingPort: "Enter Incoming Port",
    incomingServerAddress: "Incoming Server Address",
    enterIncomingServerAddress: "Enter Incoming Server Address",
    outgoingPort: "Outgoing Port",
    enterOutgoingPort: "Enter Outgoing Port",
    outgoingServerAddress: "Outgoing Server Address",
    enterOutgoingServerAddress: "enter Outgoing Server Address",
    provider: "Provider",
    enterProvider: "Enter Provider",
    saveEmailConfi: "Save Email Configuration",
    addEmailConfi: "Add Email Configuration",
    name: "Name",
    clear: "Clear",
  },
  assetsAllocation: {
    assetsAllocation: "Assets Allocation",
    assests: "Assets",
    createAssetAllocation: "Create Asset Allocation",
    handover: "Handover",
    forMaintenance: "For Maintenance",
    noResultFound: "No Result Found...",
    addAssetsAllocation: "Add Assets Allocation",
    noDataMessage: "– Create Assets Allocation now!",
    allocationForMe: "Allocation For Me",
    allocationApprovals: "Allocation Approvals",
    deAllocation: "Change Allocation Status",
    liability: "Liability",
    capital: "Capital",
    revenue: "Revenue",
    expense: "Expense",
    costOfGoodsale: "Cost of Good Sold",
    assetsCategory: "Assets Category",
    parentAccount: "Parent Account",
    selectParent: "Select Parent",
    assetsList: "Assets List",
    createAssests: 'Create Assests',
    sno: 'S.No',
    inventoryName: 'Inventory Name',
    inventoryValue: 'Inventory Value',
    SelectAssetsController: "Select Assets Controller",
  },
  requestListItems: {
    noDataMessage: "– Create Request now!",
    requestForItems: "Request For Items",
    RequestForItemsApprovals: "Request For Items Approvals",
    AssetController: "Asset Controller",
    RequestItem: "Request Item",

  },
  profile: {
    firstMenu: '  1st menu item',
    secondMenu: ' 2nd menu item (disabled)',
    thirdMenu: ' 3rd menu item (disabled)',
    dangerItem: 'a danger item',
    NoPosition: "No Position",
    NoInstitute: "No Institute",
    NotFound: "Not found",
    University: "University",
    School: "School",
    Graduated: "Graduated",
    CurrentlyWorkHere: "I currently work here",
    Statistics: "Statistics",
    StorageStatistics: "Storage Statistics",
    TotalStorageUsed: "Total storage used",
  },
  /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
  status: {
    InProcess: "InProcess",
    Approved: "Approved",
    Declined: "Declined",
    Resend: "Resend",
    Inactive: "Inactive",
    NotRequired: "Not Required",
    Cancelled: "Cancelled",
    ApprovalRequired: "Approval Required",
    Hold: "Hold",
    WaitingForOtherApprover: "Waiting For Other Approvers",
    Draft: "Draft",
    Remark: "Remark",
    Removed: "Removed"
  },
  approvalDictionaryLabel: {
    Approval: "Approval",
    [ApprovalsModuleEnum.ExpenseExecutor]: "Executor",
    [ApprovalsModuleEnum.ExpenseFinance]: "Finance",
    [ApprovalsModuleEnum.ResignationHrApproval]: "HR",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finance",
    [ApprovalsModuleEnum.ResignationItApproval]: "IT",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "Admin",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "Other Approvals",
    [ApprovalsModuleEnum.ResignationExitApproval]: "Exit Interview",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "Reporting To",
    [ApprovalsModuleEnum.TravelApproval]: "Approvers",
    [ApprovalsModuleEnum.TravelAgent]: "Agent",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "Final Approvers",
    [ApprovalsModuleEnum.LoanApproval]: "Approvers",
    [ApprovalsModuleEnum.AuctionApproval]: "Approver"

  },
  approvalDictionaryPlaceHolder: {
    Approval: "Approval",
    [ApprovalsModuleEnum.ExpenseExecutor]: "Select the Executer of the Expense",
    [ApprovalsModuleEnum.ExpenseFinance]: "Select the Approvers from Finance",
    [ApprovalsModuleEnum.ExpenseApproval]: "Select Expense Approvers",
    [ApprovalsModuleEnum.ResignationHrApproval]: "Select HR",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "Select Finance",
    [ApprovalsModuleEnum.ResignationItApproval]: "Select IT",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "Select Admin",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "Select Other Approvals",
    [ApprovalsModuleEnum.ResignationExitApproval]: "Select Exit Interview",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "Select Reporting To",
    [ApprovalsModuleEnum.TravelApproval]: "Select Approvers for travel",
    [ApprovalsModuleEnum.TravelAgent]: "Select agent for travel arrangements",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "Select Final Approvers",
    [ApprovalsModuleEnum.LoanApproval]: "Select Approvers",
    [ApprovalsModuleEnum.AuctionApproval]: "Select Approvers"

  },

  /*** Define language write side ***/
  Direction: "ltr",
};
export default en;
