import { createSlice, isPending } from "@reduxjs/toolkit";
import { createGuid, STRINGS } from "../../../../utils/base";
import { updateNotificationCount } from "../../../../utils/Shared/store/slice";
import {
  createChat,
  getAllChats,
  sendChatMessage,
  getAllChatMessage,
  getAllEmployeeWithChat,
  searchConversations,
} from "./actions";
import { findKeysAndIndicesById, findKeysById } from "../utils/Functions";
import { MESSENGER_ENUMS } from "../utils/Constant";

const defaultCurrentMessenger = {
  chatId: STRINGS.DEFAULTS.guid,
  profileImage: "",
  name: "",
  chatType: 1,
  members: [],
};
const initialState = {
  mobileIsopenChat: null,
  currentMessenger: defaultCurrentMessenger,
  currentChatBoxes: [],
  MessengerList: {},
  Conversations: [],
  ConversationsWithEmployee: [],
  loader: false,
  success: false,
  contactsConversation: [],
  conversationData: null,
};

export const messengerSlice = createSlice({
  name: "MessengerChat",
  initialState: initialState,
  reducers: {
    receiveChatMessage: (state, { payload }) => {
      let existChatMessages = state.MessengerList[payload.chatId] || [];
      if (!existChatMessages.find((message) => message.id === payload.id)) {
        state.MessengerList[payload.chatId] = [...existChatMessages, payload];
      }
      // state.MessengerList[payload.chatId] = state.MessengerList[payload.chatId]
      //   ? [...state.MessengerList[payload.chatId], payload]
      //   : [payload];
    },
    handleIsopenChat: (state, { payload }) => {
      state.mobileIsopenChat = payload;
    },
    handleMessengerItemClick: (state, { payload }) => {
      state.currentMessenger = payload;
    },
    handleRemoveChatBox: (state, { payload }) => {
      let updatedChatBoxes = state.currentChatBoxes.filter(
        (item) => item.chatId !== payload.chatId
      );
      state.currentChatBoxes = updatedChatBoxes;
    },
    handleMinimizeChatBox: (state, { payload }) => {
      let { index } = payload;
      let currentChatBox = state.currentChatBoxes[index].isMinimize;
      let updatedStatus =
        currentChatBox === 0 ? 1 : currentChatBox === 1 ? 2 : 1;
      state.currentChatBoxes[index].isMinimize = updatedStatus;
      state.currentChatBoxes[index].isExtend = 0;
    },
    handleExpendChatBox: (state, { payload }) => {
      let { index } = payload;
      let currentChatBox = state.currentChatBoxes[index].isExtend;
      let updatedStatus =
        currentChatBox === 0 ? 1 : currentChatBox === 1 ? 2 : 1;
      state.currentChatBoxes[index].isExtend = updatedStatus;
      state.currentChatBoxes[index].isMinimize = 0;
    },
    handleChatBoxAppend: (state, { payload }) => {
      console.log("Payload", payload);
      let updatedChatBoxes = [...state.currentChatBoxes];
      updatedChatBoxes = updatedChatBoxes.filter(
        (item) => item.chatId !== payload.chatId
      );
      updatedChatBoxes = [
        ...updatedChatBoxes,
        {
          ...payload,
          isExtend: 0,
          isMinimize: 0,
        },
      ];
      state.currentChatBoxes = updatedChatBoxes;
    },
    handleAppendMessage: (state, { payload }) => {
      let currentChatMessages = state.MessengerList[payload.chatId]
        ? state.MessengerList[payload.chatId]
        : [];
      // Append Last Message in MessengerList
      state.MessengerList[payload.chatId] = [...currentChatMessages, payload];
    },
    handleMessageFailure: (state, { payload }) => {
      let currentChatMessages = state.MessengerList[
        state.currentMessenger.chatId
      ]
        ? state.MessengerList[state.currentMessenger.chatId]
        : [];
      let messageIndex = currentChatMessages.findIndex(
        (item) => item.id === payload.id
      );
      currentChatMessages[messageIndex] = {
        ...currentChatMessages[messageIndex],
        status: "Error",
      };
      console.log(currentChatMessages, "currentChatMessages");
    },
    handleConversationIndexing: (state, { payload }) => {
      const currentUrl = window.location.href;
      let isMessenger = currentUrl.includes("/messenger");
      const updateConversationData = () => {
        const updatedData = Object.keys(state.conversationData)?.reduce(
          (acc, key) => {
            const originalArray = state.conversationData[key];
            let filteredArray = originalArray.filter(
              payload.chatType === MESSENGER_ENUMS.CHAT_TYPES.INDIVIDUAL_CHAT
                ? (data) => data.chatWithId !== payload.chatWithId
                : (data) => data.id !== payload.id
            );

            filteredArray.length &&
              filteredArray?.[0].chatType === payload.chatType &&
              filteredArray.unshift(payload);

            acc[key] = filteredArray;
            return acc;
          },
          {}
        );
        return updatedData;
      };
      if (isMessenger) {
        // Shuffle Messenger Conversaions
        let updatedConversations =
          payload.chatType === MESSENGER_ENUMS.CHAT_TYPES.INDIVIDUAL_CHAT
            ? state.Conversations.filter((conversation) =>
                conversation.id === STRINGS.DEFAULTS.guid
                  ? conversation.chatWithId !== payload.chatWithId
                  : conversation.id !== payload.id
              )
            : state.Conversations.filter(
                (conversation) => conversation.id !== payload.id
              );
        updatedConversations = [payload, ...updatedConversations];
        state.Conversations = updatedConversations;
      } else state.conversationData = updateConversationData();
    },
    handleStatusUpdate: (state, { payload }) => {
      let chatId = payload.chatId;
      let messageId = payload.id;
      let messageList = state.MessengerList[chatId];
      if (messageList) {
        let messageIndex = messageList.findIndex(
          (message) => message.id === messageId
        );
        state.MessengerList[chatId][messageIndex] = { ...payload };
      }
    },
    handleUserOnlineStatus: (state, { payload }) => {
      let status = payload.status;
      let user = payload.user;

      const updateConversationData = () => {
        const updatedData = { ...state.conversationData };

        Object.keys(updatedData).forEach((key) => {
          const originalArray = updatedData[key];
          const itemIndex = originalArray.findIndex(
            (data) => data.chatWithId === user.id
          );

          if (itemIndex !== -1) {
            updatedData[key] = [
              ...originalArray.slice(0, itemIndex),
              { ...originalArray[itemIndex], chatWith: user },
              ...originalArray.slice(itemIndex + 1),
            ];
          }
        });

        return updatedData;
      };

      return {
        ...state,
        conversationData: updateConversationData(),
      };
    },
    handleUpdateTypingStatus: (state, { payload }) => {
      let chatId = payload.chatId;
      let typingStatus = payload.typingStatus;
      let itemIndex = state.Conversations.findIndex(
        (conversation) => conversation.id === chatId
      );
      if (itemIndex >= 0) {
        state.Conversations[itemIndex].typingStatus = typingStatus;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllChatMessage.fulfilled, (state, { payload }) => {
        // Append chat messages & manage pagination
        if (payload.pageNo > 1) {
          state.MessengerList[payload.chatId] = [
            ...payload.data,
            ...state.MessengerList[payload.chatId],
          ];
        } else state.MessengerList[payload.chatId] = payload.data;

        if (
          (state.conversationData !== null &&
          Object.keys(state.conversationData).length > 0)
        ) {
          let { key, index } = findKeysAndIndicesById(
            state.conversationData,
            payload.chatId
          )?.[0];
          const messageCount =
            state.conversationData?.[key]?.[index].messageCount;

          if (messageCount > 0) {
            if (state.conversationData?.[key]?.[index]) {
              state.conversationData = {
                ...state.conversationData,
                [key]: [
                  ...state.conversationData[key].slice(0, index),
                  {
                    ...state.conversationData[key][index],
                    messageCount: 0,
                  },
                  ...state.conversationData[key].slice(index + 1),
                ],
              };
            }
          }
        }
        // for clear sidebar & messenger message counter
        const messengerChatIndex = state.Conversations?.findIndex(
          (item) => item.id === payload.chatId
        );

        if (
          state.Conversations.length > 0 &&
          state.Conversations[messengerChatIndex].messageCount > 0
        ) {
          state.Conversations[messengerChatIndex].messengerChatIndex = 0;
        }
      })
      .addCase(getAllChats.fulfilled, (state, { payload }) => {
        state.Conversations = payload;
      })
      .addCase(getAllEmployeeWithChat.fulfilled, (state, action) => {
        const pageNo = action?.meta?.arg?.pageNo;
        const isSearch = action?.meta?.arg?.search !== "";
        let payload = action.payload.map((conversation) => ({
          ...conversation,
          customChatId: createGuid(),
        }));
        const currentUrl = window.location.href;
        let isMessenger = currentUrl.includes("/messenger");
        let filterKey = "";

        if (!isMessenger) {
          if (isSearch) {
            filterKey = "search";
          } else {
            filterKey = action?.meta?.arg.chatTypes?.[0];
            // Filter out objects with isDisable: true when filterKey is not "search"
            payload = payload.filter(conversation => !conversation?.chatWith?.isDisable);
          }
          state.conversationData = {
            ...state.conversationData,
            [filterKey]:
              pageNo === 1
                ? payload
                : [...(state.conversationData[filterKey] || []), ...payload],
          };
        } else {
          payload = isSearch ? payload : payload.filter(conversation => !conversation?.chatWith?.isDisable);
          state.Conversations =
            pageNo === 1 ? payload : [...state.Conversations, ...payload];
        }
      })
      .addCase(searchConversations.fulfilled, (state, { payload }) => {
        state.Conversations = payload;
      })
      .addCase(createChat.fulfilled, (state, { payload }) => {
        state.Conversations = state.Conversations.find(
          (itm) => itm.id === payload.id
        )
          ? state.Conversations
          : [...state.Conversations, { ...payload }];
        state.loader = false;
        state.success = true;
      })
      .addCase(createChat.rejected, (state, { payload }) => {
        state.loader = false;
      })
      .addCase(sendChatMessage.fulfilled, (state, { payload }) => {
        const response = payload.response;
        const request = payload.request;

        if (request.chatId === STRINGS.DEFAULTS.guid) {
          // functionlity of setup new chat
          const chatboxIndex = state.currentChatBoxes.findIndex(
            (chat) => chat.customChatId === request.customChatId
          );
          // if chat box exist
          if (chatboxIndex >= 0) {
            state.currentChatBoxes[chatboxIndex] = {
              ...state.currentChatBoxes[chatboxIndex],
              chatId: response.chatId,
            };
          }
          // set current messenger chatId 
          state.currentMessenger = {...state.currentMessenger, ...state.currentChatBoxes[chatboxIndex]};

          // if conversation exist in sidebar
          const chatboxConversationIndex = state.ConversationsWithEmployee.findIndex(
            (chat) => chat.customChatId === request.customChatId
          );
          if (chatboxConversationIndex >= 0) {
            state.ConversationsWithEmployee[chatboxConversationIndex] = {
              ...state.ConversationsWithEmployee[chatboxConversationIndex],
              id: response.chatId,
            };
          }
        }else{
          const response = payload.response;
          const chatboxIndex = state.currentChatBoxes.findIndex(
            (chat) => chat.chatId === response.chatId
          );
          state.currentMessenger = {...state.currentMessenger, ...state.currentChatBoxes[chatboxIndex]};

        }

        let currentChatMessages = state.MessengerList[response.chatId]
          ? [...state.MessengerList[response.chatId]]
          : [];
        let messageIndex = currentChatMessages.findIndex(
          (item) => item.id === response.id
        );
        // ( messageIndex >-1 ? messageIndex : 0 ) means this message chat not created yet, its first time
        currentChatMessages[messageIndex > -1 ? messageIndex : 0] = response;
        state.MessengerList[response.chatId] = currentChatMessages;
      })
      .addMatcher(isPending(...[createChat]), (state) => {
        state.loader = true;
        state.success = false;
      });
  },
});

export const {
  handleIsopenChat,
  handleMessengerItemClick,
  receiveChatMessage,
  handleAppendMessage,
  handleChatBoxAppend,
  handleRemoveChatBox,
  handleMinimizeChatBox,
  handleExpendChatBox,
  handleMessageFailure,
  handleConversationIndexing,
  handleStatusUpdate,
  handleUserOnlineStatus,
  handleUpdateTypingStatus,
} = messengerSlice.actions;
export default messengerSlice.reducer;
