import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { CareerInterviewStatusEnum } from "../../../main/features/careers/utils/CareerInterviewStatusEnum";
import { CareerFilterSortEnum } from "../../../main/features/careers/utils/careerFilterSortEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerLevelEnum } from "../../../main/features/careers/utils/careerLevelTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { JobShiftTypeEnums } from "../../../main/features/careers/utils/jobShiftTypeEnum";
import { JobTypeEnums } from "../../../main/features/careers/utils/jobTypeEnum";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterSortEnum } from "../../../main/features/leave/utils/leaveFilterSortEnum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { LoanFilterSortEnum } from "../../../main/features/loan/utils/loanFilterSortEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterSortEnum } from "../../../main/features/promotion/utils/PromotionFilterSortEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterSortEnum } from "../../../main/features/resignation/utils/ResignationFilterSortEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationPurposeEnum } from "../../../main/features/resignation/utils/resignationPurposeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { TravelFilterSortEnum } from "../../../main/features/travel/utils/travleFilterSortEnum";
import { AccountTypeEnum } from "../../../main/features/trialBalance/utils/AccountTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums";
import { InputFieldTypes } from "../../../main/features/quotation/utils/quotationInputFieldTypes";
import { ProjectFeatureEnums } from "../../Shared/enums/projectFeatureEnum";

const russian = {

    sharedLabels: {
        SelectLanguage: "Select Language",
        English: "English ",
        Turkish: "Turkish",
        Hindi: "Hindi",
        Urdu: "اردو",
        Arabic: "عربي",
        czech: "Czech",
        russian: "Russian",
        french: "French",
        spanish: "Spanish",
        italian: "Italian",
        theme: "Тема",
        awardTo: "Премия",
        inprogress: "В Процессе",
        filter: "Фильтр",
        approvals: "Утверждения",
        approvers: "Утверждающие",
        members: "Участники",
        Approvers: "Утверждающие",
        resignations: "Увольнения",
        Description: "Описание",
        Descriptionisrequired: "Описание обязательно!",
        WriteDescriptionhere: "Напишите здесь описание...",
        enterDescription: "Введите описание",
        pleseEnterDescription: "Пожалуйста, введите описание",
        pleaseEnterGrade: "Пожалуйста, введите оценку",
        manager: "Менеджер",
        requiredMessageManager: "Пожалуйста, выберите менеджера",
        SelectManager: "Выберите менеджера",
        create: "Создать",
        humanresource: "HR",
        finance: "Финансы",
        it: "IT",
        admin: "Админ",
        selectMember: "Выберите участника",
        othersapprovals: "Другие Утверждения",
        exitinterview: "Выходное Интервью",
        selectAperson: "Выберите человека",
        SelectHumanResource: "Выберите HR",
        selectIt: "Выберите IT",
        selectFinance: "Выберите финансы",
        selectAdmin: "Выберите администрацию",
        selectOthersApprovals: "Выберите другие утверждения",
        inProcess: "В Процессе",
        reason: "Причина",
        refrence: "Ссылка",
        Rewards: "Награды",
        name: "Имя",
        add: "Добавить",
        action: "Действие",
        userType: "Тип Пользователя",
        SelectUserType: "Выберите Тип Пользователя",
        requiredMessageUserType: "Пожалуйста, выберите тип пользователя",
        RewardTo: "Наградить",
        WarningTo: "Предупредить",
        Approved: "Утверждено",
        Declined: "Отклонено",
        Resend: "Повторить",
        NotRequired: "Не Обязательно",
        Cancelled: "Отменено",
        InActive: "Неактивно",
        ListView: "Просмотр в виде списка",
        TableView: "Просмотр в виде таблицы",
        MyReward: "Моя Награда",
        ForApprovals: "На Утверждение",
        ForApproval: "На Утверждение",
        RewardToMe: "Награда Мне",
        Search: "Поиск",
        Disable: "Отключить",
        Attachments: "Вложения",
        Update: "Обновить",
        update: "обновить",
        AddEmployee: "Добавить Сотрудника",
        complain: "Жалоба",
        Submit: "Отправить",
        Delete: "Удалить",
        Edit: "Редактировать",
        NotDesignated: "Не Назначено",
        Profile: "Профиль",
        Settings: "Настройки",
        Logout: "Выйти",
        Table: "Таблица",
        List: "Список",
        Grid: "Сетка",
        GridView: "Вид Сетки",
        startyear: "Год Начала",
        endyear: "Год Окончания",
        startMonth: "Начальный Месяц",
        endMonth: "Конечный Месяц",
        EnterStartDate: "Введите Дату Начала",
        SelectStartDate: "Выберите Дату Начала",
        startDate: "Дата Начала",
        endDate: "Дата Окончания",
        businessLogo: "Логотип Компании",
        title: "Название",
        enterTitle: "Введите Название",
        percentage: "Процент",
        PleaseEnterPercentage: "Пожалуйста, введите процент",
        previousCharge: "Предыдущее Заряд",
        min: "Мин",
        max: "Макс",
        country: "Страна",
        requiredCountry: "Страна обязательна",
        SelectCountry: "Выберите Страну",
        latitude: "Широта",
        longitude: "Долгота",
        address: "Адрес",
        EnterAddress: "Введите Адрес",
        requiredMessageAddress: "Пожалуйста, введите адрес",
        branch: "Филиал",
        question: "Вопросы",
        allotLeaves: "Выделенные Отпуска",
        SubjectRequired: "Тема Обязательна",
        WriteSubject: "Напишите Тему",
        category: "Категория",
        amount: "Сумма",
        documents: "Документы",
        enterName: "Введите Имя",
        nameRequired: "Имя обязательно",
        accessTo: "Доступ К",
        selectAccessTo: "Выберите Доступ К",
        collaborators: "Сотрудники",
        selectCollaborators: "Выберите Сотрудников",
        readers: "Читатели",
        selectReaders: "Выберите Читателей",
        createBoard: "Создать Доску",
        createPad: "Создать Пэд",
        createGrid: "Создать Сетку",
        createSlide: "Создать Слайд",
        createMindmap: "Создать Миндмап",
        drive: "Диск",
        myDrive: "Мой Диск",
        allDocuments: "Все Документы",
        subject: "Тема",
        Subjectisrequired: "Тема обязательна",
        pleaseEnterSubject: "Пожалуйста, введите тему",
        EnterSubject: "Введите Тему",
        enterAmount: "Введите Сумму",
        pleaseEnterAmount: "Пожалуйста, введите сумму",
        upload: "Загрузить",
        value: "Значение",
        addCategory: "Добавить Категорию",
        saveCategory: "Сохранить Категорию",
        clear: "Очистить",
        loans: "Займы",
        disburse: "Выплатить",
        referenceNo: "Номер Ссылки",
        status: "Статус",
        createDate: "Дата создания",
        creator: "Создатель",
        netSalary: "Чистая зарплата",
        type: "Тип",
        PleaseSelectType: "Пожалуйста, выберите тип",
        CreatedByMe: "Создано мной",
        Members: "Участники",
        Member: "Участник",
        pleaseEnterCategory: "Пожалуйста, введите категорию",
        Memberisrequired: "Участник обязателен",
        EnterCategoryName: "Введите название категории",
        Clear: "Очистить",
        ReferenceNumber: "Номер справки",
        Grade: "Грейд",
        Status: "Статус",
        CreateDate: "Дата создания",
        Creator: "Создатель",
        SNo: "№",
        Employee: "Сотрудник",
        SelectApprovers: "Выберите утверждающих",
        Approverisrequired: "Утверждающий обязателен",
        Yes: "Да",
        No: "Нет",
        totalAmount: "Общая сумма",
        Total: "Всего",
        Date: "Дата",
        Pleaseselectdate: "Пожалуйста, выберите дату",
        SelectDate: "Выберите дату",
        requiredMessageDate: "Пожалуйста, выберите дату",
        Priority: "Приоритет",
        Pleaseselectpriority: "Пожалуйста, выберите приоритет",
        Pleaseselecttype: "Пожалуйста, выберите тип",
        SelectProject: "Выберите проект",
        SelectGroup: "Выберите группу",
        Project: "Проект",
        Group: "Группа",
        Progress: "Прогресс",
        SuretoRate: "Уверены в оценке?",
        Invalidfiletype: "Недопустимый тип файла",
        Pleaseinputyoursubject: "Пожалуйста, введите тему",
        Pleaseinputyourdescription: "Пожалуйста, введите описание",
        Return: "Вернуться",
        EnterReason: "Введите причину",
        TravelReason: "Причина поездки",
        NoDesignation: "Нет назначения",
        Hi: "Привет",
        Quotation: "Квота",
        Amountisrequired: "Сумма обязательна",
        WriteDescriptionHere: "Напишите описание здесь...",
        Types: "Типы",
        Header: "Заголовок",
        MyTeam: "Моя команда",
        Email: "Эл. почта",
        EnterEmail: "Введите адрес эл. почты",
        EmailIsRequired: "Эл. почта обязательна",
        PhoneNo: "Номер телефона",
        Designation: "Должность",
        ReferenceType: "Тип ссылки",
        referenceId: "ID ссылки",
        Save: "Сохранить",
        save: "сохранить",
        Color: "Цвет",
        Yesterday: "Вчера",
        Overdue: "Просроченный",
        Today: "Сегодня",
        Complete: "Завершено",
        DueDate: "Срок выполнения",
        SelectDueDate: "Выберите срок выполнения",
        Cover: "Обложка",
        Cancel: "Отмена",
        cancel: "Отмена",
        and: "и",
        other: "другие",
        Others: "Другие",
        Option: "Опция",
        Public: "Публичный",
        Private: "Приватный",
        click: "клик",
        With: "С",
        with: "с",
        Comments: "Комментарии",
        Commentsdot: "Комментарии...",
        Comment: "Комментарий",
        Seeless: "Показать меньше",
        Seemore: "Показать больше",
        Share: "Поделиться",
        ShareNow: "Поделиться сейчас",
        SelectEmployee: "Выберите сотрудника",
        SelectCategory: "Выберите категорию",
        PleaseSelectCategory: "Пожалуйста, выберите категорию",
        privacy: "Конфиденциальность",
        tags: "Теги",
        updateDate: "Дата обновления",
        otherApprovals: "Другие утверждения",
        resignationDate: "Дата увольнения",
        purpose: "Цель",
        warnings: "Предупреждения",
        Courses: "Курсы",
        selectType: "Выберите тип",
        daysCount: "Количество дней",
        reportingTo: "Докладывать",
        selectReason: "Выберите причину",
        pleaseSelectReason: "Пожалуйста, выберите причину",
        days: "Дни",
        day: 'день',
        Day: 'День',
        Dates: "Даты",
        selectMembers: "Выберите участников",
        selectGrade: "Выберите грейд",
        newGrade: "Новый грейд",
        currentGrade: "Текущий грейд",
        PleaseSelectMember: "Пожалуйста, выберите участника",
        enterCategory: "Введите категорию",
        deadline: "Крайний срок",
        Department: "Отдел",
        designationmessage: "Пожалуйста, выберите должность",
        selectDesignation: "Выберите должность",
        jobdescription: "Описание должности",
        salaryRange: "Диапазон зарплаты",
        interviewers: "Интервьюеры",
        hiringBuddy: "Партнер по найму",
        enterCity: "Пожалуйста, введите город",
        City: "Город",
        selectCity: "Выберите город",
        requiredCity: "Город обязателен",
        skills: "Навыки",
        experience: "Опыт (лет)",
        Experience: "Опыт",
        jobType: "Тип работы",
        education: "Образование",
        interview: "Интервьюер",
        Rating: "Рейтинг",
        poweredbyWorkw: "Работает на Workwise Ltd - Авторские права 2016-",
        copyLink: "Копировать ссылку",
        EffectiveDate: "Дата вступления в силу",
        experienceRequired: "Требуемый опыт",
        user: "Пользователи",
        postinterview: "Пост-интервью",
        Copied: "Скопировано",
        unreadNotifyItem: "Непрочитанные уведомления",
        createBy: "Создано",
        Create: "Создать",
        Open: "Открыть",
        Closed: "Закрыто",
        Nodescription: "Нет описания",
        writeDescription: "Напишите ваше описание здесь...",
        Employees: "Сотрудники",
        BasicInfo: "Основная информация",
        FatherName: "Отчество",
        EnterFatherName: "Введите отчество",
        PersonalEmail: "Личный Email",
        EnterPersonalEmail: "Введите личный Email",
        personalEmailIsRequired: "Личный Email обязателен",
        PhoneNumber: "Номер телефона",
        EnterPhoneNumber: "Введите номер телефона",
        NationalIdentity: "Национальная идентификация",
        EnterNationalIdentityNumber: "Введите номер национальной идентификации",
        Grades: "Категории",
        SelectGrade: "Выберите категорию",
        requiredMessageGrade: "Пожалуйста, выберите категорию",
        EnterProbationPeriod: "Введите испытательный срок",
        NoticePeriod: "Уведомление о сроке (дни)",
        EnterNoticePeriod: "Введите уведомление о сроке",
        requiredMessageNoticePeriod: "Пожалуйста, введите уведомление о сроке",
        requiredMessageNoticePeriodRestriction: "Срок уведомления должен быть от 1 до 60",
        DateOfBirth: "Дата рождения",
        Gender: "Пол",
        requiredMessageGender: "Пожалуйста, выберите пол",
        SelectGender: "Выберите пол",
        MartialStatus: "Семейное положение",
        SelectMartialStatus: "Выберите семейное положение",
        requiredDepartment: "Отдел обязателен",
        SelectDepartment: "Выберите отдел",
        Subsidiary: "Филиал",
        SelectSubsidiary: "Выберите филиал",
        SubsidiaryOffice: "Офис филиала",
        SelectSubsidiaryOffice: "Выберите офис филиала",
        PayrollGroup: "Группа выплат",
        SelectPayrollGroup: "Выберите группу выплат",
        DefaultApproval: "По умолчанию",
        SelectDefaultApproval: "Выберите по умолчанию",
        SelectAll: "Выбрать все",
        NonMandatoryApprovals: "Необязательные утверждения",
        SelectNonMandatoryApprovals: "Выберите необязательные утверждения",
        FirstName: "Имя",
        EnterFirstName: "Введите имя",
        requiredMessageName: "Пожалуйста, введите имя",
        requiredMessageFName: "Пожалуйста, введите имя",
        requiredMessageLName: "Пожалуйста, введите фамилию",
        LastName: "Фамилия",
        EnterLastName: "Введите фамилию",
        DateOfJoining: "Дата присоединения",
        requiredDateOfBirth: "Дата рождения обязательна",
        requiredMessageJoiningDate: "Пожалуйста, выберите дату присоединения",
        JobShift: "Смена работы",
        requiredMessageJobShift: "Пожалуйста, выберите смену работы",
        SelectJobShift: "Выберите смену работы",
        AccessRole: "Роль доступа",
        requiredMessageAccessRole: "Пожалуйста, выберите хотя бы одну роль доступа",
        SelectAccessRole: "Выберите роль доступа",
        StartEndDate: "Начальная/Конечная дата",
        ErrorInValidation: "Ошибка в валидации",
        EducationInfo: "Информация об образовании",
        NonMandatory: "Необязательно",
        Present: "Присутствующий",
        FailedToFetch: "Не удалось получить",
        Position: "Должность",
        EnterPosition: "Введите должность",
        requiredMessagePosition: "Пожалуйста, введите должность",
        Organization: "Организация",
        EnterOrganization: "Введите организацию",
        requiredMessageOrganziation: "Пожалуйста, введите организацию",
        Number: "Номер",
        EnterNumber: "Введите номер",
        requiredMessageNumber: "Пожалуйста, введите номер",
        Relation: "Отношение",
        Id: "ID",
        SelectRelation: "Выберите отношение",
        requiredMessageRelation: "Отношение обязательно",
        AccountName: "Название счета",
        BranchCode: "Код отделения",
        EnterBranchCode: "Введите код отделения",
        requiredMessageBranchCode: "Код отделения обязателен",
        BankName: "Название банка",
        requiredMessageBankName: "Название банка обязательно",
        EnterBankName: "Введите название банка",
        AccountNumber: "Номер счета",
        EnterAccountNumber: "Введите номер счета",
        requiredAccountNumber: "Номер счета обязателен",
        IBAN: "IBAN",
        EnterIBAN: "Введите IBAN",
        requiredIBAN: "IBAN обязателен",
        SortCode: "Сортировочный код",
        IsDefault: "По умолчанию",
        requiredSortCode: "Сортировочный код обязателен",
        EnterSortCode: "Введите сортировочный код",
        BankInfo: "Информация о банке",
        AccountTitle: "Название счета",
        EnterAccountTitle: "Введите название счета",
        requiredMessageAccount: "Название счета обязательно",
        validAccountTitle: "Пожалуйста, введите действительное название счета",
        AttachmentType: "Тип вложения",
        requiredAttchmentType: "Тип вложения обязателен",
        Attachment: "Вложение",
        requiredAttachment: "Вложение обязательно",
        AddAttachment: "Добавить вложение",
        requiredMessageAssignMember: "Пожалуйста, выберите назначенного члена",
        Remove: "Удалить",
        ContactNo: "Номер телефона",
        EnterContactNo: "Введите номер телефона",
        requiredContactNo: "Номер телефона обязателен",
        Contact: "Контакт",
        pleasEnterDescription: "Пожалуйста, введите описание.",
        Information: "Информация",
        Features: "Характеристики",
        AddMembers: "Добавить участников",
        Summary: "Сводка",
        AcessRole: "Роль доступа",
        defaultApprovers: "По умолчанию утверждающие",
        NonMandatoryApprovers: "Необязательные утверждающие",
        Pleasefillallrequiredfields: "Пожалуйста, заполните все обязательные поля",
        Suretodelete: "Уверены, что хотите удалить?",
        Code: "Код",
        createdDate: "Дата создания",
        Searchmembers: "Поиск участников",
        AddDate: "Добавить дату",
        duesoon: "скоро должны",
        Payroll: "Заработная плата",
        ReferenceNo: "Номер счета",
        Associate: "Сотрудник",
        InternalMembers: "Внутренние участники",
        ExternalMembers: "Внешние участники",
        TotalExpense: "Общие расходы",
        TotalExpenseAmount: "Общая сумма расходов",
        TotalPost: "Всего сообщений",
        TotalMeeting: "Всего встреч",
        Expense: "Расход",
        TotalBudget: "Общий бюджет",
        Balance: "Баланс",
        successfully: "успешно",
        enterValidEmail: 'Пожалуйста, введите действительный адрес электронной почты',
        ViewSummary: "Посмотреть сводку",
        AddAdmin: "Добавить администратора",
        AddExternals: "Добавить внешних участников",
        DefaultApprovers: "Утверждающие по умолчанию",
        Billings: "Выставление счетов",
        spent: "Потрачено",
        SelectUser: "Выберите пользователя",
        Image: "Изображение",
        Contacts: "Контакты",
        noContacts: "Нет контактов",
        AddContact: "Добавить контакт",
        Roles: "Роли",
        Tag: "Метка",
        AccessRoles: "Роли доступа",
        member: "участник",
        uploadCover: "Загрузить обложку",
        Pleaseselect: "Пожалуйста, выберите",
        composeEmail: "Написать письмо",
        send: "Отправить",
        ContactDetail: "Данные контакта",
        Detail: "Подробно",
        UploadImage: "Загрузить изображение",
        WriteName: "Введите имя",
        business: "Бизнес",
        website: "Веб-сайт",
        Unknown: "Неизвестно",
        Interested: "Заинтересован",
        NotInterested: "Не заинтересован",
        meetings: "Встречи",
        noMeetings: "Нет встреч",
        Communications: "Коммуникации",
        Details: "Детали",
        LastUpdate: "Последнее обновление",
        nameRequiredMessage: "Имя обязательно!",
        addressRequiredMessage: "Адрес обязателен!",
        Intrested: "Заинтересован",
        StartDateTime: "Дата и время начала",
        DateTimeisrequired: "Дата и время обязательны",
        Selectdatetime: "Выберите дату и время",
        Board: "Доска",
        Notes: "Заметки",
        Miletap: "Miletap",
        EMail: "Электронная почта",
        Attendance: "Посещаемость",
        Absent: "Отсутствует",
        NoData: "Нет данных",
        Prority: "Приоритет",
        Heigh: "Высокий",
        Medium: "Средний",
        Low: "Низкий",
        error: "Ошибка",
        Select: "Выбрать",
        Interview: "Собеседование",
        Meeting: "Встреча",
        Appointment: "Назначение",
        Pleaseinputyouremail: "Пожалуйста, введите ваш email",
        EnterEmailAddress: "Введите адрес электронной почты",
        AddExternalMember: "Добавить внешнего участника",
        Pleaseenterexternalmembers: "Пожалуйста, введите внешних участников",
        externals: "внешние",
        Decline: "Отклонить",
        Approve: "Утвердить",
        NotAvailable: "Недоступно",
        AddApprovals: "Добавить утверждения",
        Everyone: "Все",
        SelectDownloaders: "Выберите загрузчиков",
        SelectCollabrators: "Выберите сотрудников",
        pleaseSelectAssignMemeber: "Пожалуйста, выберите назначенного члена",
        Availed: "Использовано",
        Allotted: "Выделено",
        Degree: "Степень",
        Time: "Время",
        Mood: "Настроение",
        CheckOut: "Выход",
        CheckIn: "Вход",
        Late: "Поздно",
        State: "Состояние",
        PleaseSelectYesNo: "Пожалуйста, выберите Да/Нет",
        PleaseselectGrade: "Пожалуйста, выберите класс",
        PleaseenterAmount: "Пожалуйста, введите сумму",
        Salary: "Зарплата",
        Task: "Задача",
        Nameisrequired: "Имя обязательно",
        PhoneNumberisrequired: "Номер телефона обязателен",
        Dateisrequired: "Дата обязательна",
        Month: "Месяц",
        Year: "Год",
        FinalApprovers: "Финальные утверждающие",
        link: "Ссылка",
        forFinalApproval: "Для финального утверждения",
        validateBonus: "Невозможно обработать бонус: нет информации о зарплате для сотрудника",
        validateLoan: "Невозможно обработать займ: нет информации о зарплате для сотрудника",
        User: "Пользователь",
        Action: "Действие",
        Created: "Создано",
        Toast: "Всплывающее уведомление",
        selectyourcountry: "Пожалуйста, выберите вашу страну",
        PleaseEnterPhoneNumber: "Пожалуйста, введите номер телефона",
        Pleaseselectyourcity: "Пожалуйста, выберите ваш город",
        SelectCurrency: "Выберите валюту",
        Pleaseselectcurrency: "Пожалуйста, выберите валюту",
        totalGroups: "Всего групп",
        totalProjects: "Всего проектов",
        totalMemory: "Общая память",
        memory: "Память",
        EnterMemory: "Введите память",
        availedMemory: "Использованная память (гб)",
        used: "Использовано",
        usedMemory: "Использованная память (гб)",
        From: "От",
        To: "Кому",
        WelcomeSendEmail: "Добро пожаловать, отправьте письмо",
        SendEmail: "Отправить письмо",
        writeYourRemarksHere: "Напишите свои замечания здесь...",
        NoRemarkFound: "Замечаний не найдено",
        ToastEnable: "Всплывающие уведомления включены",
        EmailEnable: "Уведомления по электронной почте включены",
        FeatureName: "Название функции",
        LoginMessage: "Пожалуйста, сначала введите учетные данные",
        netAmount: "Чистая сумма",
        SelectedDate: "Выбранная дата",
        SelectedUser: "Выбранный пользователь",
        Default: "По умолчанию",
        mylog: "Мой журнал",
        SelectStatus: "Выберите статус",
        Selectuser: "Выберите пользователя",
        PolicyType: "Тип полиса",
        Percent: "Процент",
        uploadProfilePicture: "Загрузить фото профиля",
        inputUsername: "Введите имя пользователя",
        userName: "имя пользователя",
        Dummy: "Фиктивный",
        Lesson: "Урок",
        Duration: "Продолжительность",
        Text: "Текст",
        Enterlink: "Введите ссылку",
        Dashboard: "Панель управления",
        searchSchedule: "Поиск расписания...",
        AddOption: "Добавить вариант",
        ChangeImage: "Изменить изображение",
        DeleteImage: "Удалить изображение",
        Question: "Вопрос",
        Next: "Далее",
        Prev: "Предыдущий",
        Wrong: "Неправильно",
        Correct: "Правильно",
        YourResult: "Ваш результат",
        Createdby: "Создано",
        Lastupdated: "Последнее обновление",
        Assignedto: "Назначено",
        Addlesson: "Добавить урок",
        Addtopic: "Добавить тему",
        minutes: "минут",
        Modules: "Модули",
        Valid: "Действительно",
        Expired: "Истекший",
        AssignedMembers: "Назначенные участники",
        Start: "Начать",
        Previous: "Предыдущий",
        Read: "Читать",
        DeleteBook: "Удалить книгу",
        Assign: "Назначить",
        Explanation: "Объяснение",
        Hey: "Привет",
        requiredMessageEmail: "Пожалуйста, введите адрес электронной почты",
        BusinessName: "Название бизнеса",
        PleaseEnterBusinessName: "Пожалуйста, введите название бизнеса",
        BusinessAddress: "Адрес бизнеса",
        PleaseEnterBusinessAddress: "Пожалуйста, введите адрес бизнеса",
        Offers: "Предложения",
        PleaseEnterOffer: "Пожалуйста, введите предложение",
        CreateOffer: "Создать предложение",
        AppliedDate: "Дата подачи заявки",
        ShortList: "Краткий список",
        Finalised: "Завершено",
        Member: "Участник",
        SelectMember: 'Выбрать участника',
        Approver: "Утверждающий",
        CategoryName: "Название категории",
        serialNo: "Серийный номер",
        Allocated: "Выделено",
        Available: "Доступно",
        lost: "Потеряно",
        damage: "Повреждено",
        itemType: "Тип товара",
        Service: "Сервис",
        consumable: "Расходуемый",
        NonConsumable: "Нерасходуемый",
        Quantity: "Количество",
        accountType: "Тип счета",
        PleaseEnterQuantity: "Пожалуйста, введите количество",
        QuantityMustBePositive: "Количество должно быть положительным",
        MembersIsRequired: "Требуются участники",
        CreateRequest: "Создать запрос",
        IBANNumber: "Номер IBAN",
        About: "О нас",
        AboutMe: "Обо мне",
        TimePeriod: "Временной период",
        Company: "Компания",
        CityTown: "Город/Поселок",
        BankDetails: "Банковские реквизиты",
        Work: "Работа",
        addWorkPlace: "Добавить место работы",
        AddEducation: "Добавить образование",
        basicSalary: "Базовая зарплата",
        feed: 'Лента',
        TakeaNote: "Сделайте заметку",
        awards: 'Награды',
        Appraisal: "Оценка",
        salary: 'Зарплата',
        activityLog: "Журнал активности",
        device: 'Устройство',
        leave: 'Отпуск',
        Ip: "IP-адрес",
        DeviceId: "Идентификатор устройства",
        AppVersion: "Версия приложения",
        Selectfeature: "Выберите функцию",
        EnterYourFeedBackDescription: "Введите описание вашего отзыва",
        HideAll: "Скрыть все",
        ShowAll: "Показать все",
        PrintBy: "Печать по",
        AccCode: "Код счета",
        SelectAccountType: "Выберите тип счета",
        Files: "Файлы",
        Space: "Пространство",
        DisburseVoucher: "Выдача/Ваучер",
        TopUsersbyMostFiles: "Топ пользователей по количеству файлов",
        TopUsersbyStorageSize: "Топ пользователей по объему хранилища",
        Apply: "Применить",
        NumberOfTopUsers: "Количество топ пользователей",
        TopUsers: "Топ пользователей",
        SearchUser: "Поиск пользователя",
        ByStorageSize: "По размеру хранилища",
        ByFileSize: "По размеру файла",
        companyDashboard: "Панель управления компанией",
        signup: "Регистрация не подтверждена",
        billing: "Выставление счетов",
        PendingBills: "Ожидающие счета",
        MonthYear: "Месяц - Год",
        SendBill: "Отправить счет",
        BillingUsers: "Пользователи биллинга",
        Item: "Предмет",
        Rate: "Тариф",
        PaidAmount: "Оплаченная сумма",
        PaymentDate: "Дата оплаты",
        DarkDays: "Темные дни",
        ProcessingFee: "Плата за обработку",
        Currency: "Валюта",
        IsEnable: "Включено",
        Actions: "Действия",
        TakeNote: "Сделайте заметку",
        PleaseSelectUsers: "Пожалуйста, выберите пользователей",
        StickyNotes: "Липкие заметки",
        Copy: "Копировать",
        MarkAllRead: "Отметить все как прочитанное",
        SeeAll: "Посмотреть все",
        Refresh: "Обновить",
        close: "Закрыть",
        Calling: "Вызов",
        LoadMore: "Загрузить еще",
        Quizez: "Викторины",
        content: "Контент",

    },
    /*** navMenuLabel only used in LeftSideBar ***/
    navMenuLabel: {
        Schedules: "Расписания",
        workWiseCompanies: "Компании Workwise",
        promotions: "Продвижения",
        customApprovals: "Пользовательские утверждения",
        feed: "Лента новостей",
        messenger: "Мессенджер",
        chartOfAccount: "План счетов",
        createVoucher: "Создать ваучер",
        voucherList: "Список ваучеров",
        Quotation: "Квотации",
        QuotationClient: "Квотации для клиентов",
        transaction: "Транзакции",
        report: "Отчет",
        mailBox: "Почтовый ящик",
        groups: "Группы",
        projects: "Проекты",
        tasks: "Задачи",
        discussionBoard: "Доска обсуждений",
        pages: "Страницы",
        workBoard: "Рабочие доски",
        leadManager: "Менеджер лидов",
        schedules: "Расписания",
        expense: "Расходы",
        travel: "Путешествия",
        docsArchives: "Документы/Архивы",
        eLearning: "Электронное обучение",
        inventory: "Инвентарь",
        myTeam: "Моя команда",
        orgChart: "Организационная структура",
        employee: "Сотрудники",
        administration: "Администрация",
        appraisals: "Оценка",
        departments: "Отделы",
        leaves: "Отпуска",
        basicInfo: "Основная информация",
        holidays: "Праздники",
        careers: "Карьера",
        companiesSection: "Компании Workwise",
        companies: "Компании",
        menu: "Меню",
        hr: "HR",
        finance: "Финансы",
        inventorySection: "Инвентарь",
        privacy: "Конфиденциальность",
        TC: "Условия пользования",
        more: "больше",
        download: "Загрузки",
        feedBack: "Обратная связь",
        warnings: "Предупреждения",
        complains: "Жалобы",
        bonus: "Бонус",
        resignations: "Увольнения",
        requisition: "Запросы",
        assets: "Выделение активов",
        assetsList: "Список активов",
        createAssets: "Создать активы",
        requestListItems: "Запрос на элементы",
        rewards: "Награды",
        businessPolicy: "Бизнес-политики",
        companiesPolicy: "Политики компаний",
        form: "Формы",
        ledgerReport: "Отчеты о журнале",
        trialBalance: "Испытательный баланс",
        salary: "Зарплата",
        payroll: "Расчет заработной платы",
        jobBoard: "Доска объявлений о вакансиях",
        teams: "Моя команда",
        LD: "Обучение и развитие",
        marketPlace: "Маркетплейс",
        loan: "Займы",
        Courses: "Курсы",
        selectMembers: "Выберите участников",
        auction: "Аукцион",
        Nodescription: "Без описания",
        contract: "Контракт"
    },
    /*** AppHeader only used in Component headerTab ***/
    appHeader: {
        newsFeed: {
            posts: "Посты",
            photos: "Фотографии",
            videos: "Видео",
            polls: "Опросы",
            docs: "Документы",
            tagged: "Отмеченные",
        },
        mailBox: {},
        messenger: {},
        groups: {
            groups: "Группы",
        },
        projects: {
            projects: "Проекты",
            dashboard: "Панель управления",
        },
        Task: {
            Tasks: "Задачи",
            myTask: "Мои задачи",
            assignedByMe: "Назначенные мной",
            createdByMe: "Созданные мной",

            teamTask: "Задачи команды",
            dashboard: "Панель управления",
        },
        leadManager: {
            leadCategory: "Категория лидерства",
        },

        schedules: {
            calendar: "Календарь",
            schedule: "Расписание",
            scheduleInterviews: "Планировать интервью",
        },
        expense: {
            expenses: "Расходы",
            forApprovals: "На утверждение",
            myExpenses: "Мои расходы",
            forExecution: "На исполнение",
            forFinance: "На финансирование",
            reports: "Отчеты",
            dashboard: "Панель управления",
        },
        travel: {
            travels: "Путешествия",
            forApprovals: "На утверждение",
            agentProcess: "Процесс агента",
            travelDetail: "Детали поездки",
        },
        docsArchives: {
            documents: "Документы",
            myDrive: "Мой диск",
            myDocuments: "Мои документы",
            forApprovals: "На утверждение",
        },
        eLearning: {
            dashboard: "Панель управления",
        },
        inventory: {
            approvals: "Утверждения",
            inventory: "Инвентарь",
            category: "Категория",
        },
        customApproval: {
            CreateCustomApproval: "Создать настраиваемое утверждение",
            CustomApproval: "Настраиваемое утверждение",
            ForApproval: "На утверждение",
            MyCustomApprovals: "Мои настраиваемые утверждения",
            AllCustomApproval: "Все настраиваемые утверждения",
        },
        payroll: {
            payroll: "Зарплата",
            payrollApproval: "Утверждение заработной платы",
            salaryApproval: "Утверждение зарплаты",
        },
        // employee: {
        //   employees: "Сотрудники",
        // },
        administration: {
            administration: "Администрирование",
        },
        appraisals: {
            appraisals: "Оценки",
        },
        reward_category: {
            reward_category: "Категория наград",

        },
        departments: {
            departments: "Отделы",
        },
        leaves: {
            myLeaves: "Мои отпуска",
            forApprovals: "На утверждение",
        },
        holidays: {
            holidays: "Праздники",
        },
        warnings: {
            warnings: "Предупреждения",
        },
        resignations: {
            resignations: "Увольнения",
            approvers: "На утверждение",
        },
        complains: {
            complains: "Жалобы",
            approvers: "На утверждение",
            createComplain: "Создать жалобу",
        },

        rewards: {
            rewards: "Награды",
            AllRewards: "Все бонусы",
            CreatedByMe: "Созданные мной",
            ForApprovals: "На утверждение",
            MyRewards: "Мои награды"
        },
        promotions: {
            Promotions: "Продвижения",
        },
        salary: {
            Salary: "Зарплата",
            CreateSalary: "Создать зарплату",
        },
        pages: {
            Pages: "Страницы",
        }
    },
    /*** Main Menu ***/
    globalSearch: {
        All: "Все",
        feed: "Лента",
        Lead_Manager: "Менеджер лидов",
        Travel: "Путешествия",
        Document: "Документ",
        Project: "Проект",
        Workboard: "Рабочая доска",
        Group: "Группа",
        Expense: "Расходы",
        Task: "Задача",
        Employee: "Сотрудник",
        e_learning_course: "Курс e-learning",
        e_learning_videos: "Видео e-learning",
        e_learning_article: "Статья e-learning",
        e_learning_quiz: "Тест e-learning",
        e_learning_book: "Книга e-learning",
        e_learning_tedTalks: "TED Talks e-learning",
        rewards: "Награды",
    },

    auction: {
        auctionType: "Тип аукциона",
        personal: "Личный",
        official: "Официальный",
        offer: "Предложение",
        startingBid: "Начальная ставка",
        buyNowAmount: "Сумма выкупа",
        immediateBuy: "Моментальная покупка",
        minIncreaseBid: "Минимальное увеличение ставки",
        Auction: "Аукцион",
        CreateAuction: "Создать аукцион",
        actionType: "Тип аукциона",
        external: "Внешний",
        auctionDetail: "Детали аукциона",
        currentBid: "Текущая ставка",

        FormLabel: {
            startingBid: "Начальная ставка",
            member: "Участник",
            approvers: "Утверждающие",
            minIncreaseBid: "Минимальное увеличение ставки",
            buyNowAmount: "Сумма выкупа",
            offer: "Предложение",
            immediateBuy: "Моментальная покупка",
        },
        FormPlaceholder: {
            name: "Введите название аукциона",
            startingBid: "Введите начальную ставку",
            minIncreaseBid: "Введите минимальное увеличение ставки",
            selectMember: "Выберите участника",
            selectApprovers: "Выберите утверждающих",
            buyNowAmount: "Введите сумму выкупа",
            offer: "Введите предложение",
        },
        FormRequiredMessage: {
            name: "Пожалуйста, введите название аукциона",
            startingBid: "Пожалуйста, введите начальную ставку",
            minIncreaseBid: "Пожалуйста, введите минимальное увеличение ставки",
            buyNowAmount: "Пожалуйста, введите сумму выкупа",
            offer: "Пожалуйста, введите предложение",
            endDate: "Пожалуйста, введите дату окончания",
        },
        auctionFilterTypeEnumLabel: {
            [auctionFilterTypeEnum.All]: "Все аукционы",
            [auctionFilterTypeEnum.CreatedByMe]: "Мои аукционы",
            [auctionFilterTypeEnum.ForApproval]: "На утверждение",
        },
        statusEnum: {
            [AuctionStatusEnum.InProcess]: "В процессе",
            [AuctionStatusEnum.Approved]: "Утверждено",
            [AuctionStatusEnum.Declined]: "Отклонено",
            [AuctionStatusEnum.Resend]: "Повторная отправка",
            [AuctionStatusEnum.InActive]: "Неактивно",
            [AuctionStatusEnum.NotRequired]: "Не требуется",
            [AuctionStatusEnum.Cancelled]: "Отменено",
            [AuctionStatusEnum.Hold]: "На удержании",
            [AuctionStatusEnum.Sold]: "Продано",
        },
        auctionOfferStatus: {
            [AuctionOfferStatusEnum.BidInProcess]: "Ставка в процессе",
            [AuctionOfferStatusEnum.BidSold]: "Ставка продана",
            [AuctionOfferStatusEnum.OutBid]: "Перебито",
        },
        noDataMessage: "Создать аукцион",
    },
    newsFeed: {
        WriteForMe: "Напишите за меня",
        AddDocuments: "Добавить документы",
        AddPolls: "Добавить опросы",
        WhatsOnYourMind: "Что у вас на уме",
        Direction: "ltr",
        Generate: "Создать",
        Change: "Изменить",
        Whatwouldyouliketotalkabout: "О чем бы вы хотели поговорить?",
        Post: "Пост",
        Poll: "Опрос",
        Documents: "Документы",
        PhotoVideo: "Фото/Видео",
        Addpolloption: "Добавить вариант опроса",
        Removepoll: "Удалить опрос",
        WriteName: "Напишите имя",
        AddImageVideo: "Добавить изображение/видео",
        CheckIn: "Отметиться",
        ShareonFeed: "Поделиться в ленте",
        ShareinChat: "Поделиться в чате",
        NoPosts: "Нет постов...",
        Nomoreposts: "Больше нет постов...",
        noDataMessage: "Создайте пост сейчас!",
        Saysomethingaboutthis: "Скажите что-нибудь об этом...",
        specificTimeline: " хронология",
        SharedestinationNames: {
        [SharePostDestinations.FEED]: "Лента",
        [SharePostDestinations.MEMBERS_PROFILE]: "Профиль участника",
        [SharePostDestinations.GROUP]: "Группа",
        [SharePostDestinations.PROJECT]: "Проект",
        [SharePostDestinations.TIMELINE]: "Timeline",
        },
        SharePostSuggestionPlaceholder: {
        [SuggestionTypeEnum.Employee]: "Выберите участника",
        [SharePostDestinations.GROUP]: "Выберите группу",
        [SharePostDestinations.PROJECT]: "Выберите проект",
        },
        CreateFeedNow: "Создайте ленту сейчас!",

        QuickOptions: {
            CreateSlide: "Создать слайд",
            CreateBoard: "Создать доску",
            CreateGrid: "Создать сетку",
            CreatePad: "Создать планшет",
            CreateTask: "Создать задачу",
            CreateExpense: "Создать расход",
            AddUser: "Добавить пользователя",
        },
        reactionDescription: {
            [ReactionType.Like]: "Нравится",
            [ReactionType.Love]: "Любовь",
            [ReactionType.NoReaction]: "Нравится",
            [ReactionType.Support]: "Ценю",
            [ReactionType.Celebrate]: "Праздную",
            [ReactionType.Curious]: "Интересно",
        },
        PostTypePlaceHolder: {
            [PostType.DEFAULT]: "Что у вас на уме",
            [PostType.POLL]: "Спросите что-нибудь...",
        },
        Titlecantbeempty: "Заголовок не может быть пустым",
        Attachmentscantbeempty: "Вложения не могут быть пустыми",
        Optioncantbeempty: "Вариант не может быть пустым",
        SuccessfullySharedonFeed: "Успешно поделено в ленте",
        SuccessfullySharedonMembersProfile: "Успешно поделились в профиле участника",
        SuccessfullyPostSharedMessage:{
        [PostReferenceType.MAIN_FEED]: "Успешно поделились в основной ленте",
        [PostReferenceType.GROUP]: "Успешно поделились в группе",
        [PostReferenceType.PROJECTS]: "Успешно поделились в проекте",
        [PostReferenceType.TIMELINE]: "Успешно поделились в хронологии",
        },
    },
    mail: {
        composer: "Написать",
        menuItem: {
            inbox: "Входящие",
            starred: "Помеченные",
            snoozed: "Отложенные",
            sent: "Отправленные",
            spam: "Спам",
            archive: "Архив",
            trash: "Корзина",
            draft: "Черновики",
            allMail: "Все письма",
            createNewLabel: "Создать новую метку",
        },
        close: "Закрыть",
        send: "Отправить",
        newMessage: "Новое сообщение",
        newEmail: "Написать новое письмо",
        ComposeEmail: "Написать письмо",
    },
    messenger: {
        Messenger: "Мессенджер",
        Typing: "Печатает...",
        TypeaMessage: "Напишите сообщение...",
        Recording: "Запись...",
        nameYourGroup: "Назовите вашу группу",
        Create: "Создать",
        GroupNameRequired: "Название группы обязательно",
        searchMemberToAdd: "Поиск участника для добавления",
        contacts: "Контакты",
        selected: "Выбрано",
        whatwouldyoutalkingabout: "О чем бы вы хотели поговорить?",
        SayHi: "Поздоровайтесь с вашими контактами",
    },
    marketPlace: {
        jobBoardName: "Доска вакансий",
        requsitionName: "Заявка",
    },
    discussionBoard: {
        DiscussionBoard: "Доска обсуждений",
        Topic: "Тема",
        pleaseEnterTopicName: "Пожалуйста, введите название темы",
        enterTopicName: "Введите название темы",
        addDiscussionBoard: "Добавить доску обсуждений",
        errormessage: "Произошла ошибка при загрузке доски обсуждений.",
        noDataMessage: "– Создайте обсуждение сейчас!",
    },
    pages: {
        messageName: "Пожалуйста, введите имя",
        tags: "Теги",
        messageTags: "Пожалуйста, введите теги",
        enterTag: "Введите теги",
        createPage: "Создать страницу",
        noDataMessage: "– Создайте страницу сейчас!",
    },
    group: {
        group: "Группы",
        createGroup: "Создать группу",
        Updategroup: "Обновить группу",
        enterGroupName: "Пожалуйста, введите название группы.",
        Code: "Код",
        exampleText: "Например, G (для названия группы)",
        GroupTag: "Тег группы",
        AddAdmin: "Добавить администратора",
        TotalBudget: "Общий бюджет",
        Spent: "Потрачено",
        Balance: "Баланс",
        EnterGroupTag: "Введите тег группы",
        SaveGroupTag: "Сохранить тег группы",
        AddGroupTag: "Добавить тег группы",
        EditGroup: "Редактировать группу",
        CreateGroupNow: "Создайте группу сейчас!"
    },
    project: {
        WIP: "В процессе",
        privateProject: "Частный проект",
        Projects: "Проекты",
        UpdateProject: "Обновить проект",
        CreateProject: "Создать проект",
        noDataMessage: "– Создайте проект сейчас!",
        ProjectTag: "Тег проекта",
        ProjectRole: "Роль в проекте",
        exampleProjectName: "Например, P (для названия проекта)",
        projectDate: "Дата проекта",
        EnterProjectTag: "Введите тег проекта",
        SaveProjectTag: "Сохранить тег проекта",
        AddProjectTag: "Добавить тег проекта",
        editProject: "Редактировать проект",
        PersonalNotes: "Личные заметки",
        EnterProjectRole: "Введите роль в проекте",
        SaveCustomTag: "Сохранить настраиваемый тег",
        AddProjectRole: "Добавить роль в проекте",
        TakeaNote: "Сделайте заметку",
        PublicProject: "Публичный проект",
        ProjectFeatureTabs:{
            [ProjectFeatureEnums.Feed]: "Лента",
            [ProjectFeatureEnums.Schedule]: "Расписание",
            [ProjectFeatureEnums.WorkBoard]: "Доска работы",
            [ProjectFeatureEnums.Document]: "Документы",
            [ProjectFeatureEnums.Task]: "Задачи",
            [ProjectFeatureEnums.Expense]: "Расходы",
            [ProjectFeatureEnums.Travel]: "Путешествия",
            [ProjectFeatureEnums.Quotation]: "Оценка",
            [ProjectFeatureEnums.Budget]: "Бюджет",
            [ProjectFeatureEnums.Setting]: "Настройки",
            [ProjectFeatureEnums.DiscussionBoard]: "Доска обсуждений",
            [ProjectFeatureEnums.Pages]: "Страницы",
            [ProjectFeatureEnums.Issues]: "Проблемы",
            [ProjectFeatureEnums.Contract]: "Контракты",
          }
    },
    tasks: {
        Tasks: "Задачи",
        Predecessor: "Предшествующая",
        AssignTo: "Назначить",
        TaskDate: "Дата задачи",
        CreateTask: "Создать задачу",
        TaskAssignTo: "Назначить задачу",
        Rating: "Рейтинг",
        TaskDetail: "Детали задачи",
        ProgressofAssignedmembers: "Прогресс назначенных участников",
        SubTasks: "Подзадачи",
        SubGoals: "Подцели",
        Subtask: "Подзадача",
        CreateSubtask: "Создать подзадачу",
        Addsubtask: "Добавить подзадачу",
        AddGoal: "Добавить цель",
        ParentTask: "Родительская задача",
        DueToday: "Срок сегодня",
        DueTomorrow: "Срок завтра",
        Overdue: "Просроченные",
        Duein: "Срок через",
        days: "дней",
        TaskCreatedSuccessfully: "Задача успешно создана",
        noDataMessage: "– Создайте задачу сейчас!",

        selfTaskEnum: {
            [SelfTaskEnum.SelfTask]: "Личная задача",
            [SelfTaskEnum.AssignTask]: "Назначить на",
        },
        Priority: "Приоритет",
        taskFilterTypeEnum: {
            [TaskFilterTypeEnum.CreatedByMe]: "Назначенные мной",
            [TaskFilterTypeEnum.MyTasks]: "Мои задачи",
            [TaskFilterTypeEnum.MyTeamTasks]: "Задачи команды",
        },
        statusEnum: {
            [TaskStatusEnum.NotStarted]: "Не начата",
            [TaskStatusEnum.InProcess]: "В процессе",
            [TaskStatusEnum.Completed]: "Завершена",
            [TaskStatusEnum.RatingAssign]: "Оценка задачи",
            [TaskStatusEnum.Cancel]: "Отменена",
            [TaskStatusEnum.Hold]: "Приостановлена",
        },
        taskReferenceTypeEnum: {
            [TaskReferenceTypeEnum.General]: "Общее",
            [TaskReferenceTypeEnum.Project]: "Проект",
            [TaskReferenceTypeEnum.Group]: "Группа",
            [TaskReferenceTypeEnum.Lead]: "Лид",
        },
        taskPriorityEnum: {
            [TaskPriorityEnum.Default]: "По умолчанию",
            [TaskPriorityEnum.Low]: "Низкий",
            [TaskPriorityEnum.Medium]: "Средний",
            [TaskPriorityEnum.High]: "Высокий",
        },
        SortEnum: {
            [TaskFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [TaskFilterSortEnum.CreateDateAsc]: "Дата создания - по возрастанию",
            [TaskFilterSortEnum.StartDateDesc]: "Дата начала - по убыванию",
            [TaskFilterSortEnum.StartDateAsc]: "Дата начала - по возрастанию",
            [TaskFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [TaskFilterSortEnum.UpdateDateAsc]: "Дата обновления - по возрастанию"
        }

    },
    contracts: {
        Contract: "Контракт",
        createContract: "Создать контракт",
        contractDate: "Дата контракта",
        contractPurpose: "Цель контракта",
        selectContractPurpose: "Выберите цель контракта",
        purposeofContractisRequired: "Цель контракта обязательна",
        category: "Категория",
        purpose: "Цель",
        signatureMember: "Подписанты",
        contractDetail: "Детали контракта",
        selectCategory: "Выберите категорию",
        cateogryofContractisRequired: "Категория обязательна",
        externalMember: "Внешние подписанты",
        selectinternalMembers: "Выберите внутренних подписантов",
        internalMembers: "Внутренние подписанты",
        selectExternalMember: "Выберите внешних подписантов",
        contractType: "Тип контракта",
        contractTypeRequired: "Тип контракта обязателен",
        internal: "Внутренний",
        external: "Внешний",
        noDataMessage: "Создайте контракт сейчас!",
        contractsFilterTypeLables: {
            [ContractFilterTypeEnum.Contracts]: "Все бизнес-контракты",
            [ContractFilterTypeEnum.BusinessContract]: "Мои контракты"
        },
        statusEnum: {
            [ContractStatusEnum.InProcess]: "В процессе",
            [ContractStatusEnum.Completed]: "Завершено",
            [ContractStatusEnum.Cancelled]: "Отменено"
        },
        contractReferenceTypeEnum: {
            [ContractReferenceTypeEnum.General]: "Общий",
            [ContractReferenceTypeEnum.Project]: "Проект",
            [ContractReferenceTypeEnum.Group]: "Группа",
            [ContractReferenceTypeEnum.Lead]: "Лид"
        }
    },
    workBoard: {
        WorkBoardDashBoard: "Рабочая доска",
        pleaseEnterYourBoardName: "Пожалуйста, введите название вашей доски",
        pleaseEnterYourBoardDescription: "Пожалуйста, введите описание вашей доски",
        EnterMembers: "Введите участников",
        UpdateBoard: "Обновить доску",
        CreateBoard: "Создать доску",
        privacy: "Конфиденциальность",
        AddSection: "Добавить раздел",
        AddTodo: "Добавить задачу",
        Save: "Сохранить",
        EnterSectionTitle: "Введите название раздела",
        InSection: "В разделе",
        Label: "Метка",
        Activity: "Активность",
        Labels: "Метки",
        UploadCoverPhoto: "Загрузить обложку",
        Section: "Раздел",
        Board: " Доска",
        Todos: "Задачи",
        CompleteDate: "Дата завершения",
        DetailedDesc: "Добавить более подробное описание...",
        EnterTodo: "Введите текст для этой карточки...",
        CreateWorkBoardNow: "- Создать рабочую доску сейчас!",
        workBoardExample: "Например, РД (для доски с названием Рабочая доска)",
    },
    leadManager: {
        LeadManager: "Менеджер лидов",
        AssignedLeads: "Назначенные лиды",
        AllLeads: "Все лиды",
        Groups: "Группы",
        email: "Эл. почта",
        Lead: "Лид",
        Leads: "Лиды",
        Pleaseinputyourboardname: "Пожалуйста, введите название вашей доски!",
        exampleLeadManagerMessage: "Например, МЛ (для группы с названием Менеджер лидов)",
        inputBoardDescription: "Пожалуйста, введите описание вашей доски!",
        UpdateLeadManager: "Обновить менеджер лидов",
        CreateLeadManager: "Создать менеджер лидов",
        CreateLeadGroup: "Создать группу лидов",
        noDataMessage: "– Создайте менеджер лидов сейчас!",
        LastUpdated: "Последнее обновление",
        LeadValue: "Ценность лидов",
        individual: "Индивидуальный",
        leadEmailAddress: "Адрес электронной почты лидов",
        leadPhoneNumber: "Номер телефона лидов",
        leadAddress: "Адрес лидов",
        leadUrl: "URL лидов",
        ConvertProject: "Преобразовать в проект",
        goToProject: "Перейти к проекту",
        SelectAssignMembers: "Выберите назначенных участников",
        LeadGroup: "Группа лидов",
        leadName: "Имя лидов",
        leadMembers: "Участники лидов",
        leadWebiste: "Веб-сайт лидов",
        LeadValuenegative: "Ценность лидов не может быть отрицательной!",
        AddLead: "Добавить лид",
        SelectCommunicationTo: "Выберите коммуникацию для",
        CommunicationTypeisrequired: "Тип коммуникации обязателен!",
        PleaseselectCommunicationType: "Пожалуйста, выберите тип коммуникации",
        CommunicationType: "Тип коммуникации",
        AddCommunication: "Добавить коммуникацию",
        CommunicationTo: "Кому",
        NoCalls: "Нет звонков",
        LeadsValue: "Ценность лидов",
        metricTypeEnum: {
            [LeadManagerMetricTypeEnum.allGroup]: "Всего групп",
            [LeadManagerMetricTypeEnum.allAssign]: "Назначенные лиды",
            [LeadManagerMetricTypeEnum.leadToProject]: "Преобразовано в проект",
            [LeadManagerMetricTypeEnum.leadSection]: "Максимальный раздел лидов",
        },
    },
    schedule: {
        StartMeeting: "Начать собрание",
        MeetinginProgress: "Собрание в процессе",
        EndMeeting: "Завершить собрание",
        MeetingEnded: "Собрание завершено",
        NewTokenAdded: "Добавлен новый токен",
        Copied: "Скопировано",
        SuccessfullyBlocked: "Успешно заблокировано",
        SuccessfullyUnBlocked: "Успешно разблокировано",
        UpdateCalendarLink: "Обновить ссылку календаря",
        AddCalendarLink: "Добавить ссылку календаря",
        GetCalendarLink: "Получить ссылку календаря",
        CopyLink: "Копировать ссылку",
        Block: "Заблокировать",
        UnBlock: "Разблокировать",
        CreateSchedule: "Создать расписание",
        Draft: "Черновик",
        Finalize: "Завершить",
        Complete: "Завершено",
        Preview: "Предварительный просмотр",
        MinutesOftheMeeting: "Протокол собрания",
        MeetingId: "Идентификатор собрания",
        Venue: "Место проведения",
        Workwisecall: "Звонок через Workwise",
        ConferenceCall: "Конференц-звонок",
        Attendees: "Участники",
        Absentees: "Отсутствующие",
        PointsofDiscussion: "Темы обсуждения",
        AddPoint: "Добавить пункт",
        Print: "Печать",
        SaveToPDF: "Сохранить в PDF",
        MEETINGMINUTES: "ПРОТОКОЛ СОБРАНИЯ",
        Yes: "Да",
        No: "Нет",
        WorkwiseCall: "Звонок через Workwise",
        MeetingObjectives: "Цели собрания",
        Points: "Пункты",
        RefNo: "Номер справки",
        AttendanceatMeeting: "Присутствие на собрании",
        Loadingschedules: "Загрузка расписаний...",
        Nodata: "Нет данных...",
        Projects: "Проекты",
        Groups: "Группы",
        titleMessage: "Вы уверены? Изменение типа удалит назначенных участников.",
        pleaseSelectAssignMemeber: "Пожалуйста, выберите назначенного участника!",
        SelectAssignto: "Выберите назначение",
        GetLink: "Получить ссылку",
        Anyone: "Любой",
        anyoneText: "ссылка может создать назначение.",
        TodayEvents: "События сегодня",
        UpcomingEvents: "Предстоящие события",
        InterviewRequests: "Запросы на собеседование",
        sendviachat: "отправить через чат",
        OnVideoConference: "На видеоконференции:",
        OnWorkwiseCall: "На звонке через Workwise",
        Enterlocation: "Введите местоположение",
        Duration: "Длительность",
        inminutes: "в минутах",
        Durationisrequired: "Длительность обязательна",
        Rooms: "Комнаты",
        SelectRoom: "Выбрать комнату",
        CheckRoomAvailability: "Проверить доступность комнаты",
        Available: "Доступно",
        NotAvailable: "Недоступно",
        TravelTime: "Время в пути",
        PreparationTime: "Время подготовки",
        SendviaEmail: "Отправить по электронной почте",
        schedulerCalender: "календарь планировщика",
        Next: "Следующий",
        Prev: "Предыдущий",
        titlemyCustomButton: "заголовок myCustomButton",
        prevnexttoday: "предыдущий следующий сегодня",
        timeGridDaytimeGridWeekdayGridMonth: "timeGridDay timeGridWeek dayGridMonth",
        timeGridDay: "timeGridDay",
        top: "верх",
        block: "блок",
        minutes: "минуты",
        AllDay: "Весь день",
        timeGridWeek: "timeGridWeek",
        slotDuration: "00:15:00",
        numeric: "цифровой",
        dayGridMonth: "dayGridMonth",
        dayGridWeek: "dayGridWeek",
        timeGrid: "timeGrid",
        Day: "День",
        Week: "Неделя",
        Month: "Месяц",
        NoEventsonthisday: "Нет событий в этот день.",
        CreateInterviewSchedule: "Создать расписание интервью",
        Interviewwith: "Интервью с",
        MySchedules: "Мои расписания",
        TeamSchedules: "Расписания команды",
        Past: "Прошлое",
        Today: "Сегодня",
        Upcoming: "Предстоящий",
        ScheduleDetail: "Подробности расписания",
        JoinWorkwiseCall: "Присоединиться к звонку через Workwise",
        AddWorkwiseCall: "Добавить звонок через Workwise",
        Host: "(Хост)",
        MakeHost: "Сделать хостом",
        Attending: "Участвующий",
        UpdateSchedule: "Обновить расписание",
        Calendar: "Календарь",
        Schedule: "Расписание",
        ShareCalendarLink: "Поделиться ссылкой на календарь",
        pointer: "указатель",
        default: "по умолчанию",
        blue: "синий",
        red: "красный",
        purple: "пурпурный",
        Self: "Сам",
        AssignTo: "Назначить",
        assign: "назначить",
        min: "мин",
        Minutes: "15 минут",
        MeetingTranscript: "Транскрипт собрания",
    },
    expenses: {
        CreateExpense: "Создать расход",
        IsReimbursable: "Подлежит возмещению",
        WriteHeaderHere: "Напишите заголовок здесь...",
        ExpenseDetail: "Детали расхода",
        ExpenseDate: "Дата расхода",
        Executors: "Исполнители",
        Financers: "Финансисты",
        ExecutorRequired: "Требуется исполнитель",
        selectExecutor: "Выберите исполнителя расхода",
        noDataMessage: "Усилите свой финансовый путь - Создайте расход сейчас!",
        expenseFilterTypeEnum: {
            [ExpenseFilterTypeEnum.MyExpense]: "Мои расходы",
            [ExpenseFilterTypeEnum.ForApproval]: "На утверждение",
            [ExpenseFilterTypeEnum.ForExecutions]: "На исполнение",
            [ExpenseFilterTypeEnum.ForFinance]: "На финансирование",
        },
        expenseType: {
            [ExpenseReferenceTypeEnum.General]: "Общий",
            [ExpenseReferenceTypeEnum.Group]: "Группа",
            [ExpenseReferenceTypeEnum.Project]: "Проект",
            [ExpenseReferenceTypeEnum.Travel]: "Путешествие",
            [ExpenseReferenceTypeEnum.Assets]: "Активы",
        },
        statusEnum: {
            [ExpenseApprovalStatusEnum.InProcess]: "В процессе",
            [ExpenseApprovalStatusEnum.Approved]: "Утверждено",
            [ExpenseApprovalStatusEnum.Declined]: "Отклонено",
            [ExpenseApprovalStatusEnum.Cancelled]: "Отменено",
            [ExpenseApprovalStatusEnum.Completed]: "Завершено",
            [ExpenseApprovalStatusEnum.Hold]: "Приостановлено",
            [ExpenseApprovalStatusEnum.Disbursed]: "Выплачено",
            [ExpenseApprovalStatusEnum.WaitingForDisburse]: "Ожидает выплаты",
        },

        SortEnum: {
            [ExpenseFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [ExpenseFilterSortEnum.CreateDateAsc]: "Дата создания",
            [ExpenseFilterSortEnum.ExpenseDateDesc]: "Дата расхода - по убыванию",
            [ExpenseFilterSortEnum.ExpenseDate]: "Дата расхода",
            [ExpenseFilterSortEnum.ReferenceNo]: "Номер ссылки",
            [ExpenseFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [ExpenseFilterSortEnum.Amount]: "Сумма",
            [ExpenseFilterSortEnum.AmountDesc]: "Сумма - по убыванию"
        },
        Category: {
            [ExpenseCategoryEnum.Transport]: "Транспорт",
            [ExpenseCategoryEnum.Health]: "Здоровье",
            [ExpenseCategoryEnum.Food]: "Еда",
            [ExpenseCategoryEnum.Shopping]: "Шоппинг",
            [ExpenseCategoryEnum.Entertainment]: "Развлечения",
            [ExpenseCategoryEnum.Travel]: "Путешествие",
            [ExpenseCategoryEnum.Bill]: "Счет",
            [ExpenseCategoryEnum.Office]: "Офис",
            [ExpenseCategoryEnum.Fuel]: "Топливо",
            [ExpenseCategoryEnum.Asset]: "Актив",
            [ExpenseCategoryEnum.Other]: "Другое"
        }
    },
    travel: {
        TravelDetail: "Детали путешествия",
        CreateTravel: "Создать путешествие",
        PleaseAddTravelDetail: "Пожалуйста, добавьте детали путешествия",
        TravelToandFrom: "Путешествие туда и обратно",
        ReturnDateIsRequired: "Требуется дата возврата",
        PleaseSelectTravelType: "Пожалуйста, выберите тип путешествия",
        HotelTADACar: "Отель, TADA и автомобиль",
        HotelRequired: "Требуется отель",
        RentaCar: "Арендовать автомобиль",
        PickUpRequired: "Требуется подвоз",
        DropOffRequired: "Требуется высадка",
        TADAApplicable: "Применимо TADA",
        TravelBy: "Путешествовать на",
        SpecialRequest: "Особая просьба",
        SearchDestinationCity: "Искать город назначения",
        SearchDepartureCity: "Искать город отправления",
        PleaseSelectDepartureCity: "Пожалуйста, выберите город отправления.",
        PleaseSelectDestinationCity: "Пожалуйста, выберите город назначения.",
        DepartureTimeIsRequired: "Требуется время отправления",
        RetunTimeIsRequired: "Требуется время возврата",
        AttachmentType: "Тип вложения",
        WriteSpecialRequestDetail: "Напишите детали особой просьбы",
        Agents: "Агенты",
        Departure: "Отправление",
        Arrival: "Прибытие",
        DepartureDate: "Дата отправления",
        DepartureTime: "Время отправления",
        DepartureCity: "Город отправления",
        ShiftType: "Тип смены",
        DepartureShift: "Смена отправления",
        ArrivalCity: "Город прибытия",
        CreateTravelnow: "Создать путешествие сейчас",
        UpdateTravel: "Обновить путешествие",
        TravelAttachmentTypeOptions: {
            [TravelAttachmentTypeEnum.Passport]: "Паспорт",
            [TravelAttachmentTypeEnum.Ticket]: "Билет",
            [TravelAttachmentTypeEnum.Insurance]: "Страховка",
            [TravelAttachmentTypeEnum.CarRental]: "Аренда автомобиля",
            [TravelAttachmentTypeEnum.Other]: "Другое",
        },

        Shift: {
            [ShiftEnum.Morning]: "Утро",
            [ShiftEnum.Afternoon]: "День",
            [ShiftEnum.Evening]: "Вечер",
            [ShiftEnum.Night]: "Ночь",
        },

        travelBy: {
            plane: "Самолетом",
            bus: "Автобусом",
            ship: "Кораблём",
            train: "Поездом",
            car: "Автомобилем",
        },

        TravelOptions: {
            [TravelOptionsEnums.ByPlane]: "Самолетом",
            [TravelOptionsEnums.ByShip]: "Кораблём",
            [TravelOptionsEnums.ByBus]: "Автобусом",
            [TravelOptionsEnums.ByTrain]: "Поездом",
            [TravelOptionsEnums.ByCar]: "Автомобилем",
        },

        travelFilterTypeEnum: {
            [TravelFilterTypeEnum.Travels]: "Путешествия",
            [TravelFilterTypeEnum.MyTravel]: "Мои путешествия",
            [TravelFilterTypeEnum.ForApproval]: "На утверждение",
            [TravelFilterTypeEnum.AgentProcess]: "Процесс агента",
        },

        statusEnum: {
            [TravelStatusEnum.InProcess]: "В процессе",
            [TravelStatusEnum.Approved]: "Утверждено",
            [TravelStatusEnum.Declined]: "Отклонено",
            [TravelStatusEnum.Cancelled]: "Отменено",
            [TravelStatusEnum.ApprovedByApprovers]: "Утверждено утверждающими",
            [TravelStatusEnum.ApprovedByAgents]: "Утверждено агентами",
            [TravelStatusEnum.DeclineByApproves]: "Отклонено утверждающими",
            [TravelStatusEnum.DeclineByAgents]: "Отклонено агентами",
            [TravelStatusEnum.Hold]: "Приостановлено",
        },
        SortEnum: {
            [TravelFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [TravelFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [TravelFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [TravelFilterSortEnum.ReferenceNoAsc]: "Номер ссылки",
            [TravelFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [TravelFilterSortEnum.CreateDateAsc]: "Дата создания",
            [TravelFilterSortEnum.TravelEndDateDesc]: "Дата окончания путешествия",
            [TravelFilterSortEnum.TravelStartDate]: "Дата начала путешествия"
        }
    },
    eLearning: {
        Course: "Курс",
        eBook: "Электронная книга",
        Quiz: "Викторина",
        TedTalk: "Тед-Ток",
        Article: "Статья",
        Video: "Видео",
        BasicCourseDetail: "Основные детали курса",
        SelectSubject: "Выберите предмет",
        SelectCourse: "Выберите курс",
        PleaseSelectOption: "Пожалуйста, выберите опцию",
        Selectcoursetype: "Выберите тип курса",
        CourseName: "Название курса",
        Entercoursename: "Введите название курса",
        Level: "Уровень",
        PleaseSelectLevel: "Пожалуйста, выберите уровень",
        Selectlevel: "Выберите уровень",
        CourseAdminMembers: "Администраторы курса",
        CourseAdminisrequired: "Требуется администратор курса",
        AssignMembers: "Назначить участников",
        UpdateEBook: "Обновить электронную книгу",
        CreateEBook: "Создать электронную книгу",
        AuthorName: "Имя автора",
        EnterInformation: "Введите информацию",
        UploadBook: "Загрузить книгу (PDF)",
        CreateQuiz: "Создать викторину",
        QuizName: "Название викторины",
        CreateTedTalk: "Создать Тед-Ток",
        ViaLink: "Через ссылку",
        ViaVideoUpload: "Через загрузку видео",
        PleaseInsertLink: "Пожалуйста, вставьте ссылку",
        UploadVideo: "Загрузить видео",
        CreateArticle: "Создать статью",
        InsertLink: "Вставить ссылку",
        UploadPDF: "Загрузить фото или PDF",
        CreateVideo: "Создать видео",
        LessonDetail: "Детали урока",
        Eitherselect: "Выберите викторину или удалите ее.",
        Selectquiz: "Выберите викторину",
        AddNewQuiz: "Добавить новую викторину",
        Atleastonetopic: "Требуется хотя бы одна тема этого урока.",
        LessonNameRequired: "Требуется название урока.",
        LessonName: "Название урока",
        DeleteLesson: "Удалить урок",
        LessonDescriptionRequired: "Требуется описание урока.",
        TopicNameIsRequired: "Требуется название темы.",
        TopicName: "Название темы",
        DeleteTopic: "Удалить тему",
        TopicDescription: "Требуется описание темы.",
        eitherEnterText: "Введите текст или удалите его.",
        EitherenterA: "Введите",
        OrRemoveIt: "или удалите его.",
        CreateArticlesNow: "Создать статьи сейчас!",
        CreateCoursesNow: "Создать курсы сейчас!",
        CreateEbooksNow: "Создать электронные книги сейчас!",
        CreateElearningNow: "Создать электронное обучение сейчас!",
        CreateQuizzesNow: "Создать викторины сейчас!",
        CreateTeamDashboardNow: "Создать панель управления командой сейчас!",
        CreateTedTalksNow: "Создать Тед-Токи сейчас!",
        CreateVideosNow: "Создать видео сейчас!",
        OptionEmpty: "Опция не может быть пустой",
        CorrectOptionRequired: "Требуется правильный вариант",
        QuestionEmpty: "Вопрос не может быть пустым",
        UntitledQuestion: "Без названия",
        AddQuestion: "Добавить вопрос",
        DeleteQuestion: "Удалить вопрос",
        Quiznamerequired: "Требуется название викторины!",
        QuizexpirationDate: "Дата окончания викторины",
        QuizExpirationDateIsRequired: "Требуется дата окончания викторины",
        QuizDescriptionRequired: "Требуется описание викторины!",
        StartQuiz: "Начать викторину",
        GoToResults: "Перейти к результатам",
        Gettoknown: "Узнайте пользовательский опыт",
        Missingdata: "Отсутствующие данные.",
        UntitledLesson: "Урок без названия",
        RemoveText: "Удалить текст",
        AddText: "Добавить текст",
        RemoveImage: "Удалить изображение",
        AddImage: "Добавить изображение",
        RemoveVideo: "Удалить видео",
        AddVideo: "Добавить видео",
        RemovePDF: "Удалить PDF",
        AddPDF: "Добавить PDF",
        RemoveQuiz: "Удалить викторину",
        AddQuiz: "Добавить викторину",
        RemoveLink: "Удалить ссылку",
        AddLink: "Добавить ссылку",
        RemoveYouTubeLink: "Удалить ссылку на YouTube",
        AddYouTubeLink: "Добавить ссылку на YouTube",
        RemoveExternalCourse: "Удалить внешний курс",
        AddExternalCourse: "Добавить внешний курс",
        UntitledTopic: "Без названия",
        DashboardChart: "График панели управления",
        LatestTrendInBusiness: "Последние тенденции в бизнесе",
        InformationWillComeHere: "Информация будет здесь",
        CourseContent: "Содержание курса",
        DummyContent: "Фиктивное содержание",
        EndCourse: "Завершить курс",
        Chapters: "Главы •",
        Lectures: "Лекции •",
        LessonsInThisProgram: "Уроки в этой программе",
        BookInformation: "Информация о книге",
        TedTalksDaily: "Ежедневные Тед-Токи",
        TopicTitle: "Заголовок темы",
        VisitThis: "Посетить это",
        LearnPractical: "Изучайте практические навыки через курсы и получайте оплачиваемые стажировки.",
        Courses: "Курсы",
        Book: "Книга",
        Tedtalk: "Тед-ток",
        Articles: "Статьи",
        NoPageExist: "Страница не существует",
        Books: "Книги",
        TedTalks: "Тед-Токи",
        Videos: "Видео",
        GoTo: "Перейти к",
        CourseLevelIdLabel: {
            [CourseLevelIdEnum.Advance]: "Продвинутый",
            [CourseLevelIdEnum.Intermediate]: "Средний",
            [CourseLevelIdEnum.Beginner]: "Начинающий"
        }
    },
    attendence: {
        Attendance: "Посещаемость",
        TimeSpent: "Время проведенное",
        GraceTime: "Льготное время",
        GoodDay: "Хороший день",
        Howfeeling: "Как вы себя чувствуете сегодня?",
        Note: "Заметка",
        Noattendanceforapproval: "Нет посещаемости для утверждения.",
        Iwaslate: "Я опоздал из-за пробок.",
        Showtimespent: "Показать время проведенное",
        ClockIn: "Зарегистрируйтесь, чтобы начать учет посещаемости.",
        selectStatus: "Пожалуйста, выберите статус!",
        ByTime: "По времени",
        ByStatus: "По статусу",
        DeductionPolicy: "Политика вычетов",
        UpdateDeductionPolicy: "Обновить политику вычетов",
        AddDeductionPolicy: "Добавить политику вычетов",
        DaysRange: "Диапазон дней",
        PerDay: "За день",
        SaveDeductionPolicy: "Сохранить политику вычетов",
        AbsentPolicy: "Политика отсутствия",
        LatePolicy: "Политика опозданий",
        IsPercentage: "В процентах",

        attendanceFilterTypeEnum: {
            [AttendanceFilterTypeEnum.checkInForApproval]: "Регистрация для утверждения",
            [AttendanceFilterTypeEnum.dayWiseAttendance]: "Дневная посещаемость",
            [AttendanceFilterTypeEnum.userAttendance]: "Посещаемость пользователя",
            [AttendanceFilterTypeEnum.Summary]: "Сводка",
        },

        updateErrorMessage: {
            [AttendanceStateEnum.Off]: "Действие обновления недоступно для состояния 'выходной'",
            [AttendanceStateEnum.Holiday]: "Действие обновления недоступно для праздников",
            [AttendanceStateEnum.Leave]: "Действие обновления недоступно для состояния 'отпуск'",
        },
        statusEnum: {
            [AttendanceStatusEnum.InProcess]: "В процессе",
            [AttendanceStatusEnum.Approved]: "Утверждено",
            [AttendanceStatusEnum.Declined]: "Отклонено",
        },
        moodLabels: {
            [AttendanceMoodEnum.VeryUnsatisfied]: "Очень недоволен",
            [AttendanceMoodEnum.Unsatisfied]: "Недоволен",
            [AttendanceMoodEnum.Neutral]: "Нейтрально",
            [AttendanceMoodEnum.Satisfied]: "Доволен",
            [AttendanceMoodEnum.VerySatisfied]: "Очень доволен",
        },
        attendanceState: {
            [AttendanceStateEnum.Unknown]: "",
            [AttendanceStateEnum.Present]: "Присутствует",
            [AttendanceStateEnum.Absent]: "Отсутствует",
            [AttendanceStateEnum.Leave]: "Отпуск",
            [AttendanceStateEnum.Late]: "Опоздание",
            [AttendanceStateEnum.WorkFromHome]: "Работа из дома",
            [AttendanceStateEnum.Off]: "Выходной",
            [AttendanceStateEnum.Holiday]: "Праздник",
        },
        attendanceType: {
            [ATTENDANCE_ENUM.CHECK_IN.IN]: "Регистрация",
            [ATTENDANCE_ENUM.CHECK_IN.OUT]: "Выход",
        },
        attendanceMetricLabel: {
            [AttendanceMetricTypeEnum.overallTimeSpent]: "Общее время проведенное",
            [AttendanceMetricTypeEnum.Present]: "Присутствует",
            [AttendanceMetricTypeEnum.Absent]: "Отсутствует",
            [AttendanceMetricTypeEnum.Leave]: "Отпуск",
            [AttendanceMetricTypeEnum.Late]: "Опоздание",
        },
    },
    customApproval: {
        createCustomApproval: "Создать индивидуальное утверждение",
        customApproval: "Индивидуальное утверждение",
        noDataMessage: "– Создайте индивидуальные утверждения сейчас!",
        customApprovalFilterTypeEnum: {
            [CustomApprovalFilterTypeEnum.All]: "Все индивидуальные утверждения",
            [CustomApprovalFilterTypeEnum.CreatedByMe]: "Мои индивидуальные утверждения",
            [CustomApprovalFilterTypeEnum.ForApproval]: "Для утверждения",
        },
        statusEnum: {
            [CustomApprovalStatusEum.InProcess]: "В процессе",
            [CustomApprovalStatusEum.Approved]: "Утверждено",
            [CustomApprovalStatusEum.Declined]: "Отклонено",
            [CustomApprovalStatusEum.Resend]: "Повторно отправить",
            [CustomApprovalStatusEum.InActive]: "Неактивно",
            [CustomApprovalStatusEum.NotRequired]: "Не требуется",
            [CustomApprovalStatusEum.Cancelled]: "Отменено",
            [CustomApprovalStatusEum.Hold]: "Приостановлено",
        },
        SortEnum: {
            [CustomApprovalFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [CustomApprovalFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [CustomApprovalFilterSortEnum.ReferenceNo]: "Номер ссылки",
            [CustomApprovalFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [CustomApprovalFilterSortEnum.CreateDateAsc]: "Дата создания"
        }
    },
    reward: {
        CreateReward: "Создать награду",
        Rewards: "Награды",
        RewardTo: "Награда для",
        RewardCategory: "Категория награды",
        AddRewardCategory: "Добавить категорию награды",
        EnterRewardCategory: "Введите категорию награды",
        RewardName: "Название награды",
        EnterRewardName: "Введите название награды",
        PleaseEnterRewardName: "Пожалуйста, введите название награды",
        ReasonForReward: "Причина награды",
        EnterReasonForReward: "Введите причину награды",
        PleaseEnterReasonForReward: "Пожалуйста, введите причину награды",
        noDataMessage: "– Создайте награду сейчас!",
        rewardFilterTypeEnum: {
            [RewardFilterTypeEnum.All]: "Все награды",
            [RewardFilterTypeEnum.CreatedByMe]: "Созданные мной",
            [RewardFilterTypeEnum.ForApproval]: "Для утверждения",
            [RewardFilterTypeEnum.RewardForMe]: "Мои награды",
        },
        statusEnum: {
            [RewardStatusEnum.InProcess]: "В процессе",
            [RewardStatusEnum.Approved]: "Утверждено",
            [RewardStatusEnum.Declined]: "Отклонено",
            [RewardStatusEnum.Cancelled]: "Отменено",
            [RewardStatusEnum.Hold]: "Приостановлено",
        },
        SortEnum: {
            [RewardFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [RewardFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [RewardFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [RewardFilterSortEnum.ReferenceNo]: "Номер ссылки",
            [RewardFilterSortEnum.StatusDesc]: "Статус - по убыванию",
            [RewardFilterSortEnum.Status]: "Статус",
            [RewardFilterSortEnum.CategoryDesc]: "Категория - по убыванию",
            [RewardFilterSortEnum.Category]: "Категория",
            [RewardFilterSortEnum.NameDesc]: "Имя - по убыванию",
            [RewardFilterSortEnum.Name]: "Имя",
            [RewardFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [RewardFilterSortEnum.CreateDateAsc]: "Дата создания"
        }
    },
    document: {
        mySigned: "Мой подписанный",
        forMySignature: "Для моей подписи",
        draftSignature: "Черновик",
        inSigning: "На подписи",
        signaturesCompleted: "Подписи завершены",
        DocsArchive: "Документы и архивы",
        createDocument: "Создать документ",
        CreateSlides: "Создать слайды",
        CreateBoard: "Создать доску",
        CreatePad: "Создать блокнот",
        CreateGrid: "Создать сетку",
        newFolder: "Новая папка",
        createFolder: "Создать папку",
        uploadDocuments: "Загрузить документы",
        addBoard: "Добавить доску",
        addPad: "Добавить блокнот",
        addGrid: "Добавить сетку",
        addSlide: "Добавить слайд",
        addMindmap: "Добавить майндмэп",
        CompleteDocument: "Завершить документ",
        noDataMessage: "- Создайте документ сейчас!",
        AllDocuments: "Все документы",
        MyDrive: "Мой диск",
        MyDocuments: "Мои документы для утверждения",
        ForApprovals: "Для утверждения",
        OnlyFiles: "Только файлы",
        fileName: "Имя файла",
        shortDescription: "Краткое описание (макс 100 символов)",
        AllFiles: "Все файлы",
        Grids: "Сетки",
        Pads: "Блокноты",
        Slides: "Слайды",
        Boards: "Доски",
        Mindmaps: "Майндмэпы",
        updateFolder: "Обновить папку",
        updateGrid: "Обновить сетку",
        updatePad: "Обновить блокнот",
        updateSlide: "Обновить слайд",
        updateBoard: "Обновить доску",
        updateMindMap: "Обновить майндмэп",
        updateDocuments: "Обновить документ",
        status: {
            InProcess: "В процессе",
            Approved: "Утверждено",
            Declined: "Отклонено",
            Resend: "Повторно отправить",
            NotRequired: "Не требуется",
            Cancelled: "Отменено",
            Draft: "Черновик"
        },
        documentReferenceType: {
            [DocumentReferenceTypeEnum.General]: "Общий",
            [DocumentReferenceTypeEnum.Group]: "Группа",
            [DocumentReferenceTypeEnum.Project]: "Проект",
        },
    },
    warning: {
        WarningDetail: "Детали предупреждения",
        warningTo: "Предупреждение для",
        createWarning: "Создать предупреждение",
        enterwarningCategory: "Введите категорию предупреждения",
        noDataMessage: "– Создайте предупреждение сейчас!",
        filter: "Фильтр",
        warningFilterTypeEnum: {
            [WarningFilterTypeEnum.Warning]: "Предупреждения",
            [WarningFilterTypeEnum.CreatedByMe]: "Созданные мной",
            [WarningFilterTypeEnum.ForApproval]: "Для утверждения",
            [WarningFilterTypeEnum.WarningToMe]: "Предупреждение для меня",
        },
        statusEnum: {
            [WarningStatusEnum.InProcess]: "В процессе",
            [WarningStatusEnum.Approved]: "Утверждено",
            [WarningStatusEnum.Declined]: "Отклонено",
            [WarningStatusEnum.Cancelled]: "Отменено",
            [WarningStatusEnum.Hold]: "Приостановлено",
        },
        SortEnum: {
            [WarningFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [WarningFilterSortEnum.UpdateDateAsc]: "Дата обновления - по возрастанию",
            [WarningFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [WarningFilterSortEnum.ReferenceNo]: "Номер ссылки",
            [WarningFilterSortEnum.StatusDesc]: "Статус - по убыванию",
            [WarningFilterSortEnum.Status]: "Статус",
            [WarningFilterSortEnum.CategoryDesc]: "Категория - по убыванию",
            [WarningFilterSortEnum.Category]: "Категория",
            [WarningFilterSortEnum.NameDesc]: "Имя - по убыванию",
            [WarningFilterSortEnum.Name]: "Имя",
            [WarningFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [WarningFilterSortEnum.CreateDateAsc]: "Дата создания - по возрастанию"
        }
    },
    jobBoard: {
        jobBoard: "Доска объявлений",
        noDataMessage: " – Создайте вакансии сейчас!",
    },
    businessPolicy: {
        BusinessPolicy: "Бизнес-политика",
        policies: "Политики",
        noPolicy: "Политик не найдено...",
    },
    forms: {
        CreateForm: "Создать форму",
        Forms: "Формы",
        Attempt: "Попытка",
        CopyLink: "Копировать ссылку",
        Title: "Заголовок",
        EnterTitle: "Введите заголовок",
        PleaseEnterTitle: "Пожалуйста, введите заголовок",
        PleaseFillTheQuestionField: "Пожалуйста, заполните поле вопроса",
        EnterQuestion: "Введите вопрос",
        SelectAnswerType: "Выберите тип ответа",
        Text: "Текст",
        Number: "Число",
        Polls: "Опросы",
        Poll: "Опрос",
        AddQuestion: "Добавить вопрос",
        AddOption: "Добавить вариант",
        Details: "Детали",
        PoweredBy: "Работает на",
        Submit: "Отправить",
        EditForm: "Редактировать форму",
        EnterEmail: "Введите электронную почту",
        Question: "Вопрос...",
        YourAnswer: "Ваш ответ",
        noDataMessage: "– Создайте форму сейчас!",
        formQuestionTypeEnum: {
            [FormQuestionTypeEnum.Number]: "Число",
            [FormQuestionTypeEnum.Text]: "Текст",
            [FormQuestionTypeEnum.Poll]: "Опрос",
        },
        formFilterTypeEnum: {
            [FormFilterTypeEnum.All]: "Все формы",
            [FormFilterTypeEnum.CreatedByMe]: "Созданные мной",
            [FormFilterTypeEnum.ForApproval]: "Для утверждения",
            [FormFilterTypeEnum.FormForMe]: "Формы для меня"
        },
        statusEnum: {
            [FormStatusEnum.InProcess]: "В процессе",
            [FormStatusEnum.Approved]: "Утверждено",
            [FormStatusEnum.Declined]: "Отклонено",
            [FormStatusEnum.Resend]: "Повторно отправить",
            [FormStatusEnum.Inactive]: "Неактивно",
            [FormStatusEnum.NotRequired]: "Не требуется",
            [FormStatusEnum.Cancelled]: "Отменено",
            [FormStatusEnum.Hold]: "Приостановлено",
        },
        SortEnum: {
            [FormFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [FormFilterSortEnum.CreateDateAsc]: "Дата создания",
            [FormFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [FormFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [FormFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [FormFilterSortEnum.ReferenceNo]: "Номер ссылки"
        }
    },
    careers: {
        Careers: "Карьеры",
        CareerDetail: "Детали карьеры",
        createCareer: "Создать карьеру",
        MinSalary: "Минимальная зарплата",
        MaxSalary: "Максимальная зарплата",
        All: "Все",
        Applicants: "Кандидаты",
        ShortListed: "В шорт-листе",
        Rejected: "Отклонено",
        Completed: "Завершено",
        Finalist: "Финалист",
        Maybe: "Возможно",
        OfferSent: "Предложение отправлено",
        WaitingForInterview: "Ожидание интервью",
        OfferAccepted: "Предложение принято",
        OfferDeclined: "Предложение отклонено",
        OfferResend: "Повторное предложение",
        noDataMessage: "– Создайте карьеру сейчас!",
        writeHeaderHere: "Напишите заголовок здесь",
        pickCurrentDate: "Выберите текущую дату",
        enterAmount: "Введите сумму",
        selectApprovers: "Выберите утверждающих",
        selectFinancers: "Выберите финансистов",
        selectExecutors: "Выберите исполнителей",
        selectDesignation: "Выберите должность",
        writeDescription: "Напишите описание",
        enterMinSalary: "Введите минимальную зарплату",
        enterMaxSalary: "Введите максимальную зарплату",
        selectDepartment: "Выберите отдел",
        selectSupervisor: "Выберите руководителя",
        selectInterviewers: "Выберите интервьюеров",
        selectPostInterviewers: "Выберите пост-интервьюеров",
        description: "Напишите ваше описание здесь...",
        addSkills: "Добавить навык",
        selectJobType: "Выберите тип работы",
        selectJobshift: "Выберите смену работы",
        selectEducation: "Пожалуйста, выберите образование",
        selectCareerLevel: "Выберите уровень карьеры",
        selectEndDate: "Выберите дату окончания",
        enterreviewcriteria: "Введите критерии обзора",
        reviewCriteria: "Критерии обзора",
        enterDepartment: "Пожалуйста, введите отдел",
        supervisor: "Руководитель",
        supervisorrequired: "Руководитель обязателен",
        postInterviewers: "Пост-интервьюеры",
        jobShift: "Смена работы",
        enterJobShift: "Пожалуйста, введите смену работы",
        enterJobType: "Пожалуйста, введите тип работы",
        careerLevel: "Уровень карьеры",
        selectBuddy: "Выберите друга по найму",
        selectInerview: "Выберите интервьюеров",
        requiredInterview: "Интервьюер обязателен",
        enterCareerLevel: "Пожалуйста, введите уровень карьеры",
        createJob: "Создать работу",
        applyJob: "Подать заявку на работу",
        firstName: "Имя",
        enterFirstName: "Введите имя",
        requiredMessageFName: "Имя обязательно",
        lastName: "Фамилия",
        enterLastName: "Введите фамилию",
        requiredMessageLName: "Фамилия обязательна",
        email: "Электронная почта",
        enterEmail: "Введите электронную почту",
        EmailIsRequired: "Электронная почта обязательна",
        phoneNumber: "Номер телефона",
        enterPhoneNumber: "Введите номер телефона",
        currentSalary: "Текущая зарплата",
        expectedSalary: "Ожидаемая зарплата",
        radioMessageApplicant: "Кандидат",
        radioMessageShortlisted: "В шорт-листе",
        radioMessageCompleted: "Завершено",
        coverNote: "Сопроводительное письмо",
        noticePeriod: "Срок уведомления",
        requiredMessageFName: "Пожалуйста, введите имя",
        requiredMessageLName: "Пожалуйста, введите фамилию",
        requiredMessageEmail: "Пожалуйста, введите электронную почту",
        requiredMessagePhoneNumbeer: "Пожалуйста, введите номер телефона",
        requiredMessageCurrentSalary: "Пожалуйста, введите текущую зарплату",
        requiredMessageExpextedsalary: "Пожалуйста, введите ожидаемую зарплату",
        requiredMessageExperience: "Пожалуйста, введите опыт",
        requiredMessageNoticePeriod: "Пожалуйста, введите срок уведомления",
        appliedDate: "Дата подачи заявки",
        interviewSchedule: "Расписание интервью",
        applyNow: "Подать заявку сейчас",
        jobPost: "Работа размещена",
        jobexpire: "Работа истекает",
        skillrequired: "Требуемые навыки",
        joblocation: "Местоположение работы",
        jobType: "Тип работы",
        jobDetails: "Детали работы",
        EducationLevel: "Уровень образования",
        MinimumSalary: "Минимальная зарплата",
        MaximumSalary: "Максимальная зарплата",
        YearOfExperience: "Год опыта",
        errormessage: "Невозможно изменить статус",
        MakeOffer: "Сделать предложение",
        updatestatus: "Вы уверены, что хотите обновить статус?",
        Pleaseenterminsalary: "Пожалуйста, введите минимальную зарплату",
        Pleaseentermaxsalary: "Пожалуйста, введите максимальную зарплату",
        NoRequirements: "Нет требований",
        careerInterviewStatuEnum: {
            [CareerInterviewStatusEnum.NotScheduled]: "Не запланировано",
            [CareerInterviewStatusEnum.Ended]: "Завершено",
            [CareerInterviewStatusEnum.Scheduled]: "Запланировано",
            [CareerInterviewStatusEnum.Started]: "Начато",
            [CareerInterviewStatusEnum.WaitingForReSchedule]: "Ожидание переназначения"
        },
        statusEnum: {
            [CareerStatusEnum.InProcess]: "В процессе",
            [CareerStatusEnum.Approved]: "Утверждено",
            [CareerStatusEnum.Declined]: "Отклонено",
            [CareerStatusEnum.Cancelled]: "Отменено",
            [CareerStatusEnum.Available]: "Доступно",
            [CareerStatusEnum.Expired]: "Истекло",
            [CareerStatusEnum.Hold]: "Приостановлено",
        },
        careerFilterTypeEnum: {
            [CareerFilterTypeEnum.All]: "Все карьеры",
            [CareerFilterTypeEnum.MyCareers]: "Мои карьеры",
            [CareerFilterTypeEnum.ForApproval]: "Для утверждения",
        },
        SortEnum: {
            [CareerFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [CareerFilterSortEnum.CreateDateAsc]: "Дата создания",
            [CareerFilterSortEnum.ReferenceNoDesc]: "Дата обновления - по убыванию",
            [CareerFilterSortEnum.ReferenceNoAsc]: "Дата обновления",
            [CareerFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [CareerFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [CareerFilterSortEnum.MinSalary]: "Минимальная зарплата",
            [CareerFilterSortEnum.MinSalaryDesc]: "Минимальная зарплата - по убыванию",
            [CareerFilterSortEnum.MaxSalary]: "Максимальная зарплата",
            [CareerFilterSortEnum.MaxSalaryDesc]: "Максимальная зарплата - по убыванию",
        },
        CareerLevelEnum: {
            [CareerLevelEnum.Entry]: "Начальный уровень",
            [CareerLevelEnum.MidLevel]: "Средний уровень",
            [CareerLevelEnum.SeniorLevel]: "Старший уровень",
            [CareerLevelEnum.ExecutiveLevel]: "Уровень руководителя",
            [CareerLevelEnum.CSuite]: "C-Suite",
            [CareerLevelEnum.PresidentCeo]: "Президент/Генеральный директор",
        },
        JobTypeEnums: {
            [JobTypeEnums.FullTime]: "Полная занятость",
            [JobTypeEnums.PartTime]: "Частичная занятость",
            [JobTypeEnums.Contractual]: "Контрактная работа",
            [JobTypeEnums.Consultant]: "Консультант",
            [JobTypeEnums.Agent]: "Агент",
            [JobTypeEnums.ProjectBased]: "Проектная работа",
        },
        JobShiftTypeEnums: {
            [JobShiftTypeEnums.Day]: "День",
            [JobShiftTypeEnums.Morning]: "Утро",
            [JobShiftTypeEnums.AfterNoon]: "После обеда",
            [JobShiftTypeEnums.Night]: "Ночь",
            [JobShiftTypeEnums.Other]: "Другое",
        }
    },
    /*** HR Menu ***/
    myTeam: {
        MyTeams: "Мои команды",
        EmployeeNo: "Номер сотрудника",
        noDataMessage: "Создайте команды сейчас!",
        teamDetails: "Детали команды",
        DeductionPerMonth: "Вычет в месяц",
        teamActivities: {
            attendance: "Посещаемость",
            checkIn: "Регистрация",
            leaves: "Отпуска",
            rewards: "Награды",
            appraisals: "Оценки",
            warnings: "Предупреждения",
            complains: "Жалобы",
            courses: "Курсы",
            education: "Образование",
            experience: "Опыт",
            loan: "Кредит",
            activityLog: "Журнал активности",
            checkInCheckOut: "Регистрация/Выход",
            storageStatistics: "Статистика хранения"
        },
    },
    administration: {
        basicInfo: "Основная информация",
        Subsidiary_Office: "Филиал / Офис",
        Fiscal_Year: "Финансовый год",
        Tax_Slabs: "Налоговые ставки",
        Tax_Slabs_Group: "Группа налоговых ставок",
        E_Learning_Category: "Категория eLearning",
        Company_Policy: "Политики компании",
        Default_Hiring_Criteria: "Критерии найма по умолчанию",
        subsidiary: "Филиал",
        AddSubsidiary: "Добавить филиал",
        Business_Logo: "Логотип компании",
        Complain_Category: "Категория жалоб",
        Custom_Tag: "Пользовательский тег",
        Payroll_Group: "Группа зарплаты",
        Business_Policy: "Политика компании",
        Companies_Policy: "Политики компании",
        Billing: "Выставление счетов",
        Rebate_Category: "Категория скидок",
        Assets_Category: "Категория активов",
        AccessRole: "Роль доступа",
        Grade: "Класс",
        Default_Account_Header: "Заголовок учетной записи по умолчанию",
        Business_Paging: "Номер бизнеса",
        Discussion_Board_Category: "Категория доски обсуждений",
        Room: "Комната",
        Business_Association: "Бизнес-ассоциация",
        Designation: "Назначение",
        Company_Goal: "Цель компании",
        Individual_Goal: "Индивидуальная цель",
        Office_Timings: "Рабочее время офиса",
        Leave_Types: "Типы отпусков",
        User_Types: "Типы пользователей",
        Expense_Headers: "Заголовки расходов",
        Salary_Headers: "Заголовки заработной платы",
        Request_For_Right: "Запрос на право",
        Custom_Approval_Category: "Категория пользовательского утверждения",
        GRADE_ALLOWANCES: "Надбавки по классу",
        Paging_Category: "Категория разбивки на страницы",
        Page_Category: "Категория страницы",
        Add_Paging_Category: "Добавить категорию страницы",
        Project_Category: "Категория проекта",
        Email_Configuration: "Настройка электронной почты",
        Allowances: "Пособия",
        Default_Approvals: "Утверждения по умолчанию",
        NonMandatory_Approvals: "Необязательные утверждения",
        WORKGATING: "Критерии WIP Gating",
        Job_Description: "Описание работы",
        Rewards: "Награды",
        Reward_Category: "Категория награды",
        Add_Reward_Category: "Добавить категорию награды",
        Warning_Category: "Категория предупреждения",
        Add_Warning_Category: "Добавить категорию предупреждения",
        Holidays: "Праздники",
        paymentCard: "Платежная карта",
        addPaymentCard: "Добавить платежную карту",
        Company_Info: "Информация о компании",
        business_Info:" Бизнес информация",
        Business_Approval: "Бизнес-утверждение",
        ATTENDANCE_SETTINGS: "Настройки посещаемости",
        businessInfo : {
            businessInformation: 'Informazioni sull\'azienda',
            companyBackgroundLabel: 'Storia dell\'azienda',
            productAndServicesLable: 'Prodotti e servizi',
            competitorsLable: 'Concorrenti',
            recentNewsLable: 'Notizie recenti',
            leadershipTeamLabel: 'Team di leadership',
            organizationalStructureLabel: 'Struttura organizzativa',
            customerBaseLabel: 'Base clienti',
            FinancialHealthLabel: 'Salute finanziaria',
            industryLabel: 'Settore',
            websiteAndSocialMediaLabel: 'Sito web e social media',
            additionalCommentLabel: 'Commenti aggiuntivi',
            companyCultureLabel: 'Cultura aziendale',
            ceoLabel: 'CEO',
            companyBackground: {
                mission: 'Missione',
                vision: 'Visione',
                values: 'Valori',
                YearFounded: 'Anno di fondazione',
                placeHolders: {
                    enterMission: 'Inserisci la missione',
                    enterVision: 'Inserisci la visione',
                    enterValues: 'Inserisci i valori',
                    enterYearFounded: 'Inserisci l\'anno di fondazione',
                },
                requiredMessages: {
                    enterMission: 'Per favore, inserisci la missione',
                    enterVision: 'Per favore, inserisci la visione',
                    enterValues: 'Per favore, inserisci i valori',
                    enterYearFounded: 'Per favore, inserisci l\'anno di fondazione',
                }
            },
            productAndService: {
                Benefits: 'Benefici'
            },
            competitors: {
                MarketPosition: 'Posizione di mercato',
                Strength: 'Forza',
                Weakness: 'Debolezza',
            },
            recentNews: {
                Headline: 'Titolo',
                News: 'Notizie'
            },
            leadershipTeam: {
                Background: 'Sfondo',
                ContactNumber: 'Numero di contatto',
            },
            financialHealth: {
                Revenue: 'Entrate',
                Profitability: 'Redditività'
            },
            additionalComment: {
                label: {
                    comments: 'Commenti'
                },
                placeholders: {
                    comments: 'Inserisci i commenti'
                },
                requireMessages: {
                    enterComments: 'Per favore, inserisci i commenti'
                }
            },
            comapnyCulture: {
                placeHolders: {
                    enterDescription: 'Inserisci la descrizione',
                    enterWorkEnvironment: 'Inserisci l\'ambiente di lavoro'
                },
                label: {
                    description: 'Descrizione',
                    workEnvironment: 'Ambiente di lavoro'
                },
                requiredMessages: {
                    enterDescription: 'Per favore, inserisci la descrizione',
                    enterWorkEnvironment: 'Per favore, inserisci l\'ambiente di lavoro'
                }
            }
        },
        COMPANY_STATISTICS: "Статистика компании",
        Form: {
            Name: "Название",
            Entername: "Введите название",
            PleaseEnterName: "Введите название",
            Description: "Описание",
            enterDescription: "Пожалуйста, введите описание",
            Create: "Создать",
            Add: "Добавить",
            AddBusinessNumber: "Добавить бизнес-номер",
            AddAllowance: "Добавить пособие",
            AddAssetsCategory: "Добавить категорию активов",
            UpdateAssetsCategory: "Обновить категорию активов",
            AddBusinessAssociation: "Добавить бизнес-ассоциацию",
            AddCustomApprovalCategory: "Добавить категорию пользовательского утверждения",
            AddCustomTag: "Добавить пользовательский тег",
            AddDesignation: "Добавить назначение",
            AddComplain: "Добавить жалобу",
            Save: "Сохранить",
            Clear: "Очистить",
            CategoryName: "Название категории",
            Entercategoryname: "Введите название категории",
            SaveCategory: "Сохранить категорию",
            Date: "Дата",
            startDate: "Дата начала",
            endDate: "Дата окончания",
            Upload: "Загрузить",
            Percent: "Процент",
            Amount: "Сумма",
            EnterAmount: "Введите сумму",
            addCategory: "Добавить категорию",
            SelectMember: "Выбрать участника",
            Email: "Эл. почта",
            EnterEmail: "Введите адрес эл. почты",
            Types: "Типы",
            SelectStatus: "Выбрать статус",
            Submit: "Отправить",
            Nomoreapprovals: "Больше нет утверждений",
        },
        Table: {
            Name: "Имя",
            Members: "Члены",
            Description: "Описание",
            Value: "Значение",
            Allot_Leave: "Выделить Отпуск",
            Type: "Тип",
            Unit: "Единица",
            Taxable: "Облагается налогом",
            Provider: "Поставщик",
            IncomingServerAddress: "Адрес входящего сервера",
            OutgoingServerAddress: "Адрес исходящего сервера",
            IncomingPort: "Входящий порт",
            OutgoingPort: "Исходящий порт",
            Action: "Действие",
            startDate: "Дата начала",
            endDate: "Дата окончания",
            Actions: "Действия",
            Account: "Счет",
            approvers: "Утверждающие",
            Status: "Статус",
            Allocated: "Выделено",
            AddMember: "Добавить участника",
            CreateDate: "Дата создания",
        },
        accessRole: {
            Button: {
                AddAccessRole: "Добавить роль доступа",
                UpdateAccessRole: "Обновить роль доступа",
            },
            Drawer: {
                CreateAccessRole: "Создать роль доступа",
                UpdateAccessRole: "Обновить роль доступа",
                UserType: "Тип пользователя",
                Description: "Описание",
                placeholders: {
                    EnterName: "Введите название роли доступа",
                    UserType: "Пожалуйста, выберите тип пользователя",
                    Description: "Введите описание",
                },
                formLabel: {
                    AccessRoleName: "Название роли доступа",
                },
                placeholders: {
                    EnterName: "Введите название роли доступа",
                    SelectUserType: "Выберите тип пользователя",
                    EnterDescription: "Введите описание",
                },
            },
        },
        grade: {
            AddGrade: "Добавить оценку",
            AddGradeAllowances: "Добавить допуски для оценки",
            EnterGrade: "Введите оценку",
            EnterDescription: "Введите описание",
            Grade: "Оценка",
            Description: "Описание",
            Name: "Название",
            clear: "Очистить",
            saveGrade: "Сохранить оценку",
        },
        fiscalyear: {
            Add: "Добавить",
            AddFiscalYear: "Добавить финансовый год",
            EnterName: "Введите название",
            EnterDescription: "Введите описание",
            Fiscalyear: "Финансовый год",
            description: "Описание",
            name: "Название",
            clear: "Очистить",
            save: "Сохранить",
            startyear: "Начальный год",
            endyear: "Конечный год",
            startMonth: "Начальный месяц",
            endMonth: "Конечный месяц",
            startDate: "Начальная дата",
            endDate: "Конечная дата",
        },
        businessPolicy: {
            companypolicy: "Политики компании",
            enterName: "Введите название",
            name: "Название",
            type: "Выберите тип",
            typee: "Тип",
            description: "Описание",
            EnterDescription: "Введите описание",
            create: "Создать",
            save: "Сохранить",
            createPolicy: "Создать политику",
            updatePolicy: "Обновить политику",
        },
        taxSlab: {
            TaxSlabs: "Налоговые ставки",
            UpdateTaxSlab: "Обновить налоговую ставку",
            CreateTaxSlab: "Создать налоговую ставку",
            NoTaxSlabGroup: "Группа налоговых ставок отсутствует...",
            enterCountry: "Выберите страну",
            enterName: "Введите название",
            enterDescription: "Введите описание",
            description: "Описание",
            name: "Название",
            TaxSlab: "Налоговая ставка",
            title: "Заголовок",
            enterTitle: "Введите заголовок",
            percentage: "Процент",
            previousCharge: "Предыдущий сбор",
            min: "Мин",
            max: "Макс",
            enterMin: "Введите мин",
            enterMax: "Введите макс",
            enterPercent: "Введите процент",
            save: "Сохранить",
            clear: "Очистить",
            Add: "Добавить",
            TaxSlabgroup: "Группа налоговых ставок",
            country: "Страна",
            minimumCharge: "Минимальный сбор",
        },
        payrollGroup: {
            PayrollGroup: "Группа заработной платы",
            AddPayrollGroup: "Добавить группу заработной платы",
            save: "Сохранить группу",
            clear: "Очистить",
            Add: "Добавить группу",
            name: "Название",
            enterName: "Введите название",
        },
        subsidiaryOffice: {
            enterName: "Введите название",
            enterAddress: "Введите адрес",
            name: "Название",
            subsidiary_Office: "Филиал",
            latitude: "Широта",
            longitude: "Долгота",
            address: "Адрес",
            branch: "Филиал",
            Subsidiary: "Дочернее предприятие",
            select: "Выберите дочернее предприятие",
            save: "Сохранить",
            clear: "Очистить",
            AddSubsidiaryOffice: "Добавить филиал",
            Add: "Добавить",
            title: "Заголовок",
            enterTitle: "Введите заголовок",
        },
        defaultHiringCriteria: {
            default: "Критерии найма по умолчанию",
            question: "Вопросы",
            enterQuestions: "Введите вопрос",
            save: "Сохранить вопрос",
            clear: "Очистить",
            Add: "Добавить вопрос",
        },
        workGatingCriteria: {
            work: "Критерии WIP-контроля",
            question: "Вопросы",
            enterQuestions: "Введите вопрос",
            save: "Сохранить вопрос",
            clear: "Очистить",
            Add: "Добавить вопрос",
            addWork: "Добавить критерии WIP-контроля"
        },
        designation: {
            desig: "Должность",
            enterdesig: "Введите должность",
            enterName: "Введите название",
            enterDescription: "Введите описание",
            description: "Описание",
            name: "Название",
            save: "Сохранить должность",
            clear: "Очистить",
            Add: "Добавить должность",
        },
        appraisal: {
            Appraisal: "Оценка",
            enterAppraisal: "Введите оценку",
            enterDescription: "Введите описание",
            description: "Описание",
            save: "Сохранить оценку",
            clear: "Очистить",
            Add: "Добавить оценку",
            CompanyGoal: "Цель компании",
            IndividualGoal: "Индивидуальная цель",
            DepartmentsGoal: "Цель департамента",
            Goal: "Цель",
            saveGoal: "Сохранить цель",
            AddCompanyGoal: "Добавить цель компании",
            AddDepartmentGoal: "Добавить цель департамента",
            AddIndividualGoal: "Добавить индивидуальную цель",
            enterCompanyGoal: "Введите цель компании",
            enterDepartmentGoal: "Введите цель департамента",
            enterIndividualGoal: "Введите индивидуальную цель",
        },
        office: {
            Office_Timing: "Рабочее время",
            group: "Название группы",
            enterGroup: "Введите название",
            enterDescription: "Введите описание",
            description: "Описание",
            save: "Сохранить рабочее время",
            clear: "Очистить",
            Add: "Добавить рабочее время",
            days: "Дни",
            work: "Рабочий",
            checkIn: "Вход",
            checkOut: "Выход",
            time: "Допустимое время",
            min: "Мин",
            create: "Создать группу",
            new: "Новая временная группа",
            UpdateOfficeTiming: "Обновить рабочее время",
            CreateOfficeTiming: "Создать рабочее время",
            Monday: "Понедельник",
            Tuesday: "Вторник",
            Wednesday: "Среда",
            Thursday: "Четверг",
            Friday: "Пятница",
            Saturday: "Суббота",
            Sunday: "Воскресенье",
        },
        leave: {
            Leave: "Тип отпуска",
            leaveType: "Тип отпуска",
            enterLeave: "Введите тип отпуска",
            save: "Сохранить тип отпуска",
            clear: "Очистить",
            Add: "Добавить тип отпуска",
            enterDescription: "Введите описание",
            description: "Описание",
            allotLeaves: "Выделенные отпуска",
            balanceLeaves: "Типы отпусков (баланс отпусков)",
            selectType: "Выберите тип",
            leaveFor: "Отпуск для",
            leaveDates: "Даты отпуска",
            createleave: "Создать отпуск",
        },
        expense: {
            Expense: "Заголовок расхода",
            enterExpense: "Введите заголовок расхода",
            enterDescription: "Введите описание",
            description: "Описание",
            save: "Сохранить",
            clear: "Очистить",
            Add: "Добавить заголовок расхода",
        },
        elearningCategory: {
            elearningCategory: "Категория электронного обучения",
            description: "Описание",
            enterelearningCategory: "Введите категорию",
            enterDescription: "Введите описание",
            save: "Сохранить",
            clear: "Очистить",
            Add: "Добавить категорию электронного обучения",
        },
        salaryHeader: {
            SalaryHeader: "Заголовок зарплаты",
            AddSalaryHeader: "Добавить заголовок зарплаты",
            enterSalaryHeader: "Введите заголовок зарплаты",
            desc: "Описание",
            enterDesc: "Введите описание",
            saveHeader: "Сохранить заголовок",
            addHeader: "Добавить заголовок",
            name: "Имя",
            clear: "Очистить",
        },
        room: {
            Room: "Комната",
            Name: "Имя",
            Entername: "Введите имя",
            Description: "Описание",
            Enterdescription: "Введите описание",
            Location: "Местоположение",
            Enterlocation: "Введите местоположение",
            TotalSeats: "Общее количество мест",
            Enterseats: "Введите количество мест",
            SaveRoom: "Сохранить комнату",
            AddRoom: "Добавить комнату",
            clear: "Очистить"
        },
        RebateCategory: {
            rebateCategory: "Категория скидок",
            addRebateCategory: "Добавить категорию скидок",
            maxPercentage: "Максимальный процент",
            entermaxPercentage: "Введите максимальный процент",
            maxAmount: "Максимальная сумма",
            enterMaxAmount: "Введите максимальную сумму",
            type: "Тип",
            basic: "Базовый",
            tax: "Налог",
            fullAmount: "Полная сумма",
            rebateType: "Тип скидки",
        },
        projectCategory: {
            ProjectCategory: "Категория проекта",
            AddProjectCategory: "Добавить категорию проекта",
        },
        pageCategory: {
            Pagecategory: "Категория страницы",
            AddPageCategory: "Добавить категорию страницы",
        },
        NonMandatoryApprovals: {
            NonMandatoryApproval: "Необязательные утверждения",
        },
        jobdescription: {
            jobDesc: "Описание работы",
            designation: "Должность",
            selectDesign: "Выбрать должность",
            saveJobDesc: "Сохранить",
            addJobdesc: "Добавить описание работы",
        },
        Holiday: {
            Holidays: "Праздники",
            AddHolidays: "Добавить праздники",
            AddHoliDay: "Добавить праздник"
        },
        GradeAllowance: {
            gradeAllowance: "Пособие по категории",
            Grades: "Категории",
            AllowanceType: "Тип пособия",
            SelectType: "Выбрать тип",
            AddAllowance: "Добавить пособие",
            UpdateAllowance: "Обновить пособие",
            Allowance: "Пособие",
            IsTaxable: "Облагается налогом",
        },
        EmailConfiguration: {
            emailConfi: "Настройка электронной почты",
            enterEmailConfi: "Введите настройку электронной почты",
            incomingPort: "Входящий порт",
            enterIncomingPort: "Введите входящий порт",
            incomingServerAddress: "Адрес входящего сервера",
            enterIncomingServerAddress: "Введите адрес входящего сервера",
            outgoingPort: "Исходящий порт",
            enterOutgoingPort: "Введите исходящий порт",
            outgoingServerAddress: "Адрес исходящего сервера",
            enterOutgoingServerAddress: "Введите адрес исходящего сервера",
            provider: "Провайдер",
            enterProvider: "Введите провайдера",
            saveEmailConfi: "Сохранить настройку электронной почты",
            addEmailConfi: "Добавить настройку электронной почты",
        },
        DiscussionBoardCategory: {
            discussionBoardCategory: "Категория доски обсуждений",
            SaveDiscussionBoardCategory: "Сохранить категорию доски обсуждений",
            AddDiscussionBoardCategory: "Добавить категорию доски обсуждений",
            Save: "Сохранить",
            Clear: "Очистить",
            Update: "Обновить",
        },
        DefaultApprovers: {
            defaultApprovers: "Утверждающие по умолчанию",
        },
        DefaultAccountHeader: {
            defaultAccountHeader: "Заголовок счета по умолчанию",
            SaveAccountHeader: "Сохранить заголовок счета",
            SelectAccountTypes: "Выбрать типы счетов",
        },
        CustomTag: {
            customTag: "Пользовательский тег",
            Entercustomtag: "Введите пользовательский тег",
            SaveCustomTag: "Сохранить пользовательский тег",
            AddCustomTag: "Добавить пользовательский тег",
        },
        customApprovalCategory: {
            customApprovalcategory: "Категория пользовательского утверждения",
            enterCustomApprovalcategory: "Введите категорию пользовательского утверждения",
        },
        BusinessNumber: {
            Manual: "Manual",
            TotalNumber: "Total Number",
            Country: "Country",
            SelectCountry: "Select Country",
            Number: "Number",
            Random: "Random",
            SelectNumber: "Select Number",
            Cost: "Cost",
            Request: "Request",
        },
        BusinessAssociation: {
            businessAssociation: "BusinessAssociation",
            SaveBusinessAssociation: "Save Business Association",
            AddBusinessAssociation: "Add Business Association",
            AssociationType: "Association Type",
        },
        AssetsCategory: {
            assetsCategory: "Assets Category",
            categoryName: "Category Name",
            enterCategoryName: "Enter category name",
            desc: "Description",
            enterDesc: "Enter description",
            accountType: "Account Type",
            parentAccount: "Parent Account",
            selectParent: "Select Parent",
            selectType: "Select Type",
            add: "Add",
            save: "Save",
            clear: "Clear",
            name: "Name",
            assets: "Asset",
            liability: "Liability",
            capital: "Capital",
            revenue: "Revenue",
            expense: "Expense",
            costOfGoodsale: "Cost of Good Sold",
        },
        Allowance: {
            allowance: "Allowance",
            enterAllownace: "Enter allowance",
            percent: "Percent",
            desc: "Enter description",
            grade: "Grade",
            selectGrade: "Select Grade",
            amount: "Amount",
            benefit: "Benefit",
            deduction: "Deduction",
            texable: "Taxable",
            nonTexable: "Non Taxable",
            director: "Director",
            ceo: "CEO",
            addAllowance: "Add Allowance",
            saveAllowance: "Save Allowance",
            clear: "Clear",
            name: "Name",
            allowanceType: "Allowance Type",
            allowanceUnit: "Allowance Unit",
            istexable: "Is Taxable",
            yes: "Yes",
            no: "No",
        },
        CompanyInfo: {
            businessLogo: "Business Logo",
            basicinfo: "Basic Info",
            ShortLogo: "Short Logo",
            Logo: "Logo",
            FullLogo: "Full Logo",
            companyName: "Company Name",
            PleaseEnterCompanyName: "Please Enter Company Name",
            enterCompanyName: "Enter Company Name",
            companyEmail: "Enter Company Email",
            PleaseEnterCompanyEmail: "Please Enter Company Email",
            SelectOwnerName: "Select Owner Name",
            totalEmployees: "Total Employees",
            totalEmployeesInCall: "Total Employees In Call",
            SaveShortLogo: "Save Short Logo",
            LongLogo: "Long Logo",
            SaveLongLogo: "Save Long Logo",
            SaveFullLogo: "Save Full Logo",

        },
        billing: {
            Invoice: "Invoice",
            InvoiceNo: "Invoice No",
            CustomerName: "Customer Name",
            PayInvoice: "Pay Invoice",
            Payment: "Payment",
            BillNo: "Bill No",
            CardLast: "Card Last 4",
            BusinessName: "Business Name",
            CardHolder: "Card Holder",
            Viewinvoicedetails: "View invoice and payment details",
            Viewinvoicedetail: "View invoice detail",
            Qty: "Qty",
            BillType: "Bill Type"


        },
        PaymentCard: {
            paymentCard: "Payment Card",
            CardHolderName: "Card Holder Name",
            AddCard: "Add Card",
            PayInvoice: "Pay Invoice",
            CardHolderNameRequired: "Card Holder Name is Required.",
            PayCard: "Pay Card",
            payanotherway: "Or pay another way",
            credentialstocontinue: "Enter your credentials to continue.",
            Expiry: "Expiry",
            Brand: "Brand",
            Country: "Country",
            LastFourDigits: "Last Four Digits",

        },
    },
    billing: {
        BillType: "Тип счета",
        billingTypeEnum: {
            [BillingTypeEnum.NewUser]: "Новый пользователь",
            [BillingTypeEnum.MonthlySubscription]: "Ежемесячная подписка",
            [BillingTypeEnum.NewBusinessNumber]: "Новый бизнес-номер",
        }
    },
    businessNumber: {
        statusEnum: {
            [BuisnessNumberStatusEnum.Waiting]: "Ожидание",
            [BuisnessNumberStatusEnum.Declined]: "Отклонено",
            [BuisnessNumberStatusEnum.NumberCancelled]: "Номер отменен",
            [BuisnessNumberStatusEnum.Purchased]: "Приобретено",
            [BuisnessNumberStatusEnum.RequestCancelled]: "Запрос отменен",
            [BuisnessNumberStatusEnum.Rejected]: "Отвергнуто",
        },
    },
    appraisal: {
        Appraisals: "Оценки",
        createAppraisals: "Создать оценки",
        noDataMessage: "– Создайте оценки сейчас!",
        Approvers: "Утверждающие",
        Questions: "Вопросы",
        Tasks: "Задачи",
        Bonus: "Бонус",
        Increment: "Повышение",
        previousApraisals: "Предыдущие оценки",
        warning: "Предупреждение",
        rewards: "Награды",
        courses: "Курсы",
        Promotion: "Повышение",
        PleaseEnterBonusPercentage: "Введите процент бонуса",
        validateIncrement: "Не удалось обработать повышение: нет информации о зарплате сотрудника",
        PleaseEnterBonusAmount: "Введите сумму бонуса",
        PleaseEnterIncrementAmount: "Введите сумму повышения",
        RateAtleastOneItem: "Оцените хотя бы один элемент",
        Noemployeeselected: "Сотрудник не выбран",
        submitAppraisal: "Отправить оценки",
        Appraisedof: "Оценено",
        AppraisalApprovers: "Утверждающие оценки",
        AppraisalDetail: "Детали оценки",
        appraisalFilterTypeEnum: {
            [AppraisalFilterEnum.All]: "Все оценки",
            [AppraisalFilterEnum.CreatedByMe]: "Создано мной",
            [AppraisalFilterEnum.ForApproval]: "Для утверждения",
            [AppraisalFilterEnum.TeamAppraisal]: "Оценка команды",
            [AppraisalFilterEnum.MyApprovedAppraisal]: "Мои утвержденные оценки",
        },
        statusEnum: {
            [AppraisalStatusEnum.InProcess]: "В процессе",
            [AppraisalStatusEnum.Approved]: "Утверждено",
            [AppraisalStatusEnum.Declined]: "Отклонено",
            [AppraisalStatusEnum.Cancelled]: "Отменено",
            [AppraisalStatusEnum.Hold]: "На удержании",
        },
        SortEnum: {
            [AppraisalFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [AppraisalFilterSortEnum.CreateDateAsc]: "Дата создания",
            [AppraisalFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [AppraisalFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [AppraisalFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [AppraisalFilterSortEnum.ReferenceNo]: "Номер ссылки",
        }
    },
    loan: {
        loan: "Займ",
        createLoan: "Создать займ",
        loanTenure: "Срок займа (в месяцах)",
        enterLoanTenure: "Введите срок займа (в месяцах)",
        deduction: "Вычет/Месяцы",
        selectLoanPurpose: "Выберите цель займа",
        noDataMessage: "– Создавайте займы прямо сейчас!",
        SortBy: "Сортировать по",
        SelectSortBy: "Выберите сортировку по",
        InterestRate: "Процентная ставка",
        loanPurpose: {
            loanPurpose: "Цель займа",
            vehicle: "Транспорт",
            personal: "Личные",
            wedding: "Свадьба",
            medical: "Медицинские",
            education: "Образование",
            house: "Дом",
            other: "Другое",
        },
        loanPurposeEnumList: {
            [LoanPurposeEnum.Personal]: "Личные",
            [LoanPurposeEnum.Wedding]: "Свадьба",
            [LoanPurposeEnum.Medical]: "Медицинские",
            [LoanPurposeEnum.Education]: "Образование",
            [LoanPurposeEnum.House]: "Дом",
            [LoanPurposeEnum.Vehicle]: "Транспорт",
            [LoanPurposeEnum.Other]: "Другое",
        },
        loanFilterTypeEnum: {
            [LoanFilterTypeEnum.All]: "Все займы",
            [LoanFilterTypeEnum.ForApproval]: "Для утверждения",
            [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "Мои займы",
            [LoanFilterTypeEnum.LoanForMe]: "Займ для меня",
        },
        statusEnum: {
            [LoanStatusEnum.InProcess]: "В процессе",
            [LoanStatusEnum.Approved]: "Утверждено",
            [LoanStatusEnum.Declined]: "Отклонено",
            [LoanStatusEnum.Cancelled]: "Отменено",
            [LoanStatusEnum.Disbursed]: "Выдано",
            [LoanStatusEnum.PartialCleared]: "Частично погашено",
            [LoanStatusEnum.Completed]: "Завершено",
            [LoanStatusEnum.Hold]: "На удержании",
            [LoanStatusEnum.WaitingForDisburse]: "Ожидает выдачи",
        },
        loanTypeEnum: {
            [LoanTypeEnum.Company]: "Корпоративный займ",
            [LoanTypeEnum.WorkWise]: "Рабочий займ",
            [LoanTypeEnum.Both]: "Оба",
        },
        loanFilterSortEnum: {
            [LoanFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [LoanFilterSortEnum.CreateDateAsc]: "Дата создания",
            [LoanFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [LoanFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [LoanFilterSortEnum.ReferenceNo]: "Номер ссылки",
        },
    },
    quotations: {
        Quotation: "Котировка",
        CreateQuotation: "Создать котировку",
        quotationDate: "Дата",
        ClientName: "Имя клиента",
        ClientEmail: "Электронная почта клиента",
        PhoneNumber: "Номер телефона",
        TotalAmount: "Общая сумма",
        QuotationType: "Тип",
        Rate: "Тариф",
        Quantity: "Количество",
        Attachments: "Вложения",
        serviceItem: "Услуга/Товар",
        price: "Цена",
        tax: "Налог (%)",
        taxAmount: "Сумма налога",
        sno: "№ п/п",
        EnterClientName: "Введите имя клиента",
        clientsName: "Имя клиента",
        EnterClientEmail: "Введите электронную почту клиента",
        addressLine1: "Адрес, строка 1",
        addressLine2: "Адрес, строка 2",
        EnterAddress1: "Введите адрес 1:",
        EnterAddress2: "Введите адрес 2:",
        Product: "Продукт",
        Service: "Услуга",
        netAmount: "Чистая сумма",
        CODE: "КОД",
        DATE: "ДАТА",
        TOTAL: "ИТОГО",
        To: "Кому",
        SubTotal: "Промежуточный итог",
        noDataMessage: "– Создайте котировку сейчас!",

        quotationFilterTypeEnum: {
            [QuotationFilterEnum.All]: "Котировки",
            [QuotationFilterEnum.CreatedByMe]: "Создано мной",
            [QuotationFilterEnum.ForApproval]: "Для утверждения",
        },
        statusEnum: {
            [QuotationStatusEnum.InProcess]: "В процессе",
            [QuotationStatusEnum.Approved]: "Утверждено",
            [QuotationStatusEnum.Declined]: "Отклонено",
            [QuotationStatusEnum.Cancelled]: "Отменено",
            [QuotationStatusEnum.Hold]: "На удержании",
            [QuotationStatusEnum.ClientApproved]: "Одобрено клиентом",
            [QuotationStatusEnum.ClientRejected]: "Отклонено клиентом",
        },
        SortEnum: {
            [QuotationFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [QuotationFilterSortEnum.CreateDateAsc]: "Дата создания - по возрастанию",
            [QuotationFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [QuotationFilterSortEnum.UpdateDateAsc]: "Дата обновления - по возрастанию",
            [QuotationFilterSortEnum.ReferenceNo]: "Номер ссылки",
            [QuotationFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию"
        },
        ErrorFieldMessage:{
            [InputFieldTypes.PRICE]: "Цена должна быть больше 0",
            [InputFieldTypes.QUANTITY]: "Количество должно быть больше 0",
            [InputFieldTypes.TAX]: "Налог должен быть больше 0",
          },
          singleItemError: "Пожалуйста, добавьте хотя бы один товар в котировку",
    },
    payroll: {
        Payroll: "Зарплата",
        createPayroll: "Создать зарплату",
        noDataMessage: "– Создайте зарплату сейчас!",
        basicSalary: "Базовая зарплата",
        loan: "Займ",
        allowance: "Пособие",
        ExpenseReimbursement: "Возмещение расходов",
        deduction: "Удержание",
        tax: "Налог",
        rebate: "Скидка",
        bonus: "Бонус",
        selectMonthYear: "Выберите месяц и год",
        disburseDate: "Дата выплаты",
        Voucher: "Ваучер",
        DownloadtoExcel: "Скачать в Excel",
        PayrollDetail: "Детали зарплаты",
        TransactionMode: "Способ транзакции",
        IBANNumber: "Номер IBAN",

        statusEnum: {
            [PayrollStatusEnum.InProcess]: "В процессе",
            [PayrollStatusEnum.Approved]: "Утверждено",
            [PayrollStatusEnum.Declined]: "Отклонено",
            [PayrollStatusEnum.Resend]: "Отправить снова",
            [PayrollStatusEnum.Inactive]: "Неактивно",
            [PayrollStatusEnum.NotRequired]: "Не требуется",
            [PayrollStatusEnum.Cancelled]: "Отменено",
            [PayrollStatusEnum.Hold]: "На удержании",
            [PayrollStatusEnum.Disbursed]: "Выдано",
            [PayrollStatusEnum.Completed]: "Завершено",
        },
        payrollFilterTypeEnum: {
            [PayrollFilterTypeEnum.All]: "Зарплата",
            [PayrollFilterTypeEnum.CreatedByMe]: "Создано мной",
            [PayrollFilterTypeEnum.ForApproval]: "Для утверждения",
        },
    },
    voucher: {
        createVoucher: "Создать ваучер",
        SelectVoucherType: "Выберите тип ваучера",
        BasicSalary: "Базовая зарплата",
        LoanPayment: "Платеж по займу",
        LoanDispersable: "Выдача займа",
        Allowance: "Пособие",
        ExpenseReimbursement: "Возмещение расходов",
        Deduction: "Удержание",
        VoucherDetail: "Детали ваучера",
        SavePrint: "Сохранить и распечатать",
        Bonus: "Бонус",
        Rebate: "Скидка",
        Tax: "Налог",
        ChequeNo: "Номер чека",
        Account: "Счет",
        Narration: "Описание",
        Debit: "Дебет",
        Credit: "Кредит",
        Dr: "Дб",
        Cr: "Кр",
        differences: "Разницы",
        drCr: "Дб/Кр",
        LinkVoucher: "Связать ваучер",
        PostVoucher: "Опубликовать ваучер",
        voucherDate: "Дата ваучера",
        VoucherType: "Тип ваучера",

        voucherTypeList: {
            [voucherTypeEnum.GeneralVoucher]: "Общий ваучер",
            [voucherTypeEnum.PaymentVoucher]: "Платежный ваучер",
            [voucherTypeEnum.ReceiptVoucher]: "Кассовый ваучер",
        },
        accountTypeList: {
            [accountTypeEnum.Asset]: "Актив",
            [accountTypeEnum.Capital]: "Капитал",
            [accountTypeEnum.Expense]: "Расход",
            [accountTypeEnum.Liability]: "Обязательство",
            [accountTypeEnum.Revenue]: "Доход",
        }
    },
    trialBalance: {
        accountTypeList: {
            [AccountTypeEnum.Asset]: "Актив",
            [AccountTypeEnum.Capital]: "Капитал",
            [AccountTypeEnum.Expense]: "Расход",
            [AccountTypeEnum.Liability]: "Обязательство",
            [AccountTypeEnum.Revenue]: "Доход",
        }
    },
    businessApproval: {
        statusTypeList: {
            [ApprovalStatusTypeEnum.InProcess]: "В процессе",
            [ApprovalStatusTypeEnum.Approved]: "Утверждено",
            [ApprovalStatusTypeEnum.Declined]: "Отклонено",
            [ApprovalStatusTypeEnum.Cancelled]: "Отменено",
            [ApprovalStatusTypeEnum.Completed]: "Завершено",
        },
        approvalModuleList : {
            [ApprovalsModuleEnum.ExpenseApproval]: "Расходы",
            [ApprovalsModuleEnum.ExpenseExecutor]: "Исполнитель расходов",
            [ApprovalsModuleEnum.ExpenseFinance]: "Финансы расходов",
            [ApprovalsModuleEnum.LeaveApproval]: "Отпуск",
            [ApprovalsModuleEnum.TravelApproval]: "Поездка",
            [ApprovalsModuleEnum.TravelAgent]: "Туристический агент",
            [ApprovalsModuleEnum.WarningApproval]: "Предупреждение",
            [ApprovalsModuleEnum.UserEducation]: "Образование",
            [ApprovalsModuleEnum.UserWorkExperience]: "Опыт работы",
            [ApprovalsModuleEnum.ComplainApproval]: "Жалоба",
            [ApprovalsModuleEnum.RewardApproval]: "Награда",
            [ApprovalsModuleEnum.BonusApproval]: "Бонус",
            [ApprovalsModuleEnum.PromotionApproval]: "Повышение",
            [ApprovalsModuleEnum.RequestForItemApproval]: "Запрос на предмет",
            [ApprovalsModuleEnum.LoanApproval]: "Заем",
            [ApprovalsModuleEnum.CustomApproval]: "Индивидуальный",
            [ApprovalsModuleEnum.FormApproval]: "Форма",
            [ApprovalsModuleEnum.DocumentApproval]: "Документ",
            [ApprovalsModuleEnum.SalaryApproval]: "Заработная плата",
            [ApprovalsModuleEnum.SignupApproval]: "Регистрация",
            [ApprovalsModuleEnum.PayrollApproval]: "Заработная ведомость",
            [ApprovalsModuleEnum.CareerApproval]: "Карьера",
            [ApprovalsModuleEnum.RequisitionApproval]: "Запрос на покупку",
            [ApprovalsModuleEnum.RequisitionFinalApproval]: "Окончательное одобрение запроса",
            [ApprovalsModuleEnum.RequestForItemAssetControllerApproval]: "Запрос на предмет (Контролер активов)",
            [ApprovalsModuleEnum.ItemApproval]: "Предмет",
            [ApprovalsModuleEnum.AssetApproval]: "Актив",
            [ApprovalsModuleEnum.ResignationAdminApproval]: "Увольнение (Администратор)",
            [ApprovalsModuleEnum.ResignationExitApproval]: "Увольнение (Выход)",
            [ApprovalsModuleEnum.ResignationFinanceApproval]: "Увольнение (Финансы)",
            [ApprovalsModuleEnum.ResignationHrApproval]: "Увольнение (Кадры)",
            [ApprovalsModuleEnum.ResignationItApproval]: "Увольнение (ИТ)",
            [ApprovalsModuleEnum.ResignationOtherApproval]: "Увольнение (Другое)",
            [ApprovalsModuleEnum.ResignationReportingToApproval]: "Увольнение (Отчетность)",
            [ApprovalsModuleEnum.QuotationApproval]: "Коммерческое предложение",
            [ApprovalsModuleEnum.QuotationClientApproval]: "Коммерческое предложение (Клиент)",
            [ApprovalsModuleEnum.AppraisalApproval]: "Оценка",
            [ApprovalsModuleEnum.AuctionApproval]: "Аукцион"
        }
        
    },
    disperse: {
        transactionModeEnumList: {
            [TransactionModeTypeEnum.Cash]: "Наличные",
            [TransactionModeTypeEnum.BankTransfer]: "Банковский перевод",
            [TransactionModeTypeEnum.Cheque]: "Чек",
        }
    },
    promotion: {
        CreatePromotion: "Создать продвижение",
        Promotion: "Продвижение",
        promotionTo: "Повышение до",
        noDataMessage: "Создайте продвижение сейчас!",
        selectPromotionTo: "Выберите повышение до",
        pleseSelectPromotionTo: "Пожалуйста, выберите повышение до",
        createPromotion: "Создать продвижение",
        promotionFilterTypeEnum: {
            [PromotionFilterTypeEnum.All]: "Все продвижения",
            [PromotionFilterTypeEnum.CreatedByMe]: "Создано мной",
            [PromotionFilterTypeEnum.ForApproval]: "Для утверждения",
            [PromotionFilterTypeEnum.PromotionForMe]: "Мои повышения",
        },
        statusEnum: {
            [PromotionStatusEnum.InProcess]: "В процессе",
            [PromotionStatusEnum.Approved]: "Утверждено",
            [PromotionStatusEnum.Declined]: "Отклонено",
            [PromotionStatusEnum.Cancelled]: "Отменено",
            [PromotionStatusEnum.Hold]: "На удержании",
        },
        SortEnum: {
            [PromotionFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [PromotionFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [PromotionFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [PromotionFilterSortEnum.ReferenceNo]: "Номер ссылки",
            [PromotionFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [PromotionFilterSortEnum.CreateDateAsc]: "Дата создания",
        }
    },
    complains: {
        createComplain: "Создать жалобу",
        myComplain: "Мои жалобы",
        AllComplains: "Все жалобы",
        complainOf: "Жалоба от",
        ComplainDetail: "Детали жалобы",
        ComplainToMe: "Жалоба ко мне",
        ComplainAboutMe: "Жалоба на меня",
        noDataMessage: " – Создайте жалобы сейчас!",
        statusEnum: {
            [ComplainApprovalStatusEnum.InProcess]: "В процессе",
            [ComplainApprovalStatusEnum.Approved]: "Одобрено",
            [ComplainApprovalStatusEnum.Declined]: "Отклонено",
            [ComplainApprovalStatusEnum.Resend]: "Отправить повторно",
            [ComplainApprovalStatusEnum.Inactive]: "Неактивно",
            [ComplainApprovalStatusEnum.NotRequired]: "Не требуется",
            [ComplainApprovalStatusEnum.Cancelled]: "Отменено",
            [ComplainApprovalStatusEnum.Hold]: "На удержании",
        },
        SortEnum: {
            [ComplainFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [ComplainFilterSortEnum.CreateDateAsc]: "Дата создания",
            [ComplainFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [ComplainFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [ComplainFilterSortEnum.ReferenceNo]: "Номер ссылки",
            [ComplainFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию"
        },
    },
    salary: {
        SalaryMembers: "Члены зарплатного фонда",
        EffectiveDate: "Дата вступления в силу",
        BasicSalary: "Основная зарплата",
        Allowances: "Доплаты",
        Allowance: "Доплата",
        AllowanceDetail: "Детали доплаты",
        IsTaxable: "Облагается налогом",
        Deduction: "Вычет",
        GrossSalary: "Валовая зарплата",
        CreateSalary: "Создать зарплату",
        SalaryDetail: "Детали зарплаты",
        AddSalary: "Добавить зарплату",
        Increment: "Прибавка",
        Deductions: "Вычеты",
        AllowanceType: "Тип доплаты",
        EffectiveDateIsRequired: "Дата вступления в силу обязательна",
        BasicSalaryIsRequired: "Основная зарплата обязательна",
        Negativesalaryisnotallowed: "Отрицательная зарплата не допускается",
        Benefit: "Пособие",
        noDataMessage: "– Создайте зарплату сейчас!",
        EnterIncrementPercentage: "Пожалуйста, введите процент увеличения",
        salaryFilterTypeEnum: {
            [SalaryFilterTypeEnum.Salaries]: "Зарплаты",
            [SalaryFilterTypeEnum.CreateByMe]: "Созданные мной",
            [SalaryFilterTypeEnum.ForApproval]: "На утверждение",
        },
        statusEnum: {
            [EmployeeSalaryStatusEnum.InProcess]: "В процессе",
            [EmployeeSalaryStatusEnum.Approved]: "Одобрено",
            [EmployeeSalaryStatusEnum.Declined]: "Отклонено",
            [EmployeeSalaryStatusEnum.Cancelled]: "Отменено",
            [EmployeeSalaryStatusEnum.Hold]: "На удержании",
            [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "Ожидание утверждения от других",
        },
        SortEnum: {
            [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "Дата создания",
            [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [EmployeeSalaryFilterSortEnum.ReferenceNo]: "Номер ссылки",
        }
    },
    resignations: {
        resignationHeader: "Отставки",
        resignationDate: "Дата отставки",
        createresignation: "Создать отставку",
        noDataMessage: "– Создайте отставку сейчас!",
        reasonforresignation: "Причина отставки",
        istermination: "Является увольнением",
        resignationDetail: "Детали отставки",
        statusEnum: {
            [ResignationApprovalStatusEnum.InProcess]: "В процессе",
            [ResignationApprovalStatusEnum.Approved]: "Одобрено",
            [ResignationApprovalStatusEnum.Declined]: "Отклонено",
            [ResignationApprovalStatusEnum.Cancelled]: "Отменено",
            [ResignationApprovalStatusEnum.Completed]: "Завершено",
            [ResignationApprovalStatusEnum.Hold]: "На удержании",
        },
        resignationFilterTypeEnum: {
            [ResignationFilterTypeEnum.Resignation]: "Отставка",
            [ResignationFilterTypeEnum.CreateByMe]: "Созданные мной",
            [ResignationFilterTypeEnum.ForApproval]: "На утверждение",
        },
        resignationTypeEnum: {
            [ResignationTypeEnum.Resignation]: "Отставка",
            [ResignationTypeEnum.Termination]: "Увольнение",
            [ResignationTypeEnum.Both]: "Оба",
        },
        SortEnum: {
            [ResignationFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [ResignationFilterSortEnum.CreateDateAsc]: "Дата создания",
            [ResignationFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [ResignationFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [ResignationFilterSortEnum.ReferenceNoDesc]: "Номер ссылки - по убыванию",
            [ResignationFilterSortEnum.ReferenceNo]: "Номер ссылки",
        },
        ResignationPurposeEnum: {
            [ResignationPurposeEnum.InsufficientWorkLifeBalance]: "Недостаточный баланс работы и личной жизни",
            [ResignationPurposeEnum.UnrealisticGoalsAndPerformanceObjectives]: "Нереалистичные цели и задачи",
            [ResignationPurposeEnum.LackOfAClearPathForCareerAdvancement]: "Отсутствие четкого пути для карьерного роста",
            [ResignationPurposeEnum.FeelUnsupportedByManage]: "Чувство отсутствия поддержки со стороны руководства",
            [ResignationPurposeEnum.DontFeelChallenged]: "Не чувствую вызова",
            [ResignationPurposeEnum.TimeOffAndFlexibility]: "Отпуск и гибкость",
            [ResignationPurposeEnum.Relocation]: "Переезд",
            [ResignationPurposeEnum.Others]: "Другие",
        },
    },
    Employees: {
        employeeAttachments: "Вложения сотрудника",
        CreateEmployeeNow: "Создать сотрудника сейчас",
        ProbationPeriod: "Испытательный срок (дни)",
        requiredMessageProbation: "Пожалуйста, введите свой испытательный срок",
        requiredMessageProbationPeriod: "Испытательный срок должен быть от 0 до 30",
        EmployeeNo: "Табельный номер",
        EnterEmployeeNo: "Введите табельный номер",
        EmploymentType: "Тип занятости",
        SelectEmploymentType: "Выберите тип занятости",
        requiredMessageEmploymentType: "Пожалуйста, выберите тип занятости",
        OfficeShift: "Смена в офисе",
        requiredMessageOfficeShift: "Пожалуйста, выберите смену в офисе",
        SelectOfficeShift: "Выберите смену в офисе",
        UpdateBasicInfo: "Обновить основную информацию",
        Degree: "Степень",
        EnterDegree: "Введите степень",
        requiredDegree: "Пожалуйста, введите степень",
        Institute: "Институт",
        EnterInstituteName: "Введите название института",
        requiredMessageInstitute: "Пожалуйста, введите название института",
        ObtainedMarks: "Полученные баллы",
        requiredMessageObtained: "Пожалуйста, введите полученные баллы",
        EnterObtainedMarks: "Введите полученные баллы",
        TotalMarks: "Общее количество баллов",
        EnterTotalMarks: "Введите общее количество баллов",
        requiredMessageMarks: "Пожалуйста, введите общее количество баллов",
        UpdateEducation: "Обновить образование",
        AddEducation: "Добавить образование",
        WorkExperienceInfo: "Информация об опыте работы",
        UpdateExperience: "Обновить опыт работы",
        AddExperience: "Добавить опыт работы",
        EmergencyInfo: "Информация о чрезвычайной ситуации",
        UpdateEmergencyForm: "Обновить форму чрезвычайной ситуации",
        AddEmergency: "Добавить чрезвычайную ситуацию",
        UpdateEmergency: "Обновить чрезвычайную ситуацию",
        UpdateBank: "Обновить банковские данные",
        AddBank: "Добавить банковские данные",
        EnableEmployee: "Вы уверены, что хотите включить этого сотрудника?",
        DisableEmployee: "Вы уверены, что хотите отключить этого сотрудника?",
        Enable: "Включить",
        Disabled: "Отключено",
        UpdateFamily: "Обновить семью",
        AddFamily: "Добавить семью",
        AddEmployeesLinkage: "Добавить связь сотрудников",
        AllEmployees: "Все сотрудники",
        FamilyInfo: "Информация о семье",
        Basic_Info: "Основная информация",
        Email_Configuration: "Конфигурация электронной почты",
        Bank_Detail: "Банковские данные",
        Education: "Образование",
        Emergency_Info: "Информация о чрезвычайной ситуации",
        Work_Experience: "Опыт работы",
        Experience: "Опыт",
        Rebate: "Скидка",
        Salary: "Зарплата",
        Leaves: "Отпуска",
        Devices: "Устройства",
        basicInfo: "Основная информация",
        Appraisal: "Оценка",
        Family: "Семья",
        Attachments: "Вложения",
        My_Team: "Моя команда",
        Employees_Linkage: "Связь сотрудников",
        Company_Goal: "Цель компании",
        Individual_Goal: "Индивидуальная цель",
        Emails: "Электронные письма",
        Finance: "Финансы",
        Goal: "Цели",
        RebateInfo: "Информация о скидках",
        UpdateRebate: "Обновить скидку",
        AddRebate: "Добавить скидку",
        AddSalary: "Добавить зарплату",
        AddEmailConfiguration: "Добавить конфигурацию электронной почты",
        AddBank: "Добавить банковские данные",
        Statistics: "Статистика",
    },
    leaves: {
        leave: "Отпуск",
        leavedetail: "Детали отпуска",
        Leave: "Тип отпуска",
        leaveType: "Тип отпуска",
        enterLeave: "Введите тип отпуска",
        save: "Сохранить тип отпуска",
        Add: "Добавить тип отпуска",
        allotLeaves: "Выделенные отпуска",
        balanceLeaves: "Типы отпусков (оставшиеся отпуска)",
        leaveFor: "Отпуск для",
        leaveDates: "Даты отпуска",
        createleave: "Создать отпуск",
        messages: "Пожалуйста, выберите тип отпуска",
        allocatedLeaves: "Выделенные отпуска",
        availedLeaves: "Использованные отпуска",
        remainingLeaves: "Оставшиеся отпуска",
        noDataMessage: " – Создать отпуск сейчас!",
        Leavesinfo: "Информация об отпуске",
        UpdateLeave: "Обновить отпуск",
        leavesFilterTypeEnum: {
            [LeaveFilterTypeEnum.All]: "Все отпуска",
            [LeaveFilterTypeEnum.CreatedByMe]: "Созданные мной",
            [LeaveFilterTypeEnum.ForApproval]: "На утверждение",
            [LeaveFilterTypeEnum.LeaveForMe]: "Мои отпуска",
        },
        statusEnum: {
            [LeaveStatusEum.InProcess]: "В процессе",
            [LeaveStatusEum.Approved]: "Утвержден",
            [LeaveStatusEum.Declined]: "Отклонен",
            [LeaveStatusEum.Resend]: "Отправить повторно",
            [LeaveStatusEum.InActive]: "Неактивен",
            [LeaveStatusEum.NotRequired]: "Не требуется",
            [LeaveStatusEum.Cancelled]: "Отменен",
            [LeaveStatusEum.Hold]: "В ожидании",
        },
        SortEnum: {
            [LeaveFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [LeaveFilterSortEnum.CreateDateAsc]: "Дата создания",
            [LeaveFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [LeaveFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [LeaveFilterSortEnum.ReferenceNoDesc]: "Номер справки - по убыванию",
            [LeaveFilterSortEnum.ReferenceNo]: "Номер справки",
        }
    },
    bonus: {
        Bonus: "Бонус",
        createBonus: "Создать бонус",
        BonusTo: "Бонус для",
        CurrentSalary: "Текущая зарплата",
        noDataMessage: "– Создать бонус сейчас!",
        PercentageShouldNotExceed: "Процент не должен превышать 100",
        bonusFilterTypeEnum: {
            [BonusFilterTypeEnum.All]: "Все бонусы",
            [BonusFilterTypeEnum.CreatedByMe]: "Созданные мной",
            [BonusFilterTypeEnum.ForApproval]: "На утверждение",
            [BonusFilterTypeEnum.MyBonus]: "Мой бонус",
        },
        statusEnum: {
            [BonusStatusEnum.InProcess]: "В процессе",
            [BonusStatusEnum.Approved]: "Утвержден",
            [BonusStatusEnum.Declined]: "Отклонен",
            [BonusStatusEnum.Cancelled]: "Отменен",
            [BonusStatusEnum.Inactive]: "Неактивен",
            [BonusStatusEnum.NotRequired]: "Не требуется",
            [BonusStatusEnum.Resend]: "Отправить повторно",
            [BonusStatusEnum.WaitingForOtherApproval]: "Ожидание утверждения других",
            [BonusStatusEnum.Hold]: "В ожидании",
            [BonusStatusEnum.Disbursed]: "Выдан",
            [BonusStatusEnum.Completed]: "Завершен",
        },
        SortEnum: {
            [BonusFilterSortEnum.CreateDateDesc]: "Дата создания - по убыванию",
            [BonusFilterSortEnum.CreateDateAsc]: "Дата создания",
            [BonusFilterSortEnum.UpdateDateDesc]: "Дата обновления - по убыванию",
            [BonusFilterSortEnum.UpdateDateAsc]: "Дата обновления",
            [BonusFilterSortEnum.ReferenceNoDesc]: "Номер справки - по убыванию",
            [BonusFilterSortEnum.ReferenceNo]: "Номер справки",
            [BonusFilterSortEnum.Amount]: "Сумма",
            [BonusFilterSortEnum.AmountDesc]: "Сумма - по убыванию",
            [BonusFilterSortEnum.Salary]: "Зарплата",
            [BonusFilterSortEnum.SalaryDesc]: "Зарплата - по убыванию"
        },


    },
    chartOfAccount: {
        ChartOfAccount: "План счетов",
        CreateAccount: "Создать счет",
        CreateChartOfAccount: "Создать план счетов",
        accountType: "Тип счета",
        selectType: "Выберите тип",
        parentAccount: "Родительский счет",
        selectParent: "Выберите родительский счет",
        EnterAccountType: "Пожалуйста, введите тип счета",
        clossingBalance: "Закрывающий баланс",
    },
    ledgerReports: {
        reports: "Отчеты",
        selectAccount: "Выберите счет",
        selectDate: "Выберите дату",
        generateReport: "Сгенерировать отчет",
        VoucherNo: "Номер квитанции",
    },
    requisitions: {
        requisition: "Заявки",
        MyRequisitions: "Мои заявки",
        createRequisition: "Создать заявку",
        PleaseEnterRequisitionName: "Введите название заявки",
        EnterRequisitionName: "Введите название заявки",
        Budget: "Бюджет",
        EnterBudget: "Введите бюджет",
        Dateofrequestexpire: "Дата истечения срока запроса",
        noDataMessage: "– Создайте заявки сейчас!",
    },

    DefaultApprovers: {
        TypeEnum: {
            [DefaultApprovalTypeEnum.Expense]: "Расход",
            [DefaultApprovalTypeEnum.ExpenseFinancers]: "Финансисты расходов",
            [DefaultApprovalTypeEnum.Travel]: "Путешествие",
            [DefaultApprovalTypeEnum.TravelAgent]: "Туристический агент",
            [DefaultApprovalTypeEnum.Loan]: "Займ",
            [DefaultApprovalTypeEnum.Leave]: "Отпуск",
            [DefaultApprovalTypeEnum.Asset]: "Актив",
            [DefaultApprovalTypeEnum.Salary]: "Зарплата",
            [DefaultApprovalTypeEnum.Payroll]: "Расчетный листок",
            [DefaultApprovalTypeEnum.Reward]: "Награда",
            [DefaultApprovalTypeEnum.ResignationHr]: "Уход HR",
            [DefaultApprovalTypeEnum.ResignationAdmin]: "Уход администратора",
            [DefaultApprovalTypeEnum.ResignationIt]: "Уход IT",
            [DefaultApprovalTypeEnum.ResignationFinance]: "Уход финансов",
            [DefaultApprovalTypeEnum.ResignationExit]: "Выход",
            [DefaultApprovalTypeEnum.Requisition]: "Запрос",
            [DefaultApprovalTypeEnum.CustomApproval]: "Пользовательское утверждение",
            [DefaultApprovalTypeEnum.Forms]: "Формы",
            [DefaultApprovalTypeEnum.Appraisals]: "Оценки",
            [DefaultApprovalTypeEnum.Promotion]: "Повышение",
            [DefaultApprovalTypeEnum.Warning]: "Предупреждение",
            [DefaultApprovalTypeEnum.Bonus]: "Бонус",
            [DefaultApprovalTypeEnum.Complains]: "Жалобы",
            [DefaultApprovalTypeEnum.Career]: "Карьера",
            [DefaultApprovalTypeEnum.Quotations]: "Котировки",
            [DefaultApprovalTypeEnum.RequisitionFinal]: "Запрос на окончательное утверждение",
            [DefaultApprovalTypeEnum.Attendance]: "Посещаемость",
            [DefaultApprovalTypeEnum.Auction]: "Аукцион",
        },
    },
    NonMandatoryApprovers: {
        TypeEnum: {
            [NonMandatoryApprovalTypeEnum.Expense]: "Расход",
            [NonMandatoryApprovalTypeEnum.Travel]: "Путешествие",
            [NonMandatoryApprovalTypeEnum.Loan]: "Займ",
            [NonMandatoryApprovalTypeEnum.Leave]: "Отпуск",
            [NonMandatoryApprovalTypeEnum.Asset]: "Актив",
            [NonMandatoryApprovalTypeEnum.Salary]: "Зарплата",
            [NonMandatoryApprovalTypeEnum.Payroll]: "Расчетный листок",
            [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "Финансисты расходов",
            [NonMandatoryApprovalTypeEnum.TravelAgent]: "Туристический агент",
            [NonMandatoryApprovalTypeEnum.Reward]: "Награда",
            [NonMandatoryApprovalTypeEnum.ResignationHr]: "Уход HR",
            [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "Уход администратора",
            [NonMandatoryApprovalTypeEnum.ResignationIt]: "Уход IT",
            [NonMandatoryApprovalTypeEnum.ResignationFinance]: "Уход финансов",
            [NonMandatoryApprovalTypeEnum.ResignationExit]: "Выход",
            [NonMandatoryApprovalTypeEnum.Requisition]: "Запрос",
            [NonMandatoryApprovalTypeEnum.Warning]: "Предупреждение",
            [NonMandatoryApprovalTypeEnum.Complain]: "Жалоба",
            [NonMandatoryApprovalTypeEnum.Bonus]: "Бонус",
            [NonMandatoryApprovalTypeEnum.Promotion]: "Повышение",
            [NonMandatoryApprovalTypeEnum.RequestForItem]: "Запрос на товар",
            [NonMandatoryApprovalTypeEnum.CustomApproval]: "Пользовательское утверждение",
            [NonMandatoryApprovalTypeEnum.Form]: "Форма",
            [NonMandatoryApprovalTypeEnum.Document]: "Документ",
            [NonMandatoryApprovalTypeEnum.Career]: "Карьера",
            [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "Окончательное утверждение запроса",
            [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "Запрос на товар контролера активов",
            [NonMandatoryApprovalTypeEnum.Quotation]: "Котировка",
            [NonMandatoryApprovalTypeEnum.QuotationClient]: "Котировка клиента",
            [NonMandatoryApprovalTypeEnum.Appraisal]: "Оценка",
            [NonMandatoryApprovalTypeEnum.Attendance]: "Посещаемость",
            [NonMandatoryApprovalTypeEnum.Auction]: "Аукцион",
        }
    },
    activityLog: {
        DeviceType: "Тип устройства",
        DeviceToken: "Токен устройства",
        OSVersion: "Версия ОС",
        Device: "Устройство",
        IP: "IP",
        Comment: "Комментарий",
        DeviceName: "Название устройства",
        LoginDate: "Дата входа в систему",
    },
    ErrorMessage: {
        someThingWentWrong: "Что-то пошло не так",
    },
    setting: {
        BasicInformation: "Основная информация",
        ChangePassword: "Изменить пароль",
        Notifications: "Уведомления",
        Mobile: "Мобильный #",
        About: "О нас",
        ExistingPassword: "Текущий пароль",
        EnterExistingPassword: "Введите текущий пароль",
        NewPassword: "Новый пароль",
        EnterNewPassword: "Введите новый пароль",
        ConfirmPassword: "Подтвердите пароль",
        EnterConfirmPassword: "Введите подтверждение пароля",
        Signature: "Подпись документа",
        NoSignature: "Пожалуйста, создайте подпись",
        ErrorSignature: "Ошибка при создании подписи",
        NoSignatureSave: "Нет подписи для сохранения",
        SignatureSaved: "Подпись сохранена",
        SaveSignature: "Сохранить подпись",
        ClearSignature: "Очистить подпись",
        SignatureCreated: "Подпись создана",
        SignatureActive: "Активная",
        FailedSignature: "Не удалось получить подписи",
        BlockNotificationFeatureTypeEnum: {
            [NotificationBlockFeatureTypeEnum.Reward]: "Награда",
            [NotificationBlockFeatureTypeEnum.Feed]: "Лента",
            [NotificationBlockFeatureTypeEnum.Document]: "Документ",
            [NotificationBlockFeatureTypeEnum.Project]: "Проект",
            [NotificationBlockFeatureTypeEnum.Task]: "Задача",
            [NotificationBlockFeatureTypeEnum.WorkBoard]: "Рабочая доска",
            [NotificationBlockFeatureTypeEnum.Schedule]: "Расписание",
            [NotificationBlockFeatureTypeEnum.Group]: "Группа",
            [NotificationBlockFeatureTypeEnum.Messenger]: "Мессенджер",
            [NotificationBlockFeatureTypeEnum.Expense]: "Расход",
            [NotificationBlockFeatureTypeEnum.Auction]: "Аукцион",
            [NotificationBlockFeatureTypeEnum.Leave]: "Отпуск",
            [NotificationBlockFeatureTypeEnum.Travel]: "Путешествие",
            [NotificationBlockFeatureTypeEnum.Warning]: "Предупреждение",
            [NotificationBlockFeatureTypeEnum.Complain]: "Жалоба",
            [NotificationBlockFeatureTypeEnum.Bonus]: "Бонус",
            [NotificationBlockFeatureTypeEnum.Promotion]: "Повышение",
            [NotificationBlockFeatureTypeEnum.Loan]: "Займ",
            [NotificationBlockFeatureTypeEnum.CustomApproval]: "Пользовательское утверждение",
            [NotificationBlockFeatureTypeEnum.Form]: "Форма",
            [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "Зарплата сотрудника",
            [NotificationBlockFeatureTypeEnum.Payroll]: "Расчетный листок",
            [NotificationBlockFeatureTypeEnum.Career]: "Карьера",
            [NotificationBlockFeatureTypeEnum.Requisition]: "Запрос",
            [NotificationBlockFeatureTypeEnum.Resignation]: "Уход",
            [NotificationBlockFeatureTypeEnum.Quotation]: "Котировка",
            [NotificationBlockFeatureTypeEnum.Appraisal]: "Оценка",
            [NotificationBlockFeatureTypeEnum.LeadManager]: "Менеджер лидов",
            [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "Доска обсуждений",
            [NotificationBlockFeatureTypeEnum.Pages]: "Страницы",
        }
    },
    emailConfiguration: {
        emailConfiHeader: "Конфигурация электронной почты пользователя",
        emailConfi: "Имя пользователя",
        password: "Пароль",
        enterEmailConfi: "Введите имя пользователя",
        incomingPort: "Входящий порт",
        enterIncomingPort: "Введите входящий порт",
        incomingServerAddress: "Адрес входящего сервера",
        enterIncomingServerAddress: "Введите адрес входящего сервера",
        outgoingPort: "Исходящий порт",
        enterOutgoingPort: "Введите исходящий порт",
        outgoingServerAddress: "Адрес исходящего сервера",
        enterOutgoingServerAddress: "Введите адрес исходящего сервера",
        provider: "Провайдер",
        enterProvider: "Введите провайдера",
        saveEmailConfi: "Сохранить конфигурацию электронной почты",
        addEmailConfi: "Добавить конфигурацию электронной почты",
        name: "Имя",
        clear: "Очистить"
    },
    assetsAllocation: {
        assetsAllocation: "Распределение активов",
        assests: "Активы",
        createAssetAllocation: "Создать распределение активов",
        handover: "Передача",
        forMaintenance: "На обслуживание",
        noResultFound: "Ничего не найдено...",
        addAssetsAllocation: "Добавить распределение активов",
        noDataMessage: "– Создайте распределение активов сейчас!",
        allocationForMe: "Мое распределение",
        allocationApprovals: "Утверждение распределения",
        deAllocation: "Изменить статус распределения",
        liability: "Обязательства",
        capital: "Капитал",
        revenue: "Доходы",
        expense: "Расходы",
        costOfGoodsale: "Себестоимость продажи",
        assetsCategory: "Категория активов",
        parentAccount: "Родительский счет",
        selectParent: "Выбрать родителя",
        assetsList: "Список активов",
        createAssests: 'Создать активы',
        sno: '№',
        inventoryName: 'Наименование инвентаря',
        inventoryValue: 'Стоимость инвентаря',
        SelectAssetsController: "Выбрать контроллера активов",
    },
    requestListItems: {
        noDataMessage: "– Создайте заявку сейчас!",
        requestForItems: "Запрос на предметы",
        RequestForItemsApprovals: "Утверждение запроса на предметы",
        AssetController: "Контроллер активов",
        RequestItem: "Запросить предмет",
    },
    profile: {
        firstMenu: '  Пункт меню 1',
        secondMenu: ' Пункт меню 2 (отключено)',
        thirdMenu: ' Пункт меню 3 (отключено)',
        dangerItem: 'Опасный элемент',
        NoPosition: "Нет позиции",
        NoInstitute: "Нет института",
        NotFound: "Не найдено",
        University: "Университет",
        School: "Школа",
        Graduated: "Окончил",
        CurrentlyWorkHere: "Я работаю здесь",
        Statistics: "Статистика",
        StorageStatistics: "Статистика хранилища",
        TotalStorageUsed: "Всего использовано хранилища",

    },

    /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
    status: {
        InProcess: "В процессе",
        Approved: "Одобрено",
        Declined: "Отклонено",
        Resend: "Повторная отправка",
        Inactive: "Неактивно",
        NotRequired: "Не требуется",
        Cancelled: "Отменено",
        ApprovalRequired: "Требуется одобрение",
        Hold: "На удержании",
        WaitingForOtherApprover: "Ожидание других утверждающих",
        Draft: "Черновик",
        Remark: "Замечание",
        Removed: "Удалено"
    },
    approvalDictionaryLabel: {
        Approval: "Одобрение",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Исполнитель",
        [ApprovalsModuleEnum.ExpenseFinance]: "Финансы",
        [ApprovalsModuleEnum.ResignationHrApproval]: "Кадры",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Финансы",
        [ApprovalsModuleEnum.ResignationItApproval]: "ИТ",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Администрация",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Прочие одобрения",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Выходное интервью",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Отчетность перед",
        [ApprovalsModuleEnum.TravelApproval]: "Утверждающие",
        [ApprovalsModuleEnum.TravelAgent]: "Агент",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Конечные утверждающие",
        [ApprovalsModuleEnum.LoanApproval]: "Утверждающие",
        [ApprovalsModuleEnum.AuctionApproval]: "Утверждающий"
    },
    approvalDictionaryPlaceHolder: {
        Approval: "Одобрение",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Выберите исполнителя расходов",
        [ApprovalsModuleEnum.ExpenseFinance]: "Выберите утверждающих из финансового отдела",
        [ApprovalsModuleEnum.ExpenseApproval]: "Выберите утверждающих расходы",
        [ApprovalsModuleEnum.ResignationHrApproval]: "Выберите кадровика",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Выберите утверждающих из финансового отдела",
        [ApprovalsModuleEnum.ResignationItApproval]: "Выберите утверждающих из ИТ отдела",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Выберите утверждающих из административного отдела",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Выберите прочие одобрения",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Выберите утверждающих для интервью по выходу",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Выберите утверждающих для отчетности",
        [ApprovalsModuleEnum.TravelApproval]: "Выберите утверждающих для поездки",
        [ApprovalsModuleEnum.TravelAgent]: "Выберите агента для организации поездки",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Выберите конечных утверждающих",
        [ApprovalsModuleEnum.LoanApproval]: "Выберите утверждающих",
        [ApprovalsModuleEnum.AuctionApproval]: "Выберите утверждающих"
    },
    /*** Define language write side ***/
    Direction: "ltr",
};

export default russian;