import { createSlice } from "@reduxjs/toolkit";
import { businessStorageDashboardAction, employeeDashboardAction } from "./action";

const initialState = {
  userCountData: {},
  storageCountData:{}
};

const DashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(employeeDashboardAction.fulfilled, (state, action) => {
        state.userCountData = action.payload;
      })
      .addCase(employeeDashboardAction.pending, (state, action) => {})
      .addCase(employeeDashboardAction.rejected, (state, action) => {})
      .addCase(businessStorageDashboardAction.fulfilled, (state, action) => {
        state.storageCountData = action.payload;
      })
      .addCase(businessStorageDashboardAction.pending, (state, action) => {})
      .addCase(businessStorageDashboardAction.rejected, (state, action) => {});
  },
});

export const {} = DashboardSlice.actions;
export default DashboardSlice.reducer;
