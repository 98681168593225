import { IoLogoWhatsapp } from "react-icons/io";
import { IoMdCall } from "react-icons/io";
import { MdOutlineTextsms } from "react-icons/md";
import { MdMarkEmailRead } from "react-icons/md";


export const leadColorCode = [
  { colorCode: "#0095c2" },
  { colorCode: "#87e980" },
  { colorCode: "#f99e2d" },
  { colorCode: "#14803f" },
  { colorCode: "#f2df56" },
  { colorCode: "#f5da21" },
  { colorCode: "#365899" },
  { colorCode: "#f25656" },
];

export const leadSectionEnum = [
  {
    name: "Potential",
    colorCode: "#1276D0",
    id: 1,
  },

  {
    name: "Contact Established",
    colorCode: "#777777",
    id: 2,
  },

  {
    name: "Introductions Completed",
    colorCode: "#E97551",
    id: 3,
  },

  {
    name: "Interested",
    colorCode: "#006D5B",
    id: 4,
  },

  {
    name: "Contract Sent",
    colorCode: "#FECD2F",
    id: 5,
  },

  {
    name: "Completed",
    colorCode: "#14A06E",
    id: 6,
  },

  {
    name: "Not Interested (Potential)",
    colorCode: "#365899",
    id: 7,
  },

  {
    name: "Not Interested",
    colorCode: "#FD4A26",
    id: 8,
  },
];

export const leadDetailSectionEnum = [
  {
    name: "Potential",
    colorCode: "#1276D0",
    sectionType: 1,

  },

  {
    name: "Contact Established",
    colorCode: "#777777",
    sectionType: 2,
  },

  {
    name: "Introductions Completed",
    colorCode: "#E97551",
    sectionType: 3,
  },


  {
    name: "Contract Sent",
    colorCode: "#FECD2F",
    sectionType: 4,
  },

  {
    name: "Completed",
    colorCode: "#14A06E",
    sectionType: 5,
  },

]

export const ScheduleReferenceTypeEnum = {
  General: 1,
  Group: 2,
  Project: 3,
  Lead: 4,
};
export const AddMemberEnumModal = {
  Member: 1,
  AssignMember: 2,
};

export const LeadManagerMetricTypeEnum = {

  allGroup: 1,
  allAssign: 2,
  leadToProject: 3,
  leadSection: 4

}

export const LeadManagerDetailInterestStatusEnum = {
  Interested: 1,
  NotInterested: 2,
  Unknown: 3
}
export const LeadManagerdetailInterestStatus = {
  [LeadManagerDetailInterestStatusEnum.Interested]: "Interested",
  [LeadManagerDetailInterestStatusEnum.NotInterested]: "Not Interested",
  [LeadManagerDetailInterestStatusEnum.Unknown]: "Unknown",

}

export const getColorCodeByValue = (section) => {
  const obj = leadDetailSectionEnum.find(item => item.sectionType === section)
  return obj.colorCode
}

export const LeadManagerCommunicationTypeEnum =
{
  Call: 1,
  Whatsapp: 2,
  Email: 3,
  SMS: 4,
}

export const LeadManagerCommunicationType = [
  {
    label: "Call",
    value: 1,
  },
  {
    label: "Whatsapp",
    value: 2
  },
  {
    label: "Email",
    value: 3
  },
  {
    label: "SMS",
    value: 4
  }
]

export const LeadManagerCommunicationTypeLables = {
  [LeadManagerCommunicationTypeEnum.Call]: "Call",
  [LeadManagerCommunicationTypeEnum.Whatsapp]: "Whatsapp",
}


export const LeadManagerCommunicationTypeIcons = {
  [LeadManagerCommunicationTypeEnum.Whatsapp]: <IoLogoWhatsapp />,
  [LeadManagerCommunicationTypeEnum.Call]: <IoMdCall />,
  [LeadManagerCommunicationTypeEnum.Email]: <MdMarkEmailRead />,
  [LeadManagerCommunicationTypeEnum.SMS]: <MdOutlineTextsms />,

}

export const LeadManagerMemberTypeEnum = {
	User:1,
	Admin:2
}

export const LeadManagerFilterSortEnum = {
  CreateDateDesc: 1,
  CreateDateAsc: 2,
  UpdateDateDesc: 3,
  UpdateDateAsc: 4,
  ReferenceNo: 5,
}