export const WorkBoardReferenceTypeEnum = {
  General: 1,
  Project: 2,
  Group: 3,
};
export const WorkBoardMemberActionTypeEnum = {
  WorkBoardMember: 1,
  WorkBoardToDoMember: 2,
};

export const WorkBoardMemberTypeEnum = {
  Member: 1,
  Admin: 2,
};

export const WorkBoardFilterSortEnum = {
  CreateDateDesc: 1,
  CreateDateAsc: 2,
  UpdateDateDesc: 3,
  UpdateDateAsc: 4,
  ReferenceNo: 5,
  ReferenceNoDesc: 6,
}
