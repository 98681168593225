import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  businessStorageDashboardService,
  documentDashboardService,
  employeeDashboardService,
  expenseDashboardService,
  groupDashboardService,
  projectDashboardService,
  userTaskDashboardService,
  leaveDashboardService,
  loanDashboardService,
  allApprovalDashboardService
} from "../Service/service";
import { APIRESPONSETOASTDURATION } from "../../../../utils/base";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { responseCode } from "../../../../services/enums/responseCode";

export const employeeDashboardAction = createAsyncThunk(
  "Dashboard/employeeDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await employeeDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const businessStorageDashboardAction = createAsyncThunk(
  "Dashboard/businessStorageDashboard",
  async (businessId, { dispatch, getState, rejectWithValue }) => {
    const res = await businessStorageDashboardService(businessId);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const groupDashboardAction = createAsyncThunk(
  "Dashboard/groupDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await groupDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);
export const projectDashboardAction = createAsyncThunk(
  "Dashboard/projectDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await projectDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const expenseDashboardAction = createAsyncThunk(
  "Dashboard/expenseDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await expenseDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const userTaskDashboardAction = createAsyncThunk(
  "Dashboard/userTaskDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await userTaskDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);


export const DocumentDashboardAction = createAsyncThunk(
  "Dashboard/DocumentDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await documentDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);



export const leaveDashboardAction = createAsyncThunk(
  "Dashboard/LeaveDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await leaveDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const loanDashboardAction = createAsyncThunk(
  "Dashboard/LoanDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await loanDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);



export const allApprovalDashboardAction = createAsyncThunk(
  "Dashboard/AllApprovalDashboard",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await allApprovalDashboardService(payload);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: APIRESPONSETOASTDURATION,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);




