import MasterConfig from "../../../../utils/services/MasterConfig"

export const employeeDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/EmployeeDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};


export const businessStorageDashboardService = (businessId) => {
	return MasterConfig.get(`api/Dashboard/BusinessStorageDashboard?businessId=${businessId}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const groupDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/GroupDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const projectDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/ProjectDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const userTaskDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/UserTaskDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};


export const expenseDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/ExpenseDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const documentDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/DocumentDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};


// New Apis
export const leaveDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/LeaveDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const loanDashboardService = (payload) => {
	return MasterConfig.post(`api/Dashboard/LoanDashboard`,payload)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const allApprovalDashboardService = ({businessId}) => {
	return MasterConfig.get(`api/Dashboard/AllApprovalDashboard?businessId=${businessId}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
