import { createSlice, current } from "@reduxjs/toolkit";
import {
  changeMailSeenFlag,
  composeMail,
  getAllMail,
  getMailById,
  getMailByIdAction,
  getMailFolders,
  refreshMail,
} from "./Api";
import { getAllBusinessEmailConfiguration } from "../../emailConfiguration/store/actions";
import moment from "moment";

export const mailSlice = createSlice({
  name: "mail",
  initialState: {
    allMail: [],
    currentParamId: "",
    currentPageSize: 1,
    mailDetail: undefined,
    mailFolderItem: null,

    mailDrawerStatus: false,
    mailMobComposerStatus: false,
    mailComposerStatus: false,
    mailComposerInstances: [],
    responseCode: null,
    inProcess: false,
    isRefresh: false,
    errorMessage: "",
    emailConfigurations: null,
    resetToken: null,
  },

  reducers: {
    openMailMenuDrawer: (state, action) => {
      state.mailDrawerStatus = action.payload;
    },
    openMailMobComposer: (state, action) => {
      state.mailMobComposerStatus = action.payload;
    },

    handleMailComposer: (state, { payload }) => {
      state.mailComposerInstances = payload;
    },
    handleMailComposerClose: (state, { payload }) => {
      state.mailComposerInstances = state.mailComposerInstances.filter(
        (item) => item.id !== payload
      );
    },
    handleMailComposerIsMax: (state, action) => {
      const { mailComposerInstances } = current(state);
      return {
        ...state, //copying the orignal state
        mailComposerInstances: mailComposerInstances.map((item) =>
          item.id === action.payload.id
            ? {
              ...item,
              isMax: action.payload.status,
            }
            : item
        ),
      };
    },
    handleMailMinimize: (state, action) => {
      const { mailComposerInstances } = current(state);
      return {
        ...state, //copying the orignal state
        mailComposerInstances: mailComposerInstances.map((item) =>
          item.id === action.payload.id
            ? {
              ...item,
              isMinimize: action.payload.status,
            }
            : item
        ),
      };
    },
    updateParamsId: (state, action) => {
      state.currentParamId = action.payload;
    },
    updateEmail: (state, { payload }) => {
      state.allMail = payload;
    },
    resetAllMailState: (state, action) => {
      state.allMail = [];
    },
    updateResetToken: (state, { payload }) => {
      state.resetToken = moment().unix();
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(composeMail.pending, (state, { payload }) => {
        //sending mail pending
      })
      .addCase(composeMail.fulfilled, (state, { payload }) => {
        //sending mail fulfilled
      })
      .addCase(composeMail.rejected, (state, { payload }) => {
        //sending mail rejected
      })
      /////
      .addCase(getMailFolders.pending, (state, { payload }) => {

      })
      .addCase(getMailFolders.fulfilled, (state, { payload }) => {
        state.mailFolderItem = payload.data;
      })
      .addCase(getMailFolders.rejected, (state, { payload }) => {
        // state.mailFolderItem = null;
        state.errorMessage = payload?.message;
        state.responseCode = payload?.responseCode;
      })
      ////
      .addCase(getAllMail.pending, (state, { payload }) => {
        state.inProcess = true;
      })
      .addCase(getAllMail.fulfilled, (state, action) => {
        action?.meta?.arg?.pageNo === 1
          ? (state.allMail = action?.payload?.data)
          : (state.allMail = [...state.allMail, ...action?.payload?.data]);
        state.inProcess = false;
      })
      .addCase(getAllMail.rejected, (state, { payload }) => {
        state.inProcess = false;
        state.allMail = null;
        state.errorMessage = payload?.message;
      })
      ///////
      .addCase(getAllBusinessEmailConfiguration.fulfilled, (state, { payload }) => {
        state.emailConfigurations = payload.data;
        state.inProcess = false;
      })
      ///////
      .addCase(getMailByIdAction.pending, (state, { payload }) => {
        state.mailDetail = null;
      })
      .addCase(getMailByIdAction.fulfilled, (state, { payload }) => {
        state.mailDetail = payload.data;
      })
      .addCase(getMailByIdAction.rejected, (state, { payload }) => {

      })
  }



  // extraReducers: {
  //   [composeMail.pending]: (state, { payload }) => {
  //     //sending mail pending
  //   },
  //   [composeMail.fulfilled]: (state, { payload }) => {
  //     //sending mail fulfilled
  //   },
  //   [composeMail.rejected]: (state, { payload }) => {
  //     //sending mail rejected
  //   },
  //   [getMailFolders.pending]: (state, { payload }) => {},

  //   [getMailFolders.fulfilled]: (state, { payload }) => {
  //   state.mailFolderItem = payload.data;
  // },
  // [getMailFolders.rejected]: (state, { payload }) => {
  //   // state.mailFolderItem = null;
  //   state.errorMessage = payload?.message;
  //   state.responseCode = payload?.responseCode;
  // },

  // [getAllMail.pending]: (state, { payload }) => {
  //   state.inProcess = true;
  // },

  //   [getAllMail.fulfilled]: (state, action) => {
  //   action?.meta?.arg?.pageNo === 1
  //     ? (state.allMail = action?.payload?.data)
  //     : (state.allMail = [...state.allMail, ...action?.payload?.data]);

  //   state.inProcess = false;
  // },

  //   [getAllMail.rejected]: (state, { payload }) => {
  //   state.inProcess = false;
  //   state.allMail = null;
  //   state.errorMessage = payload?.message;
  // },

  // [getAllBusinessEmailConfiguration.fulfilled]: (state, { payload }) => {
  //   state.emailConfigurations = payload.data;
  //   state.inProcess = false;
  // },

  //   // [refreshMail.pending]: (state, { payload }) => {
  //   //     state.isRefresh = true;
  //   // },
  //   // [refreshMail.fulfilled]: (state, { payload }) => {
  //   //     state.isRefresh = false;
  //   //     state.allMail = payload.data;
  //   // },

  //   // [refreshMail.rejected]: (state, { payload }) => {
  //   //     state.isRefresh = false;
  //   // },

  //   [getMailById.pending]: (state, { payload }) => {
  //     state.mailDetail = null;
  //   },
  //   [getMailById.fulfilled]: (state, { payload }) => {
  //     state.mailDetail = payload.data;
  //   },
  //   [getMailById.rejected]: (state, payload) => {},

  //   // [changeMailSeenFlag.pending]: (state, { payload, meta }) => {
  //   //     state.allMail = state.allMail.map((value) => value.id === meta.arg.uid ? ({
  //   //         ...value,
  //   //         isRead: !value.isRead
  //   //     }) : value)
  //   // },
  //   // [changeMailSeenFlag.fulfilled]: (state, { payload, meta }) => {
  //   // },
  //   // [changeMailSeenFlag.rejected]: (state, payload) => {

  //   // }
  // },
});

export const {
  openMailMenuDrawer,
  openMailMobComposer,
  handleMailComposer,
  updateParamsId,
  handleMailComposerClose,
  handleMailComposerIsMax,
  handleMailMinimize,
  resetAllMailState,
  updateResetToken,
} = mailSlice.actions;
export default mailSlice.reducer;
