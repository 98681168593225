import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums"
import { InputFieldTypes } from "../../../main/features/quotation/utils/quotationInputFieldTypes";
import { ProjectFeatureEnums } from "../../Shared/enums/projectFeatureEnum";

const arabic = {
  /*** PLEASE READ FIRST ALL COMMENTS BEFORE ADD LABELS ***/

  /*** add all shared labels like send, ok, cancel, etc ***/
  sharedLabels: {

    SelectLanguage: "Select Language",
    English: "English",
    Turkish: "Turkish",
    Hindi: "Hindi",
    Urdu: "اردو",
    Arabic: "عربي",
    czech: "Czech",
    russian: "Russian",
    french: "French",
    spanish: "Spanish",
    italian: "Italian",
    theme: "السمة",
    awardTo: "منح لـ",
    inprogress: "قيد التنفيذ",
    filter: "تصفية",
    approvals: "الموافقات",
    approvers: "الموافقون",
    members: "الأعضاء",
    Approvers: "الموافقون",
    resignations: "استقالات",
    Description: "الوصف",
    Descriptionisrequired: "الوصف مطلوب!",
    WriteDescriptionhere: "اكتب الوصف هنا...",
    enterDescription: "أدخل الوصف",
    pleseEnterDescription: "الرجاء إدخال الوصف",
    pleaseEnterGrade: "الرجاء إدخال الدرجة",
    manager: "المدير",
    requiredMessageManager: "الرجاء اختيار المدير",
    SelectManager: "اختر المدير",
    create: "إنشاء",
    humanresource: "الموارد البشرية",
    finance: "المالية",
    it: "تكنولوجيا المعلومات",
    admin: "المشرف",
    selectMember: "اختر العضو",
    othersapprovals: "الموافقات الأخرى",
    exitinterview: "مقابلة الخروج",
    selectAperson: "اختر شخصاً",
    SelectHumanResource: "اختر الموارد البشرية",
    selectIt: "اختر تكنولوجيا المعلومات",
    selectFinance: "اختر المالية",
    selectAdmin: "اختر المشرف",
    selectOthersApprovals: "اختر الموافقات الأخرى",
    inProcess: "قيد التنفيذ",
    reason: "السبب",
    refrence: "الإشارة",
    Rewards: "المكافآت",
    name: "الاسم",
    add: "إضافة",
    action: "العمل",
    userType: "نوع المستخدم",
    SelectUserType: "اختر نوع المستخدم",
    requiredMessageUserType: "الرجاء اختيار نوع المستخدم",
    RewardTo: "منح لـ",
    Approved: "تم الموافقة عليه",
    Declined: "تم رفضه",
    Resend: "إعادة الإرسال",
    NotRequired: "غير مطلوب",
    Cancelled: "تم الإلغاء",
    InActive: "غير فعّال",
    ListView: "عرض القائمة",
    TableView: "عرض الجدول",
    MyReward: "مكافأتي",
    ForApprovals: "للموافقة",
    ForApproval: "للموافقة",
    RewardToMe: "المكافأة لي",
    Search: "بحث",
    Disable: "تعطيل",
    Attachments: "المرفقات",
    Update: "تحديث",
    update: "تحديث",
    AddEmployee: "إضافة موظف",
    complain: "شكوى",
    Submit: "إرسال",
    Delete: "حذف",
    Edit: "تحرير",
    NotDesignated: "غير معين",
    Profile: "الملف الشخصي",
    Settings: "الإعدادات",
    Logout: "تسجيل الخروج",
    Table: "الجدول",
    List: "القائمة",
    Grid: "الشبكة",
    GridView: "الشبكة",
    startyear: "بداية السنة",
    endyear: "نهاية السنة",
    startMonth: "بداية الشهر",
    endMonth: "نهاية الشهر",
    EnterStartDate: "أدخل تاريخ البداية",
    SelectStartDate: "اختر تاريخ البداية",
    startDate: "تاريخ البداية",
    endDate: "تاريخ النهاية",
    businessLogo: "شعار الشركة",
    title: "العنوان",
    enterTitle: "أدخل العنوان",
    percentage: "النسبة المئوية",
    PleaseEnterPercentage: "الرجاء إدخال النسبة المئوية",
    previousCharge: "الشحن السابق",
    min: "الحد الأدنى",
    max: "الحد الأقصى",
    country: "الدولة",
    requiredCountry: "الدولة مطلوبة",
    SelectCountry: "اختر الدولة",
    latitude: "خط العرض",
    longitude: "خط الطول",
    address: "العنوان",
    EnterAddress: "أدخل العنوان",
    requiredMessageAddress: "الرجاء إدخال العنوان",
    branch: "الفرع",
    question: "الأسئلة",
    allotLeaves: "تخصيص الإجازات",
    SubjectRequired: "الموضوع مطلوب",
    WriteSubject: "كتابة الموضوع",
    category: "الفئة",
    amount: "المبلغ",
    documents: "المستندات",
    enterName: "أدخل الاسم",
    nameRequired: "الاسم مطلوب",
    accessTo: "الوصول إلى",
    selectAccessTo: "اختر الوصول إلى",
    collaborators: "المتعاونون",
    selectCollaborators: "اختر المتعاونين",
    readers: "القراء",
    selectReaders: "اختر القراء",
    createBoard: "إنشاء لوحة",
    createPad: "إنشاء دفتر",
    createGrid: "إنشاء شبكة",
    createSlide: "إنشاء شريحة",
    createMindmap: "إنشاء خريطة ذهنية",
    drive: "المحرك",
    myDrive: "محركي",
    allDocuments: "كل الوثائق",
    subject: "الموضوع",
    Subjectisrequired: "الموضوع مطلوب",
    pleaseEnterSubject: "الرجاء إدخال الموضوع",
    EnterSubject: "أدخل الموضوع",
    enterAmount: "أدخل المبلغ",
    pleaseEnterAmount: "الرجاء إدخال المبلغ",
    upload: "رفع",
    value: "القيمة",
    addCategory: "إضافة الفئة",
    saveCategory: "حفظ الفئة",
    clear: "مسح",
    loans: "القروض",
    disburse: "صرف",
    referenceNo: "الرقم المرجعي",
    status: "الحالة",
    createDate: "تاريخ الإنشاء",
    creator: "المنشئ",
    netSalary: "الراتب الصافي",
    type: "النوع",
    PleaseSelectType: "الرجاء اختيار النوع",
    CreatedByMe: "تم إنشاؤها بواسطتي",
    Members: "الأعضاء",
    Member: "العضو",
    pleaseEnterCategory: "الرجاء إدخال الفئة",
    Memberisrequired: "العضو مطلوب",
    EnterCategoryName: "أدخل اسم الفئة",
    Clear: "مسح",
    ReferenceNumber: "الرقم المرجعي",
    Grade: "الدرجة",
    Status: "الحالة",
    CreateDate: "تاريخ الإنشاء",
    Creator: "المنشئ",
    SNo: "رقم السلسلة",
    Employee: "الموظف",
    SelectApprovers: "اختر الموافقين",
    Approverisrequired: "الموافق مطلوب",
    Yes: "نعم",
    No: "لا",
    totalAmount: "المبلغ الإجمالي",
    Total: "الإجمالي",
    Date: "التاريخ",
    Pleaseselectdate: "الرجاء اختيار التاريخ",
    SelectDate: "اختر التاريخ",
    requiredMessageDate: "الرجاء اختيار التاريخ",
    Priority: "الأولوية",
    Pleaseselectpriority: "الرجاء اختيار الأولوية",
    Pleaseselecttype: "الرجاء اختيار النوع",
    SelectProject: "اختر المشروع",
    SelectGroup: "اختر المجموعة",
    Project: "المشروع",
    Group: "المجموعة",
    Progress: "التقدم",
    SuretoRate: "هل أنت متأكد من التقييم؟",
    Invalidfiletype: "نوع الملف غير صالح",
    Pleaseinputyoursubject: "الرجاء إدخال الموضوع",
    Pleaseinputyourdescription: "الرجاء إدخال الوصف",
    Return: "العودة",
    EnterReason: "أدخل السبب",
    TravelReason: "سبب السفر",
    NoDesignation: "بدون تسمية",
    Hi: "مرحبًا",
    Quotation: "اقتباس",
    Amountisrequired: "المبلغ مطلوب",
    WriteDescriptionHere: "اكتب الوصف هنا...",
    Types: "الأنواع",
    Header: "الرأس",
    MyTeam: "فريقي",
    Email: "البريد الإلكتروني",
    EnterEmail: "أدخل البريد الإلكتروني",
    EmailIsRequired: "البريد الإلكتروني مطلوب",
    PhoneNo: "رقم الهاتف",
    Designation: "التسمية",
    ReferenceType: "نوع المرجع",
    referenceId: "رقم المرجع",
    Save: "حفظ",
    save: "حفظ",
    Color: "اللون",
    Yesterday: "أمس",
    Overdue: "متأخر",
    Today: "اليوم",
    Complete: "اكتمال",
    DueDate: "تاريخ الاستحقاق",
    SelectDueDate: "اختر تاريخ الاستحقاق",
    Cover: "الغلاف",
    Cancel: "إلغاء",
    cancel: "إلغاء",
    and: "و",
    other: "آخر",
    Others: "الآخرين",
    Option: "خيار",
    Public: "عام",
    Private: "خاص",
    click: "انقر",
    With: "مع",
    with: "مع",
    Comments: "التعليقات",
    Commentsdot: "التعليقات...",
    Comment: "تعليق",
    Seeless: "رؤية أقل",
    Seemore: "رؤية المزيد",
    Share: "مشاركة",
    ShareNow: "مشاركة الآن",
    SelectEmployee: "اختر الموظف",
    SelectCategory: "اختر الفئة",
    PleaseSelectCategory: "الرجاء اختيار الفئة",
    privacy: "الخصوصية",
    tags: "الوسوم",
    updateDate: "تاريخ التحديث",
    otherApprovals: "الموافقات الأخرى",
    resignationDate: "تاريخ الاستقالة",
    purpose: "الغرض",
    warnings: "تحذيرات",
    Courses: "الدورات",
    selectType: "اختر النوع",
    daysCount: "عدد الأيام",
    reportingTo: "التبليغ إلى",
    selectReason: "اختر السبب",
    pleaseSelectReason: "الرجاء اختيار السبب",
    days: "أيام",
    day: 'يوم',
    Dates: "التواريخ",
    selectMembers: "اختر الأعضاء",
    selectGrade: "اختر الدرجة",
    newGrade: "درجة جديدة",
    currentGrade: "الدرجة الحالية",
    PleaseSelectMember: "الرجاء تحديد العضو",
    enterCategory: "أدخل الفئة",
    deadline: "الموعد النهائي",
    Department: "القسم",
    designationmessage: "الرجاء اختيار التسمية",
    selectDesignation: "اختر التسمية",
    jobdescription: "وصف الوظيفة",
    salaryRange: "نطاق الراتب",
    interviewers: "المقابلين",
    hiringBuddy: "زميل التوظيف",
    enterCity: "الرجاء إدخال اسم المدينة",
    City: "المدينة",
    selectCity: "اختر المدينة",
    requiredCity: "المدينة مطلوبة",
    skills: "المهارات",
    experience: "الخبرة (سنوات)",
    Experience: "الخبرة",
    jobType: "نوع الوظيفة",
    education: "التعليم",
    interview: "المقابلة",
    Rating: "التقييم",
    poweredbyWorkw: "مشغل بواسطة وركوايز ليمتد - حقوق النشر 2016-",
    copyLink: "نسخ الرابط",
    EffectiveDate: "تاريخ النفاذ",
    experienceRequired: "الخبرة المطلوبة",
    user: "المستخدمين",
    postinterview: "بعد المقابلة",
    Copied: "تم النسخ",
    unreadNotifyItem: "عنصر الإشعار غير المقروء",
    createBy: "تم الإنشاء بواسطة",
    Create: "إنشاء",
    Open: "مفتوح",
    Closed: "مغلق",
    Nodescription: "لا يوجد وصف",
    writeDescription: "اكتب وصفك هنا...",
    Employees: "الموظفين",
    BasicInfo: "معلومات أساسية",
    FatherName: "اسم الأب",
    EnterFatherName: "أدخل اسم الأب",
    PersonalEmail: "البريد الإلكتروني الشخصي",
    EnterPersonalEmail: "أدخل البريد الإلكتروني الشخصي",
    personalEmailIsRequired: "البريد الإلكتروني الشخصي مطلوب",
    PhoneNumber: "رقم الهاتف",
    EnterPhoneNumber: "أدخل رقم الهاتف",
    NationalIdentity: "الهوية الوطنية",
    EnterNationalIdentityNumber: "أدخل رقم الهوية الوطنية",
    Grades: "الدرجات",
    SelectGrade: "اختر الدرجة",
    requiredMessageGrade: "الرجاء تحديد الدرجة",
    EnterProbationPeriod: "أدخل فترة الاختبار",
    NoticePeriod: "فترة الإشعار (أيام)",
    EnterNoticePeriod: "أدخل فترة الإشعار",
    requiredMessageNoticePeriod: "الرجاء إدخال فترة الإشعار الخاصة بك",
    requiredMessageNoticePeriodRestriction: "يجب أن تكون فترة الإشعار بين 1 و 60",
    DateOfBirth: "تاريخ الميلاد",
    Gender: "الجنس",
    requiredMessageGender: "الرجاء تحديد الجنس",
    SelectGender: "اختر الجنس",
    MartialStatus: "الحالة الاجتماعية",
    SelectMartialStatus: "اختر الحالة الاجتماعية",
    requiredDepartment: "القسم مطلوب",
    SelectDepartment: "اختر القسم",
    Subsidiary: "الشركة الفرعية",
    SelectSubsidiary: "اختر الشركة الفرعية",
    SubsidiaryOffice: "مكتب الشركة الفرعية",
    SelectSubsidiaryOffice: "اختر مكتب الشركة الفرعية",
    PayrollGroup: "مجموعة الرواتب",
    SelectPayrollGroup: "اختر مجموعة الرواتب",
    DefaultApproval: "الموافقة الافتراضية",
    SelectDefaultApproval: "اختر الموافقة الافتراضية",
    SelectAll: "تحديد الكل",
    NonMandatoryApprovals: "الموافقات غير الإلزامية",
    SelectNonMandatoryApprovals: "اختر الموافقات غير الإلزامية",
    FirstName: "الاسم الأول",
    EnterFirstName: "أدخل الاسم الأول",
    requiredMessageName: "الرجاء إدخال الاسم",
    requiredMessageFName: "الرجاء إدخال الاسم الأول",
    requiredMessageLName: "الرجاء إدخال الاسم الأخير",
    LastName: "الاسم الأخير",
    EnterLastName: "أدخل الاسم الأخير",
    DateOfJoining: "تاريخ الانضمام",
    requiredDateOfBirth: "تاريخ الميلاد مطلوب",
    requiredMessageJoiningDate: "الرجاء تحديد تاريخ الانضمام",
    JobShift: "وردية العمل",
    requiredMessageJobShift: "الرجاء تحديد وردية العمل",
    SelectJobShift: "اختر وردية العمل",
    AccessRole: "دور الوصول",
    requiredMessageAccessRole: "الرجاء اختيار دور واحد على الأقل",
    SelectAccessRole: "اختر دور الوصول",
    StartEndDate: "تاريخ البداية / النهاية",
    ErrorInValidation: "خطأ في التحقق",
    EducationInfo: "معلومات التعليم",
    NonMandatory: "غير إلزامي",
    Present: "حاضر",
    FailedToFetch: "فشل في الحصول على البيانات",
    Position: "الموقف",
    EnterPosition: "أدخل الموقف",
    requiredMessagePosition: "الرجاء إدخال الموقف",
    Organization: "المنظمة",
    EnterOrganization: "أدخل المنظمة",
    requiredMessageOrganziation: "الرجاء إدخال المنظمة",
    Number: "الرقم",
    EnterNumber: "أدخل الرقم",
    requiredMessageNumber: "الرجاء إدخال الرقم",
    Relation: "العلاقة",
    SelectRelation: "اختر العلاقة",
    requiredMessageRelation: "العلاقة مطلوبة",
    AccountName: "اسم الحساب",
    BranchCode: "رمز الفرع",
    EnterBranchCode: "أدخل رمز الفرع",
    requiredMessageBranchCode: "رمز الفرع مطلوب",
    BankName: "اسم البنك",
    requiredMessageBankName: "اسم البنك مطلوب",
    EnterBankName: "أدخل اسم البنك",
    AccountNumber: "رقم الحساب",
    EnterAccountNumber: "أدخل رقم الحساب",
    requiredAccountNumber: "رقم الحساب مطلوب",
    IBAN: "الرقم الدولي للحساب المصرفي",
    EnterIBAN: "أدخل الرقم الدولي للحساب المصرفي",
    requiredIBAN: "الرقم الدولي للحساب المصرفي مطلوب",
    SortCode: "رمز التصنيف",
    requiredSortCode: "رمز التصنيف مطلوب",
    EnterSortCode: "أدخل رمز التصنيف",
    BankInfo: "معلومات البنك",
    AccountTitle: "عنوان الحساب",
    EnterAccountTitle: "أدخل عنوان الحساب",
    requiredMessageAccount: "عنوان الحساب مطلوب",
    validAccountTitle: "الرجاء إدخال عنوان حساب صالح",
    AttachmentType: "نوع المرفق",
    requiredAttchmentType: "نوع المرفق مطلوب",
    Attachment: "المرفق",
    requiredAttachment: "المرفق مطلوب",
    AddAttachment: "إضافة مرفق",
    requiredMessageAssignMember: "الرجاء تحديد العضو المخصص",
    Remove: "إزالة",
    ContactNo: "رقم الاتصال",
    EnterContactNo: "أدخل رقم الاتصال",
    requiredContactNo: "رقم الاتصال مطلوب",
    Contact: "جهة الاتصال",
    pleasEnterDescription: "الرجاء إدخال الوصف",
    Information: "معلومات",
    Features: "الميزات",
    AddMembers: "إضافة أعضاء",
    Summary: "ملخص",
    AcessRole: "دور الوصول",
    defaultApprovers: "الموافقون الافتراضيون",
    NonMandatoryApprovers: "الموافقون غير الإلزاميين",
    Pleasefillallrequiredfields: "الرجاء ملء جميع الحقول المطلوبة",
    Suretodelete: "هل أنت متأكد أنك تريد حذفها؟",
    Code: "الكود",
    createdDate: "تاريخ الإنشاء",
    Searchmembers: "البحث في الأعضاء",
    AddDate: "إضافة تاريخ",
    duesoon: "قريبًا الاستحقاق",
    Payroll: "الرواتب",
    ReferenceNo: "الرقم المرجعي",
    Associate: "الشريك",
    InternalMembers: "الأعضاء الداخليين",
    ExternalMembers: "الأعضاء الخارجيين",
    TotalExpense: "إجمالي المصاريف",
    TotalExpenseAmount: "إجمالي مبلغ المصاريف",
    TotalPost: "إجمالي الوظائف",
    TotalMeeting: "إجمالي الاجتماعات",
    Expense: "المصروفات",
    TotalBudget: "إجمالي الميزانية",
    Balance: "الرصيد",
    successfully: "بنجاح",
    enterValidEmail: 'الرجاء إدخال عنوان بريد إلكتروني صحيح',
    ViewSummary: "عرض الملخص",
    AddAdmin: "إضافة مسؤول",
    AddExternals: "إضافة خارجيين",
    DefaultApprovers: "الموافقون الافتراضيون",
    Billings: "الفواتير",
    spent: "تم الصرف",
    SelectUser: "تحديد المستخدم",
    Image: "صورة",
    Contacts: "جهات الاتصال",
    noContacts: "لا توجد جهات اتصال",
    AddContact: "إضافة جهة اتصال",
    Roles: "الأدوار",
    Tag: "وسم",
    AccessRoles: "أدوار الوصول",
    member: "عضو",
    uploadCover: "تحميل الغلاف",
    Pleaseselect: "يرجى التحديد",
    composeEmail: "كتابة البريد الإلكتروني",
    send: "إرسال",
    ContactDetail: "تفاصيل الاتصال",
    Detail: "تفصيل",
    UploadImage: "تحميل صورة",
    WriteName: "كتابة الاسم",
    business: "عمل",
    website: "موقع الويب",
    Unknown: "غير معروف",
    Interested: "مهتم",
    NotInterested: "غير مهتم",
    meetings: "اجتماعات",
    noMeetings: "لا توجد اجتماعات",
    Communications: "الاتصالات",
    Details: "تفاصيل",
    LastUpdate: "آخر تحديث",
    nameRequiredMessage: "الاسم مطلوب!",
    addressRequiredMessage: "العنوان مطلوب!",
    Intrested: "مهتم",
    StartDateTime: "تاريخ ووقت البدء",
    DateTimeisrequired: "التاريخ والوقت مطلوبين",
    Selectdatetime: "حدد التاريخ والوقت",
    Board: "لوحة",
    Notes: "ملاحظات",
    Miletap: "ميلتاب",
    EMail: "البريد الإلكتروني",
    Attendance: "الحضور",
    Absent: "غائب",
    NoData: "لا توجد بيانات",
    Prority: "أفضلية",
    Heigh: "عالية",
    Medium: "متوسط",
    Low: "منخفضة",
    error: "خطأ",
    Select: "اختر",
    Interview: "مقابلة",
    Meeting: "اجتماع",
    Appointment: "موعد",
    Pleaseinputyouremail: "الرجاء إدخال بريدك الإلكتروني",
    EnterEmailAddress: "أدخل عنوان البريد الإلكتروني",
    AddExternalMember: "إضافة عضو خارجي",
    Pleaseenterexternalmembers: "الرجاء إدخال أعضاء خارجيين",
    externals: "خارجيين",
    Decline: "رفض",
    NotAvailable: "غير متاح",
    AddApprovals: "إضافة موافقات",
    Everyone: "الجميع",
    SelectDownloaders: "تحديد العارضين",
    SelectCollabrators: "تحديد المتعاونين",
    pleaseSelectAssignMemeber: "الرجاء تحديد عضو للتكليف!",
    Availed: "تم الاستفادة منه",
    Allotted: "تم التخصيص",
    Degree: "الدرجة",
    Time: "الوقت",
    Mood: "الحالة",
    CheckOut: "الخروج",
    CheckIn: "تسجيل الدخول",
    Late: "متأخر",
    State: "الحالة",
    PleaseSelectYesNo: "الرجاء تحديد نعم/لا",
    PleaseselectGrade: "الرجاء تحديد الدرجة",
    PleaseenterAmount: "الرجاء إدخال المبلغ",
    Salary: "الراتب",
    Task: "المهمة",
    Nameisrequired: "الاسم مطلوب",
    PhoneNumberisrequired: "رقم الهاتف مطلوب",
    Dateisrequired: "التاريخ مطلوب",
    Month: "شهر",
    Year: "سنة",
    FinalApprovers: "الموافقون النهائيين",
    link: "رابط",
    forFinalApproval: "للموافقة النهائية",
    validateBonus: "تعذر معالجة العلاوة: لا تتوفر معلومات عن الراتب للموظف",
    validateLoan: "تعذر معالجة القرض: لا تتوفر معلومات عن الراتب للموظف",
    User: "المستخدم",
    Action: "الإجراء",
    Created: "تم الإنشاء",
    selectyourcountry: "الرجاء اختيار بلدك",
    PleaseEnterPhoneNumber: "الرجاء إدخال رقم الهاتف",
    Pleaseselectyourcity: "الرجاء اختيار مدينتك",
    SelectCurrency: "اختر العملة",
    Pleaseselectcurrency: "الرجاء اختيار العملة",
    totalGroups: "مجموع الفئات",
    totalProjects: "مجموع المشاريع",
    totalMemory: "إجمالي الذاكرة",
    memory: "الذاكرة",
    EnterMemory: "إدخال الذاكرة",
    availedMemory: "الذاكرة المتاحة (جيجابايت)",
    used: "المستخدم",
    usedMemory: "الذاكرة المستخدمة (جيجابايت)",
    From: "من",
    To: "إلى",
    WelcomeSendEmail: "مرحبًا بك، أرسل بريدًا إلكترونيًا",
    SendEmail: "إرسال بريد إلكتروني",
    writeYourRemarksHere: "اكتب تعليقاتك هنا...",
    NoRemarkFound: "لم يتم العثور على تعليق",
    ToastEnable: "تمكين الإشعارات المنبثقة",
    EmailEnable: "تمكين البريد الإلكتروني",
    FeatureName: "اسم الميزة",
    LoginMessage: "يرجى إدخال بيانات الاعتماد أولاً",
    SelectedDate: "التاريخ المحدد",
    SelectedUser: "المستخدم المحدد",
    Default: "افتراضي",
    netAmount: "المبلغ الصافي",
    mylog: "سجلي",
    SelectStatus: "اختر الحالة",
    Selectuser: "اختر المستخدم",
    PolicyType: "نوع السياسة",
    Percent: "النسبة المئوية",
    uploadProfilePicture: "تحميل صورة الملف الشخصي",
    inputUsername: "يرجى إدخال اسم المستخدم",
    userName: "اسم المستخدم",
    Dummy: "وهمي",
    Lesson: "درس",
    Duration: "المدة",
    Text: "نص",
    Enterlink: "أدخل الرابط",
    Dashboard: "لوحة القيادة",
    searchSchedule: "ابحث في الجدول...",
    AddOption: "أضف خيارًا",
    ChangeImage: "تغيير الصورة",
    DeleteImage: "حذف الصورة",
    Question: "سؤال",
    Next: "التالي",
    Prev: "السابق",
    Wrong: "خطأ",
    Correct: "صحيح",
    YourResult: "نتيجتك",
    Createdby: "أنشئ بواسطة",
    Lastupdated: "آخر تحديث",
    Assignedto: "مخصص لـ",
    Addlesson: "أضف درسًا",
    Addtopic: "أضف موضوعًا",
    minutes: "دقائق",
    Modules: "الوحدات",
    Valid: "صالح",
    Expired: "منتهي الصلاحية",
    AssignedMembers: "الأعضاء المخصصين",
    Start: "ابدأ",
    Previous: "السابق",
    Read: "اقرأ",
    DeleteBook: "حذف الكتاب",
    Assign: "تعيين",
    Explanation: "تفسير",
    Hey: "مرحبًا",
    requiredMessageEmail: "يرجى إدخال البريد الإلكتروني",
    BusinessName: "اسم العمل",
    PleaseEnterBusinessName: "يرجى إدخال اسم العمل",
    BusinessAddress: "عنوان العمل",
    PleaseEnterBusinessAddress: "يرجى إدخال عنوان العمل",
    Offers: "العروض",
    PleaseEnterOffer: "يرجى إدخال العرض",
    CreateOffer: "إنشاء عرض",
    AppliedDate: "تاريخ التطبيق",
    ShortList: "القائمة القصيرة",
    Finalised: "مُنجز",
    Member: "عضو",
    SelectMember: "اختر عضوًا",
    Approver: "الموافقون",
    CategoryName: "اسم الفئة",
    serialNo: "رقم تسلسلي",
    Allocated: "مخصص",
    Available: "متاح",
    lost: "ضائع",
    damage: "تالف",
    itemType: "نوع العنصر",
    Service: "خدمة",
    consumable: "قابل للاستهلاك",
    NonConsumable: "غير قابل للاستهلاك",
    Quantity: "الكمية",
    accountType: "نوع الحساب",
    PleaseEnterQuantity: "يرجى إدخال الكمية",
    QuantityMustBePositive: "يجب أن تكون الكمية موجبة",
    MembersIsRequired: "الأعضاء مطلوبون",
    CreateRequest: "إنشاء طلب",
    IBANNumber: "رقم IBAN",
    About: "حول",
    AboutMe: "عني",
    TimePeriod: "الفترة الزمنية",
    Company: "شركة",
    CityTown: "مدينة/بلدة",
    BankDetails: "تفاصيل البنك",
    Work: "العمل",
    addWorkPlace: "إضافة مكان عمل",
    AddEducation: "إضافة تعليم",
    basicSalary: "الراتب الأساسي",
    feed: "تغذية",
    TakeaNote: "خذ ملاحظة",
    awards: "الجوائز",
    Appraisal: "التقييم",
    salary: "الراتب",
    activityLog: "سجل النشاط",
    device: "الجهاز",
    leave: "إجازة",
    Ip: "العنوان IP",
    DeviceId: "معرف الجهاز",
    AppVersion: "إصدار التطبيق",
    Selectfeature: "اختر الميزة",
    EnterYourFeedBackDescription: "أدخل وصف ملاحظاتك",
    HideAll: "إخفاء الكل",
    ShowAll: "إظهار الكل",
    PrintBy: "طباعة بواسطة",
    AccCode: "رمز الحساب",
    SelectAccountType: "اختر نوع الحساب",
    Files: "ملفات",
    Space: "مساحة",
    DisburseVoucher: "صرف/قسيمة",
    TopUsersbyMostFiles: "أعلى المستخدمين من حيث معظم الملفات",
    TopUsersbyStorageSize: "أعلى المستخدمين من حيث حجم التخزين",
    Apply: "تطبيق",
    NumberOfTopUsers: "عدد أعلى المستخدمين",
    TopUsers: "أعلى المستخدمين",
    SearchUser: "بحث عن مستخدم",
    ByStorageSize: "بحسب حجم التخزين",
    ByFileSize: "بحسب حجم الملف",
    companyDashboard: "لوحة تحكم الشركة",
    signup: "التسجيل غير مؤكد",
    billing: "الفواتير",
    PendingBills: "الفواتير المعلقة",
    MonthYear: "الشهر - السنة",
    SendBill: "إرسال الفاتورة",
    BillingUsers: "مستخدمي الفوترة",
    Item: "البند",
    Rate: "السعر",
    PaidAmount: "المبلغ المدفوع",
    PaymentDate: "تاريخ الدفع",
    DarkDays: "الأيام المظلمة",
    ProcessingFee: "رسوم المعالجة",
    Currency: "لعملة",
    IsEnable: "ممكّن",
    Actions: "الإجراءات",
    TakeNote: "خذ ملاحظة",
    PleaseSelectUsers: "يرجى اختيار المستخدمين",
    StickyNotes: "ملاحظات لاصقة",
    Copy: "نسخ",
    MarkAllRead: "تمييز الكل كمقروء",
    SeeAll: "عرض الكل",
    Refresh: "تحديث",
    close: "إغلاق",
    Calling: "يتصل",
    LoadMore: "تحميل المزيد",
    Quizez: "اختبارات",
    content: "محتوى",
  },
  /*** Main Menu ***/
  newsFeed: {
    WriteForMe: "اكتب لي",
    AddDocuments: "أضف وثائق",
    AddPolls: "أضف استطلاعات",
    WhatsOnYourMind: "ماذا يخطر في بالك",
    Direction: "ltr",
    Generate: "انشئ",
    Change: "تغيير",
    Whatwouldyouliketotalkabout: "عن ماذا ترغب في الحديث؟",
    Post: "نشر",
    Poll: "استطلاع",
    Documents: "وثائق",
    PhotoVideo: "صورة/فيديو",
    Addpolloption: "إضافة خيار استطلاع",
    Removepoll: "إزالة الاستطلاع",
    WriteName: "اكتب الاسم",
    AddImageVideo: "أضف صورة/فيديو",
    CheckIn: "تسجيل الحضور",
    ShareonFeed: "مشاركة على الفيد",
    ShareinChat: "مشاركة في الدردشة",
    NoPosts: "لا توجد مشاركات...",
    Nomoreposts: "لا مزيد من المشاركات...",
    noDataMessage: "أنشئ مشاركة الآن!",
    Saysomethingaboutthis: "قل شيئًا عن هذا...",
    specificTimeline: " الجدول الزمني لـ",
    SharedestinationNames: {
      [SharePostDestinations.FEED]: "التغذية",
      [SharePostDestinations.MEMBERS_PROFILE]: "ملف العضو",
      [SharePostDestinations.GROUP]: "المجموعة",
      [SharePostDestinations.PROJECT]: "المشروع",
      [SharePostDestinations.TIMELINE]: "Timeline",
    },
    SharePostSuggestionPlaceholder: {
      [SuggestionTypeEnum.Employee]: "اختر العضو",
      [SharePostDestinations.GROUP]: "اختر المجموعة",
      [SharePostDestinations.PROJECT]: "اختر المشروع",
    },
    QuickOptions: {
      CreateSlide: "إنشاء شريحة",
      CreateBoard: "إنشاء لوحة",
      CreateGrid: "إنشاء شبكة",
      CreatePad: "إنشاء وسادة",
      CreateTask: "إنشاء مهمة",
      CreateExpense: "إنشاء مصروف",
      AddUser: "إضافة مستخدم",
    },
    reactionDescription: {
      [ReactionType.Like]: "إعجاب",
      [ReactionType.Love]: "حب",
      [ReactionType.NoReaction]: "إعجاب",
      [ReactionType.Support]: "تقدير",
      [ReactionType.Celebrate]: "احتفال",
      [ReactionType.Curious]: "فضول",
    },
    PostTypePlaceHolder: {
      [PostType.DEFAULT]: "ماذا يخطر في بالك",
      [PostType.POLL]: "اسأل شيئًا...",
    },
    Titlecantbeempty: "لا يمكن أن يكون العنوان فارغًا",
    Attachmentscantbeempty: "لا يمكن أن تكون المرفقات فارغة",
    Optioncantbeempty: "لا يمكن أن يكون الخيار فارغًا",
    SuccessfullySharedonFeed: "تم المشاركة بنجاح على الفيد",
    SuccessfullySharedonMembersProfile: "تمت المشاركة بنجاح على الملف الشخصي للعضو",
    SuccessfullyPostSharedMessage:{
      [PostReferenceType.MAIN_FEED]: "تمت المشاركة بنجاح في التغذية الرئيسية",
      [PostReferenceType.GROUP]: "تمت المشاركة بنجاح في المجموعة",
      [PostReferenceType.PROJECTS]: "تمت المشاركة بنجاح في المشروع",
      [PostReferenceType.TIMELINE]: "تمت المشاركة بنجاح في الجدول الزمني",
    },
  },

  /*** navMenuLabel only used in LeftSideBar ***/
  navMenuLabel: {
    createVoucher: "إنشاء قسيمة",
    voucherList: "قائمة القسائم",
    workWiseCompanies: "الشركات العمالية",
    promotions: "پروموشنز",
    customApprovals: "حسب ضرورت منظوری",
    feed: "نیوز فیڈ",
    messenger: "میسنجر",
    mailBox: "میل باکس",
    groups: "گروپس",
    projects: "پروجیکٹس",
    tasks: "ٹاسک",
    workBoard: "ورک بورڈ",
    leadManager: "لیڈ مینیجر",
    schedules: "شیڈولز",
    expense: "اخراجات",
    travel: "سفر",
    docsArchives: "دستاویزات",
    eLearning: "ای لرننگ",
    inventory: "انوینٹری",
    customApproval: "اپنی مرضی کی منظوری",
    myTeam: "میری ٹیم",
    orgChart: "تنظیم چارٹ",
    payroll: "پے رول",
    employee: "ملازمین",
    administration: "انتظامیہ",
    appraisals: "تشخیص",
    departments: "محکمے",
    loan: "قرض",
    leaves: "چھٹی",
    loan: "قرضے",
    holidays: "چھٹیاں",
    careers: "کیریئر",
    companies: "کمپنی",
    menu: "مین مینو",
    hr: "حوالہ جات",
    privacy: "رازداری",
    TC: "اصطلاح اور شرط",
    more: "معلومات",
    feedBack: "تاثرات",
    warnings: "انتباہات",
    complains: "شکایت",
    bonus: "انعام",
    resignations: "استعفی",
    requisition: "الاستيلاء",
    rewards: "انعامات",
    businessPolicy: "کاروباری پالیسی",
    form: "فارم",
    finance: "مالیات",
    chartOfAccount: "اکاؤنٹ کا چارٹ",
    report: "رپورٹ",
    voucher: "واؤچر",
    Quotation: "اقتباس",
    QuotationClient: "عميل الاقتباس",
    ledgerReport: "لیجر رپورٹ",
    salary: "تنخواہ",
    payroll: "پے رول",
    LD: "L&D",
    jobBoard: "مجلس العمل",
    teams: "فريقي",
    transaction: "المعاملات",
    assets: "تخصيص الأصول",
    assetsList: "قائمة الأصول",
    createAssets: "إنشاء الأصول",
    requestListItems: "طلب العناصر",
    marketPlace: "السوق",
    Courses: "الدورات",
    selectMembers: "اختر الأعضاء",
    auction: "المزاد",
    Nodescription: "لا توجد وصف",
    discussionBoard: "لوحة المناقشة",
    pages: "صفحات",
    trialBalance: "ميزان المراجعة",
    contract: "عقد"
  },
  /*** AppHeader only used in Component headerTab ***/
  appHeader: {
    /*** القائمة الرئيسية ***/
    newsFeed: {
      posts: "المشاركات",
      photos: "الصور",
      videos: "الفيديوهات",
      polls: "الاستطلاعات",
      docs: "الوثائق",
      tagged: "مع العلامات",
    },
    mailBox: {},
    messenger: {},
    groups: {
      groups: "المجموعات",
    },
    projects: {
      projects: "المشاريع",
      dashboard: "لوحة التحكم",
    },
    Task: {
      Tasks: "المهام",
      myTask: "مهامي",
      assignedByMe: "تكليفها من قبلي",
      createdByMe: "أنشئت بواسطتي",

      teamTask: "مهمة الفريق",
      dashboard: "لوحة التحكم",
    },
    leadManager: {
      leadCategory: "فئة الرصد",
    },

    schedules: {
      calendar: "التقويم",
      schedule: "الجدول",
      scheduleInterviews: "جدولة المقابلات",
    },
    expense: {
      expenses: "النفقات",
      forApprovals: "للموافقة",
      myExpenses: "نفقاتي",
      forExecution: "للتنفيذ",
      forFinance: "للتمويل",
      reports: "التقارير",
      dashboard: "لوحة التحكم",
    },
    travel: {
      travels: "الرحلات",
      forApprovals: "للموافقة",
      agentProcess: "عملية الوكيل",
      travelDetail: "تفاصيل الرحلة",
    },
    docsArchives: {
      documents: "الوثائق",
      myDrive: "محركي",
      myDocuments: "وثائقي",
      forApprovals: "للموافقة",
    },
    eLearning: {
      dashboard: "لوحة التحكم",
    },
    inventory: {
      approvals: "الموافقات",
      inventory: "المخزون",
      category: "الفئة",
    },
    customApproval: {
      CreateCustomApproval: "إنشاء موافقة مخصصة",
      CustomApproval: "موافقة مخصصة",
      ForApproval: "للموافقة",
      MyCustomApprovals: "موافقاتي المخصصة",
      AllCustomApproval: "كل موافقة مخصصة",
    },
    payroll: {
      payroll: "الرواتب",
      payrollApproval: "موافقة الرواتب",
      salaryApproval: "موافقة الراتب",
    },
    // employee: {
    //   employees: "الموظفين",
    // },
    administration: {
      administration: "الإدارة",
    },
    appraisals: {
      appraisals: "التقييمات",
    },
    reward_category: {
      reward_category: "فئة الجوائز",
    },
    departments: {
      departments: "الأقسام",
    },
    leaves: {
      myLeaves: "إجازاتي",
      forApprovals: "للموافقة",
    },
    holidays: {
      holidays: "العطلات",
    },
    warnings: {
      warnings: "تحذيرات",
    },
    resignations: {
      resignations: "الاستقالات",
      approvers: "الموافقون",
    },
    complains: {
      complains: "الشكاوى",
      approvers: "الموافقون",
      createComplain: "إنشاء شكوى",
    },

    rewards: {
      rewards: "الجوائز",
      AllRewards: "جميع المكافآت",
      CreatedByMe: "تم إنشاؤها بواسطتي",
      ForApprovals: "للموافقة",
      MyRewards: "مكافآتي"
    },
    promotions: {
      Promotions: "الترقيات",
    },
    salary: {
      Salary: "الراتب",
      CreateSalary: "إنشاء الراتب",
    },
    pages: {
      Pages: "الصفحات",
    }
  },
  /*** Main Menu ***/
  globalSearch: {
    All: "الكل",
    feed: "الخلاصة",
    Lead_Manager: "مدير العملاء المحتملين",
    Travel: "السفر",
    Document: "وثيقة",
    Project: "مشروع",
    Workboard: "لوحة العمل",
    Group: "مجموعة",
    Expense: "النفقات",
    Task: "مهمة",
    Employee: "موظف",
    e_learning_course: "دورة التعلم الإلكتروني",
    e_learning_videos: "فيديوهات التعلم الإلكتروني",
    e_learning_article: "مقال التعلم الإلكتروني",
    e_learning_quiz: "اختبار التعلم الإلكتروني",
    e_learning_book: "كتاب التعلم الإلكتروني",
    e_learning_tedTalks: "محاضرات تيد التعليمية",
    rewards: "المكافآت",
  },

  newsFeed: {
    WriteForMe: "اكتب لي",
    AddDocuments: "أضف وثائق",
    AddPolls: "أضف استطلاعات",
    WhatsOnYourMind: "ماذا يخطر في بالك",
    Direction: "ltr",
    Generate: "انشئ",
    Change: "تغيير",
    Whatwouldyouliketotalkabout: "عن ماذا ترغب في الحديث؟",
    Post: "نشر",
    Poll: "استطلاع",
    Documents: "وثائق",
    PhotoVideo: "صورة/فيديو",
    Addpolloption: "إضافة خيار استطلاع",
    Removepoll: "إزالة الاستطلاع",
    WriteName: "اكتب الاسم",
    AddImageVideo: "أضف صورة/فيديو",
    CheckIn: "تسجيل الحضور",
    ShareonFeed: "مشاركة على الفيد",
    ShareinChat: "مشاركة في الدردشة",
    NoPosts: "لا توجد مشاركات...",
    Nomoreposts: "لا مزيد من المشاركات...",
    noDataMessage: "أنشئ مشاركة الآن!",
    CreateFeedNow: "أنشئ الخلاصة الآن!",
    QuickOptions: {
      CreateSlide: "إنشاء شريحة",
      CreateBoard: "إنشاء لوحة",
      CreateGrid: "إنشاء شبكة",
      CreatePad: "إنشاء وسادة",
      CreateTask: "إنشاء مهمة",
      CreateExpense: "إنشاء مصروف",
      AddUser: "إضافة مستخدم",
    },
    reactionDescription: {
      [ReactionType.Like]: "إعجاب",
      [ReactionType.Love]: "حب",
      [ReactionType.NoReaction]: "إعجاب",
      [ReactionType.Support]: "تقدير",
      [ReactionType.Celebrate]: "احتفال",
      [ReactionType.Curious]: "فضول",
    },
    PostTypePlaceHolder: {
      [PostType.DEFAULT]: "ماذا يخطر في بالك",
      [PostType.POLL]: "اسأل شيئًا...",
    },
    Titlecantbeempty: "لا يمكن أن يكون العنوان فارغًا",
    Attachmentscantbeempty: "لا يمكن أن تكون المرفقات فارغة",
    Optioncantbeempty: "لا يمكن أن يكون الخيار فارغًا",
    SuccessfullySharedonFeed: "تم المشاركة بنجاح على الفيد",
  },
  mail: {
    composer: "نیا میل بنائیں",
    menuItem: {
      inbox: "ان باکس",
      starred: "ستارہ لگے ہوئے",
      snoozed: "اسنوز کردہ",
      sent: "ارسال کردہ",
      spam: "اسپام",
      archive: "آرکائیو",
      trash: "کوڑے دان",
      draft: "مسودے",
      allMail: "تمام میل",
      createNewLabel: "نیا لیبل بنائیں",
    },
    close: "بند کریں",
    send: "بھیجیں",
    newMessage: "نیا پیغام",
  },
  loanDictionary: {
    createLoan: "قرض بنائیں",
    loanTenureInMonths: "قرض کی مدت/مہینے",
    deductionPerMonth: "مہینے/کٹوتی",
    pleaseEnterloanTenureInMonths: "براہ کرم قرض کی مدت درج کریں",
  },
  auction: {
    auctionType: "نوع المزاد",
    personal: "شخصي",
    official: "رسمي",
    offer: "عرض",
    startingBid: "المزايدة الابتدائية",
    buyNowAmount: "مبلغ الشراء الفوري",
    immediateBuy: "شراء فوري",
    minIncreaseBid: "الحد الأدنى لزيادة المزايدة",
    Auction: "المزاد",
    CreateAuction: "إنشاء مزاد",
    actionType: "نوع المزاد", // تحتاج إلى إزالة - زائدة
    external: "خارجي",
    auctionDetail: "تفاصيل المزاد",
    currentBid: "المزايدة الحالية",

    FormLabel: {
      startingBid: "المزايدة الابتدائية",
      member: "عضو",
      approvers: "الموافقون",
      minIncreaseBid: "الحد الأدنى لزيادة المزايدة",
      buyNowAmount: "مبلغ الشراء الفوري",
      offer: "عرض",
      immediateBuy: "شراء فوري",
    },
    FormPlaceholder: {
      name: "أدخل اسم المزاد",
      startingBid: "أدخل المزايدة الابتدائية",
      minIncreaseBid: "أدخل الحد الأدنى لزيادة المزايدة",
      selectMember: "اختر العضو",
      selectApprovers: "اختر الموافقين",
      buyNowAmount: "أدخل مبلغ الشراء الفوري",
      offer: "أدخل العرض",
    },
    FormRequiredMessage: {
      name: "يرجى إدخال اسم المزاد",
      startingBid: "يرجى إدخال المزايدة الابتدائية",
      minIncreaseBid: "يرجى إدخال الحد الأدنى لزيادة المزايدة",
      buyNowAmount: "يرجى إدخال مبلغ الشراء الفوري",
      offer: "يرجى إدخال العرض",
      endDate: "يرجى إدخال تاريخ الانتهاء",
    },
    auctionFilterTypeEnumLabel: {
      [auctionFilterTypeEnum.All]: "جميع المزادات",
      [auctionFilterTypeEnum.CreatedByMe]: "مزاداتي",
      [auctionFilterTypeEnum.ForApproval]: "للموافقة",
    },
    statusEnum: {
      [AuctionStatusEnum.InProcess]: "قيد التنفيذ",
      [AuctionStatusEnum.Approved]: "تم الموافقة عليه",
      [AuctionStatusEnum.Declined]: "تم الرفض",
      [AuctionStatusEnum.Resend]: "إعادة الإرسال",
      [AuctionStatusEnum.InActive]: "غير نشط",
      [AuctionStatusEnum.NotRequired]: "غير مطلوب",
      [AuctionStatusEnum.Cancelled]: "تم الإلغاء",
      [AuctionStatusEnum.Hold]: "في الانتظار",
    },
    auctionOfferStatus: {
      [AuctionOfferStatusEnum.BidInProcess]: "قيد التنفيذ",
      [AuctionOfferStatusEnum.BidSold]: "تم البيع",
    },
    noDataMessage: "إنشاء مزاد",
  },
  messenger: {
    Messenger: "المرسل",
    Typing: "يكتب...",
    TypeaMessage: "اكتب رسالة...",
    Recording: "تسجيل...",
    nameYourGroup: "اختر اسم مجموعتك",
    Create: "إنشاء",
    GroupNameRequired: "اسم المجموعة مطلوب",
    searchMemberToAdd: "البحث عن عضو للإضافة",
    contacts: "جهات الاتصال",
    selected: "تم اختياره",
    whatwouldyoutalkingabout: "عن ماذا تتحدث؟",
    SayHi: "قل مرحبًا لجهات الاتصال الخاصة بك",
  },
  marketPlace: {
    jobBoardName: "لوحة الوظائف",
    requsitionName: "الاستحواذ",
  },
  discussionBoard: {
    DiscussionBoard: "لوحة المناقشة",
    Topic: "موضوع",
    pleaseEnterTopicName: "الرجاء إدخال اسم الموضوع",
    enterTopicName: "أدخل اسم الموضوع",
    addDiscussionBoard: "إضافة لوحة المناقشة",
    errormessage: "حدث خطأ أثناء جلب لوحة المناقشة.",
    noDataMessage: "– أنشئ مناقشة الآن!",
  },
  pages: {
    messageName: "الرجاء إدخال الاسم",
    tags: "الوسوم",
    messageTags: "الرجاء إدخال الوسوم",
    enterTag: "أدخل الوسوم",
    createPage: "إنشاء صفحة",
    noDataMessage: "– أنشئ صفحة الآن!"
  },
  group: {
    group: "المجموعات",
    createGroup: "إنشاء مجموعة",
    Updategroup: "تحديث المجموعة",
    enterGroupName: "الرجاء إدخال اسم المجموعة.",
    Code: "الكود",
    exampleText: "على سبيل المثال: ج (لاسم مجموعة)",
    GroupTag: "وسم المجموعة",
    AddAdmin: "إضافة مدير",
    TotalBudget: "الميزانية الإجمالية",
    Spent: "المبلغ المدفوع",
    Balance: "الرصيد",
    EnterGroupTag: "إدخال وسم المجموعة",
    SaveGroupTag: "حفظ وسم المجموعة",
    AddGroupTag: "إضافة وسم المجموعة",
    EditGroup: "تعديل المجموعة",
    CreateGroupNow: "أنشئ المجموعة الآن!",
  },
  project: {
    WIP: "قيد التنفيذ",
    privateProject: "مشروع خاص",
    Projects: "المشاريع",
    UpdateProject: "تحديث المشروع",
    CreateProject: "إنشاء مشروع",
    noDataMessage: "– أنشئ مشروعًا الآن!",
    ProjectTag: "وسم المشروع",
    ProjectRole: "دور المشروع",
    exampleProjectName: "على سبيل المثال: ب (لاسم المشروع)",
    projectDate: "تاريخ المشروع",
    EnterProjectTag: "أدخل وسم المشروع",
    SaveProjectTag: "حفظ وسم المشروع",
    AddProjectTag: "إضافة وسم المشروع",
    editProject: "تحرير المشروع",
    PersonalNotes: "ملاحظات شخصية",
    EnterProjectRole: "أدخل دور المشروع",
    SaveCustomTag: "حفظ الوسم المخصص",
    AddProjectRole: "إضافة دور المشروع",
    TakeaNote: "خذ ملاحظة",
    PublicProject: "المشروع العام",
    ProjectFeatureTabs:{
      [ProjectFeatureEnums.Feed]: "الأخبار",
      [ProjectFeatureEnums.Schedule]: "الجدول",
      [ProjectFeatureEnums.WorkBoard]: "لوحة العمل",
      [ProjectFeatureEnums.Document]: "الملفات",
      [ProjectFeatureEnums.Task]: "المهام",
      [ProjectFeatureEnums.Expense]: "المصروفات",
      [ProjectFeatureEnums.Travel]: "الرحلات",
      [ProjectFeatureEnums.Quotation]: "التعريفات",
      [ProjectFeatureEnums.Budget]: "الميزانية",
      [ProjectFeatureEnums.Setting]: "الإعدادات",
      [ProjectFeatureEnums.DiscussionBoard]: "لوحة المناقشة",
      [ProjectFeatureEnums.Pages]: "الصفحات",
      [ProjectFeatureEnums.Issues]: "المشاكل",
      [ProjectFeatureEnums.Contract]: "العقود",
    }
  },
  tasks: {
    Tasks: "المهام",
    Predecessor: "السابق",
    AssignTo: "تخصيص لـ",
    TaskDate: "تاريخ المهمة",
    CreateTask: "إنشاء مهمة",
    TaskAssignTo: "تخصيص المهمة لـ",
    Rating: "التقييم",
    TaskDetail: "تفاصيل المهمة",
    ProgressofAssignedmembers: "تقدم الأعضاء المختصين",
    SubTasks: "مهام فرعية",
    Subtask: "مهمة فرعية",
    CreateSubtask: "إنشاء مهمة فرعية",
    Addsubtask: "إضافة مهمة فرعية",
    ParentTask: "المهمة الأصلية",
    DueToday: "تاريخ الاستحقاق اليوم",
    DueTomorrow: "تاريخ الاستحقاق غدًا",
    Overdue: "متأخر",
    Duein: "الاستحقاق في",
    days: "أيام",
    TaskCreatedSuccessfully: "تم إنشاء المهمة بنجاح",
    noDataMessage: "– أنشئ مهمة الآن!",

    selfTaskEnum: {
      [SelfTaskEnum.SelfTask]: "مهمة ذاتية",
      [SelfTaskEnum.AssignTask]: "تخصيص لـ",
    },
    Priority: "الأولوية",
    taskFilterTypeEnum: {
      [TaskFilterTypeEnum.CreatedByMe]: "تعيينها من قبلي",
      [TaskFilterTypeEnum.MyTasks]: "مهامي",
      [TaskFilterTypeEnum.MyTeamTasks]: "مهام الفريق",
    },
    statusEnum: {
      [TaskStatusEnum.NotStarted]: "لم تبدأ بعد",
      [TaskStatusEnum.InProcess]: "قيد التنفيذ",
      [TaskStatusEnum.Completed]: "مكتملة",
      [TaskStatusEnum.RatingAssign]: "تقييم مخصص",
      [TaskStatusEnum.Cancel]: "تم الإلغاء",
      [TaskStatusEnum.Hold]: "تم الإيقاف",
    },
    taskReferenceTypeEnum: {
      [TaskReferenceTypeEnum.General]: "عام",
      [TaskReferenceTypeEnum.Project]: "مشروع",
      [TaskReferenceTypeEnum.Group]: "مجموعة",
      [TaskReferenceTypeEnum.Lead]: "قيادة",
    },
    taskPriorityEnum: {
      [TaskPriorityEnum.Default]: "افتراضي",
      [TaskPriorityEnum.Low]: "منخفض",
      [TaskPriorityEnum.Medium]: "متوسط",
      [TaskPriorityEnum.High]: "عالي",
    },
    SortEnum: {
      [TaskFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [TaskFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء - تصاعدي",
      [TaskFilterSortEnum.StartDateDesc]: "تاريخ البدء - تنازلي",
      [TaskFilterSortEnum.StartDateAsc]: "تاريخ البدء - تصاعدي",
      [TaskFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلي",
      [TaskFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث - تصاعدي"
    }

  },
  contracts: {
    Contract: "عقد",
    createContract: "إنشاء عقد",
    contractDate: "تاريخ العقد",
    contractPurpose: "غرض العقد",
    selectContractPurpose: "اختر غرض العقد",
    purposeofContractisRequired: "غرض العقد مطلوب",
    category: "فئة",
    purpose: "غرض",
    signatureMember: "أعضاء التوقيع",
    contractDetail: "تفاصيل العقد",
    selectCategory: "اختر الفئة",
    cateogryofContractisRequired: "الفئة مطلوبة",
    externalMember: "أعضاء التوقيع الخارجيين",
    selectinternalMembers: "اختر أعضاء التوقيع الداخليين",
    internalMembers: "أعضاء التوقيع الداخليين",
    selectExternalMember: "اختر أعضاء التوقيع الخارجيين",
    contractType: "نوع العقد",
    contractTypeRequired: "نوع العقد مطلوب",
    internal: "داخلي",
    external: "خارجي",
    noDataMessage: "أنشئ العقد الآن!",
    contractsFilterTypeLables: {
      [ContractFilterTypeEnum.Contracts]: "جميع العقود التجارية",
      [ContractFilterTypeEnum.BusinessContract]: "عقودي"
    },
    statusEnum: {
      [ContractStatusEnum.InProcess]: "قيد المعالجة",
      [ContractStatusEnum.Completed]: "مكتمل",
      [ContractStatusEnum.Cancelled]: "ملغى"
    },
    contractReferenceTypeEnum: {
      [ContractReferenceTypeEnum.General]: "عام",
      [ContractReferenceTypeEnum.Project]: "مشروع",
      [ContractReferenceTypeEnum.Group]: "مجموعة",
      [ContractReferenceTypeEnum.Lead]: "عميل محتمل"
    }
  },
  workBoard: {
    WorkBoardDashBoard: "لوحة العمل",
    pleaseEnterYourBoardName: "الرجاء إدخال اسم اللوحة",
    pleaseEnterYourBoardDescription: "الرجاء إدخال وصف اللوحة",
    EnterMembers: "أدخل الأعضاء",
    UpdateBoard: "تحديث اللوحة",
    CreateBoard: "إنشاء لوحة",
    privacy: "الخصوصية",
    AddSection: "إضافة قسم",
    AddTodo: "إضافة مهمة",
    Save: "حفظ",
    EnterSectionTitle: "إدخال عنوان القسم",
    InSection: "في القسم",
    Label: "تصنيف",
    Activity: "النشاط",
    Labels: "التصنيفات",
    UploadCoverPhoto: "تحميل صورة الغلاف",
    Section: "القسم",
    Board: "اللوحة",
    Todos: "المهام",
    CompleteDate: "تاريخ الانتهاء",
    DetailedDesc: "أضف وصفًا مفصلًا إضافيًا...",
    EnterTodo: "أدخل النص لهذه البطاقة...",
    CreateWorkBoardNow: "- إنشاء لوحة العمل الآن!",
    workBoardExample: "مثال: WB (للوحة بعنوان لوحة العمل)",
  },
  leadManager: {
    LeadManager: "مدير العملاء",
    AssignedLeads: "العملاء المخصصون",
    AllLeads: "جميع العملاء",
    Groups: "المجموعات",
    email: "البريد الإلكتروني",
    Lead: "العميل",
    Leads: "العملاء",
    Pleaseinputyourboardname: "يرجى إدخال اسم لوحتك!",
    exampleLeadManagerMessage: "مثال: LM (لمجموعة تسمى مدير العملاء)",
    inputBoardDescription: "يرجى إدخال وصف لوحتك!",
    UpdateLeadManager: "تحديث مدير العملاء",
    CreateLeadManager: "إنشاء مدير العملاء",
    CreateLeadGroup: "إنشاء مجموعة العملاء",
    noDataMessage: "– أنشئ مدير العملاء الآن!",
    LastUpdated: "آخر تحديث",
    LeadValue: "قيمة العميل",
    individual: "فرد",
    leadEmailAddress: "عنوان البريد الإلكتروني للعميل",
    leadPhoneNumber: "رقم هاتف العميل",
    leadAddress: "عنوان العميل",
    leadUrl: "رابط العميل",
    ConvertProject: "تحويل إلى مشروع",
    goToProject: "انتقل إلى المشروع",
    SelectAssignMembers: "تحديد أعضاء التكليف",
    LeadGroup: "مجموعة العملاء",
    leadName: "اسم العميل",
    leadMembers: "أعضاء العملاء",
    leadWebiste: "موقع العميل",
    LeadValuenegative: "قيمة العميل لا يمكن أن تكون سالبة!",
    AddLead: "إضافة عميل",
    SelectCommunicationTo: "تحديد وسيلة التواصل",
    CommunicationTypeisrequired: "نوع التواصل مطلوب!",
    PleaseselectCommunicationType: "يرجى تحديد نوع التواصل",
    CommunicationType: "نوع التواصل",
    AddCommunication: "إضافة وسيلة تواصل",
    CommunicationTo: "التواصل مع",
    NoCalls: "لا مكالمات",
    LeadsValue: "قيمة العملاء",
    metricTypeEnum: {
      [LeadManagerMetricTypeEnum.allGroup]: "إجمالي المجموعات",
      [LeadManagerMetricTypeEnum.allAssign]: "العملاء المخصصون",
      [LeadManagerMetricTypeEnum.leadToProject]: "تحويل إلى مشروع",
      [LeadManagerMetricTypeEnum.leadSection]: "قسم العملاء الأقصى",
    },
  },
  schedule: {
    StartMeeting: "بدء الاجتماع",
    MeetinginProgress: "الاجتماع قائم",
    EndMeeting: "انتهاء الاجتماع",
    MeetingEnded: "انتهى الاجتماع",
    NewTokenAdded: "تمت إضافة رمز جديد",
    Copied: "تم النسخ",
    SuccessfullyBlocked: "تم حظر بنجاح",
    SuccessfullyUnBlocked: "تم إلغاء الحظر بنجاح",
    UpdateCalendarLink: "تحديث رابط التقويم",
    AddCalendarLink: "إضافة رابط التقويم",
    GetCalendarLink: "الحصول على رابط التقويم",
    CopyLink: "نسخ الرابط",
    Block: "حظر",
    UnBlock: "إلغاء الحظر",
    CreateSchedule: "إنشاء الجدول",
    Draft: "مسودة",
    Finalize: "إنهاء",
    Complete: "اكتمال",
    Preview: "معاينة",
    MinutesOftheMeeting: "دقائق الاجتماع",
    MeetingId: "رقم الاجتماع",
    Venue: "المكان",
    Workwisecall: "مكالمة ورك وايز",
    ConferenceCall: "مكالمة مؤتمر",
    Attendees: "الحاضرون",
    Absentees: "الغائبون",
    PointsofDiscussion: "نقاط النقاش",
    AddPoint: "إضافة نقطة",
    Print: "طباعة",
    SaveToPDF: "حفظ إلى PDF",
    MEETINGMINUTES: "دقائق الاجتماع",
    Yes: "نعم",
    No: "لا",
    WorkwiseCall: "مكالمة ورك وايز",
    MeetingObjectives: "أهداف الاجتماع",
    Points: "النقاط",
    RefNo: "رقم المرجع",
    AttendanceatMeeting: "الحضور في الاجتماع",
    Loadingschedules: "جاري تحميل الجداول...",
    Nodata: "لا توجد بيانات...",
    Projects: "المشاريع",
    Groups: "المجموعات",
    titleMessage: "هل أنت متأكد؟ سيؤدي تغيير النوع إلى إزالة الأعضاء المعينين.",
    pleaseSelectAssignMemeber: "يرجى تحديد عضو معين!",
    SelectAssignto: "تحديد للتعيين",
    GetLink: "الحصول على الرابط",
    Anyone: "أي شخص",
    anyoneText: "مع الرابط يمكن لأي شخص إنشاء موعد.",
    TodayEvents: "فعاليات اليوم",
    UpcomingEvents: "الفعاليات القادمة",
    InterviewRequests: "طلبات المقابلة",
    sendviachat: "إرسال عبر الدردشة",
    OnVideoConference: "في مؤتمر الفيديو:",
    OnWorkwiseCall: "على مكالمة ورك وايز",
    Enterlocation: "أدخل الموقع",
    Duration: "المدة",
    inminutes: "بالدقائق",
    Durationisrequired: "المدة مطلوبة",
    Rooms: "الغرف",
    SelectRoom: "حدد الغرفة",
    CheckRoomAvailability: "تحقق من توفر الغرفة",
    Available: "متاحة",
    NotAvailable: "غير متاحة",
    TravelTime: "وقت السفر",
    PreparationTime: "وقت التحضير",
    SendviaEmail: "إرسال عبر البريد الإلكتروني",
    schedulerCalender: "تقويم المجدول",
    Next: "التالي",
    Prev: "السابق",
    titlemyCustomButton: "عنوان الزر المخصص",
    prevnexttoday: "السابق التالي اليوم",
    timeGridDaytimeGridWeekdayGridMonth: "شبكة الوقت يوم شبكة الوقت أسبوع شبكة الوقت شهر",
    timeGridDay: "شبكة الوقت يوم",
    top: "أعلى",
    block: "حظر",
    minutes: "دقائق",
    AllDay: "طوال اليوم",
    timeGridWeek: "شبكة الوقت أسبوع",
    slotDuration: "00:15:00",
    numeric: "رقمي",
    dayGridMonth: "شبكة اليوم شهر",
    dayGridWeek: "شبكة اليوم أسبوع",
    timeGrid: "شبكة الوقت",
    Day: "يوم",
    Week: "أسبوع",
    Month: "شهر",
    NoEventsonthisday: "لا توجد فعاليات في هذا اليوم.",
    CreateInterviewSchedule: "إنشاء جدول المقابلة",
    Interviewwith: "المقابلة مع",
    MySchedules: "جداولي",
    TeamSchedules: "جداول الفريق",
    Past: "الماضي",
    Today: "اليوم",
    Upcoming: "القادم",
    ScheduleDetail: "تفاصيل الجدول",
    JoinWorkwiseCall: "الانضمام إلى مكالمة ورك وايز",
    AddWorkwiseCall: "إضافة مكالمة ورك وايز",
    Host: "(المضيف)",
    MakeHost: "جعل المضيف",
    Attending: "الحاضرين",
    UpdateSchedule: "تحديث الجدول",
    Calendar: "التقويم",
    Schedule: "الجدول",
    ShareCalendarLink: "مشاركة رابط التقويم",
    pointer: "مؤشر",
    default: "افتراضي",
    blue: "أزرق",
    red: "أحمر",
    purple: "أرجواني",
    Self: "ذاتي",
    AssignTo: "تعيين لـ",
    assign: "تعيين",
    min: "دقيقة",
    Minutes: "15 دقيقة",
  },
  expenses: {
    CreateExpense: "إنشاء مصروف",
    IsReimbursable: "قابل للاسترداد",
    WriteHeaderHere: "اكتب العنوان هنا...",
    ExpenseDetail: "تفاصيل المصروف",
    ExpenseDate: "تاريخ المصروف",
    Executors: "المنفذون",
    Financers: "الممولون",
    ExecutorRequired: "المنفذ مطلوب",
    selectExecutor: "حدد المنفذ للمصروف",
    noDataMessage: "مكّن رحلتك المالية - أنشئ مصروفًا الآن!",
    expenseFilterTypeEnum: {
      [ExpenseFilterTypeEnum.MyExpense]: "مصروفي",
      [ExpenseFilterTypeEnum.ForApproval]: "للموافقة",
      [ExpenseFilterTypeEnum.ForExecutions]: "للتنفيذ",
      [ExpenseFilterTypeEnum.ForFinance]: "للتمويل",
    },
    expenseType: {
      [ExpenseReferenceTypeEnum.General]: "عام",
      [ExpenseReferenceTypeEnum.Group]: "مجموعة",
      [ExpenseReferenceTypeEnum.Project]: "مشروع",
      [ExpenseReferenceTypeEnum.Travel]: "سفر",
      [ExpenseReferenceTypeEnum.Assets]: "أصول",
    },
    statusEnum: {
      [ExpenseApprovalStatusEnum.InProcess]: "قيد التنفيذ",
      [ExpenseApprovalStatusEnum.Approved]: "تمت الموافقة عليه",
      [ExpenseApprovalStatusEnum.Cancelled]: "تم الإلغاء",
      [ExpenseApprovalStatusEnum.Completed]: "تم الاكتمال",
      [ExpenseApprovalStatusEnum.Hold]: "في الانتظار",
      [ExpenseApprovalStatusEnum.Disbursed]: "صرف",
    },
    SortEnum: {
      [ExpenseFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [ExpenseFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء",
      [ExpenseFilterSortEnum.ExpenseDateDesc]: "تاريخ المصاريف - تنازلي",
      [ExpenseFilterSortEnum.ExpenseDate]: "تاريخ المصاريف",
      [ExpenseFilterSortEnum.ReferenceNo]: "الرقم المرجعي",
      [ExpenseFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلي",
      [ExpenseFilterSortEnum.Amount]: "المبلغ",
      [ExpenseFilterSortEnum.AmountDesc]: "المبلغ - تنازلي"
    },
    Category: {
      [ExpenseCategoryEnum.Transport]: "النقل",
      [ExpenseCategoryEnum.Health]: "الصحة",
      [ExpenseCategoryEnum.Food]: "الطعام",
      [ExpenseCategoryEnum.Shopping]: "التسوق",
      [ExpenseCategoryEnum.Entertainment]: "الترفيه",
      [ExpenseCategoryEnum.Travel]: "السفر",
      [ExpenseCategoryEnum.Bill]: "الفاتورة",
      [ExpenseCategoryEnum.Office]: "المكتب",
      [ExpenseCategoryEnum.Fuel]: "الوقود",
      [ExpenseCategoryEnum.Asset]: "الأصل",
      [ExpenseCategoryEnum.Other]: "أخرى"
    }

  },
  travel: {
    TravelDetail: "تفاصيل السفر",
    CreateTravel: "إنشاء رحلة",
    PleaseAddTravelDetail: "يرجى إضافة تفاصيل السفر",
    TravelToandFrom: "السفر من وإلى",
    ReturnDateIsRequired: "تاريخ العودة مطلوب",
    PleaseSelectTravelType: "يرجى تحديد نوع السفر",
    HotelTADACar: "فندق، تادا وسيارة",
    HotelRequired: "الفندق مطلوب",
    RentaCar: "استئجار سيارة",
    PickUpRequired: "مطلوب الالتقاط",
    DropOffRequired: "مطلوب الإفلات",
    TADAApplicable: "تادا قابلة للتطبيق",
    TravelBy: "وسيلة السفر",
    SpecialRequest: "طلب خاص",
    SearchDestinationCity: "ابحث عن مدينة الوجهة",
    SearchDepartureCity: "ابحث عن مدينة المغادرة",
    PleaseSelectDepartureCity: "يرجى تحديد مدينة المغادرة.",
    PleaseSelectDestinationCity: "يرجى تحديد مدينة الوجهة.",
    DepartureTimeIsRequired: "وقت المغادرة مطلوب",
    RetunTimeIsRequired: "وقت العودة مطلوب",
    AttachmentType: "نوع المرفق",
    WriteSpecialRequestDetail: "اكتب تفاصيل الطلب الخاص",
    Agents: "وكلاء",
    Departure: "المغادرة",
    Arrival: "الوصول",
    DepartureDate: "تاريخ المغادرة",
    DepartureTime: "وقت المغادرة",
    DepartureCity: "مدينة المغادرة",
    ShiftType: "نوع التحول",
    DepartureShift: "تحول المغادرة",
    ArrivalCity: "مدينة الوصول",
    CreateTravelnow: "إنشاء السفر الآن",

    TravelAttachmentTypeOptions: {
      [TravelAttachmentTypeEnum.Passport]: "جواز السفر",
      [TravelAttachmentTypeEnum.Ticket]: "تذكرة",
      [TravelAttachmentTypeEnum.Insurance]: "تأمين",
      [TravelAttachmentTypeEnum.CarRental]: "تأجير سيارة",
      [TravelAttachmentTypeEnum.Other]: "آخر",
    },

    Shift: {
      [ShiftEnum.Morning]: "الصباح",
      [ShiftEnum.Afternoon]: "بعد الظهر",
      [ShiftEnum.Evening]: "المساء",
      [ShiftEnum.Night]: "الليل",
    },

    travelBy: {
      plane: "بواسطة الطائرة",
      bus: "بواسطة الحافلة",
      ship: "بواسطة السفينة",
      train: "بواسطة القطار",
      car: "بواسطة السيارة",
    },

    TravelOptions: {
      [TravelOptionsEnums.ByPlane]: "بواسطة الطائرة",
      [TravelOptionsEnums.ByShip]: "بواسطة السفينة",
      [TravelOptionsEnums.ByBus]: "بواسطة الحافلة",
      [TravelOptionsEnums.ByTrain]: "بواسطة القطار",
      [TravelOptionsEnums.ByCar]: "بواسطة السيارة",
    },

    travelFilterTypeEnum: {
      [TravelFilterTypeEnum.Travels]: "السفر",
      [TravelFilterTypeEnum.MyTravel]: "سفري",
      [TravelFilterTypeEnum.ForApproval]: "للموافقة",
      [TravelFilterTypeEnum.AgentProcess]: "عملية الوكيل",
    },

    statusEnum: {
      [TravelStatusEnum.InProcess]: "قيد التنفيذ",
      [TravelStatusEnum.Approved]: "تم الموافقة",
      [TravelStatusEnum.Declined]: "تم الرفض",
      [TravelStatusEnum.Cancelled]: "تم الإلغاء",
      [TravelStatusEnum.ApprovedByApprovers]: "تمت الموافقة من قبل الموافقين",
      [TravelStatusEnum.ApprovedByAgents]: "تمت الموافقة من قبل الوكلاء",
      [TravelStatusEnum.DeclineByApproves]: "تم الرفض من قبل الموافقين",
      [TravelStatusEnum.DeclineByAgents]: "تم الرفض من قبل الوكلاء",
      [TravelStatusEnum.Hold]: "تم الاحتجاز",
    },
  },
  eLearning: {
    Course: "دورة",
    eBook: "كتاب إلكتروني",
    Quiz: "اختبار",
    TedTalk: "محاضرة تيد",
    Article: "مقال",
    Video: "فيديو",
    BasicCourseDetail: "تفاصيل الدورة الأساسية",
    SelectSubject: "اختر الموضوع",
    SelectCourse: "اختر الدورة",
    PleaseSelectOption: "يرجى اختيار الخيار",
    Selectcoursetype: "اختر نوع الدورة",
    CourseName: "اسم الدورة",
    Entercoursename: "أدخل اسم الدورة",
    Level: "المستوى",
    PleaseSelectLevel: "يرجى اختيار المستوى",
    Selectlevel: "اختر المستوى",
    CourseAdminMembers: "أعضاء إدارة الدورة",
    CourseAdminisrequired: "إدارة الدورة مطلوبة",
    AssignMembers: "تعيين الأعضاء",
    UpdateEBook: "تحديث الكتاب الإلكتروني",
    CreateEBook: "إنشاء كتاب إلكتروني",
    AuthorName: "اسم المؤلف",
    EnterInformation: "أدخل المعلومات",
    UploadBook: "رفع الكتاب (PDF)",
    CreateQuiz: "إنشاء اختبار",
    QuizName: "اسم الاختبار",
    CreateTedTalk: "إنشاء محاضرة تيد",
    ViaLink: "عبر الرابط",
    ViaVideoUpload: "عبر رفع الفيديو",
    PleaseInsertLink: "يرجى إدخال الرابط",
    UploadVideo: "رفع الفيديو",
    CreateArticle: "إنشاء مقال",
    InsertLink: "أدخل الرابط",
    UploadPDF: "رفع صورة أو PDF",
    CreateVideo: "إنشاء فيديو",
    LessonDetail: "تفاصيل الدرس",
    Eitherselect: "إما اختيار اختبار أو حذفه.",
    Selectquiz: "اختر الاختبار",
    AddNewQuiz: "أضف اختبارًا جديدًا",
    Atleastonetopic: "مطلوب على الأقل موضوع واحد لهذا الدرس.",
    LessonNameRequired: "اسم الدرس مطلوب.",
    LessonName: "اسم الدرس",
    DeleteLesson: "حذف الدرس",
    LessonDescriptionRequired: "وصف الدرس مطلوب.",
    TopicNameIsRequired: "اسم الموضوع مطلوب.",
    TopicName: "اسم الموضوع",
    DeleteTopic: "حذف الموضوع",
    TopicDescription: "وصف الموضوع مطلوب.",
    eitherEnterText: "إما إدخال النص أو حذفه.",
    EitherenterA: "إما إدخال",
    OrRemoveIt: "أو حذفه.",
    CreateArticlesNow: "أنشئ مقالات الآن!",
    CreateCoursesNow: "أنشئ دورات الآن!",
    CreateEbooksNow: "أنشئ كتب إلكترونية الآن!",
    CreateElearningNow: "أنشئ تعليم إلكتروني الآن!",
    CreateQuizzesNow: "أنشئ اختبارات الآن!",
    CreateTeamDashboardNow: "أنشئ لوحة فريق الآن!",
    CreateTedTalksNow: "أنشئ محاضرات تيد الآن!",
    CreateVideosNow: "أنشئ فيديوهات الآن!",
    OptionEmpty: "الخيار لا يمكن أن يكون فارغًا",
    CorrectOptionRequired: "الخيار الصحيح مطلوب",
    QuestionEmpty: "السؤال لا يمكن أن يكون فارغًا",
    UntitledQuestion: "سؤال بدون عنوان",
    AddQuestion: "أضف سؤالًا",
    DeleteQuestion: "حذف السؤال",
    Quiznamerequired: "اسم الاختبار مطلوب!",
    QuizexpirationDate: "تاريخ انتهاء الاختبار",
    QuizExpirationDateIsRequired: "تاريخ انتهاء الاختبار مطلوب",
    QuizDescriptionRequired: "وصف الاختبار مطلوب!",
    StartQuiz: "ابدأ الاختبار",
    GoToResults: "اذهب إلى النتائج",
    Gettoknown: "تعرف على تجربة المستخدم",
    Missingdata: "بيانات مفقودة.",
    UntitledLesson: "درس بدون عنوان",
    RemoveText: "حذف النص",
    AddText: "أضف نصًا",
    RemoveImage: "حذف الصورة",
    AddImage: "أضف صورة",
    RemoveVideo: "حذف الفيديو",
    AddVideo: "أضف فيديو",
    RemovePDF: "حذف PDF",
    AddPDF: "أضف PDF",
    RemoveQuiz: "حذف الاختبار",
    AddQuiz: "أضف اختبارًا",
    RemoveLink: "حذف الرابط",
    AddLink: "أضف رابطًا",
    RemoveYouTubeLink: "حذف رابط YouTube",
    AddYouTubeLink: "أضف رابط YouTube",
    RemoveExternalCourse: "حذف دورة خارجية",
    AddExternalCourse: "أضف دورة خارجية",
    UntitledTopic: "موضوع بدون عنوان",
    DashboardChart: "مخطط لوحة القيادة",
    LatestTrendInBusiness: "أحدث اتجاه في الأعمال",
    InformationWillComeHere: "المعلومات ستأتي هنا",
    CourseContent: "محتوى الدورة",
    DummyContent: "محتوى وهمي",
    EndCourse: "إنهاء الدورة",
    Chapters: "الفصول •",
    Lectures: "المحاضرات •",
    LessonsInThisProgram: "الدروس في هذا البرنامج",
    BookInformation: "معلومات الكتاب",
    TedTalksDaily: "محاضرات تيد اليومية",
    TopicTitle: "عنوان الموضوع",
    VisitThis: "قم بزيارة هذا",
    LearnPractical: "تعلم المهارات العملية من خلال الدورات واحصل على تدريب مدفوع.",
    Courses: "الدورات",
    Book: "الكتاب",
    Tedtalk: "محاضرة تيد",
    Articles: "المقالات",
    NoPageExist: "لا توجد صفحة",
    Books: "الكتب",
    TedTalks: "محاضرات تيد",
    Videos: "الفيديوهات",
    GoTo: "اذهب إلى",
    CourseLevelIdLabel: {
      [CourseLevelIdEnum.Advance]: "متقدم",
      [CourseLevelIdEnum.Intermediate]: "متوسط",
      [CourseLevelIdEnum.Beginner]: "مبتدئ"
    }
  },
  attendence: {
    Attendance: "الحضور",
    TimeSpent: "الوقت المستغرق",
    GraceTime: "وقت السماح",
    GoodDay: "يوم جيد",
    Howfeeling: "كيف تشعر اليوم؟",
    Note: "ملاحظة",
    Noattendanceforapproval: "لا توجد حضور للموافقة.",
    Iwaslate: "تأخرت بسبب حركة المرور.",
    Showtimespent: "عرض الوقت المستغرق",
    ClockIn: "قم بتسجيل الدخول لبدء سجل الحضور الخاص بك.",
    selectStatus: "يرجى اختيار حالة!",
    ByTime: "حسب الوقت",
    ByStatus: "حسب الحالة",
    DeductionPolicy: "سياسة الخصم",
    UpdateDeductionPolicy: "تحديث سياسة الخصم",
    AddDeductionPolicy: "إضافة سياسة الخصم",
    DaysRange: "نطاق الأيام",
    PerDay: "لكل يوم",
    SaveDeductionPolicy: "حفظ سياسة الخصم",
    AbsentPolicy: "سياسة الغياب",
    LatePolicy: "سياسة التأخير",
    IsPercentage: "كنسبة مئوية",

    attendanceFilterTypeEnum: {
      [AttendanceFilterTypeEnum.checkInForApproval]: "تسجيل الدخول للموافقة",
      [AttendanceFilterTypeEnum.dayWiseAttendance]: "الحضور اليومي",
      [AttendanceFilterTypeEnum.userAttendance]: "حضور المستخدم",
      [AttendanceFilterTypeEnum.Summary]: "الملخص",
    },

    updateErrorMessage: {
      [AttendanceStateEnum.Off]: "التحديث غير متاح للحالة المغلقة",
      [AttendanceStateEnum.Holiday]: "التحديث غير متاح للأيام العطل",
      [AttendanceStateEnum.Leave]: "التحديث غير متاح لحالة الإجازة",
    },
    statusEnum: {
      [AttendanceStatusEnum.InProcess]: "قيد المعالجة",
      [AttendanceStatusEnum.Approved]: "موافق عليه",
      [AttendanceStatusEnum.Declined]: "مرفوض",
    },
    moodLabels: {
      [AttendanceMoodEnum.VeryUnsatisfied]: "غير راضٍ جدًا",
      [AttendanceMoodEnum.Unsatisfied]: "غير راضٍ",
      [AttendanceMoodEnum.Neutral]: "محايد",
      [AttendanceMoodEnum.Satisfied]: "راضٍ",
      [AttendanceMoodEnum.VerySatisfied]: "راضٍ جدًا",
    },
    attendanceState: {
      [AttendanceStateEnum.Unknown]: "",
      [AttendanceStateEnum.Present]: "حاضر",
      [AttendanceStateEnum.Absent]: "غائب",
      [AttendanceStateEnum.Leave]: "إجازة",
      [AttendanceStateEnum.Late]: "متأخر",
      [AttendanceStateEnum.WorkFromHome]: "العمل من المنزل",
      [AttendanceStateEnum.Off]: "مغلق",
      [AttendanceStateEnum.Holiday]: "عطلة",
    },
    attendanceType: {
      [ATTENDANCE_ENUM.CHECK_IN.IN]: "تسجيل الدخول",
      [ATTENDANCE_ENUM.CHECK_IN.OUT]: "تسجيل الخروج",
    },
    attendanceMetricLabel: {
      [AttendanceMetricTypeEnum.overallTimeSpent]: "إجمالي الوقت المستغرق",
      [AttendanceMetricTypeEnum.Present]: "حاضر",
      [AttendanceMetricTypeEnum.Absent]: "غائب",
      [AttendanceMetricTypeEnum.Leave]: "إجازة",
      [AttendanceMetricTypeEnum.Late]: "متأخر",
    },
  },
  customApproval: {
    createCustomApproval: "إنشاء موافقة مخصصة",
    customApproval: "الموافقة المخصصة",
    noDataMessage: "– أنشئ موافقات مخصصة الآن!",
    customApprovalFilterTypeEnum: {
      [CustomApprovalFilterTypeEnum.All]: "كل الموافقات المخصصة",
      [CustomApprovalFilterTypeEnum.CreatedByMe]: "موافقاتي المخصصة",
      [CustomApprovalFilterTypeEnum.ForApproval]: "للموافقة",
    },
    statusEnum: {
      [CustomApprovalStatusEum.InProcess]: "قيد التنفيذ",
      [CustomApprovalStatusEum.Approved]: "تمت الموافقة عليها",
      [CustomApprovalStatusEum.Declined]: "تم الرفض",
      [CustomApprovalStatusEum.Resend]: "إعادة الإرسال",
      [CustomApprovalStatusEum.InActive]: "غير نشطة",
      [CustomApprovalStatusEum.NotRequired]: "غير مطلوبة",
      [CustomApprovalStatusEum.Cancelled]: "تم الإلغاء",
      [CustomApprovalStatusEum.Hold]: "في الانتظار",
    },
    SortEnum: {
      [CustomApprovalFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلي",
      [CustomApprovalFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث",
      [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلي",
      [CustomApprovalFilterSortEnum.ReferenceNo]: "الرقم المرجعي",
      [CustomApprovalFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [CustomApprovalFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء"
    }

  },
  reward: {
    CreateReward: "إنشاء مكافأة",
    Rewards: "المكافآت",
    RewardTo: "مكافأة لـ",
    RewardCategory: "فئة المكافأة",
    EnterRewardCategory: "أدخل فئة المكافأة",
    RewardName: "اسم المكافأة",
    EnterRewardName: "أدخل اسم المكافأة",
    PleaseEnterRewardName: "الرجاء إدخال اسم المكافأة",
    ReasonForReward: "سبب المكافأة",
    EnterReasonForReward: "أدخل سبب المكافأة",
    PleaseEnterReasonForReward: "الرجاء إدخال سبب المكافأة",
    noDataMessage: "– قم بإنشاء مكافأة الآن!",
    rewardFilterTypeEnum: {
      [RewardFilterTypeEnum.All]: "كل المكافآت",
      [RewardFilterTypeEnum.CreatedByMe]: "تم إنشاؤها بواسطتي",
      [RewardFilterTypeEnum.ForApproval]: "للموافقة",
      [RewardFilterTypeEnum.RewardForMe]: "مكافآتي",
    },
    statusEnum: {
      [RewardStatusEnum.InProcess]: "قيد التنفيذ",
      [RewardStatusEnum.Approved]: "تمت الموافقة عليها",
      [RewardStatusEnum.Declined]: "تم الرفض",
      [RewardStatusEnum.Cancelled]: "تم الإلغاء",
      [RewardStatusEnum.Hold]: "تم الاحتجاز",
    },
    SortEnum: {
      [RewardFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلياً",
      [RewardFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث",
      [RewardFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلياً",
      [RewardFilterSortEnum.ReferenceNo]: "الرقم المرجعي",
      [RewardFilterSortEnum.StatusDesc]: "الحالة - تنازلياً",
      [RewardFilterSortEnum.Status]: "الحالة",
      [RewardFilterSortEnum.CategoryDesc]: "الفئة - تنازلياً",
      [RewardFilterSortEnum.Category]: "الفئة",
      [RewardFilterSortEnum.NameDesc]: "الاسم - تنازلياً",
      [RewardFilterSortEnum.Name]: "الاسم",
      [RewardFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلياً",
      [RewardFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء"
    }
  },
  document: {
    mySigned: "موقّع من قبلي",
    forMySignature: "لتوقيعي",
    draftSignature: "مسودة",
    inSigning: "قيد التوقيع",
    signaturesCompleted: "التوقيعات مكتملة",
    DocsArchive: "المستندات والأرشيف",
    createDocument: "إنشاء مستند",
    CreateSlides: "إنشاء شرائح",
    CreateBoard: "إنشاء لوحة",
    CreatePad: "إنشاء وسادة",
    CreateGrid: "إنشاء شبكة",
    newFolder: "مجلد جديد",
    createFolder: "إنشاء مجلد",
    uploadDocuments: "تحميل المستندات",
    addBoard: "إضافة لوحة",
    addPad: "إضافة وسادة",
    addGrid: "إضافة شبكة",
    addSlide: "إضافة شريحة",
    addMindmap: "إضافة خريطة ذهنية",
    CompleteDocument: "استكمال المستند",
    noDataMessage: "- قم بإنشاء مستند الآن!",
    AllDocuments: "جميع المستندات",
    MyDrive: "محركي",
    MyDocuments: "مستنداتي للموافقة",
    ForApprovals: "للموافقة",
    OnlyFiles: "الملفات فقط",
    fileName: "اسم الملف",
    shortDescription: "وصف قصير (حد أقصى 100 حرف)",
    AllFiles: "جميع الملفات",
    Grids: "شبكات",
    Pads: "وسادات",
    Slides: "شرائح",
    Boards: "لوحات",
    Mindmaps: "خرائط العقل",
    updateFolder: "تحديث المجلد",
    updateGrid: "تحديث الشبكة",
    updatePad: "تحديث الوسادة",
    updateSlide: "تحديث الشريحة",
    updateBoard: "تحديث اللوحة",
    updateMindMap: "تحديث الخريطة الذهنية",
    updateDocuments: "تحديث المستند",

    status: {
      InProcess: "قيد التنفيذ",
      Approved: "تمت الموافقة عليه",
      Declined: "تم الرفض",
      Resend: "إعادة الإرسال",
      NotRequired: "غير مطلوب",
      Cancelled: "تم الإلغاء",
      Draft: "مسودة"
    },
    documentReferenceType: {
      [DocumentReferenceTypeEnum.General]: "عام",
      [DocumentReferenceTypeEnum.Group]: "مجموعة",
      [DocumentReferenceTypeEnum.Project]: "مشروع",
    },
  },
  warning: {
    WarningDetail: "تفاصيل التحذير",
    warningTo: "التحذير إلى",
    createWarning: "إنشاء تحذير",
    enterwarningCategory: "أدخل فئة التحذير",
    noDataMessage: '– أنشئ تحذيرًا الآن!',
    filter: "تصفية",
    warningFilterTypeEnum: {
      [WarningFilterTypeEnum.Warning]: "تحذيرات",
      [WarningFilterTypeEnum.CreatedByMe]: "تم إنشاؤها بواسطتي",
      [WarningFilterTypeEnum.ForApproval]: "للموافقة",
      [WarningFilterTypeEnum.WarningToMe]: "تحذير إلي",
    },
    statusEnum: {
      [WarningStatusEnum.InProcess]: "قيد التنفيذ",
      [WarningStatusEnum.Approved]: "تمت الموافقة عليه",
      [WarningStatusEnum.Declined]: "تم الرفض",
      [WarningStatusEnum.Cancelled]: "تم الإلغاء",
      [WarningStatusEnum.Hold]: "في الانتظار",
    },
    SortEnum: {
      [WarningFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [WarningFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi - Artan",
      [WarningFilterSortEnum.ReferenceNoDesc]: "Referans Numarası - Azalan",
      [WarningFilterSortEnum.ReferenceNo]: "Referans Numarası",
      [WarningFilterSortEnum.StatusDesc]: "Durum - Azalan",
      [WarningFilterSortEnum.Status]: "Durum",
      [WarningFilterSortEnum.CategoryDesc]: "Kategori - Azalan",
      [WarningFilterSortEnum.Category]: "Kategori",
      [WarningFilterSortEnum.NameDesc]: "Ad - Azalan",
      [WarningFilterSortEnum.Name]: "Ad",
      [WarningFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [WarningFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi - Artan"
    }

  },
  jobBoard: {
    jobBoard: "لوحة الوظائف",
    noDataMessage: " – أنشئ وظائف الآن!",
  },
  businessPolicy: {
    BusinessPolicy: "سياسة الأعمال",
    policies: "السياسات",
    noPolicy: "لم يتم العثور على أي سياسة...",
  },
  forms: {
    CreateForm: "إنشاء نموذج",
    Forms: "النماذج",
    Attempt: "محاولة",
    CopyLink: "نسخ الرابط",
    Title: "العنوان",
    EnterTitle: "أدخل العنوان",
    PleaseEnterTitle: "الرجاء إدخال العنوان",
    PleaseFillTheQuestionField: "الرجاء ملء حقل السؤال",
    EnterQuestion: "أدخل السؤال",
    SelectAnswerType: "اختر نوع الإجابة",
    Text: "نص",
    Number: "رقم",
    Polls: "استطلاعات",
    AddQuestion: "إضافة سؤال",
    AddOption: "إضافة خيار",
    Details: "تفاصيل",
    PoweredBy: "بتشغيل من",
    Submit: "إرسال",
    EditForm: "تعديل النموذج",
    EnterEmail: "أدخل البريد الإلكتروني",
    Question: "السؤال...",
    YourAnswer: 'إجابتك',
    noDataMessage: "– أنشئ نموذجًا الآن!",
    formQuestionTypeEnum: {
      [FormQuestionTypeEnum.Number]: "رقم",
      [FormQuestionTypeEnum.Text]: "نص",
      [FormQuestionTypeEnum.Poll]: "استطلاعات",
    },
    formFilterTypeEnum: {
      [FormFilterTypeEnum.All]: "كل النماذج",
      [FormFilterTypeEnum.CreatedByMe]: "تم إنشاؤها بواسطتي",
      [FormFilterTypeEnum.ForApproval]: "للموافقة",
      [FormFilterTypeEnum.FormForMe]: "نموذج لي",
    },
    statusEnum: {
      [FormStatusEnum.InProcess]: "قيد التنفيذ",
      [FormStatusEnum.Approved]: "تمت الموافقة عليه",
      [FormStatusEnum.Declined]: "تم الرفض",
      [FormStatusEnum.Resend]: "إعادة الإرسال",
      [FormStatusEnum.InActive]: "غير نشط",
      [FormStatusEnum.NotRequired]: "غير مطلوب",
      [FormStatusEnum.Cancelled]: "تم الإلغاء",
      [FormStatusEnum.Hold]: "في الانتظار",
    },
    SortEnum: {
      [FormFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [FormFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء",
      [FormFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلي",
      [FormFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث",
      [FormFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلي",
      [FormFilterSortEnum.ReferenceNo]: "الرقم المرجعي"
    }
  },
  careers: {
    Careers: "الوظائف",
    CareerDetail: "تفاصيل الحياة المهنية",
    createCareer: "إنشاء وظيفة",
    MinSalary: "الحد الأدنى للراتب",
    MaxSalary: "الحد الأقصى للراتب",
    Applicants: "المتقدمين",
    ShortListed: "تمت المقابلة",
    Rejected: "تم الرفض",
    Completed: "تم الانتهاء",
    Finalist: "الفائز",
    Maybe: "ربما",
    OfferSent: "تم إرسال العرض",
    WaitingForInterview: "في انتظار المقابلة",
    OfferAccepted: "تم قبول العرض",
    OfferDeclined: "تم رفض العرض",
    OfferResend: "إعادة إرسال العرض",
    noDataMessage: "– إنشاء وظيفة الآن!",
    writeHeaderHere: "اكتب هنا العنوان",
    pickCurrentDate: "اختر التاريخ الحالي",
    enterAmount: "أدخل المبلغ",
    selectApprovers: "حدد الموافقين",
    selectFinancers: "حدد الجهات الممولة",
    selectExecutors: "حدد النفذيين",
    selectDesignation: "حدد التسمية",
    writeDescription: "اكتب الوصف",
    enterMinSalary: "أدخل الحد الأدنى للراتب",
    enterMaxSalary: "أدخل الحد الأقصى للراتب",
    selectDepartment: "حدد القسم",
    selectSupervisor: "حدد المشرف",
    selectInterviewers: "حدد المقابلين",
    selectPostInterviewers: "حدد مقابلين بعد المقابلة",
    description: "اكتب الوصف هنا...",
    addSkills: "أضف مهارة",
    selectJobType: "حدد نوع الوظيفة",
    selectJobshift: "حدد نوع الوردية",
    selectEducation: "يرجى تحديد التعليم",
    selectCareerLevel: "حدد مستوى الوظيفة",
    selectEndDate: "حدد تاريخ الانتهاء",
    enterreviewcriteria: "أدخل معايير المراجعة",
    reviewCriteria: "معايير المراجعة",
    enterDepartment: "الرجاء إدخال القسم",
    supervisor: "المشرف",
    supervisorrequired: "المشرف مطلوب",
    postInterviewers: "مقابلين بعد المقابلة",
    jobShift: "نوع الوردية",
    enterJobShift: "الرجاء إدخال نوع الوردية",
    enterJobType: "الرجاء إدخال نوع الوظيفة",
    careerLevel: "مستوى الوظيفة",
    selectBuddy: "حدد رفيق التوظيف",
    selectInerview: "حدد المقابلين",
    requiredInterview: "المقابل مطلوب",
    enterCareerLevel: "الرجاء إدخال مستوى الوظيفة",
    createJob: "إنشاء وظيفة",
    applyJob: "تقديم طلب للوظيفة",
    firstName: "الاسم الأول",
    enterFirstName: "أدخل الاسم الأول",
    requiredMessageFName: "الاسم الأول مطلوب",
    lastName: "الاسم الأخير",
    enterLastName: "أدخل الاسم الأخير",
    requiredMessageLName: "الاسم الأخير مطلوب",
    email: "البريد الإلكتروني",
    enterEmail: "أدخل البريد الإلكتروني",
    EmailIsRequired: "البريد الإلكتروني مطلوب",
    phoneNumber: "رقم الهاتف",
    enterPhoneNumber: "أدخل رقم الهاتف",
    currentSalary: "الراتب الحالي",
    expectedSalary: "الراتب المتوقع",
    radioMessageApplicant: "متقدم",
    radioMessageShortlisted: "تمت المقابلة",
    radioMessageCompleted: "تم الانتهاء",
    coverNote: "ملحوظة التغطية",
    noticePeriod: "فترة الإشعار",
    requiredMessageExperience: "الرجاء إدخال الخبرة",
    appliedDate: "تاريخ التقديم",
    interviewSchedule: "جدول المقابلات",
    applyNow: "قدم الآن",
    jobPost: "تم نشر الوظيفة",
    jobexpire: "انتهاء صلاحية الوظيفة",
    skillrequired: "المهارات المطلوبة",
    joblocation: "موقع الوظيفة",
    jobType: "نوع الوظيفة",
    jobDetails: "تفاصيل الوظيفة",
    EducationLevel: "مستوى التعليم",
    MinimumSalary: "الحد الأدنى للراتب",
    MaximumSalary: "الحد الأقصى للراتب",
    YearOfExperience: "عام من الخبرة",
    errormessage: "لا يمكن تغيير الحالة",
    MakeOffer: "قدم عرض",
    updatestatus: "هل أنت متأكد أنك تريد تحديث الحالة؟",
    Pleaseenterminsalary: "يرجى إدخال الحد الأدنى للراتب ",
    Pleaseentermaxsalary: "يرجى إدخال الحد الأقصى للراتب",
    NoRequirements: "لا توجد متطلبات",
    statusEnum: {
      [CareerStatusEnum.InProcess]: "قيد التنفيذ",
      [CareerStatusEnum.Approved]: "تم الموافقة",
      [CareerStatusEnum.Declined]: "تم الرفض",
      [CareerStatusEnum.Cancelled]: "تم الإلغاء",
      [CareerStatusEnum.Hold]: "في الانتظار",
    },
    careerFilterTypeEnum: {
      [CareerFilterTypeEnum.All]: "كل الوظائف",
      [CareerFilterTypeEnum.MyCareers]: "وظائفي",
      [CareerFilterTypeEnum.ForApproval]: "للموافقة",
    },

  },
  /*** HR Menu ***/
  myTeam: {
    MyTeams: "فرقي",
    EmployeeNo: "رقم الموظف",
    noDataMessage: "– قم بإنشاء الفرق الآن!",
    teamDetails: "تفاصيل الفريق",
    DeductionPerMonth: "الخصم شهريًا",
    teamActivities: {
      attendence: "الحضور",
      checkIn: "تسجيل الدخول",
      leaves: "الإجازات",
      rewards: "المكافآت",
      appraisals: "التقييمات",
      warnings: "التحذيرات",
      complains: "الشكاوي",
      courses: "الدورات",
      education: "التعليم",
      experience: "الخبرة",
      loan: "القرض",
      activityLog: "سجل الأنشطة",
      checkInCheckOut: "تسجيل الوصول / تسجيل المغادرة",
      storageStatistics: "إحصائيات التخزين"
    },

  },

  administration: {
    Company_Goal: "هدف الشركة",
    basicInfo: "المعلومات الأساسية",
    Subsidiary_Office: "مكتب فرعي",
    AddSubsidiary: "إضافة فرع",
    Fiscal_Year: "السنة المالية",
    Tax_Slabs: "فئات الضرائب",
    Tax_Slabs_Group: "مجموعة فئات الضرائب",
    E_Learning_Category: "فئة التعلم الإلكتروني",
    Company_Policy: "سياسات الشركة",
    Default_Hiring_Criteria: "معايير التوظيف الافتراضية",
    subsidiary: "الشركة التابعة",
    Business_Logo: "شعار الشركة",
    Complain_Category: "فئة الشكاوى",
    Custom_Tag: "علامة مخصصة",
    Payroll_Group: "مجموعة الرواتب",
    Business_Policy: "سياسة العمل",
    Companies_Policy: "سياسات الشركات",
    Billing: "الفوترة",
    Rebate_Category: "فئة الخصم",
    Assets_Category: "فئة الأصول",
    AccessRole: "دور الوصول",
    Grade: "الدرجة",
    Default_Account_Header: "رأس الحساب الافتراضي",
    Business_Paging: "رقم العمل",
    Discussion_Board_Category: "فئة لوحة النقاش",
    Room: "غرفة",
    Business_Association: "رابطة الأعمال",
    Designation: "التسمية",
    Appraisal: "التقييم",
    Office_Timings: "أوقات المكتب",
    Leave_Types: "أنواع الإجازات",
    User_Types: "أنواع المستخدمين",
    Expense_Headers: "رأس المصروفات",
    Salary_Headers: "رأس الراتب",
    Request_For_Right: "طلب الحق",
    Custom_Approval_Category: "فئة الموافقة المخصصة",
    GRADE_ALLOWANCES: "البدلات حسب الدرجة",
    Paging_Category: "فئة الصفحة",
    Page_Category: "تصنيف الصفحة",
    Project_Category: "فئة المشروع",
    Email_Configuration: "تكوين البريد الإلكتروني",
    Allowances: "البدلات",
    Default_Approvals: "الموافقات الافتراضية",
    NonMandatory_Approvals: "الموافقات غير الإلزامية",
    WORKGATING: "معايير تصفية العمل في التقدم",
    Job_Description: "وصف الوظيفة",
    Rewards: "المكافآت",
    Reward_Category: "فئة المكافآت",
    Warning_Category: "فئة التحذير",
    Holidays: "العطل",
    paymentCard: "بطاقة الدفع",
    Company_Info: "معلومات الشركة",
    business_Info:"معلومات العمل",
    Business_Approval: "موافقة العمل",
    COMPANY_STATISTICS: "إحصائيات الشركة",
    ATTENDANCE_SETTINGS: 'إعدادات الحضور',
    businessInfo : {
      businessInformation: 'معلومات العمل',
      companyBackgroundLabel: 'خلفية الشركة',
      productAndServicesLable: 'المنتجات والخدمات',
      competitorsLable: 'المنافسون',
      recentNewsLable: 'أخبار حديثة',
      leadershipTeamLabel: 'فريق القيادة',
      organizationalStructureLabel: 'الهيكل التنظيمي',
      customerBaseLabel: 'قاعدة العملاء',
      FinancialHealthLabel: 'الصحة المالية',
      industryLabel: 'الصناعة',
      websiteAndSocialMediaLabel: 'الموقع ووسائل التواصل الاجتماعي',
      additionalCommentLabel: 'تعليقات إضافية',
      companyCultureLabel: 'ثقافة الشركة',
      ceoLabel: 'الرئيس التنفيذي',
      companyBackground: {
          mission: 'مهمة',
          vision: 'رؤية',
          values: 'قيم',
          YearFounded: 'سنة التأسيس',
          placeHolders: {
              enterMission: 'أدخل المهمة',
              enterVision: 'أدخل الرؤية',
              enterValues: 'أدخل القيم',
              enterYearFounded: 'أدخل سنة التأسيس',
          },
          requiredMessages: {
              enterMission: 'يرجى إدخال المهمة',
              enterVision: 'يرجى إدخال الرؤية',
              enterValues: 'يرجى إدخال القيم',
              enterYearFounded: 'يرجى إدخال سنة التأسيس',
          }
      },
      productAndService: {
          Benefits: 'الفوائد'
      },
      competitors: {
          MarketPosition: 'موقع السوق',
          Strength: 'القوة',
          Weakness: 'الضعف',
      },
      recentNews: {
          Headline: 'العنوان',
          News: 'الأخبار'
      },
      leadershipTeam: {
          Background: 'الخلفية',
          ContactNumber: 'رقم الاتصال',
      },
      financialHealth: {
          Revenue: 'الإيرادات',
          Profitability: 'الربحية'
      },
      additionalComment: {
          label: {
              comments: 'تعليقات'
          },
          placeholders: {
              comments: 'أدخل التعليقات'
          },
          requireMessages: {
              enterComments: 'يرجى إدخال التعليقات'
          }
      },
      comapnyCulture: {
          placeHolders: {
              enterDescription: 'أدخل الوصف',
              enterWorkEnvironment: 'أدخل بيئة العمل'
          },
          label: {
              description: 'الوصف',
              workEnvironment: 'بيئة العمل'
          },
          requiredMessages: {
              enterDescription: 'يرجى إدخال الوصف',
              enterWorkEnvironment: 'يرجى إدخال بيئة العمل'
          }
      }
    },
    Form: {
      Name: "الاسم",
      Entername: "أدخل الاسم",
      PleaseEnterName: "الرجاء إدخال الاسم",
      Description: "الوصف",
      enterDescription: "الرجاء إدخال الوصف",
      Create: "إنشاء",
      Add: "إضافة",
      Save: "حفظ",
      Clear: "مسح",
      CategoryName: "اسم الفئة",
      Entercategoryname: "أدخل اسم الفئة",
      SaveCategory: "حفظ الفئة",
      Date: "تاريخ",
      startDate: "تاريخ البداية",
      endDate: "تاريخ الانتهاء",
      Upload: "تحميل",
      Percent: "النسبة المئوية",
      Amount: "المبلغ",
      EnterAmount: "أدخل المبلغ",
      addCategory: "إضافة فئة",
      SelectMember: "اختيار العضو",
      Email: "البريد الإلكتروني",
      EnterEmail: "أدخل البريد الإلكتروني",
      Types: "الأنواع",
      SelectStatus: "اختيار الحالة",
      Submit: "تقديم",
      Nomoreapprovals: "لا موافقات إضافية",
      AddAllowance: "إضافة بدل",
      AddAssetsCategory: "إضافة فئة الأصول",
    },
    Table: {
      Name: "الاسم",
      Members: "الأعضاء",
      Description: "الوصف",
      Value: "القيمة",
      Allot_Leave: "تخصيص إجازة",
      Type: "النوع",
      Unit: "الوحدة",
      Taxable: "خاضع للضريبة",
      Provider: "المزود",
      IncomingServerAddress: "عنوان الخادم الوارد",
      OutgoingServerAddress: "عنوان الخادم الصادر",
      IncomingPort: "منفذ الوارد",
      OutgoingPort: "منفذ الصادر",
      Action: "الإجراء",
      startDate: "تاريخ البداية",
      endDate: "تاريخ الانتهاء",
      Actions: "الإجراءات",
      Account: "الحساب",
      approvers: "الموافقين",
      Status: "الحالة",
      Allocated: "المخصص",
      AddMember: "إضافة عضو",
      CreateDate: "تاريخ الإنشاء",
    },
    accessRole: {
      Button: {
        AddAccessRole: "إضافة دور الوصول",
        UpdateAccessRole: "تحديث دور الوصول",
      },
      Drawer: {
        CreateAccessRole: "إنشاء دور الوصول",
        UpdateAccessRole: "تحديث دور الوصول",
        UserType: "نوع المستخدم",
        Description: "الوصف",
        placeholders: {
          EnterName: "أدخل اسم دور الوصول",
          UserType: "يرجى تحديد نوع المستخدم",
          Description: "أدخل الوصف",
        },
        formLabel: {
          AccessRoleName: "اسم دور الوصول",
        },
        placeholders: {
          EnterName: "أدخل دور الوصول",
          SelectUserType: "حدد نوع المستخدم",
          EnterDescription: "أدخل الوصف",
        },
      },
    },
    grade: {
      AddGrade: "إضافة الدرجة",
      EnterGrade: "أدخل الدرجة",
      EnterDescription: "أدخل الوصف",
      Grade: "الدرجة",
      Description: "الوصف",
      Name: "الاسم",
      clear: "مسح",
      saveGrade: "حفظ الدرجة",
    },
    fiscalyear: {
      Add: "إضافة",
      EnterName: "أدخل الاسم",
      EnterDescription: "أدخل الوصف",
      Fiscalyear: "السنة المالية",
      description: "الوصف",
      name: "الاسم",
      clear: "مسح",
      save: "حفظ ",
      startyear: "بداية السنة",
      endyear: "نهاية السنة",
      startMonth: "بداية الشهر",
      endMonth: "نهاية الشهر",
      startDate: "تاريخ البداية",
      endDate: "تاريخ النهاية",
    },
    businessPolicy: {
      companypolicy: "سياسات الشركة",
      enterName: "أدخل الاسم",
      name: "الاسم",
      type: "حدد النوع",
      typee: "النوع",
      description: "الوصف",
      EnterDescription: "أدخل الوصف",
      create: "إنشاء",
      save: "حفظ",
      createPolicy: "إنشاء سياسة",
      updatePolicy: "تحديث السياسة",
    },
    taxSlab: {
      TaxSlabs: "فئات الضرائب",
      UpdateTaxSlab: "تحديث فئة الضريبة",
      CreateTaxSlab: "إنشاء فئة الضريبة",
      NoTaxSlabGroup: "لا توجد مجموعة ضريبية...",
      enterCountry: "حدد البلد",
      enterName: "أدخل الاسم",
      enterDescription: "أدخل الوصف",
      description: "الوصف",
      name: "الاسم",
      TaxSlab: "فئة الضريبة",
      title: "العنوان",
      enterTitle: "أدخل العنوان",
      percentage: "النسبة المئوية",
      previousCharge: "الرسوم السابقة",
      min: "الحد الأدنى",
      max: "الحد الأقصى",
      enterMin: "أدخل الحد الأدنى",
      enterMax: "أدخل الحد الأقصى",
      enterPercent: "أدخل النسبة المئوية",
      save: "حفظ",
      clear: "مسح",
      Add: "إضافة",
      TaxSlabgroup: "مجموعة فئات الضرائب",
      country: "البلد",
      minimumCharge: "الرسوم الدنيا",
    },
    payrollGroup: {
      PayrollGroup: "مجموعة الرواتب",
      save: "حفظ المجموعة",
      clear: "مسح",
      Add: "إضافة المجموعة",
      name: "الاسم",
      enterName: "أدخل الاسم",
    },
    subsidiaryOffice: {
      enterName: "أدخل الاسم",
      enterAddress: "أدخل العنوان",
      name: "الاسم",
      subsidiary_Office: "المكتب الفرعي",
      latitude: "خط العرض",
      longitude: "خط الطول",
      address: "العنوان",
      branch: "الفرع",
      Subsidiary: "الشركة التابعة",
      select: "حدد الشركة التابعة",
      save: "حفظ",
      clear: "مسح",
      Add: "إضافة",
      title: "العنوان",
      enterTitle: "أدخل العنوان",
    },
    defaultHiringCriteria: {
      default: "معايير التوظيف الافتراضية",
      question: "الأسئلة",
      enterQuestions: "أدخل السؤال",
      save: "حفظ السؤال",
      clear: "مسح",
      Add: "إضافة السؤال",
    },
    workGatingCriteria: {
      work: "معايير تصفية العمل في التقدم",
      question: "الأسئلة",
      enterQuestions: "أدخل السؤال",
      save: "حفظ السؤال",
      clear: "مسح",
      Add: "إضافة السؤال",
    },
    designation: {
      desig: "التسمية",
      enterdesig: "أدخل التسمية",
      enterName: "أدخل الاسم",
      enterDescription: "أدخل الوصف",
      description: "الوصف",
      name: "الاسم",
      save: "حفظ التسمية",
      clear: "مسح",
      Add: "إضافة التسمية",
    },
    appraisal: {
      Appraisal: "التقييم",
      enterAppraisal: "أدخل التقييم",
      enterDescription: "أدخل الوصف",
      description: "الوصف",
      save: "حفظ التقييم",
      clear: "مسح",
      Add: "إضافة التقييم",
      CompanyGoal: "هدف الشركة",
      IndividualGoal: "هدف فردي",
      DepartmentsGoal: "أهداف الأقسام",
      Goal: "هدف",
      saveGoal: "حفظ الهدف",
      AddCompanyGoal: "إضافة هدف للشركة",
      AddDepartmentGoal: "إضافة هدف للقسم",
      AddIndividualGoal: "إضافة هدف فردي",
      enterCompanyGoal: "أدخل هدف الشركة",
      enterDepartmentGoal: "أدخل هدف القسم",
      enterIndividualGoal: "أدخل هدف فردي",
    },
    office: {
      Office_Timing: "أوقات المكتب",
      group: "اسم المجموعة",
      enterGroup: "أدخل الاسم",
      enterDescription: "أدخل الوصف",
      description: "الوصف",
      save: "حفظ أوقات المكتب",
      clear: "مسح",
      Add: "إضافة أوقات المكتب",
      days: "الأيام",
      work: "هل العمل مستمر؟",
      checkIn: "تسجيل الدخول",
      checkOut: "تسجيل الخروج",
      time: "وقت السماح",
      min: "الحد الأدنى",
      create: "إنشاء المجموعة",
      new: "مجموعة الوقت الجديدة",
      UpdateOfficeTiming: "تحديث أوقات المكتب",
      CreateOfficeTiming: "إنشاء أوقات المكتب",
      Monday: "الاثنين",
      Tuesday: "الثلاثاء",
      Wednesday: "الأربعاء",
      Thursday: "الخميس",
      Friday: "الجمعة",
      Saturday: "السبت",
      Sunday: "الأحد",
    },
    leave: {
      Leave: "نوع الإجازة",
      leaveType: "نوع الإجازة",
      enterLeave: "أدخل نوع الإجازة",
      save: "حفظ نوع الإجازة",
      clear: "مسح",
      Add: "إضافة نوع الإجازة",
      enterDescription: "أدخل الوصف",
      description: "الوصف",
      allotLeaves: "تخصيص الإجازات",
      balanceLeaves: "أنواع الإجازات (رصيد الإجازات)",
      selectType: "اختر النوع",
      leaveFor: "الإجازة لصالح",
      leaveDates: "تواريخ الإجازة",
      createleave: "إنشاء الإجازة",
    },
    expense: {
      Expense: "رأس المصروفات",
      enterExpense: "أدخل رأس المصروفات",
      enterDescription: "أدخل الوصف",
      description: "الوصف",
      save: "حفظ رأس المصروفات",
      clear: "مسح",
      Add: "إضافة رأس المصروفات",
    },
    elearningCategory: {
      elearningCategory: "فئة التعلم الإلكتروني",
      description: "الوصف",
      enterelearningCategory: "أدخل الفئة ",
      enterDescription: "أدخل الوصف",
      save: "حفظ فئة التعلم الإلكتروني",
      clear: "مسح",
      Add: "إضافة فئة التعلم الإلكتروني",
    },
    salaryHeader: {
      SalaryHeader: "رأس الراتب",
      enterSalaryHeader: "أدخل رأس الراتب",
      desc: "الوصف",
      enterDesc: "أدخل الوصف",
      saveHeader: "حفظ رأس الراتب",
      addHeader: "إضافة رأس الراتب",
      name: "الاسم",
      clear: "مسح",
    },
    room: {
      Room: "غرفة",
      Name: "الاسم",
      Entername: "أدخل الاسم",
      Description: "الوصف",
      Enterdescription: "أدخل الوصف",
      Location: "الموقع",
      Enterlocation: "أدخل الموقع",
      TotalSeats: "إجمالي المقاعد",
      Enterseats: "أدخل عدد المقاعد",
      SaveRoom: "حفظ الغرفة",
      AddRoom: "إضافة غرفة",
      clear: "مسح",
    },
    RebateCategory: {
      rebateCategory: "فئة الخصم",
      maxPercentage: "أقصى نسبة",
      entermaxPercentage: "أدخل الحد الأقصى للنسبة",
      maxAmount: "أقصى مبلغ",
      enterMaxAmount: "أدخل الحد الأقصى للمبلغ",
      type: "النوع",
      basic: "أساسي",
      tax: "ضريبة",
      fullAmount: "المبلغ الكامل",
      rebateType: "نوع الخصم",
    },
    projectCategory: {
      ProjectCategory: "فئة المشروع",
      AddProjectCategory: "إضافة فئة المشروع",
    },
    pageCategory: {
      Pagecategory: "فئة الصفحة",
      AddPageCategory: "إضافة فئة الصفحة",
    },
    NonMandatoryApprovals: {
      NonMandatoryApproval: "الموافقات غير الإلزامية",
    },
    jobdescription: {
      jobDesc: "وصف الوظيفة",
      designation: "التسمية",
      selectDesign: "اختر التسمية",
      saveJobDesc: "حفظ وصف الوظيفة",
      addJobdesc: "إضافة وصف الوظيفة",
    },
    Holiday: {
      Holidays: "العطلات",
      AddHoliDay: "إضافة عطلة"
    },
    GradeAllowance: {
      gradeAllowance: "المخصصات حسب الدرجة",
      Grades: "الدرجات",
      AllowanceType: "نوع المخصصة",
      SelectType: "اختر النوع",
      AddAllowance: "إضافة مخصصة",
      UpdateAllowance: "تحديث المخصصة",
      Allowance: "المخصصة",
      IsTaxable: "خاضعة للضريبة",
    },
    EmailConfiguration: {
      emailConfi: "تكوين البريد الإلكتروني",
      enterEmailConfi: "أدخل تكوين البريد الإلكتروني",
      incomingPort: "منفذ الوارد",
      enterIncomingPort: "أدخل منفذ الوارد",
      incomingServerAddress: "عنوان الخادم الوارد",
      enterIncomingServerAddress: "أدخل عنوان الخادم الوارد",
      outgoingPort: "منفذ الصادر",
      enterOutgoingPort: "أدخل منفذ الصادر",
      outgoingServerAddress: "عنوان الخادم الصادر",
      enterOutgoingServerAddress: "أدخل عنوان الخادم الصادر",
      provider: "مزود البريد",
      enterProvider: "أدخل مزود البريد",
      saveEmailConfi: "حفظ تكوين البريد الإلكتروني",
      addEmailConfi: "إضافة تكوين البريد الإلكتروني",
    },
    DiscussionBoardCategory: {
      discussionBoardCategory: "فئة لوحة المناقشة",
      SaveDiscussionBoardCategory: "حفظ فئة لوحة المناقشة",
      AddDiscussionBoardCategory: "إضافة فئة لوحة المناقشة",
    },
    DefaultApprovers: {
      defaultApprovers: "الموافقون الافتراضيون",
    },
    DefaultAccountHeader: {
      defaultAccountHeader: "عنوان الحساب الافتراضي",
      SaveAccountHeader: "حفظ عنوان الحساب",
      SelectAccountTypes: "اختيار أنواع الحساب",
    },
    CustomTag: {
      customTag: "علامة مخصصة",
      Entercustomtag: "أدخل العلامة المخصصة",
      SaveCustomTag: "حفظ العلامة المخصصة",
      AddCustomTag: "إضافة العلامة المخصصة",
    },
    customApprovalCategory: {
      customApprovalcategory: "فئة الموافقة المخصصة",
      enterCustomApprovalcategory: "أدخل فئة الموافقة المخصصة",
    },
    BusinessNumber: {
      Manual: "يدوي",
      TotalNumber: "العدد الإجمالي",
      Country: "الدولة",
      SelectCountry: "اختيار الدولة",
      Number: "الرقم",
      Random: "عشوائي",
      SelectNumber: "اختيار الرقم",
      Cost: "التكلفة",
      Request: "الطلب",
    },
    BusinessAssociation: {
      businessAssociation: "رابطة الأعمال",
      SaveBusinessAssociation: "حفظ رابطة الأعمال",
      AddBusinessAssociation: "إضافة رابطة الأعمال",
      AssociationType: "نوع الرابطة",
    },
    AssetsCategory: {
      assetsCategory: "فئة الأصول",
      categoryName: "اسم الفئة",
      enterCategoryName: "أدخل اسم الفئة",
      desc: "الوصف",
      enterDesc: "أدخل الوصف",
      accountType: "نوع الحساب",
      parentAccount: "الحساب الأصل",
      selectParent: "اختيار الحساب الأصل",
      selectType: "اختيار النوع",
      add: "إضافة",
      save: "حفظ",
      clear: "مسح",
      name: "الاسم",
      assets: "الأصول",
      liability: "الالتزامات",
      capital: "رأس المال",
      revenue: "الإيرادات",
      expense: "النفقات",
      costOfGoodsale: "تكلفة البضاعة المباعة",
    },
    Allowance: {
      allowance: "البدلات",
      enterAllownace: "أدخل البدل",
      percent: "النسبة المئوية",
      desc: "أدخل الوصف",
      grade: "الدرجة",
      selectGrade: "اختيار الدرجة",
      amount: "المبلغ",
      benefit: "الفائدة",
      deduction: "الخصم",
      texable: "خاضعة للضريبة",
      nonTexable: "غير خاضعة للضريبة",
      director: "المدير",
      ceo: "الرئيس التنفيذي",
      addAllowance: "إضافة بدل",
      saveAllowance: "حفظ البدل",
      clear: "مسح",
      name: "الاسم",
      allowanceType: "نوع البدل",
      allowanceUnit: "وحدة البدل",
      istexable: "خاضعة للضريبة",
      yes: "نعم",
      no: "لا",
    },
    CompanyInfo: {
      businessLogo: "شعار الشركة",
      basicinfo: "معلومات أساسية",
      ShortLogo: "شعار قصير",
      Logo: "شعار",
      FullLogo: "شعار كامل",
      companyName: "اسم الشركة",
      PleaseEnterCompanyName: "الرجاء إدخال اسم الشركة",
      enterCompanyName: "ادخل اسم الشركة",
      companyEmail: "ادخل بريد الشركة الإلكتروني",
      PleaseEnterCompanyEmail: "الرجاء إدخال بريد الشركة الإلكتروني",
      SelectOwnerName: "اختر اسم المالك",
      totalEmployees: "إجمالي عدد الموظفين",
      totalEmployeesInCall: "إجمالي عدد الموظفين في المكالمة",
      SaveShortLogo: "حفظ الشعار القصير",
      LongLogo: "شعار طويل",
      SaveLongLogo: "حفظ الشعار الطويل",
      SaveFullLogo: "حفظ الشعار الكامل",
    },
    billing: {
      Invoice: "فاتورة",
      InvoiceNo: "رقم الفاتورة",
      CustomerName: "اسم العميل",
      PayInvoice: "دفع الفاتورة",
      Payment: "الدفع",
      BillNo: "رقم الفاتورة",
      CardLast: "آخر 4 أرقام من البطاقة",
      BusinessName: "اسم الشركة",
      CardHolder: "حامل البطاقة",
      Viewinvoicedetails: "عرض تفاصيل الفاتورة والدفع",
      Viewinvoicedetail: "عرض تفاصيل الفاتورة",
      Qty: "الكمية",
    },
    PaymentCard: {
      paymentCard: "بطاقة الدفع",
      CardHolderName: "اسم حامل البطاقة",
      AddCard: "إضافة بطاقة",
      PayInvoice: "دفع الفاتورة",
      CardHolderNameRequired: "اسم حامل البطاقة مطلوب.",
      PayCard: "دفع بالبطاقة",
      payanotherway: "أو دفع بطريقة أخرى",
      credentialstocontinue: "أدخل بيانات الاعتماد للمتابعة.",
      Expiry: "تاريخ الانتهاء",
      Brand: "العلامة التجارية",
      Country: "البلد",
      LastFourDigits: "آخر أربعة أرقام",
    },

  },

  billing: {
    BillType: "نوع الفاتورة",
    billingTypeEnum: {
      [BillingTypeEnum.NewUser]: "مستخدم جديد",
      [BillingTypeEnum.MonthlySubscription]: "الاشتراك الشهري",
      [BillingTypeEnum.NewBusinessNumber]: "رقم العمل الجديد"
    }
  },

  businessNumber: {
    statusEnum: {
      [BuisnessNumberStatusEnum.Waiting]: "في انتظار",
      [BuisnessNumberStatusEnum.Declined]: "تم الرفض",
      [BuisnessNumberStatusEnum.NumberCancelled]: "تم إلغاء الرقم",
      [BuisnessNumberStatusEnum.Purchased]: "تم الشراء",
      [BuisnessNumberStatusEnum.RequestCancelled]: "تم إلغاء الطلب",
      [BuisnessNumberStatusEnum.Rejected]: "تم الرفض",
    },
  },
  businessApproval: {
    statusTypeList: {
      [ApprovalStatusTypeEnum.InProcess]: "قيد التنفيذ",
      [ApprovalStatusTypeEnum.Approved]: "تمت الموافقة",
      [ApprovalStatusTypeEnum.Declined]: "تم الرفض",
      [ApprovalStatusTypeEnum.Cancelled]: "تم الإلغاء",
      [ApprovalStatusTypeEnum.Completed]: "تم الاكتمال",
    },
    approvalModuleList : {
      [ApprovalsModuleEnum.ExpenseApproval]: "المصاريف",
      [ApprovalsModuleEnum.ExpenseExecutor]: "مراقب المصاريف",
      [ApprovalsModuleEnum.ExpenseFinance]: "مالية المصاريف",
      [ApprovalsModuleEnum.LeaveApproval]: "الإجازات",
      [ApprovalsModuleEnum.TravelApproval]: "السفر",
      [ApprovalsModuleEnum.TravelAgent]: "وكيل السفر",
      [ApprovalsModuleEnum.WarningApproval]: "تحذير",
      [ApprovalsModuleEnum.UserEducation]: "التعليم",
      [ApprovalsModuleEnum.UserWorkExperience]: "خبرة العمل",
      [ApprovalsModuleEnum.ComplainApproval]: "الشكوى",
      [ApprovalsModuleEnum.RewardApproval]: "المكافأة",
      [ApprovalsModuleEnum.BonusApproval]: "البونص",
      [ApprovalsModuleEnum.PromotionApproval]: "الترقية",
      [ApprovalsModuleEnum.RequestForItemApproval]: "طلب عنصر",
      [ApprovalsModuleEnum.LoanApproval]: "القرض",
      [ApprovalsModuleEnum.CustomApproval]: "مخصص",
      [ApprovalsModuleEnum.FormApproval]: "نموذج",
      [ApprovalsModuleEnum.DocumentApproval]: "وثيقة",
      [ApprovalsModuleEnum.SalaryApproval]: "الراتب",
      [ApprovalsModuleEnum.SignupApproval]: "التسجيل",
      [ApprovalsModuleEnum.PayrollApproval]: "الرواتب",
      [ApprovalsModuleEnum.CareerApproval]: "المسيرة المهنية",
      [ApprovalsModuleEnum.RequisitionApproval]: "طلب الشراء",
      [ApprovalsModuleEnum.RequisitionFinalApproval]: "الموافقة النهائية على الطلب",
      [ApprovalsModuleEnum.RequestForItemAssetControllerApproval]: "طلب عنصر (مدير الأصول)",
      [ApprovalsModuleEnum.ItemApproval]: "عنصر",
      [ApprovalsModuleEnum.AssetApproval]: "الأصول",
      [ApprovalsModuleEnum.ResignationAdminApproval]: "الاستقالة (إداري)",
      [ApprovalsModuleEnum.ResignationExitApproval]: "الاستقالة (خروج)",
      [ApprovalsModuleEnum.ResignationFinanceApproval]: "الاستقالة (مالية)",
      [ApprovalsModuleEnum.ResignationHrApproval]: "الاستقالة (موارد بشرية)",
      [ApprovalsModuleEnum.ResignationItApproval]: "الاستقالة (تقنية المعلومات)",
      [ApprovalsModuleEnum.ResignationOtherApproval]: "الاستقالة (أخرى)",
      [ApprovalsModuleEnum.ResignationReportingToApproval]: "الاستقالة (تقرير إلى)",
      [ApprovalsModuleEnum.QuotationApproval]: "عرض الأسعار",
      [ApprovalsModuleEnum.QuotationClientApproval]: "عرض الأسعار (عميل)",
      [ApprovalsModuleEnum.AppraisalApproval]: "تقييم الأداء",
      [ApprovalsModuleEnum.AuctionApproval]: "مزاد"
  }
  },
  appraisal: {
    Appraisals: "التقييمات",
    createAppraisals: "إنشاء تقييم",
    noDataMessage: "– قم بإنشاء تقييم الآن!",
    Approvers: "الموافقون",
    Questions: "الأسئلة",
    Tasks: "المهام",
    Bonus: "المكافأة",
    Increment: "الزيادة",
    previousApraisals: "التقييمات السابقة",
    warning: "تحذير",
    rewards: "المكافآت",
    courses: "الدورات",
    Promotion: "الترقية",
    PleaseEnterBonusPercentage: "الرجاء إدخال نسبة المكافأة",
    PleaseEnterBonusAmount: "الرجاء إدخال مبلغ المكافأة",
    PleaseEnterIncrementAmount: "الرجاء إدخال مبلغ الزيادة",
    RateAtleastOneItem: "قيم على الأقل عنصر واحد",
    Noemployeeselected: "لم يتم اختيار أي موظف",
    submitAppraisal: "تقديم التقييم",
    Appraisedof: "تم تقييمه من قبل",
    AppraisalApprovers: "الموافقون على التقييم",
    AppraisalDetail: "تفاصيل التقييم",
    appraisalFilterTypeEnum: {
      [AppraisalFilterEnum.All]: "كل التقييمات",
      [AppraisalFilterEnum.CreatedByMe]: "تم إنشاؤها بواسطتي",
      [AppraisalFilterEnum.ForApproval]: "للموافقة",
      [AppraisalFilterEnum.TeamAppraisal]: "تقييم الفريق",
      [AppraisalFilterEnum.MyApprovedAppraisal]: "تقييمي الموافق عليه",
    },
    statusEnum: {
      [AppraisalStatusEnum.InProcess]: "قيد التنفيذ",
      [AppraisalStatusEnum.Approved]: "تمت الموافقة",
      [AppraisalStatusEnum.Declined]: "تم الرفض",
      [AppraisalStatusEnum.Cancelled]: "تم الإلغاء",
      [AppraisalStatusEnum.Hold]: "تم التعليق",
    },
    SortEnum: {
      [AppraisalFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [AppraisalFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء",
      [AppraisalFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلي",
      [AppraisalFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث",
      [AppraisalFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلي",
      [AppraisalFilterSortEnum.ReferenceNo]: "الرقم المرجعي"
    }

  },
  employees: {
    placeholders: {
      fName: "پہلا نام درج کریں",
      lName: "آخری نام درج کریں",
      fatherName: "والد کا نام درج کریں",
      email: "ای میل درج کریں",
      pEmail: "ذاتی ای میل درج کریں",
      phNo: "فون نمبر درج کریں",
      rAddress: "رہائشی پتہ درج کریں",
      pAddress: "مستقل پتہ درج کریں",
      cnicNo: "CNIC نمبر درج کریں",
      searchToSelect: "منتخب کرنے کے لیے تلاش کریں",
      probPeriod: "پروبیشن کی مدت داخل کریں",
      noticePeriod: "نوٹس کی مدت درج کریں",
      selectDate: "تاریخ منتخب کریں",
      selectGender: "صنف منتخب کریں",
      selectMarital: "ازدواجی حیثیت کو منتخب کریں",
      selectShift: "شفٹ کو منتخب کریں",
      empNo: "ملازم نمبر درج کریں",
      empType: "ایمپلائیمنٹ کی قسم منتخب کریں",
      selectUserType: "صارف کی قسم منتخب کریں",
      selectAccessRole: "رسائی کا کردار منتخب کریں",
      degree: "ڈگری داخل کریں",
      institute: "انسٹی ٹیوٹ کا نام درج کریں",
      desc: "تفصیل درج کریں",
      tMarks: "کل نمبر درج کریں",
      oMarks: "حاصل کردہ نمبر درج کریں",
      sDate: "شروع تاریخ",
      eDate: "آخری تاریخ",

      position: "پوزیشن درج کریں",
      name: "نام درج کریں",
      address: "پتہ درج کریں",
      number: "نمبر درج کریں",
      selectRelation: "رشتہ منتخب کریں",
      bankName: "بینک کا نام درج کریں",
      accTitle: "اکاؤنٹ کا عنوان درج کریں",
      branchCode: "برانچ کوڈ درج کریں",
      accNo: "اکاؤنٹ نمبر درج کریں",
      iban: "iban نمبر درج کریں",
      sortCode: "ترتیب کوڈ درج کریں",
    },
    EmployeeForm: {
      AddImage: "تصویر شامل کریں",
      FirstName: "پہلا نام",
      LastName: "آخری نام",
      FatherName: "والد کا نام",
      Email: "ای میل",
      PersonalEmail: "ذاتی ای میل",
      CNICNumber: "سی این آئی سی نمبر",
      rAddress: "رہائشی پتہ",
      pAddress: "مستقل پتہ",
      PhoneNumber: " فون نمبر",
      Designation: " عہدہ",
      Manager: " مینیجر",
      Grades: "درجات",
      Department: "شعبہ",
      Country: "ملک",
      City: "شہر",
      Languages: "زبانیں",
      ProbationPeriod: "ٹرائی مدت",
      NoticePeriod: "نوٹس کی مدت",
      DateOfBirth: "پیدائش کی تاریخ",
      DateOfJoining: "شمولیت کی تاریخ",
      Gender: "صنف",
      MaritalStatus: "ازدواجی حیثیت",
      OfficeShift: "آفس شفٹ",
      EmploymentType: "ملازمت کی قسم",
      UserType: "صارف کی قسم",
      AccessRole: "رسائی کا کردار",
      EmpNo: "ملازم کا نمبر",
    },
    EmergencyForm: {
      EmergencyInfo: "ایمرجنسی معلومات",
      Name: "نام",
      Address: "پتہ",
      Number: "نمبر",
      Relation: "رشتہ",
    },
    EducationForm: {
      EducationInfo: "تعلیمی معلومات",
      Degree: "ڈگری",
      Institute: "انسٹی ٹیوٹ",
      StartEndDate: "شروع/اختتام کی تاریخ",
      StartDate: "شروع کرنے کی تاریخ",
      Description: "تفصیل",
      TotalMarks: "کل مارکس",
      ObtainedMarks: "مارکس حاصل کئے",
      Present: "موجودہ",
      Attachments: "ملحقات",
      AddMoreEducation: "مزید تعلیم شامل کریں",
      City: "شہر",
      Upload: "اپ لوڈ",
    },
    WorkExperienceForm: {
      WorkExperienceInfo: "کام کے تجربے کی معلومات",
      Employer: "آجر",
      Position: "پوزیشن",
      Department: "محکمہ",
      StartEndDate: "شروع/اختتام کی تاریخ",
      StartDate: "شروع کرنے کی تاریخ",
      Present: "موجودہ",
      AddMoreExperience: "مزید تجربہ شامل کریں",
      EmploymentType: "ملازمت کی قسم",
      City: "شہر",
    },
    UserForm: {
      UserRights: "صارف کے حقوق",
      UserType: "صارف کی قسم",
      UserRole: "صارف کا کردار",
    },
    BankForm: {
      BankInfo: "بینک کی معلومات",
      BankName: "بینک کا نام",
      AccountTitle: "اکاؤنٹ ٹائٹل",
      BranchCode: "برانچ کوڈ",
      AccountNumber: "اکاؤنٹ نمبر",
      IBAN: "IBAN",
      SortCode: "سانٹ کوڈ",
      City: "شہر",
      Country: "ملک",
      AddMoreBank: "مزید بینک شامل کریں",
    },
    EmployeeList: {
      number: "نمبر",
      email: "ای میل",
      designation: "عہدہ",
    },
    submit: "جمع کرائیں",
  },
  leaves: {
    leave: "الإجازات",
    leavedetail: "تفاصيل الإجازة",
    Leave: "نوع الإجازة",
    leaveType: "نوع الإجازة",
    enterLeave: "أدخل نوع الإجازة",
    save: "حفظ نوع الإجازة",
    clear: "مسح",
    Add: "إضافة نوع الإجازة",
    enterDescription: "أدخل الوصف",
    description: "الوصف",
    allotLeaves: "الإجازات المخصصة",
    balanceLeaves: "أنواع الإجازات (رصيد الإجازات)",
    selectType: "اختر النوع",
    leaveFor: "الإجازة لصالح",
    leaveDates: "تواريخ الإجازة",
    createleave: "إنشاء إجازة",
    days: "أيام",
    leavetype: "نوع الإجازة",
    approvers: "الموافقون",
    selectMembers: "اختر الأعضاء",
    daysCount: "عدد الأيام",
    messages: "الرجاء اختيار نوع الإجازة",
    allocatedLeaves: "الإجازات المخصصة",
    availedLeaves: "الإجازات المتاحة",
    remainingLeaves: "الإجازات المتبقية",
    noDataMessage: " - أنشئ إجازتك الآن!",
    Leavesinfo: "معلومات الإجازات",
    UpdateLeave: "تحديث الإجازة",
    leavesFilterTypeEnum: {
      [LeaveFilterTypeEnum.All]: "كل الإجازات",
      [LeaveFilterTypeEnum.CreatedByMe]: "تم إنشاؤها بواسطتي",
      [LeaveFilterTypeEnum.ForApproval]: "للموافقة",
      [LeaveFilterTypeEnum.LeaveForMe]: "إجازاتي",
    },
    statusEnum: {
      [LeaveStatusEum.InProcess]: "قيد التنفيذ",
      [LeaveStatusEum.Approved]: "تمت الموافقة",
      [LeaveStatusEum.Declined]: "تم الرفض",
      [LeaveStatusEum.Resend]: "إعادة الإرسال",
      [LeaveStatusEum.InActive]: "غير نشط",
      [LeaveStatusEum.NotRequired]: "غير مطلوب",
      [LeaveStatusEum.Cancelled]: "تم الإلغاء",
      [LeaveStatusEum.Hold]: "في الانتظار",
    },
  },
  loan: {
    loan: "قرض",
    createLoan: "إنشاء قرض",
    loanTenure: "مدة القرض (بالشهور)",
    enterLoanTenure: "أدخل مدة القرض (بالشهور)",
    deduction: "الخصم/الشهور",
    selectLoanPurpose: "اختر غرض القرض",
    noDataMessage: "– أنشئ قروض الآن!",
    SortBy: "رتب حسب",
    SelectSortBy: "اختر الترتيب حسب",
    InterestRate: "معدل الفائدة",
    loanPurpose: {
      loanPurpose: "هدف القرض",
      vehicle: "مركبة",
      personal: "شخصي",
      wedding: "زواج",
      medical: "طبي",
      education: "تعليم",
      house: "منزل",
      other: "آخر",
    },
    loanPurposeEnumList: {
      [LoanPurposeEnum.Personal]: "شخصي",
      [LoanPurposeEnum.Wedding]: "زواج",
      [LoanPurposeEnum.Medical]: "طبي",
      [LoanPurposeEnum.Education]: "تعليم",
      [LoanPurposeEnum.House]: "منزل",
      [LoanPurposeEnum.Vehicle]: "مركبة",
      [LoanPurposeEnum.Other]: "آخر",
    },
    loanFilterTypeEnum: {
      [LoanFilterTypeEnum.All]: "جميع القروض",
      [LoanFilterTypeEnum.ForApproval]: "للموافقة",
      [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "قروضي",
      [LoanFilterTypeEnum.LoanForMe]: "قرض لي",
    },
    statusEnum: {
      [LoanStatusEnum.InProcess]: "قيد التنفيذ",
      [LoanStatusEnum.Approved]: "تم الموافقة عليه",
      [LoanStatusEnum.Declined]: "تم الرفض",
      [LoanStatusEnum.Cancelled]: "تم الإلغاء",
      [LoanStatusEnum.Disbursed]: "صرف",
      [LoanStatusEnum.PartialCleared]: "تم التسوية جزئياً",
      [LoanStatusEnum.Completed]: "اكتمل",
      [LoanStatusEnum.Hold]: "تم الإيقاف",
      [LoanStatusEnum.Disbursed]: "في انتظار الصرف",
    },
    loanTypeEnum: {
      [LoanTypeEnum.Company]: "قرض الشركة",
      [LoanTypeEnum.WorkWise]: "قرض ورك وايز",
    },
  },
  quotations: {
    Quotation: "عرض سعر (Quotation)",
    CreateQuotation: "إنشاء عرض سعر (Create Quotation)",
    quotationDate: "تاريخ (Date)",
    ClientName: "اسم العميل (Client's Name)",
    ClientEmail: "بريد العميل الإلكتروني (Client's Email)",
    PhoneNumber: "رقم الهاتف (Phone Number)",
    TotalAmount: "المبلغ الإجمالي (Total Amount)",
    QuotationType: "نوع العرض (Type)",
    Rate: "السعر (Rate)",
    Quantity: "الكمية (Quantity)",
    Attachments: "المرفقات (Attachments)",
    serviceItem: "الخدمة/العنصر",
    price: "السعر",
    tax: "الضريبة (%)",
    taxAmount: "مبلغ الضريبة",
    sno: "رقم السلسلة",
    EnterClientName: "أدخل اسم العميل",
    clientsName: "اسم العميل",
    EnterClientEmail: "أدخل بريد العميل الإلكتروني",
    addressLine1: "العنوان السطر 1",
    addressLine2: "العنوان السطر 2",
    EnterAddress1: "أدخل العنوان 1:",
    EnterAddress2: "أدخل العنوان 2:",
    Product: "المنتج",
    Service: "الخدمة",
    netAmount: "المبلغ الصافي",
    CODE: "الكود",
    DATE: "التاريخ",
    TOTAL: "المجموع",
    To: "إلى",
    SubTotal: "المجموع الفرعي",
    noDataMessage: "– أنشئ عرض الأسعار الآن!",
    quotationFilterTypeEnum: {
      [QuotationFilterEnum.All]: "جميع العروض (Quotations)",
      [QuotationFilterEnum.CreatedByMe]: "تم إنشاؤها بواسطتي (Created By Me)",
      [QuotationFilterEnum.ForApproval]: "للموافقة (For Approval)",
    },
    statusEnum: {
      [QuotationStatusEnum.InProcess]: "قيد التنفيذ (In Process)",
      [QuotationStatusEnum.Approved]: "تمت الموافقة عليه (Approved)",
      [QuotationStatusEnum.Declined]: "تم الرفض (Declined)",
      [QuotationStatusEnum.Cancelled]: "تم الإلغاء (Cancelled)",
      [QuotationStatusEnum.Hold]: "تم التعليق (Hold)",
      [QuotationStatusEnum.ClientApproved]: "موافقة العميل",
      [QuotationStatusEnum.ClientRejected]: "رفض العميل",
    },
    SortEnum: {
      [QuotationFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [QuotationFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء - تصاعدي",
      [QuotationFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلي",
      [QuotationFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث - تصاعدي",
      [QuotationFilterSortEnum.ReferenceNo]: "الرقم المرجعي",
      [QuotationFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلي"
    },
    ErrorFieldMessage:{
      [InputFieldTypes.PRICE]: "يجب أن يكون السعر أكثر من 0",
      [InputFieldTypes.QUANTITY]: "يجب أن يكون الكمية أكثر من 0",
      [InputFieldTypes.TAX]: "يجب أن يكون الضريبة أكثر من 0",
    },
    singleItemError: "يرجى إضافة عنصر واحد على الأقل إلى العرض",

  },
  payroll: {
    Payroll: "كشوف المرتبات",
    createPayroll: "إنشاء كشف المرتبات",
    noDataMessage: "– أنشئ كشف المرتبات الآن!",
    basicSalary: "الراتب الأساسي",
    loan: "قرض",
    allowance: "بدل",
    ExpenseReimbursement: "تعويض المصروفات",
    deduction: "الخصم",
    tax: "الضريبة",
    rebate: "الخصم",
    bonus: "العلاوة",
    selectMonthYear: "حدد الشهر والسنة",
    dispereseDate: "تاريخ التوزيع",
    Voucher: "إيصال",
    DownloadtoExcel: "تحميل إلى Excel",
    PayrollDetail: "تفاصيل كشف المرتبات",
    TransactionMode: "وضعية العملية",
    IBANNumber: "رقم الحساب البنكي الدولي (IBAN)",
    statusEnum: {
      [PayrollStatusEnum.InProcess]: "قيد التنفيذ",
      [PayrollStatusEnum.Approved]: "تمت الموافقة",
      [PayrollStatusEnum.Declined]: "تم الرفض",
      [PayrollStatusEnum.Resend]: "إعادة الإرسال",
      [PayrollStatusEnum.Inactive]: "غير نشط",
      [PayrollStatusEnum.NotRequired]: "غير مطلوب",
      [PayrollStatusEnum.Cancelled]: "تم الإلغاء",
      [PayrollStatusEnum.Hold]: "في الانتظار",
      [PayrollStatusEnum.Disbursed]: "صرف",
      [PayrollStatusEnum.Completed]: "اكتمل",
    },
    payrollFilterTypeEnum: {
      [PayrollFilterTypeEnum.All]: "الرواتب",
      [PayrollFilterTypeEnum.CreatedByMe]: "تم الإنشاء بواسطتي",
      [PayrollFilterTypeEnum.ForApproval]: "للموافقة",
    },
  },
  voucher: {
    createVoucher: "إنشاء فاتورة",
    SelectVoucherType: "اختر نوع الفاتورة",
    BasicSalary: "الراتب الأساسي",
    LoanPayment: "دفع القرض",
    LoanDispersable: "القرض القابل للتوزيع",
    Allowance: "البدلات",
    ExpenseReimbursement: "تعويض المصروفات",
    Deduction: "الخصم",
    VoucherDetail: "تفاصيل الفاتورة",
    SavePrint: "حفظ وطباعة",
    Bonus: "العلاوة",
    Rebate: "الخصم",
    Tax: "الضريبة",
    ChequeNo: "رقم الشيك",
    Account: "الحساب",
    Narration: "الشرح",
    Debit: "مدين",
    Credit: "دائن",
    Dr: "مدين",
    Cr: "دائن",
    differences: "الاختلافات",
    drCr: "مدين/دائن",
    LinkVoucher: "ربط الفاتورة",
    PostVoucher: "نشر الفاتورة",
    voucherDate: "تاريخ القسيمة",
    VoucherType: "نوع القسيمة",

    voucherTypeList: {
      [voucherTypeEnum.GeneralVoucher]: "فاتورة عامة",
      [voucherTypeEnum.PaymentVoucher]: "فاتورة الدفع",
      [voucherTypeEnum.ReceiptVoucher]: "فاتورة الاستلام",
    },
  },
  trialBalance: {
    accountTypeList: {
      [accountTypeEnum.Asset]: "أصل",
      [accountTypeEnum.Capital]: "رأس المال",
      [accountTypeEnum.Expense]: "مصروف",
      [accountTypeEnum.Liability]: "التزام",
      [accountTypeEnum.Revenue]: "إيراد",
    },
  },
  disperse: {
    transactionModeEnumList: {
      [TransactionModeTypeEnum.Cash]: "نقدًا",
      [TransactionModeTypeEnum.BankTransfer]: "تحويل بنكي",
      [TransactionModeTypeEnum.Cheque]: "شيك",
    },
  },
  promotion: {
    CreatePromotion: "إنشاء ترقية",
    Promotion: "ترقية",
    promotionTo: "ترقية إلى",
    noDataMessage: "قم بإنشاء ترقية الآن!",
    selectPromotionTo: "حدد ترقية إلى",
    pleseSelectPromotionTo: "يرجى تحديد ترقية إلى",
    createPromotion: "إنشاء ترقية",
    promotionFilterTypeEnum: {
      [PromotionFilterTypeEnum.All]: "كل الترقيات",
      [PromotionFilterTypeEnum.CreatedByMe]: "تم إنشاؤها بواسطتي",
      [PromotionFilterTypeEnum.ForApproval]: "للموافقة",
      [PromotionFilterTypeEnum.PromotionForMe]: "ترقياتي",
    },
    statusEnum: {
      [PromotionStatusEnum.InProcess]: "قيد التنفيذ",
      [PromotionStatusEnum.Approved]: "تمت الموافقة عليها",
      [PromotionStatusEnum.Declined]: "تم رفضها",
      [PromotionStatusEnum.Cancelled]: "تم الإلغاء",
      [PromotionStatusEnum.Hold]: "في الانتظار",
    },
  },
  warnings: {
    inprogress: "جاری ہے",
    filter: "فلٹر",
    approvers: "منظوری دینے والے",
    createwarnings: "انتباہ بنائیں",
    warnings: "انتباہ ",
  },
  complains: {
    createComplain: "إنشاء شكوى",
    myComplain: "شكاويي",
    AllComplains: "جميع الشكاوي",
    complainOf: "شكوى من",
    ComplainDetail: "تفاصيل الشكوى",
    ComplainToMe: "شكوى لي",
    ComplainAboutMe: "شكوى حولي",
    noDataMessage: " - أنشئ الشكاوي الآن!",
    statusEnum: {
      [ComplainApprovalStatusEnum.InProcess]: "قيد التنفيذ",
      [ComplainApprovalStatusEnum.Approved]: "تمت الموافقة عليها",
      [ComplainApprovalStatusEnum.Declined]: "تم رفضها",
      [ComplainApprovalStatusEnum.Resend]: "إعادة الإرسال",
      [ComplainApprovalStatusEnum.Inactive]: "غير نشطة",
      [ComplainApprovalStatusEnum.NotRequired]: "غير مطلوبة",
      [ComplainApprovalStatusEnum.Cancelled]: "تم الإلغاء",
      [ComplainApprovalStatusEnum.Hold]: "في الانتظار",
    },
    SortEnum: {
      [ComplainFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [ComplainFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء",
      [ComplainFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلي",
      [ComplainFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث",
      [ComplainFilterSortEnum.ReferenceNo]: "الرقم المرجعي",
      [ComplainFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلي"
    }

  },
  salary: {
    SalaryMembers: "أعضاء الراتب",
    EffectiveDate: "تاريخ النفاذ",
    BasicSalary: "الراتب الأساسي",
    Allowances: "البدلات",
    Allowance: "البدل",
    AllowanceDetail: "تفصيل البدل",
    IsTaxable: "هل خاضع للضريبة",
    Deduction: "الخصم",
    GrossSalary: "الراتب الإجمالي",
    CreateSalary: "إنشاء راتب",
    SalaryDetail: "تفصيل الراتب",
    AddSalary: "إضافة راتب",
    Increment: "الزيادة",
    Deductions: "الخصومات",
    AllowanceType: "نوع البدل",
    EffectiveDateIsRequired: "تاريخ النفاذ مطلوب",
    BasicSalaryIsRequired: "الراتب الأساسي مطلوب",
    Negativesalaryisnotallowed: "غير مسموح بالراتب السالب",
    Benefit: "الفائدة",
    noDataMessage: " - أنشئ راتبًا الآن!",
    EnterIncrementPercentage: "الرجاء إدخال نسبة الزيادة",
    salaryFilterTypeEnum: {
      [SalaryFilterTypeEnum.Salaries]: "الرواتب",
      [SalaryFilterTypeEnum.CreateByMe]: "تم إنشاؤها بواسطتي",
      [SalaryFilterTypeEnum.ForApproval]: "للموافقة",
    },
    statusEnum: {
      [EmployeeSalaryStatusEnum.InProcess]: "قيد التنفيذ",
      [EmployeeSalaryStatusEnum.Approved]: "تمت الموافقة عليه",
      [EmployeeSalaryStatusEnum.Declined]: "تم الرفض",
      [EmployeeSalaryStatusEnum.Cancelled]: "تم الإلغاء",
      [EmployeeSalaryStatusEnum.Hold]: "تم الإيقاف",
      [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "في انتظار الموافقة الأخرى",
    },
    SortEnum: {
      [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلي",
      [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء",
      [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلي",
      [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث",
      [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "الرقم المرجعي - تنازلي",
      [EmployeeSalaryFilterSortEnum.ReferenceNo]: "الرقم المرجعي"
    }

  },
  resignations: {
    resignationHeader: "استقالات",
    resignationDate: "تاريخ الاستقالة",
    createresignation: "إنشاء استقالة",
    noDataMessage: "– قم بإنشاء استقالة الآن!",
    reasonforresignation: "سبب الاستقالة",
    istermination: "هل هو إنهاء",
    resignationDetail: "تفاصيل الاستقالة",
    statusEnum: {
      [ResignationApprovalStatusEnum.InProcess]: "قيد التنفيذ",
      [ResignationApprovalStatusEnum.Approved]: "تم الموافقة عليه",
      [ResignationApprovalStatusEnum.Declined]: "تم رفضه",
      [ResignationApprovalStatusEnum.Cancelled]: "تم الإلغاء",
      [ResignationApprovalStatusEnum.Completed]: "اكتمل",
      [ResignationApprovalStatusEnum.Hold]: "في الانتظار",
    },
    resignationFilterTypeEnum: {
      [ResignationFilterTypeEnum.Resignation]: "استقالة",
      [ResignationFilterTypeEnum.CreateByMe]: "تم إنشاؤها بواسطتي",
      [ResignationFilterTypeEnum.ForApproval]: "للموافقة",
    },
    resignationTypeEnum: {
      [ResignationTypeEnum.Resignation]: "استقالة",
      [ResignationTypeEnum.Termination]: "إنهاء",
    },
  },
  Employees: {
    employeeAttachments: "مرفقات الموظف",
    CreateEmployeeNow: "إنشاء موظف الآن",
    ProbationPeriod: "فترة التجربة (أيام)",
    requiredMessageProbation: "يرجى إدخال فترة التجربة الخاصة بك",
    requiredMessageProbationPeriod: "يجب أن تكون فترة التجربة بين 0 و 30 يومًا",
    EmployeeNo: "رقم الموظف",
    EnterEmployeeNo: "أدخل رقم الموظف",
    EmploymentType: "نوع التوظيف",
    SelectEmploymentType: "اختر نوع التوظيف",
    requiredMessageEmploymentType: "يرجى تحديد نوع التوظيف",
    OfficeShift: "وردية العمل",
    requiredMessageOfficeShift: "يرجى تحديد وردية العمل",
    SelectOfficeShift: "اختر وردية العمل",
    UpdateBasicInfo: "تحديث المعلومات الأساسية",
    Degree: "الدرجة العلمية",
    EnterDegree: "أدخل الدرجة العلمية",
    requiredDegree: "يرجى إدخال الدرجة العلمية",
    Institute: "المعهد",
    EnterInstituteName: "أدخل اسم المعهد",
    requiredMessageInstitute: "يرجى إدخال اسم المعهد",
    ObtainedMarks: "الدرجات المحصلة",
    requiredMessageObtained: "يرجى إدخال الدرجات المحصلة",
    EnterObtainedMarks: "أدخل الدرجات المحصلة",
    TotalMarks: "الدرجات الكلية",
    EnterTotalMarks: "أدخل الدرجات الكلية",
    requiredMessageMarks: "يرجى إدخال الدرجات الكلية",
    UpdateEducation: "تحديث التعليم",
    AddEducation: "إضافة تعليم",
    WorkExperienceInfo: "معلومات الخبرة العملية",
    UpdateExperience: "تحديث الخبرة",
    AddExperience: "إضافة خبرة",
    EmergencyInfo: "معلومات الطوارئ",
    UpdateEmergencyForm: "تحديث نموذج الطوارئ",
    AddEmergency: "إضافة حالة طوارئ",
    UpdateEmergency: "تحديث الطوارئ",
    UpdateBank: "تحديث البنك",
    AddBank: "إضافة بنك",
    EnableEmployee: "هل أنت متأكد من تمكين هذا الموظف؟",
    DisableEmployee: "هل أنت متأكد من تعطيل هذا الموظف؟",
    Enable: "تمكين",
    Disabled: "معطل",
    UpdateFamily: "تحديث العائلة",
    AddFamily: "إضافة العائلة",
    AddEmployeesLinkage: "إضافة ربط الموظفين",
    AllEmployees: "كل الموظفين",
    FamilyInfo: "معلومات العائلة",
    Basic_Info: "معلومات أساسية",
    Email_Configuration: "تكوين البريد الإلكتروني",
    Bank_Detail: "تفاصيل البنك",
    Education: "التعليم",
    Emergency_Info: "معلومات الطوارئ",
    Work_Experience: "الخبرة العملية",
    Experience: "الخبرة",
    Rebate: "الخصم",
    Salary: "الراتب",
    Leaves: "الإجازات",
    Devices: "الأجهزة",
    basicInfo: "معلومات أساسية",
    Appraisal: "التقييم",
    Family: "العائلة",
    Attachments: "المرفقات",
    My_Team: "فريقي",
    Employees_Linkage: "ربط الموظفين",
    Emails: "البريد الإلكتروني",
    Finance: "مالية",
    Goal: "هدف",
    RebateInfo: "معلومات الخصم",
    UpdateRebate: "تحديث الخصم",
    AddRebate: "إضافة خصم",
    AddSalary: "إضافة راتب",
    Statistics: "الإحصاء"

  },
  rewards: {
    PleaseEnterAwardName: "منظوری دینے والے",
    approvers: "منظوری دینے والے",
    createComplain: "شکایت کریں",
  },
  bonus: {
    Bonus: "مكافأة",
    createBonus: "إنشاء مكافأة",
    BonusTo: "المكافأة لـ",
    CurrentSalary: "الراتب الحالي",
    noDataMessage: "– أنشئ مكافأة الآن!",
    PercentageShouldNotExceed: "النسبة المئوية لا يجب أن تتجاوز 100",
    bonusFilterTypeEnum: {
      [BonusFilterTypeEnum.All]: "كل المكافآت",
      [BonusFilterTypeEnum.CreatedByMe]: "تم إنشاؤها بواسطتي",
      [BonusFilterTypeEnum.ForApproval]: "للموافقة",
      [BonusFilterTypeEnum.MyBonus]: "مكافأتي",
    },
    statusEnum: {
      [BonusStatusEnum.InProcess]: "قيد التنفيذ",
      [BonusStatusEnum.Approved]: "تمت الموافقة",
      [BonusStatusEnum.Declined]: "تم الرفض",
      [BonusStatusEnum.Cancelled]: "تم الإلغاء",
      [BonusStatusEnum.Inactive]: "غير نشط",
      [BonusStatusEnum.NotRequired]: "غير مطلوب",
      [BonusStatusEnum.Resend]: "إعادة الإرسال",
      [BonusStatusEnum.WaitingForOtherApproval]: "في انتظار موافقة أخرى",
      [BonusStatusEnum.Hold]: "في الانتظار",
      [BonusStatusEnum.Disbursed]: "صرف",
    },
    SortEnum: {
      [BonusFilterSortEnum.CreateDateDesc]: "تاريخ الإنشاء - تنازلياً",
      [BonusFilterSortEnum.CreateDateAsc]: "تاريخ الإنشاء",
      [BonusFilterSortEnum.UpdateDateDesc]: "تاريخ التحديث - تنازلياً",
      [BonusFilterSortEnum.UpdateDateAsc]: "تاريخ التحديث",
      [BonusFilterSortEnum.ReferenceNoDesc]: "رقم الإشارة - تنازلياً",
      [BonusFilterSortEnum.ReferenceNo]: "رقم الإشارة",
      [BonusFilterSortEnum.Amount]: "المبلغ",
      [BonusFilterSortEnum.AmountDesc]: "المبلغ - تنازلياً",
      [BonusFilterSortEnum.Salary]: "الراتب",
      [BonusFilterSortEnum.SalaryDesc]: "الراتب - تنازلياً"
    }
  },
  chartOfAccount: {
    ChartOfAccount: "جدول الحسابات",
    CreateAccount: "إنشاء حساب",
    CreateChartOfAccount: "إنشاء جدول للحسابات",
    accountType: "نوع الحساب",
    selectType: "اختر النوع",
    parentAccount: "الحساب الأصل",
    selectParent: "اختر الأصل",
    EnterAccountType: "يرجى إدخال نوع الحساب",
    clossingBalance: "الرصيد الختامي",
  },
  ledgerReports: {
    reports: "التقارير",
    selectAccount: "اختر الحساب",
    selectDate: "اختر التاريخ",
    generateReport: "إنشاء تقرير",
    VoucherNo: "رقم السند",
  },
  requisitions: {
    requisition: "الطلبات",
    MyRequisitions: "طلباتي",
    createRequisition: "إنشاء طلب",
    PleaseEnterRequisitionName: "الرجاء إدخال اسم الطلب",
    EnterRequisitionName: "أدخل اسم الطلب",
    Budget: "الميزانية",
    EnterBudget: "أدخل الميزانية",
    Dateofrequestexpire: "تاريخ انتهاء الطلب",
    noDataMessage: "– أنشئ طلبات الآن!"
  },
  DefaultApprovers: {
    TypeEnum: {
      [DefaultApprovalTypeEnum.Expense]: "المصاريف",
      [DefaultApprovalTypeEnum.ExpenseFinancers]: "ممولي المصاريف",
      [DefaultApprovalTypeEnum.Travel]: "السفر",
      [DefaultApprovalTypeEnum.TravelAgent]: "وكيل السفر",
      [DefaultApprovalTypeEnum.Loan]: "القرض",
      [DefaultApprovalTypeEnum.Leave]: "الإجازة",
      [DefaultApprovalTypeEnum.Asset]: "الأصول",
      [DefaultApprovalTypeEnum.Salary]: "الراتب",
      [DefaultApprovalTypeEnum.Payroll]: "المرتبات",
      [DefaultApprovalTypeEnum.Reward]: "المكافأة",
      [DefaultApprovalTypeEnum.ResignationHr]: "استقالة الموارد البشرية",
      [DefaultApprovalTypeEnum.ResignationAdmin]: "استقالة الإدارة",
      [DefaultApprovalTypeEnum.ResignationIt]: "استقالة تقنية المعلومات",
      [DefaultApprovalTypeEnum.ResignationFinance]: "استقالة مالية",
      [DefaultApprovalTypeEnum.ResignationExit]: "استقالة خروج",
      [DefaultApprovalTypeEnum.Requisition]: "الطلب",
      [DefaultApprovalTypeEnum.CustomApproval]: "الموافقة المخصصة",
      [DefaultApprovalTypeEnum.Forms]: "النماذج",
      [DefaultApprovalTypeEnum.Appraisals]: "التقييمات",
      [DefaultApprovalTypeEnum.Promotion]: "الترقية",
      [DefaultApprovalTypeEnum.Warning]: "تحذير",
      [DefaultApprovalTypeEnum.Bonus]: "المكافأة الإضافية",
      [DefaultApprovalTypeEnum.Complains]: "الشكاوي",
      [DefaultApprovalTypeEnum.Career]: "الحياة المهنية",
      [DefaultApprovalTypeEnum.Quotations]: "الاقتباسات",
      [DefaultApprovalTypeEnum.RequisitionFinal]: "الطلب النهائي",
    },
  },
  NonMandatoryApprovers: {
    TypeEnum: {
      [NonMandatoryApprovalTypeEnum.Expense]: "المصروفات",
      [NonMandatoryApprovalTypeEnum.Travel]: "السفر",
      [NonMandatoryApprovalTypeEnum.Loan]: "القرض",
      [NonMandatoryApprovalTypeEnum.Leave]: "الإجازة",
      [NonMandatoryApprovalTypeEnum.Asset]: "الأصل",
      [NonMandatoryApprovalTypeEnum.Salary]: "الراتب",
      [NonMandatoryApprovalTypeEnum.Payroll]: "كشوف المرتبات",
      [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "ممولو المصروفات",
      [NonMandatoryApprovalTypeEnum.TravelAgent]: "وكيل السفر",
      [NonMandatoryApprovalTypeEnum.Reward]: "المكافأة",
      [NonMandatoryApprovalTypeEnum.ResignationHr]: "استقالة الموارد البشرية",
      [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "استقالة الإدارة",
      [NonMandatoryApprovalTypeEnum.ResignationIt]: "استقالة تكنولوجيا المعلومات",
      [NonMandatoryApprovalTypeEnum.ResignationFinance]: "استقالة الشؤون المالية",
      [NonMandatoryApprovalTypeEnum.ResignationExit]: "استقالة الخروج",
      [NonMandatoryApprovalTypeEnum.Requisition]: "طلب",
      [NonMandatoryApprovalTypeEnum.Warning]: "تحذير",
      [NonMandatoryApprovalTypeEnum.Complain]: "شكوى",
      [NonMandatoryApprovalTypeEnum.Bonus]: "مكافأة",
      [NonMandatoryApprovalTypeEnum.Promotion]: "ترقية",
      [NonMandatoryApprovalTypeEnum.RequestForItem]: "طلب العنصر",
      [NonMandatoryApprovalTypeEnum.CustomApproval]: "الموافقة المخصصة",
      [NonMandatoryApprovalTypeEnum.Form]: "نموذج",
      [NonMandatoryApprovalTypeEnum.Document]: "مستند",
      [NonMandatoryApprovalTypeEnum.Career]: "المهنة",
      [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "موافقة الطلب النهائية",
      [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "طلب العنصر مراقب الأصول",
      [NonMandatoryApprovalTypeEnum.Quotation]: "عرض الأسعار",
      [NonMandatoryApprovalTypeEnum.QuotationClient]: "عرض الأسعار للعميل",
      [NonMandatoryApprovalTypeEnum.Appraisal]: "التقييم",
    }
  },
  activityLog: {
    DeviceType: "نوع الجهاز",
    DeviceToken: "رمز الجهاز",
    OSVersion: "إصدار النظام الأساسي",
    Device: "الجهاز",
    IP: "عنوان",
    Comment: "تعليق",
    DeviceName: "اسم الجهاز",
    LoginDate: "تاريخ الدخول",
  },
  ErrorMessage: {
    someThingWentWrong: "حدث خطأ ما"
  },
  setting: {
    BasicInformation: "المعلومات الأساسية",
    ChangePassword: "تغيير كلمة المرور",
    Notifications: "الإشعارات",
    Mobile: "رقم الجوال",
    About: "حول",
    ExistingPassword: "كلمة المرور الحالية",
    EnterExistingPassword: "ادخل كلمة المرور الحالية",
    NewPassword: "كلمة المرور الجديدة",
    EnterNewPassword: "ادخل كلمة المرور الجديدة",
    ConfirmPassword: "تأكيد كلمة المرور",
    EnterConfirmPassword: "ادخل تأكيد كلمة المرور",
    Signature: "توقيع الوثيقة",
    NoSignature: "الرجاء إنشاء توقيع",
    ErrorSignature: "خطأ في إنشاء التوقيع",
    NoSignatureSave: "لا يوجد توقيع لحفظه",
    SignatureSaved: "تم حفظ التوقيع",
    SaveSignature: "حفظ التوقيع",
    ClearSignature: "مسح التوقيع",
    SignatureCreated: "تم إنشاء التوقيع",
    SignatureActive: "نشط",
    FailedSignature: "فشل في جلب التواقيع",
    BlockNotificationFeatureTypeEnum: {
      [NotificationBlockFeatureTypeEnum.Reward]: "مكافأة",
      [NotificationBlockFeatureTypeEnum.Feed]: "تغذية",
      [NotificationBlockFeatureTypeEnum.Document]: "مستند",
      [NotificationBlockFeatureTypeEnum.Project]: "مشروع",
      [NotificationBlockFeatureTypeEnum.Task]: "مهمة",
      [NotificationBlockFeatureTypeEnum.WorkBoard]: "لوحة العمل",
      [NotificationBlockFeatureTypeEnum.Schedule]: "جدول",
      [NotificationBlockFeatureTypeEnum.Group]: "مجموعة",
      [NotificationBlockFeatureTypeEnum.Messenger]: "رسول",
      [NotificationBlockFeatureTypeEnum.Expense]: "نفقة",
      [NotificationBlockFeatureTypeEnum.Auction]: "مزاد",
      [NotificationBlockFeatureTypeEnum.Leave]: "إجازة",
      [NotificationBlockFeatureTypeEnum.Travel]: "السفر",
      [NotificationBlockFeatureTypeEnum.Warning]: "تحذير",
      [NotificationBlockFeatureTypeEnum.Complain]: "شكوى",
      [NotificationBlockFeatureTypeEnum.Bonus]: "مكافأة",
      [NotificationBlockFeatureTypeEnum.Promotion]: "ترقية",
      [NotificationBlockFeatureTypeEnum.Loan]: "قرض",
      [NotificationBlockFeatureTypeEnum.CustomApproval]: "موافقة مخصصة",
      [NotificationBlockFeatureTypeEnum.Form]: "نموذج",
      [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "راتب الموظف",
      [NotificationBlockFeatureTypeEnum.Payroll]: "كشف المرتبات",
      [NotificationBlockFeatureTypeEnum.Career]: "مهنة",
      [NotificationBlockFeatureTypeEnum.Requisition]: "طلب",
      [NotificationBlockFeatureTypeEnum.Resignation]: "استقالة",
      [NotificationBlockFeatureTypeEnum.Quotation]: "اقتباس",
      [NotificationBlockFeatureTypeEnum.Appraisal]: "تقدير",
      [NotificationBlockFeatureTypeEnum.LeadManager]: "مدير الرصاص",
      [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "لوحة المناقشة",
      [NotificationBlockFeatureTypeEnum.Pages]: "صفحات",
    }
  },
  emailConfiguration: {
    emailConfiHeader: "تكوين البريد الإلكتروني للمستخدم",
    emailConfi: "اسم المستخدم",
    password: "كلمة المرور",
    enterEmailConfi: "ادخل اسم المستخدم",
    incomingPort: "منفذ الوارد",
    enterIncomingPort: "ادخل منفذ الوارد",
    incomingServerAddress: "عنوان خادم الوارد",
    enterIncomingServerAddress: "ادخل عنوان خادم الوارد",
    outgoingPort: "منفذ الصادر",
    enterOutgoingPort: "ادخل منفذ الصادر",
    outgoingServerAddress: "عنوان خادم الصادر",
    enterOutgoingServerAddress: "ادخل عنوان خادم الصادر",
    provider: "مزود الخدمة",
    enterProvider: "ادخل مزود الخدمة",
    saveEmailConfi: "حفظ تكوين البريد الإلكتروني",
    addEmailConfi: "إضافة تكوين البريد الإلكتروني",
    name: "الاسم",
    clear: "مسح",
  },
  assetsAllocation: {
    assetsAllocation: "توزيع الأصول",
    assests: "الأصول",
    createAssetAllocation: "إنشاء توزيع الأصول",
    handover: "تسليم",
    forMaintenance: "للصيانة",
    noResultFound: "لم يتم العثور على نتائج...",
    addAssetsAllocation: "إضافة توزيع الأصول",
    noDataMessage: "– أنشئ توزيع الأصول الآن!",
    allocationForMe: "توزيع لي",
    allocationApprovals: "الموافقات على التوزيع",
    deAllocation: "تغيير حالة التوزيع",
    liability: "الالتزام",
    capital: "رأس المال",
    revenue: "الإيرادات",
    expense: "المصروفات",
    costOfGoodsale: "تكلفة البضائع المباعة",
    assetsCategory: "فئة الأصول",
    parentAccount: "الحساب الرئيسي",
    selectParent: "اختر رئيسي",
    assetsList: "قائمة الأصول",
    createAssests: 'إنشاء الأصول',
    sno: 'رقم',
    inventoryName: 'اسم المخزون',
    inventoryValue: 'قيمة المخزون',
    SelectAssetsController: "اختر مراقب الأصول",
  },
  requestListItems: {
    noDataMessage: "– أنشئ طلب الآن!",
    requestForItems: "طلب للبنود",
    RequestForItemsApprovals: "الموافقات على طلب البنود",
    AssetController: "مراقب الأصول",
    RequestItem: "طلب بند",
  },
  profile: {
    firstMenu: 'البند الأول في القائمة',
    secondMenu: 'البند الثاني في القائمة (معطل)',
    thirdMenu: 'البند الثالث في القائمة (معطل)',
    dangerItem: 'بند خطير',
    NoPosition: "لا يوجد منصب",
    NoInstitute: "لا يوجد معهد",
    NotFound: "لم يتم العثور عليه",
    University: "جامعة",
    School: "مدرسة",
    Graduated: "تخرج",
    CurrentlyWorkHere: "أنا أعمل هنا حاليا",
    Statistics: "إحصائيات",
    StorageStatistics: "إحصائيات التخزين",
    TotalStorageUsed: "إجمالي التخزين المستخدم"
  },

  /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
  status: {
    InProcess: "قيد التنفيذ",
    Approved: "تمت الموافقة عليه",
    Declined: "تم رفضه",
    Resend: "إعادة الإرسال",
    Inactive: "غير نشط",
    NotRequired: "غير مطلوب",
    Cancelled: "تم الإلغاء",
    ApprovalRequired: "الموافقة مطلوبة",
    Hold: "معلق",
    WaitingForOtherApprover: "في انتظار الموافقة من الموافقين الآخرين",
    Draft: "مسودة",
    Remark: "ملاحظة",
    Removed: "تمت الإزالة"
  },
  approvalDictionaryLabel: {
    Approval: "الموافقة",
    [ApprovalsModuleEnum.ExpenseExecutor]: "التنفيذي",
    [ApprovalsModuleEnum.ExpenseFinance]: "المالية",
    [ApprovalsModuleEnum.ResignationHrApproval]: "الموارد البشرية",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "المالية",
    [ApprovalsModuleEnum.ResignationItApproval]: "تكنولوجيا المعلومات",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "الإدارة",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "موافقات أخرى",
    [ApprovalsModuleEnum.ResignationExitApproval]: "مقابلة خروج",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "تقديم تقرير لـ",
    [ApprovalsModuleEnum.TravelApproval]: "الموافقات",
    [ApprovalsModuleEnum.TravelAgent]: "الوكيل",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "الموافقات النهائية",
    [ApprovalsModuleEnum.LoanApproval]: "الموافقات",
    [ApprovalsModuleEnum.AuctionApproval]: "الموافقات"

  },

  approvalDictionaryPlaceHolder: {
    Approval: "الموافقة",
    [ApprovalsModuleEnum.ExpenseExecutor]: "حدد المنفذ للمصاريف",
    [ApprovalsModuleEnum.ExpenseFinance]: "حدد الموافقين من المالية",
    [ApprovalsModuleEnum.ExpenseApproval]: "حدد موافقي المصاريف",
    [ApprovalsModuleEnum.ResignationHrApproval]: "حدد الموارد البشرية",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "حدد المالية",
    [ApprovalsModuleEnum.ResignationItApproval]: "حدد تكنولوجيا المعلومات",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "حدد الإدارة",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "حدد الموافقات الأخرى",
    [ApprovalsModuleEnum.ResignationExitApproval]: "حدد مقابلة الخروج",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "حدد التقديم التقرير لـ",
    [ApprovalsModuleEnum.TravelApproval]: "حدد الموافقات للسفر",
    [ApprovalsModuleEnum.TravelAgent]: "حدد الوكيل لترتيبات السفر",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "حدد الموافقات النهائية",
    [ApprovalsModuleEnum.LoanApproval]: "حدد الموافقات",
    [ApprovalsModuleEnum.AuctionApproval]: "حدد الموافقات"

  },

  /*** Define language write side ***/
  Direction: "rtl",
};
export default arabic;
