import React, { useContext, useEffect, useState } from "react";
import { Rate, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ItemContentCareers, SingleItem } from "../../../../../../sharedComponents/Card/CardStyle";
import CardProfileTopView from "../../../../../travel/view/UI/CardProfileTopView";
import AvatarCustom from "../../../../../../sharedComponents/Avatar/avatarOLD";
import DetailTabs from "../../../components/DetailedTabs";
import RemarksApproval from "../../../../../../sharedComponents/AppComponents/Approvals/view";
import { ApprovalsModule } from "../../../../../../sharedComponents/AppComponents/Approvals/enums";
import { getAllAppraisalByIdAction } from "../../../../store/action";
import { removeIdentifier } from "../../../../store/slice";
import { MODAL_TYPES } from "../../../../utils/appraisalModalTypeEnum";
import { CustomModal } from "../../../UI/appraisalCustomModal";
import StatusTag from "../../../UI/statusTag";
import { STRINGS } from "../../../../../../../utils/base";
import { NotificationFeatureTypeEnum } from "../../../../../notifiation/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import { readCountforAppraisal } from "../../../../store/slice";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";

function AppraisalDetail({ id, index }) {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { appraisal, sharedLabels } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const [state, setState] = useState({
    identifier: moment().unix(),
    appraisalDetail: {
      creator: {},
      approvers: [],
      comment: "",
      ratingAssign: 0,
      status: 1,
      startDate: "",
      isLoading: true,
      tasks: [],
      questions: [],
      user: {},
      userId: STRINGS.DEFAULTS.guid,
      referenceNo: "",
      bonus: 0,
      basicSalary: 0,
      bonusId: STRINGS.DEFAULTS.guid,
      salaryId: STRINGS.DEFAULTS.guid,
    },
    approvers: {
      isOpen: false,
      data: [],
    },
    customModalDetail: {
      type: null, // MODAL_TYPES.QUESTION or MODAL_TYPES.TASK or null
      open: false,
      data: null,
    },
  });

  const { data } = useSelector((state) => state.appraisalModuleSlice);
  let appraisalDetail = data !== null ? data[state.identifier] : [];
  appraisalDetail =
    appraisalDetail && appraisalDetail?.length > 0 && appraisalDetail[0];

  useEffect(() => {
    id && dispatch(getAllAppraisalByIdAction({ id: id, identifier: state.identifier }));
  }, [id]);


  const {
    approverStatus,
    approvers,
    comment,
    createdBy,
    creator,
    endDate,
    questions,
    ratingAssign,
    startDate,
    status,
    tasks,
    user,
    userId,
    referenceNo,
    bonus,
    basicSalary,
    notificationCount,
    bonusId,
    salaryId,
  } = state.appraisalDetail;

  //open custom Modal for tasks and questions
  const openCustomModal = (e, data, type) => {
    e.stopPropagation();

    setState({
      ...state,
      customModalDetail: {
        type: type,
        open: true,
        data: data,
      },
    });
  };

  //close custom Modal for tasks and questions
  const closeModal = () => {
    setState({
      ...state,
      customModalDetail: { ...state.customModalDetail, open: false, data: [] },
    });
  };

  useEffect(() => {
    appraisalDetail &&
      setState({
        ...state,
        appraisalDetail: {
          ...state.appraisalDetail,
          ...appraisalDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: appraisalDetail?.approvers,
        },
      });
  }, [appraisalDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Appraisal,
          featureId: id,
        },
      };
      dispatch(readNotificationAction(payload)).then((response) => {
        dispatch(readCountforAppraisal(id));
      });
    }
  }, [notificationCount]);

  if (state.appraisalDetail.isLoading) return <Skeleton />;

  return (
    <>
      <SingleItem className="cursor-pointer" >
        <CardProfileTopView
          profileImgSrc={
            <AvatarCustom
              width={40}
              height={40}
              src={creator?.image}
              name={creator?.name}
              round
            ></AvatarCustom>
          }
          createDate={startDate}
          isPublic={true}
          name={creator?.name}
          destination={
            creator && creator.designation
              ? creator.designation
              : "Not Designated"
          }
          refNo={referenceNo}
          status={<StatusTag status={status}></StatusTag>}
          profileImgSize={40}
        />
        <ItemContentCareers className="!h-[100px] !max-h-[100px]">
          <div className="flex justify-between items-center">
            <div className="font-bold text-[14px] text-primary-color">
              {sharedLabels.Comments}
            </div>
            <div className="rating">
              <Rate defaultValue={ratingAssign} disabled value={ratingAssign} />
            </div>
          </div>
          <p className="careersDescShort">{comment}</p>
        </ItemContentCareers>
        <div className="cardSections">
          <div className="cardSectionItem">
            <div className="cardSection__title">{appraisal.Appraisedof}</div>
            <div className="cardSection__body">{user?.name}</div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">{sharedLabels.createdDate}</div>
            <div className="cardSection__body">
              {moment(startDate).format("Do MMM YY")}
            </div>
          </div>
          {/* <div className="cardSectionItem">
            <div className="cardSection__title">{"Bonus"}</div>
            <div className="cardSection__body">
              {bonus ? Math.round(parseInt(bonus)) : "0"}
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">{"Increment"}</div>
            <div className="cardSection__body">
              {basicSalary ? basicSalary : "0"}
            </div>
          </div> */}

          <div className="cardSectionItem">
            <div className="cardSection__title">{appraisal.Questions}</div>
            <div className="cardSection__body">
              <div
                className="singleTag"
                onClick={(e) => openCustomModal(e, questions, MODAL_TYPES.QUESTION)}
              >{`Questions(${questions?.length})`}</div>
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">{appraisal.Tasks}</div>
            <div className="cardSection__body">
              <div
                className="singleTag"
                onClick={(e) => openCustomModal(e, tasks, MODAL_TYPES.TASK)}
              >{`Tasks(${tasks?.length})`}</div>
            </div>
          </div>
        </div>
      </SingleItem>
      <CustomModal
        type={state.customModalDetail.type}
        open={state.customModalDetail.open}
        setOpen={closeModal}
        data={state.customModalDetail.data}
      />
      <DetailTabs
        questions={questions}
        tasks={tasks}
        bonusId={bonusId}
        salaryId={salaryId}
        RemarksApproval={
          <>
            <RemarksApproval
              module={ApprovalsModule.AppraisalApproval}
              status={status}
              reference={id}
              data={approvers}
              title={appraisal.AppraisalApprovers}
              notRequiredMemberId={[userId]}
              itemCreator={creator}
            />
          </>
        }
      />
    </>
  );
}

export default AppraisalDetail;
